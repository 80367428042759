import { JsonWithoutNull, Optional } from "../types";

export type URLParamValue =
  | string
  | boolean
  | number
  | (string | undefined)[]
  | JsonWithoutNull;

export function getQueryParam<T extends URLParamValue>(
  paramName: string
): T | undefined {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has(paramName)) {
    const param = parseUrlParam(searchParams.get(paramName));
    return param as T;
  }
}

export function setQueryParam<T extends Optional<URLParamValue>>(
  paramName: string,
  value: T,
  asJson?: boolean
): void {
  const searchParams = new URLSearchParams(window.location.search);

  if (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0)
  ) {
    searchParams.delete(paramName);
  } else {
    const encodedParamValue = encodeUrlParam(value as URLParamValue, asJson);
    searchParams.set(paramName, encodedParamValue);
  }

  commitToUrl(searchParams);
}

export function commitToUrl(searchParams: URLSearchParams): void {
  searchParams.sort();
  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${searchParams.toString()}`
  );
}

function encodeUrlParam(value: URLParamValue, asJson?: boolean): string {
  if (asJson) {
    return `json-${encodeURI(JSON.stringify(value))}`;
  } else if (Array.isArray(value)) {
    return `(${(value as (string | undefined)[])
      .map((v) => String(v))
      .join(",")})`;
  } else if (typeof value === "boolean") {
    return String(value);
  } else if (typeof value === "number") {
    return String(value);
  } else if (typeof value === "string") {
    return value;
  }
  throw Error(
    `Invalid URL parameter value (${value}). If you want to endode JSON you must set the 'asJson' flag`
  );
}

const arrayRegex = "\\(([a-z0-9-_ /,]*)\\)";
const jsonRegex = "^json.*$";

export function parseUrlParam(value: string | null): Optional<URLParamValue> {
  if (value === null) return undefined;

  if (RegExp(jsonRegex).test(value)) {
    try {
      return JSON.parse(decodeURI(value.replace(RegExp("^json-"), "")));
    } catch (e) {
      console.error(`Failed to parse URL parameter ${value} as json`);
      return undefined;
    }
  } else if (RegExp(arrayRegex, "gi").test(value)) {
    const commaSeparatedList = RegExp(arrayRegex, "gi").exec(value)?.[1];
    if (commaSeparatedList) {
      return commaSeparatedList
        .split(",")
        .map((value) => (value === "undefined" ? undefined : value));
    } else {
      return undefined;
    }
  } else if (value === "true" || value === "false") {
    return Boolean(value);
  } else if (/^\d+$/.test(value)) {
    return parseInt(value, 10);
  }
  return value;
}
