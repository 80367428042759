import {
  DirectionalHint,
  IButtonProps,
  IButtonStyles,
  IconButton as FluentIconButton,
  ITooltipProps,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../constants";

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    background: NeutralColors.gray30,
    borderRadius: 0,
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
      "&.is-checked:hover": {
        background: `${NeutralColors.gray50} !important`,
      },
      "*": {
        color: NeutralColors.gray190,
      },
    },
  },
  icon: { root: { color: NeutralColors.gray190 } },
  rootHovered: {
    background: NeutralColors.gray40,
  },
  rootPressed: {
    background: NeutralColors.gray50,
  },
  rootDisabled: {
    background: NeutralColors.gray30,
  },
  iconDisabled: {
    color: `${NeutralColors.gray90} !important`,
  },
  rootChecked: {
    background: NeutralColors.gray50,
  },
};

const toolTipProps: ITooltipProps = {
  styles: {
    root: {
      background: NeutralColors.gray10,
      color: NeutralColors.gray160,
    },
  },
};

export const IconButton: FC<IButtonProps & { toolTipText?: string }> = ({
  styles,
  toolTipText,
  ...restProps
}) => {
  const mergedStyles = useMemo(() => mergeStyleSets(iconButtonStyles, styles), [
    styles,
  ]);
  // Use useId() to ensure that the ID is unique on the page.
  const tooltipId = useId("tooltip");

  if (toolTipText) {
    return (
      <TooltipHost
        content={toolTipText}
        id={tooltipId}
        directionalHint={DirectionalHint.rightCenter}
        tooltipProps={toolTipProps}
      >
        <FluentIconButton
          aria-describedby={tooltipId}
          styles={mergedStyles}
          {...restProps}
        ></FluentIconButton>
      </TooltipHost>
    );
  }
  return <FluentIconButton styles={mergedStyles} {...restProps} />;
};
