import {
  Depths,
  IButtonStyles,
  Icon,
  IIconProps,
  IIconStyles,
  NeutralColors,
  Stack,
  StackItem,
} from "@fluentui/react";
import React, { FC, useCallback, useState } from "react";
import { IconButton } from "../styledFluentComponents";

export enum AlertType {
  INFORMATION,
}

export interface AlertProps {
  type: AlertType;
  onClose?: () => void;
}

export const Alert: FC<AlertProps> = ({ type, onClose, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const onCloseHandler = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <Stack
      horizontal
      verticalAlign="center"
      styles={{
        root: {
          background: alertConfig[type].background,
          color: alertConfig[type].color,
          boxShadow: Depths.depth8,
          minWidth: 380,
          fontSize: 12,
        },
      }}
    >
      <Icon styles={iconStyles} iconName={alertConfig[type].iconName} />
      <StackItem grow>{children}</StackItem>
      <IconButton
        styles={closeStyles}
        iconProps={closeIconProps}
        onClick={onCloseHandler}
      />
    </Stack>
  );
};

interface AlertConfig {
  iconName: string;
  color: string;
  background: string;
}

const alertConfig: Record<AlertType, AlertConfig> = {
  [AlertType.INFORMATION]: {
    iconName: "Info",
    color: NeutralColors.gray190,
    background: NeutralColors.gray20,
  },
};

const closeIconProps: IIconProps = { iconName: "Cancel" };
const iconStyles: IIconStyles = {
  root: { color: "inherit", fontSize: 16, marginLeft: 12, marginRight: 8 },
};
const closeStyles: IButtonStyles = {
  root: {
    color: "inherit",
    background: "inherit",
    marginLeft: 20,
  },
};
