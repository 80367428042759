import {
  ContextualMenu,
  IButtonStyles,
  IconButton,
  IDragOptions,
  IIconProps,
  IModalStyleProps,
  IModalStyles,
  IStyleFunctionOrObject,
  Modal,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC } from "react";
import { PinnableTooltipData } from "../../types/types";

interface PinnedTooltipModalProps {
  data: PinnableTooltipData;
  onClose: () => void;
  hide: boolean;
}

const modalDragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};

const getModalStyles = (
  data: PinnableTooltipData,
  hide: boolean
): IStyleFunctionOrObject<IModalStyleProps, IModalStyles> => {
  return {
    root: {
      display: hide ? "none" : "flex",
      alignItems: "start",
      justifyContent: "left",
    },
    main: {
      minHeight: 0,
      minWidth: 0,
      overflowY: "initial",
      top: data.tooltipAttributes.top,
      left: data.tooltipAttributes.left,
    },
  };
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const cancelIconStyles: IButtonStyles = {
  root: {
    width: 15,
    height: 15,
    marginLeft: "auto",
    display: "none",
    backgroundColor: NeutralColors.gray10,
    color: NeutralColors.gray130,
  },
  rootHovered: {
    background: NeutralColors.gray40,
    color: NeutralColors.gray130,
  },
};

export const PinnedTooltipModal: FC<PinnedTooltipModalProps> = ({
  data,
  onClose,
  hide,
}) => {
  return (
    <Modal
      isOpen={true}
      dragOptions={modalDragOptions}
      isModeless={true}
      styles={getModalStyles(data, hide)}
    >
      <div className="pinned-tooltip" data-tooltip-id={data.tooltipElementId}>
        {data.tooltipData.stratigraphy && (
          <div className="pinned-tooltip__stratigraphy">
            <div className="pinned-tooltip__stratigraphy__item">
              <span
                style={{
                  background: data.tooltipData.stratigraphy.group.color,
                }}
              ></span>
              <span>{data.tooltipData.stratigraphy.group.name}</span>
            </div>
            <div className="pinned-tooltip__stratigraphy__item">
              <span
                style={{
                  background: data.tooltipData.stratigraphy.formation.color,
                }}
              ></span>
              <span>{data.tooltipData.stratigraphy.formation.name}</span>
            </div>
          </div>
        )}
        <div className="pinned-tooltip__heading">
          <span>{data.tooltipData.heading} </span>
          <IconButton
            className="pinned-tooltip__heading__close"
            iconProps={cancelIcon}
            ariaLabel="Close pinned tooltip"
            styles={cancelIconStyles}
            onClick={onClose}
          />
        </div>
        {data.tooltipData.rows.map((row, index) => (
          <div key={index} className="pinned-tooltip__row">
            <span>{row.label}:&nbsp;</span>
            <span>{row.value}</span>
          </div>
        ))}
        <div
          className={`pinned-tooltip__beak ${
            data.tooltipAttributes.tooltipAboveMouse
              ? ""
              : "pinned-tooltip__beak--top"
          }`}
        ></div>
      </div>
    </Modal>
  );
};
