import firebase from "firebase/app";
import "firebase/firestore";
import { DefaultWellboreName } from "../constants";
import { Optional } from "../types";
import { Wellbore } from "../types/types";

export async function getWellbores(): Promise<Wellbore[]> {
  const wellbores: Wellbore[] = [];
  const querySnapshot = await firebase
    .firestore()
    .collection("wellbores")
    .get();

  querySnapshot.forEach(function (doc) {
    const wellWithDocId = { id: doc.id, ...doc.data() } as Wellbore;
    wellbores.push(wellWithDocId);
  });
  return wellbores;
}

export async function getDefaultWellbore(
  wellboreName: Optional<string>
): Promise<Wellbore> {
  const querySnapshot = await firebase
    .firestore()
    .collection("wellbores")
    .where("name", "==", wellboreName ?? DefaultWellboreName)
    .limit(1)
    .get();
  let defaultDocument = querySnapshot.docs[0];
  if (!defaultDocument?.exists) {
    const alternativeSnapshot = await firebase
      .firestore()
      .collection("wellbores")
      .limit(1)
      .get();
    defaultDocument = alternativeSnapshot.docs[0];
  }
  const wellWithDocId = {
    id: defaultDocument.id,
    ...defaultDocument.data(),
  } as Wellbore;
  return wellWithDocId;
}
