import { SharedColors } from "@fluentui/theme";
import sum from "lodash/sum";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFetchJson, useQueryParamState } from "../../hooks";
import { selectActiveWellbore, selectCuttings } from "../../store";
import { Optional } from "../../types";
import {
  BitSizeData,
  BitSizeLog,
  Cutting,
  Domain,
  LasFileJson,
  LogCurveName,
  LogCurveOptions,
  LogData,
  ProcessedRefLogs,
} from "../../types/types";
import { getCurve } from "../../utils/las-files";

export const useRefLog = (): [
  Optional<LogCurveOptions>,
  React.Dispatch<React.SetStateAction<Optional<LogCurveOptions>>>,
  Optional<ProcessedRefLogs>
] => {
  const cuttings = useSelector(selectCuttings);
  const activeWellbore = useSelector(selectActiveWellbore);
  const [selectedRefLog, setSelectedRefLog] = useQueryParamState<
    Optional<LogCurveOptions>
  >("refLog", undefined);
  const [processedRefLogs, setProcessedRefLogs] = useState<ProcessedRefLogs>({
    [LogCurveName.GAMMA_RAY]: undefined,
    [LogCurveName.CALIPER]: undefined,
    [LogCurveName.BIT_SIZE]: undefined,
  });
  const updateProcessedRefLogs = useCallback(
    (processedRefLogs: Partial<ProcessedRefLogs>) => {
      setProcessedRefLogs((prevState) => ({
        ...prevState,
        ...processedRefLogs,
      }));
    },
    []
  );
  const wellLogData = useFetchJson<LasFileJson>(
    activeWellbore?.wellLog?.reference
  );
  const bitSizeData = useFetchJson<BitSizeLog>(
    "/files/wellbore-bit-size-log.json"
  );

  /**
   * Update gamma and caliper log data
   */
  useEffect(() => {
    if (wellLogData) {
      const depths = getCurve(wellLogData, LogCurveName.DEPTH);

      if (depths && cuttings) {
        updateProcessedRefLogs({
          [LogCurveName.GAMMA_RAY]: generateLogData(
            depths as number[],
            getCurve(wellLogData, LogCurveName.GAMMA_RAY),
            cuttings
          ),
          [LogCurveName.CALIPER]: generateLogData(
            depths as number[],
            getCurve(wellLogData, LogCurveName.CALIPER),
            cuttings
          ),
        });

        return;
      }
    }

    updateProcessedRefLogs({
      [LogCurveName.GAMMA_RAY]: undefined,
      [LogCurveName.CALIPER]: undefined,
    });
  }, [cuttings, updateProcessedRefLogs, wellLogData]);

  /**
   * Update bit size log data
   */
  useEffect(() => {
    if (
      cuttings &&
      activeWellbore &&
      bitSizeData &&
      bitSizeData[activeWellbore.name]
    ) {
      updateProcessedRefLogs({
        [LogCurveName.BIT_SIZE]: generateBitSizeLogData(
          bitSizeData[activeWellbore.name],
          cuttings
        ),
      });
    } else {
      updateProcessedRefLogs({
        [LogCurveName.BIT_SIZE]: undefined,
      });
    }
  }, [cuttings, activeWellbore, updateProcessedRefLogs, bitSizeData]);

  return [selectedRefLog, setSelectedRefLog, processedRefLogs];
};

export const gammaRayXDomain: Domain = { min: 0, max: 200 };

export const RefLogColor: Record<LogCurveOptions, string> = {
  [LogCurveName.GAMMA_RAY]: SharedColors.red20,
  [LogCurveName.CALIPER]: "#009200",
  [LogCurveName.BIT_SIZE]: SharedColors.red20,
};

export function generateLogData(
  depths: number[],
  values: (number | undefined)[],
  cuttings: Cutting[]
): Optional<LogData[]> {
  const data: LogData[] = [];
  let allValuesAreUndefined = true;
  const PRECISION = 3;
  cuttings.forEach((c, i) => {
    const indexOfMeasurementsWithin3m = depths.reduce<number[]>(
      (acc, depth, i) => {
        if (c.depth - PRECISION <= depth && depth <= c.depth + PRECISION) {
          acc.push(i);
        }
        return acc;
      },
      []
    );
    const validValues = indexOfMeasurementsWithin3m.reduce<number[]>(
      (acc, indexOfMeasurement) => {
        const value = values[indexOfMeasurement];
        if (value !== undefined) {
          acc.push(value);
        }
        return acc;
      },
      []
    );
    const meanOfValidValues = validValues.length
      ? sum(validValues) / validValues.length
      : undefined;

    if (meanOfValidValues !== undefined) {
      allValuesAreUndefined = false;
    }

    data.push({
      index: i,
      depth: c.depth,
      value: meanOfValidValues,
    });
  });

  if (allValuesAreUndefined) {
    return undefined;
  }

  return data;
}

export function generateBitSizeLogData(
  bitSizeData: BitSizeData[],
  cuttings: Cutting[]
): Optional<LogData[]> {
  const result: LogData[] = [];
  let allValuesAreUndefined = true;

  for (const [i, cutting] of cuttings.entries()) {
    const bitSize = getBitSize(bitSizeData, cutting.depth);

    if (bitSize) {
      allValuesAreUndefined = false;
    }

    result.push({
      index: i,
      depth: cutting.depth,
      value: bitSize,
    });
  }

  if (allValuesAreUndefined) {
    return undefined;
  }

  return result;
}

function getBitSize(
  bitSizeData: BitSizeData[],
  depth: number
): Optional<number> {
  let prevDepth = -1;
  for (const bitSize of bitSizeData) {
    if (prevDepth < depth && depth <= bitSize.depth) {
      return bitSize.diameter;
    }
    prevDepth = bitSize.depth;
  }
  return undefined;
}
