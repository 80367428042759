import * as d3 from "d3";
import { GeoPermissibleObjects } from "d3";
import { AnalyticsName } from "../../../constants";
import { MapArea, Wellbore, WellborePosition } from "../../../types/types";
import { roundByStep } from "../../../utils";
import { passesFilter } from "../common";
import { getWellboreIconUrl } from "./helpers";
import WellboreAttributeResolver from "./WellboreAttributeResolver";

interface BlockGeoData {
  properties: {
    block_name: string;
  };
}
interface QuadGeoData {
  properties: {
    quadrant: string;
  };
}

interface FieldGeoData {
  properties: {
    fieldName: string;
  };
}

export function drawFields(
  fieldsGeoJson: any, // eslint-disable-line
  path: d3.GeoPath<any, d3.GeoPermissibleObjects> // eslint-disable-line
): void {
  const fieldsContainer = d3.select("#fields-container");
  fieldsContainer.attr("display", "none");

  fieldsContainer
    .selectAll("field-path")
    .data(fieldsGeoJson.features)
    .enter()
    .append("path")
    .attr("class", "field-path")
    .attr("d", path as any); // eslint-disable-line

  fieldsContainer
    .selectAll(".field-label")
    .data(fieldsGeoJson.features)
    .enter()
    .append("text")
    .attr("class", "field-label")
    .attr("x", (d) => path.centroid(d as GeoPermissibleObjects)[0])
    .attr("y", (d) => path.centroid(d as GeoPermissibleObjects)[1])
    .text((d) => {
      return (d as FieldGeoData).properties.fieldName;
    });
}

export function drawLand(
  landGeoJson: any, // eslint-disable-line
  path: d3.GeoPath<any, d3.GeoPermissibleObjects> // eslint-disable-line
): void {
  const landContainer = d3.select("#land-container");

  landContainer
    .selectAll(".land-path")
    .data(landGeoJson.features)
    .join(
      (enter) =>
        enter
          .append("path")
          .attr("class", "land-path")
          .attr("d", path as any) // eslint-disable-line
    );
}
export function drawQuads(
  quadGeoJson: any, // eslint-disable-line
  path: d3.GeoPath<any, d3.GeoPermissibleObjects> // eslint-disable-line
): void {
  const quadContainer = d3.select("#quad-container");

  quadContainer
    .selectAll("quad-path")
    .data(quadGeoJson.features)
    .enter()
    .append("path")
    .attr("class", "quad-path")

    .attr("d", path as any); // eslint-disable-line

  quadContainer
    .selectAll(".quad-label")
    .data(quadGeoJson.features)
    .enter()
    .append("text")
    .attr("class", "quad-label")
    .attr("x", (d) => path.centroid(d as GeoPermissibleObjects)[0])
    .attr("y", (d) => path.centroid(d as GeoPermissibleObjects)[1])
    .text((d) => {
      return (d as QuadGeoData).properties.quadrant;
    });
}

export function drawBlocks(
  blocksGeoJson: any, // eslint-disable-line
  path: d3.GeoPath<any, d3.GeoPermissibleObjects> // eslint-disable-line
): void {
  const blockContainer = d3.select("#block-container");

  blockContainer.attr("display", "none");
  blockContainer
    .selectAll("block-path")
    .data(blocksGeoJson.features)
    .enter()
    .append("path")
    .attr("class", "block-path")
    .attr("d", path as any); // eslint-disable-line

  blockContainer
    .selectAll(".block-label")
    .data(blocksGeoJson.features)
    .enter()
    .append("text")
    .attr("class", "block-label")
    .attr("x", (d) => path.centroid(d as GeoPermissibleObjects)[0])
    .attr("y", (d) => path.centroid(d as GeoPermissibleObjects)[1])
    .text((d) => {
      return (d as BlockGeoData).properties.block_name;
    });
}

export function drawWellbores(
  wellbores: Wellbore[],
  visibleArea: MapArea,
  attributeResolver: WellboreAttributeResolver,
  selectedWellbore: Wellbore | undefined,
  updateSelectedWellbore: (dataOrEvent: any) => void, // eslint-disable-line
  filters: AnalyticsName[]
): void {
  const wellboreContainer = d3.select("#wellbore-container");

  const currentZoom = visibleArea.transform.k;
  const attributes = attributeResolver.forceGetDynamicAttributes(currentZoom);

  // Use rounded radius as svg image element used by Icon only updates height / width by steps of 0.5
  const roundedPointRadius = roundByStep(attributes.point.radius, 0.5);
  const pointHeight = roundedPointRadius * 2;

  // Add a small padding to point so there there is room between point and svg image (icon)
  const pointRadiusWithPadding = roundedPointRadius + attributes.point.padding;

  const updateSelection = wellboreContainer
    .selectAll(".wellbore")
    .data(wellbores, (d) => (d as Wellbore).name);

  const enterSelection = updateSelection
    .enter()
    .append("g")
    .attr("class", "wellbore")
    .classed("wellbore--active", (d) => {
      return d.name === selectedWellbore?.name;
    })
    .on("click", updateSelectedWellbore);

  enterSelection
    .append("circle")
    .attr("class", "wellbore__point")
    .classed("wellbore__point--filtered", (d) => !passesFilter(d, filters))
    .attr("r", pointRadiusWithPadding)
    .attr("cx", (d) => attributeResolver.getPointPositionX(d))
    .attr("cy", (d) => attributeResolver.getPointPositionY(d));

  enterSelection
    .append("svg:image")
    .attr("xlink:href", (d) => getWellboreIconUrl(d))
    .attr("class", "wellbore__icon")
    .attr("width", pointHeight)
    .attr("height", pointHeight)
    .attr("x", (d) => attributeResolver.getIconPositionX(d, roundedPointRadius))
    .attr("y", (d) =>
      attributeResolver.getIconPositionY(d, roundedPointRadius)
    );

  enterSelection
    .append("text")
    .attr("class", "wellbore__label")
    .attr("display", attributes.label.display)
    .attr("font-size", attributes.label.fontSize)
    .attr("x", (d) => attributeResolver.getLabelPositionX(d, currentZoom))
    .attr("y", (d) => attributeResolver.getLabelPositionY(d, currentZoom))
    .text((d) => {
      return (d as Wellbore).name;
    });

  updateSelection
    .classed("wellbore--active", (d) => {
      return d.name === selectedWellbore?.name;
    })
    .on("click", updateSelectedWellbore);

  updateSelection
    .selectAll("circle")
    .classed(
      "wellbore__point--filtered",
      (d) => !passesFilter(d as Wellbore, filters)
    )
    .attr("r", pointRadiusWithPadding);

  updateSelection
    .selectAll("image")
    .attr("x", (d) =>
      attributeResolver.getIconPositionX(d as Wellbore, roundedPointRadius)
    )
    .attr("y", (d) =>
      attributeResolver.getIconPositionY(d as Wellbore, roundedPointRadius)
    )
    .attr("width", pointHeight)
    .attr("height", pointHeight);

  updateSelection
    .selectAll(".wellbore__label")
    .attr("display", attributes.label.display)
    .attr("font-size", attributes.label.fontSize)
    .attr("x", (d) =>
      attributeResolver.getLabelPositionX(d as Wellbore, currentZoom)
    )
    .attr("y", (d) =>
      attributeResolver.getLabelPositionY(d as Wellbore, currentZoom)
    );

  updateSelection.exit().remove();
}

export function drawNonRwiWellbores(
  nonRwiWellbores: WellborePosition[],
  visibleArea: MapArea,
  attributeResolver: WellboreAttributeResolver
): void {
  const nonRwiWellboreContainer = d3.select("#non-rwi-wellbore-container");

  const currentZoom = visibleArea.transform.k;
  const attributes = attributeResolver.forceGetDynamicAttributes(currentZoom);

  const updateSelection = nonRwiWellboreContainer
    .selectAll(".wellbore")
    .data(nonRwiWellbores, (d) => (d as WellborePosition).name);

  const enterSelection = updateSelection
    .enter()
    .append("g")
    .attr("class", "wellbore");

  enterSelection
    .append("circle")
    .attr("class", "wellbore__point")
    .attr("r", attributes.nonRwiPoint.radius)
    .attr("cx", (d) => attributeResolver.getPointPositionX(d))
    .attr("cy", (d) => attributeResolver.getPointPositionY(d));

  enterSelection
    .append("text")
    .attr("class", "wellbore__label")
    .attr("display", attributes.nonRwiLabel.display)
    .attr("font-size", attributes.nonRwiLabel.fontSize)
    .attr("x", (d) =>
      attributeResolver.getLabelPositionX(d, currentZoom, 4.5, 55)
    )
    .attr("y", (d) =>
      attributeResolver.getLabelPositionY(d, currentZoom, 1.5, 30)
    )
    .text((d) => {
      return d.name;
    });

  updateSelection
    .selectAll(".wellbore__point")
    .attr("r", attributes.nonRwiPoint.radius);

  updateSelection
    .selectAll(".wellbore__label")
    .attr("display", attributes.nonRwiLabel.display)
    .attr("font-size", attributes.nonRwiLabel.fontSize)
    .attr("x", (d) =>
      attributeResolver.getLabelPositionX(
        d as WellborePosition,
        currentZoom,
        4.5,
        55
      )
    )
    .attr("y", (d) =>
      attributeResolver.getLabelPositionY(
        d as WellborePosition,
        currentZoom,
        1.5,
        30
      )
    );

  updateSelection.exit().remove();
}
