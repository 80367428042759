import React, { ReactNode } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

export interface PrivateRouteProps extends RouteProps {
  authenticated: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  authenticated,
  ...rest
}) => {
  if (!Component) return null;
  return (
    <Route
      {...rest}
      render={(props): ReactNode =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
