import {
  ContextualMenuItemType,
  DirectionalHint,
  IContextualMenuItem,
  Layer,
} from "@fluentui/react";
import React, { FC, useMemo, useRef } from "react";
import { DropdownName, MapLayerName } from "../../../constants";
import { useDropdowns } from "../../../context";
import {
  ContextualMenu,
  ContextualMenuSelectionMode,
  SidebarButton,
} from "../../styledFluentComponents";

const mapLayers: {
  key: MapLayerName;
  text: string;
}[] = [
  { key: MapLayerName.RWI, text: "RWI wellbores" },
  { key: MapLayerName.NON_RWI, text: "Non-RWI wellbores" },
  { key: MapLayerName.BLOCKS_AND_QUADS, text: "Blocks and Quadrants" },
];

const iconProps = { iconName: "MapLayers" };

export const MapLayerSelector: FC = () => {
  const { isOpen, toggleDropdown } = useDropdowns();

  const buttonRef = useRef(null);

  const items: IContextualMenuItem[] = useMemo(
    () => [
      {
        key: "layerSelection",
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          topDivider: true,
          bottomDivider: false,
          title: "Layers",

          items: mapLayers.map((layer, i) => {
            return {
              key: layer.key,
              text: layer.text,
              canCheck: true,
              checked: true,
              disabled: true,
              data: {
                filterName: layer.key,
              },
            };
          }),
        },
      },
    ],
    []
  );

  return (
    <>
      <SidebarButton
        ariaLabel="Select layers"
        elementRef={buttonRef}
        onClick={() => toggleDropdown(DropdownName.MAP_LAYERS)}
        checked={isOpen[DropdownName.MAP_LAYERS]}
        iconProps={iconProps}
        rightAligned
      ></SidebarButton>

      {isOpen[DropdownName.MAP_LAYERS] && (
        <Layer>
          <ContextualMenu
            target={buttonRef.current}
            styles={{ root: {} }}
            items={items}
            shouldFocusOnMount={true}
            shouldFocusOnContainer={true}
            directionalHint={DirectionalHint.leftTopEdge}
            selectionMode={ContextualMenuSelectionMode.MULTIPLE}
          />
        </Layer>
      )}
    </>
  );
};
