import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useThumbnail } from "../../hooks";
import { selectActiveWellbore, selectSelectedImageType } from "../../store";
import { Thumbnail, ThumbnailChunk } from "../../types/types";

interface PreviewImageProps {
  cuttingIndex: number;
}

const NUMBER_OF_IMAGES_TO_PRELOAD = 300;

export const PreviewImage: FC<PreviewImageProps> = ({ cuttingIndex }) => {
  const activeWellbore = useSelector(selectActiveWellbore);
  const selectedImageType = useSelector(selectSelectedImageType);
  const thumbnail = useThumbnail(activeWellbore, selectedImageType);

  const [hide, setHide] = useState(true);
  useEffect(() => {
    setHide(false);

    const t = (setTimeout(() => {
      setHide(true);
    }, 1000) as unknown) as number;

    return () => clearTimeout(t);
  }, [cuttingIndex]);

  const [container, setContainer] = useState<HTMLElement>();
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const miniMapElement = document.getElementById("openseadragon-mini-map");
      if (miniMapElement) {
        setContainer(miniMapElement);
      } else {
        setContainer(undefined);
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  if (thumbnail && container) {
    const [width, height] = getPreviewImageDimensions(container, thumbnail);

    return ReactDOM.createPortal(
      <div
        className="preview-background"
        style={{ height: `${hide ? 0 : 100}%` }}
      >
        <div
          className="preview-image"
          style={{ height: `${hide ? 0 : height}px`, width: `${width}px` }}
        >
          <div
            style={{
              width: `${width}px`,
              transform: `translateY(-${height * cuttingIndex}px)`,
            }}
          >
            {getChunksToLoad(
              thumbnail.chunks,
              cuttingIndex,
              NUMBER_OF_IMAGES_TO_PRELOAD
            ).map((c) => {
              return (
                <img
                  key={c.startIndex}
                  src={c.storageReference}
                  alt="cutting preview"
                ></img>
              );
            })}
          </div>
        </div>
      </div>,
      container
    );
  }
  return null;
};

function getChunksToLoad(
  chunks: ThumbnailChunk[],
  cuttingIndex: number,
  amountToPreload: number
) {
  const maxIndexToLoad = Math.min(
    cuttingIndex + amountToPreload,
    chunks[chunks.length - 1].endIndex
  );

  return chunks.filter((c) => c.endIndex <= maxIndexToLoad);
}

function getPreviewImageDimensions(
  container: HTMLElement,
  thumbnail: Thumbnail
) {
  const rect = container.getBoundingClientRect();
  const containerAspectRatio = rect.width / rect.height;
  const thumbnailAspectRatio = thumbnail.imageWidth / thumbnail.imageHeight;

  const thumbnailIsConstrainedByHeight =
    containerAspectRatio > thumbnailAspectRatio;
  let width, height;
  if (thumbnailIsConstrainedByHeight) {
    height = rect.height;
    width = thumbnailAspectRatio * height;
  } else {
    width = rect.width;
    height = width / thumbnailAspectRatio;
  }

  return [width, height];
}
