import { DirectionalHint, IContextualMenuItem, Layer } from "@fluentui/react";
import React, { FC, useRef } from "react";
import { DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import {
  ContextualMenu,
  ContextualMenuSelectionMode,
  SidebarButton,
} from "../styledFluentComponents";

interface SidebarDropdownProps {
  onItemClick: (
    ev?:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) => boolean | void;
  items: IContextualMenuItem[];
  dropDownName: DropdownName;
  buttonAriaLabel: string;
  buttonLabel: string;
  selectionMode: ContextualMenuSelectionMode;
}

export const SidebarDropdown: FC<SidebarDropdownProps> = ({
  onItemClick,
  items,
  dropDownName,
  buttonAriaLabel,
  buttonLabel,
  selectionMode,
}) => {
  const { isOpen, toggleDropdown } = useDropdowns();
  const buttonRef = useRef(null);

  return (
    <>
      <SidebarButton
        ariaLabel={buttonAriaLabel}
        elementRef={buttonRef}
        onClick={() => toggleDropdown(dropDownName)}
        checked={isOpen[dropDownName]}
      >
        {buttonLabel}
      </SidebarButton>

      {isOpen[dropDownName] && (
        <Layer>
          <ContextualMenu
            target={buttonRef.current}
            items={items}
            onItemClick={onItemClick}
            shouldFocusOnMount={true}
            shouldFocusOnContainer={true}
            directionalHint={DirectionalHint.rightTopEdge}
            selectionMode={selectionMode}
          />
        </Layer>
      )}
    </>
  );
};
