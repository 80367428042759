import { Stack, StackItem } from "@fluentui/react";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import { useWellTopsData } from "../../../hooks";
import {
  selectActiveWellbore,
  selectAllAnalyticsData,
  selectSelectedTab,
} from "../../../store";
import { analyticsNames } from "../../../texts";
import { Optional, StateSetter } from "../../../types";
import { SortMethod } from "../../../utils";
import {
  DefaultButton,
  Dropdown,
  IconButton,
  IconLink,
} from "../../styledFluentComponents";
import {
  downloadIcon,
  filterIcon,
  getExportToExcelTooltipText,
  infoIcon,
  sortMethodDropdownStyles,
  sortOptions,
} from "../config";
import { exportToExcel } from "../utils/excelExport";
import { DropdownOnChange } from "../utils/hooks";
import { FilterTextField } from "./FilterTextField";

interface TabHeaderProps {
  informationTooltipText: string;
  informationPDFUrl: Optional<string>;
  tab: AnalyticsName;
  filterActive: boolean;
  setFilterActive: StateSetter<boolean>;
  setFilterText: StateSetter<Optional<string>>;
  groupsCollapsed: boolean;
  toggleCollapseAllGroups: () => void;
  sortMethod?: SortMethod;
  onSortMethodChange?: DropdownOnChange;
  disableExpand?: boolean;
  disableSorting?: boolean;
  disableFiltering?: boolean;
}

export const TabHeader: FC<TabHeaderProps> = ({
  informationTooltipText,
  informationPDFUrl,
  tab,
  filterActive,
  setFilterActive,
  setFilterText,
  groupsCollapsed,
  toggleCollapseAllGroups,
  sortMethod,
  onSortMethodChange,
  disableExpand = false,
  disableSorting = false,
  disableFiltering = false,
}) => {
  const activeWellbore = useSelector(selectActiveWellbore);
  const wellTopsData = useWellTopsData();
  const analyticsData = useSelector(selectAllAnalyticsData);
  const selectedTab = useSelector(selectSelectedTab);

  /**
   * Keyboard event listener to open and close the filtering input
   */
  useEffect(() => {
    if (!disableFiltering && selectedTab === tab) {
      const keypressHandler = (event: KeyboardEvent) => {
        if (event.key === "f") {
          setFilterActive((prevState) => !prevState);
          // prevent "f" input on FilterTextField
          event.preventDefault();
        }
      };
      document.addEventListener("keydown", keypressHandler);

      return () => document.removeEventListener("keydown", keypressHandler);
    }
  }, [disableFiltering, selectedTab, setFilterActive, tab]);

  return (
    <>
      <Stack
        className="analytics-pane--horizontal-margin analytics-pane__header-container"
        horizontal
        verticalAlign={"center"}
      >
        {filterActive && (
          <FilterTextField
            setFilterText={setFilterText}
            setFilterActive={setFilterActive}
            placeHolder={`Filter ${analyticsNames[tab]} data by name`}
          />
        )}
        {!filterActive && (
          <>
            <h2 className="analytics-pane__heading">{analyticsNames[tab]}</h2>
            <StackItem grow>
              <IconLink
                iconProps={infoIcon}
                aria-label="Open file"
                toolTipText={informationTooltipText}
                disabled={!informationPDFUrl}
                href={informationPDFUrl}
              />
            </StackItem>
            {activeWellbore && (
              <IconButton
                iconProps={downloadIcon}
                ariaLabel="Export to Excel"
                toolTipText={getExportToExcelTooltipText(activeWellbore.name)}
                onClick={() => {
                  if (activeWellbore && wellTopsData) {
                    exportToExcel(
                      activeWellbore,
                      analyticsData,
                      wellTopsData,
                      tab
                    );
                  }
                }}
              />
            )}
            {!disableFiltering && (
              <IconButton
                iconProps={filterIcon}
                ariaLabel={`Filter on ${analyticsNames[tab]} Data`}
                toolTipText={`Filter on ${analyticsNames[tab]} Data (F)`}
                onClick={() => {
                  setFilterActive(true);
                }}
              />
            )}
          </>
        )}
      </Stack>
      <Stack
        className="analytics-pane--horizontal-margin"
        horizontal
        verticalAlign={"center"}
      >
        <StackItem grow>
          <DefaultButton
            text={groupsCollapsed ? "Expand" : "Collapse"}
            grayScale
            onClick={toggleCollapseAllGroups}
            disabled={disableExpand}
          />
        </StackItem>
        <Dropdown
          options={sortOptions}
          onChange={onSortMethodChange}
          selectedKey={sortMethod}
          placeholder="Sort by"
          styles={sortMethodDropdownStyles}
          disabled={disableSorting}
        />
      </Stack>
    </>
  );
};
