import { NeutralColors } from "@fluentui/theme";
import * as d3 from "d3";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { TooltipType } from "../../constants";
import { usePinnedTooltips } from "../../context";
import {
  isDefaultZoomArea,
  selectCuttings,
  selectSelectedWellbore,
  selectSelectedZoomArea,
  setActiveZoomAreaWithPadding,
  setSelectedCutting,
  useAppDispatch,
} from "../../store";
import {
  PinnableTooltipData,
  Size,
  VerticalMargin,
  WellTopsPlotData,
  ZoomArea,
} from "../../types/types";
import { DoubleClickDetector, isTWellbore } from "../../utils";
import {
  addTooltipElement,
  createTooltipAdder,
  TooltipData,
} from "../plot-tooltip/plot-tooltip";
import { TrackContentProps } from "../track";

const DEFAULT_COLOR = "#fff";
const STROKE_WIDTH_ADJUSTMENT = 1;

interface Scales {
  x: d3.ScaleBand<string>;
  y: d3.ScaleLinear<number, number, never>;
}

interface WellboreTopsChartProps extends TrackContentProps {
  data: WellTopsPlotData[] | undefined;
}

export const WellboreTopsChart: FC<WellboreTopsChartProps> = ({
  size,
  renderingConstraints,
  data,
}) => {
  const dispatch = useAppDispatch();
  const cuttings = useSelector(selectCuttings);
  const selectedWellbore = useSelector(selectSelectedWellbore);
  const selectedZoomArea = useSelector(selectSelectedZoomArea);

  const { setActiveTooltip } = usePinnedTooltips();

  const scales = useMemo(
    () => ({
      x: d3
        .scaleBand()
        .range([STROKE_WIDTH_ADJUSTMENT, size.width - STROKE_WIDTH_ADJUSTMENT])
        .domain(["formation", "group"])
        .padding(0),
      y: d3
        .scaleLinear()
        .range([
          renderingConstraints.outerTrackMargin.top,
          size.height - renderingConstraints.outerTrackMargin.bottom,
        ]),
    }),
    [renderingConstraints.outerTrackMargin, size]
  );

  /**
   * Set cutting based on the cutting having the nearest depth after the input
   */
  const setNearestCuttingByDepth = useCallback(
    (depth: number) => {
      if (cuttings && isDefaultZoomArea(selectedZoomArea, cuttings.length)) {
        const closestCutting = cuttings.reduce((nearestCutting, cutting) => {
          const isCuttingWithinLowerBound = depth <= cutting.depth;
          const isCuttingCloser =
            Math.abs(depth - cutting.depth) <
            Math.abs(depth - nearestCutting.depth);
          if (isCuttingWithinLowerBound && isCuttingCloser) {
            return cutting;
          }
          return nearestCutting;
        });
        dispatch(setSelectedCutting(closestCutting));
      }
    },
    [cuttings, dispatch, selectedZoomArea]
  );

  /**
   * Set zoom area
   */
  const setZoomArea = useCallback(
    (zoomArea: ZoomArea) => {
      dispatch(setActiveZoomAreaWithPadding(zoomArea));
    },
    [dispatch]
  );

  /**
   * Initialize the plot
   */
  useEffect(() => {
    const svg = d3.select("#wellbore-tops__chart");

    const dataContainer = svg
      .append("g")
      .attr("id", "wellbore-tops__data-container");

    const tooltip = addTooltipElement("wellbore-tops-tooltip");

    return () => {
      dataContainer.selectAll("*").remove();
      tooltip.remove();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      updateScales(scales, data, size, renderingConstraints.outerTrackMargin);
      drawWellboreTops(
        scales,
        data,
        setNearestCuttingByDepth,
        setZoomArea,
        setActiveTooltip
      );
    } else {
      clearPlot();
    }
  }, [
    data,
    size,
    scales,
    renderingConstraints.outerTrackMargin,
    setNearestCuttingByDepth,
    setZoomArea,
    setActiveTooltip,
  ]);

  const isTWell = selectedWellbore && isTWellbore(selectedWellbore.name);

  return (
    <svg width={size.width} height={size.height} id="wellbore-tops__chart">
      {(!data || data.length === 0) && isTWell && (
        <text
          x={size.width / 2}
          y={size.height / 2}
          style={{
            alignmentBaseline: "middle",
            textAnchor: "middle",
            writingMode: "vertical-lr",
            fill: NeutralColors.gray130,
            fontSize: 18,
          }}
        >
          T-well : no NPD information
        </text>
      )}
    </svg>
  );
};

function updateScales(
  scales: Scales,
  data: WellTopsPlotData[],
  size: Size,
  trackMargin: VerticalMargin
) {
  scales.x.range([
    STROKE_WIDTH_ADJUSTMENT,
    size.width - STROKE_WIDTH_ADJUSTMENT,
  ]);

  const numberOfCuttings = data
    .filter((d) => d.type === "group")
    .reduce((sumOfCuttings, d) => {
      sumOfCuttings += d.numberOfCuttingsInFormation;
      return sumOfCuttings;
    }, 0);

  scales.y
    .range([trackMargin.top, size.height - trackMargin.bottom])
    .domain([0, numberOfCuttings]);
}

const doubleClickDetector = new DoubleClickDetector();

function drawWellboreTops(
  scales: Scales,
  data: WellTopsPlotData[],
  setNearestCuttingByDepth: (depth: number) => void,
  setZoomArea: (zoomArea: ZoomArea) => void,
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void
) {
  const dataContainer = d3.select("#wellbore-tops__data-container");

  const [addTooltip, removeTooltip] = createTooltipAdder(
    "wellbore-tops-tooltip",
    getTooltipData,
    setActiveTooltip,
    TooltipType.WELLBORE_TOPS
  );

  // eslint-disable-next-line
  const attributeSetter = (selection: any) =>
    selection
      .attr("x", (d: WellTopsPlotData) => scales.x(d.type) as number)
      .attr("y", (d: WellTopsPlotData) => scales.y(d.numberOfCuttingsAbove))
      .attr("width", () => scales.x.bandwidth())
      .attr(
        "height",
        (d: WellTopsPlotData) =>
          scales.y(d.numberOfCuttingsInFormation + d.numberOfCuttingsAbove) -
          scales.y(d.numberOfCuttingsAbove)
      )
      .attr("fill", (d: WellTopsPlotData) => d.color ?? DEFAULT_COLOR);

  doubleClickDetector
    // eslint-disable-next-line
    .onClick((e: any) => {
      const data: WellTopsPlotData = e.srcElement.__data__;
      setNearestCuttingByDepth(data.top);
    })
    // eslint-disable-next-line
    .onDoubleClick((e: any) => {
      const data: WellTopsPlotData = e.srcElement.__data__;

      setZoomArea({
        topIdx: data.numberOfCuttingsAbove,
        bottomIdx:
          data.numberOfCuttingsAbove + data.numberOfCuttingsInFormation - 1,
      });
    });

  dataContainer
    .selectAll(".formation-rect")
    .data(data, (d) => {
      const data = d as WellTopsPlotData;
      return `${data.type}-${data.name}`;
    })
    .join(
      (enter) =>
        enter
          .append("rect")
          .attr("class", "formation-rect")
          .on("mouseover", addTooltip)
          .on("click", (e) => {
            doubleClickDetector.detect(e);
          })
          .call(attributeSetter),
      (update) =>
        update
          .on("click", null)
          .on("click", (e) => {
            doubleClickDetector.detect(e);
          })
          .call(attributeSetter),
      (exit) => exit.remove()
    );

  dataContainer.on("mouseout", removeTooltip);
}

function clearPlot() {
  d3.select("#wellbore-tops__data-container").selectAll("*").remove();
}

const getTooltipData = (plotData: WellTopsPlotData): TooltipData => ({
  heading: plotData.name,
  rows: [
    { label: "Top", value: `${plotData.top} m` },
    { label: "Bottom", value: `${plotData.bottom} m` },
    { label: "Thickness", value: `${plotData.thickness} m` },
  ],
});
