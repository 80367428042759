import { IStackStyles, Stack } from "@fluentui/react";
import { Depths } from "@fluentui/theme";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import {
  selectSelectedCutting,
  selectSelectedImageType,
  selectSelectedImageType2,
  setSelectedImageType,
  setSelectedImageType2,
  useAppDispatch,
} from "../../store";
import { Optional } from "../../types";
import { ViewerController } from "../stage/ViewerController";
import { CompareToggle } from "./CompareToggle";
import { CuttingsNavigationButton } from "./CuttingsNavigationButton";
import { GridViewButton } from "./GridViewButton";
import { ImageAdjustPanel } from "./ImageAdjustButton";
import { ImageCompareSlider } from "./ImageCompareSlider";
import { ImageTypeDropdown } from "./ImageTypeDropdown";

interface ViewerToolbarProps {
  gridView: boolean;
  setGridView: (gridView: boolean | ((state: boolean) => boolean)) => void;
  compareMode: boolean;
  setCompareMode: (compareMode: boolean) => void;
  viewerController: Optional<ViewerController>;
}

const toolbarSyles: IStackStyles = {
  root: {
    boxShadow: Depths.depth16,
    borderRadius: "2px",
  },
};

const ViewerToolbarImlp: FC<ViewerToolbarProps> = ({
  gridView,
  setGridView,
  compareMode,
  setCompareMode,
  viewerController,
}) => {
  const dispatch = useAppDispatch();
  const selectedCutting = useSelector(selectSelectedCutting);
  const selectedImageType = useSelector(selectSelectedImageType);
  const selectedImageType2 = useSelector(selectSelectedImageType2);

  const { isOpen, closeDropdown } = useDropdowns();
  const [hoveringToolbar, setHoveringToolbar] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const imageReference = selectedCutting?.imageReferences.find(
    (r) => r.type === selectedImageType
  );

  useEffect(() => {
    const allMenuesAreClosed =
      !isOpen[DropdownName.SELECT1] &&
      !isOpen[DropdownName.ADJUST1] &&
      !isOpen[DropdownName.SELECT2] &&
      !isOpen[DropdownName.ADJUST2] &&
      !compareMode;

    let timeout = -1;

    if (hoveringToolbar) {
      setShow(true);
    } else if (allMenuesAreClosed && !hoveringToolbar) {
      timeout = (setTimeout(() => {
        setShow(false);
      }, 1000) as unknown) as number;
    }
    return () => clearTimeout(timeout);
  }, [isOpen, hoveringToolbar, compareMode]);

  useEffect(() => {
    if (gridView) {
      setCompareMode(false);
      closeDropdown([DropdownName.ADJUST1]);
    }
    // eslint-disable-next-line
  }, [gridView]);

  return (
    <>
      <Stack
        className={`viewer-toolbar ${show ? "viewer-toolbar--visible" : ""}`}
        grow={0}
        reversed
        styles={toolbarSyles}
      >
        <Stack
          horizontal
          onMouseOver={() => setHoveringToolbar(true)}
          onMouseLeave={() => setHoveringToolbar(false)}
          onFocus={() => setHoveringToolbar(true)}
          onBlur={() => setHoveringToolbar(false)}
        >
          <ImageTypeDropdown
            dropdownName={DropdownName.SELECT1}
            compareModeActive={compareMode}
            selectedImageType={selectedImageType}
            setSelectedImageType={(type: string) =>
              dispatch(setSelectedImageType(type))
            }
          />

          <ImageAdjustPanel
            dropdownName={DropdownName.ADJUST1}
            updateAdjustments={viewerController?.setAdjustmentsA.bind(
              viewerController
            )}
            disabled={gridView}
            selectedImageType={selectedImageType}
          />
          <CuttingsNavigationButton direction="up" disabled={gridView} />
          <GridViewButton setGridView={setGridView} gridView={gridView} />
          <CuttingsNavigationButton direction="down" disabled={gridView} />
          {!compareMode && (
            <CompareToggle
              autoFocus
              openCompareMode={() => setCompareMode(true)}
              disabled={!imageReference?.comparable || gridView}
            />
          )}
          {compareMode && (
            <>
              <ImageAdjustPanel
                dropdownName={DropdownName.ADJUST2}
                disabled={gridView}
                updateAdjustments={viewerController?.setAdjustmentsB.bind(
                  viewerController
                )}
                selectedImageType={selectedImageType2}
              />
              <ImageTypeDropdown
                dropdownName={DropdownName.SELECT2}
                compareModeActive={compareMode}
                selectedImageType={selectedImageType2}
                setSelectedImageType={(type: string) =>
                  dispatch(setSelectedImageType2(type))
                }
                disabled={gridView}
              />
            </>
          )}
        </Stack>
        {compareMode && (
          <ImageCompareSlider
            closeCompareMode={() => setCompareMode(false)}
            viewerController={viewerController}
          />
        )}
      </Stack>
    </>
  );
};

export const ViewerToolbar = React.memo(ViewerToolbarImlp);
