import { useEffect } from "react";

import { WellDataSymbol } from "./WellDataSymbols";
import { useAppSprings } from "../../context";
import { useTooltipClearState } from "../../hooks";
import { TooltipType } from "../../constants";

export function useWellDataSymbols(): [
  WellDataSymbol[],
  React.Dispatch<React.SetStateAction<WellDataSymbol[]>>
] {
  const [
    selectedWellDataSymbols,
    setSelectedWellDataSymbols,
  ] = useTooltipClearState<WellDataSymbol[]>(TooltipType.WELLBORE_SYMBOL, []);

  const { setNumberOfSelectedWellDataSymbols } = useAppSprings();

  useEffect(() => {
    setNumberOfSelectedWellDataSymbols(selectedWellDataSymbols.length);
  }, [selectedWellDataSymbols, setNumberOfSelectedWellDataSymbols]);

  return [selectedWellDataSymbols, setSelectedWellDataSymbols];
}
