import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  IDetailsGroupRenderProps,
  SelectionMode,
  SelectionZone,
} from "@fluentui/react";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import {
  selectActiveWellbore,
  selectCuttings,
  selectSelectedCutting,
  StoreState,
  useAppDispatch,
} from "../../../store";
import { CSVFileJson, TopGroup } from "../../../types/types";
import { DetailsList } from "../../styledFluentComponents";
import { fetchAnalyticsData } from "../analyticsSlice";
import { columnsConfig, PDFtoolTipTexts, tocConfig } from "../config";
import { useItemsAndGroups, useStoredSelection } from "../utils/hooks";
import { selections } from "../utils/selections";
import {
  getOnRenderRow,
  getStructuredDataFromCSVFile,
  renderGroupHeader,
} from "../utils/tab-helpers";
import { SelectedItemsClearer } from "./SelectedItemsClearer";
import { TabHeader } from "./TabHeader";

const listGroupProps: IDetailsGroupRenderProps = {
  onRenderHeader: (props) => {
    return renderGroupHeader(props, tocConfig, undefined);
  },
};
const onRenderRow = getOnRenderRow(AnalyticsName.TOC, tocConfig);

const TOCTabImpl: FC = () => {
  const dispatch = useAppDispatch();

  const selectedCutting = useSelector(selectSelectedCutting);
  const activeWellbore = useSelector(selectActiveWellbore);
  const cuttings = useSelector(selectCuttings);

  const selectedElements = useStoredSelection(AnalyticsName.TOC);
  const data = useSelector((state: StoreState) => state.analytics.srcData.toc);

  useEffect(() => {
    if (cuttings) {
      const dataTransformer = (src: CSVFileJson, wellTops: TopGroup[]) => {
        return getStructuredDataFromCSVFile(src, wellTops, tocConfig, cuttings);
      };

      dispatch(
        fetchAnalyticsData(
          AnalyticsName.TOC,
          activeWellbore?.analyticsData?.toc?.dataReference,
          dataTransformer
        )
      );
    }
  }, [dispatch, activeWellbore?.analyticsData?.toc?.dataReference, cuttings]);

  const {
    items,
    groups,
    filterActive,
    setFilterActive,
    setFilterText,
    groupsCollapsed,
    toggleCollapseAllGroups,
    clearSelection,
  } = useItemsAndGroups(
    data,
    selectedCutting,
    selections[AnalyticsName.TOC],
    undefined,
    true
  );

  return (
    <>
      <TabHeader
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        setFilterText={setFilterText}
        groupsCollapsed={groupsCollapsed}
        toggleCollapseAllGroups={toggleCollapseAllGroups}
        informationTooltipText={PDFtoolTipTexts.tocData}
        informationPDFUrl={
          activeWellbore?.analyticsData?.toc?.methodologyReference
        }
        tab={AnalyticsName.TOC}
        disableSorting={true}
        disableExpand={true}
        disableFiltering={true}
      />

      <SelectionZone
        className="analytics-pane__selection-zone"
        selection={selections[AnalyticsName.TOC]}
        selectionMode={SelectionMode.multiple}
        selectionPreservedOnEmptyClick={true}
      >
        <DetailsList
          columns={columnsConfig}
          items={items}
          groups={groups}
          onRenderRow={onRenderRow}
          groupProps={listGroupProps}
          compact={true}
          isHeaderVisible={false}
          checkboxVisibility={CheckboxVisibility.always}
          setKey="set"
          getKey={(item) => item.key}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selections[AnalyticsName.TOC]}
          selectionMode={SelectionMode.multiple}
          selectionPreservedOnEmptyClick={true}
        />
      </SelectionZone>
      <SelectedItemsClearer
        numberOfSelectedItems={selectedElements.length}
        onClearSelection={clearSelection}
      />
    </>
  );
};

export const TOCTab = React.memo(TOCTabImpl);
