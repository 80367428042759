import { IDropdownOption } from "@fluentui/react";
import firebase from "firebase/app";
import {
  TooltipAttributes,
  TooltipData,
} from "../components/plot-tooltip/plot-tooltip";
import { AnalyticsName, ChartType, TooltipType } from "../constants";
import { Optional } from "./utils/base";

export interface Wellbore {
  name: string;
  id: string;
  cuttings: Cutting[];
  backdrops?: Backdrop[];
  thumbnails?: Thumbnail[];
  wellLog?: WellLog;
  coordinates?: firebase.firestore.GeoPoint;
  analyticsData?: AnalyticsData;
  npdId: string;
  metadata: WellboreMetadata;
}

export interface Backdrop {
  name: string;
  reference: string;
  type: string;
}

export interface Thumbnail {
  imageType: string;
  imageHeight: number;
  imageWidth: number;
  chunks: ThumbnailChunk[];
}

export interface ThumbnailChunk {
  startIndex: number;
  endIndex: number;
  storageReference: string;
}

export interface WellLog {
  reference: string;
}

export type ProcessedRefLogs = {
  [curveName in LogCurveOptions]: LogData[] | undefined;
};

export interface AnalyticsData {
  [AnalyticsName.XRF]?: XRFReferences;
  [AnalyticsName.TOC]?: DataReferences;
  [AnalyticsName.XRD]?: DataReferences;
  [AnalyticsName.SPEC_CAM]?: DataReferences;
  [AnalyticsName.QEM_SCAN]?: QemscanReferences;
}

export interface WellboreMetadata {
  welltype: string;
  content?: WellboreContent;
  entryDate: firebase.firestore.Timestamp;
  completionDate: firebase.firestore.Timestamp;
}

export enum WellboreContent {
  GAS = "GAS",
  OIL = "OIL",
  DRY = "DRY",
  OIL_SHOWS = "OIL SHOWS",
  OIL_GAS = "OIL/GAS",
  OIL_GAS_SHOWS = "OIL/GAS SHOWS",
  GAS_SHOWS = "GAS SHOWS",
  GAS_CONDENSATE = "GAS/CONDENSATE",
  NOT_APPLICABLE = "NOT APPLICABLE",
  SHOWS = "SHOWS",
  OIL_GAS_CONDENSATE = "OIL/GAS/CONDENSATE",
}

export interface DataReferences {
  dataReference?: string;
  methodologyReference?: string;
}

export interface XRFReferences extends DataReferences {
  cuttingsAnalysisMethodologyReference?: string;
  cuttingsAnalysisReference?: string;
}

export interface QemscanReferences extends DataReferences {
  extendedMethodologyReference?: string;
}

export interface Dimension {
  height: number;
  width: number;
}

export interface ScaleFactor {
  vertical: number;
  horizontal: number;
}

export interface ImageReference {
  name: string;
  reference: string;
  type: string;
  shortname: string;
  dimension?: Dimension;
  resolutionHeight: number;
  resolutionWidth: number;
  comparable: boolean;
  originalMetadata?: ImageMetadata;
}

export interface ImageMetadata {
  colorSpace?: string;
  date?: firebase.firestore.Timestamp;
  filename?: string;
  resolutionHeight?: number;
  resolutionWidth?: number;
}

export interface Cutting {
  depth: number;
  id: string;
  imageReferences: ImageReference[];
}

export interface VerticalMargin {
  top: number;
  bottom: number;
}
export interface HorizontalMargin {
  left: number;
  right: number;
}
export interface Margin extends VerticalMargin, HorizontalMargin {}

export interface VerticalPadding {
  top: number;
  bottom: number;
}

export interface Domain {
  min: number;
  max: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface FirebaseMock {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  auth: any;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  firestore: any;
  _triggerAuthStateChange: (user?: firebase.User) => void;
  _triggerIdTokenChange: (user?: firebase.User) => void;
  _updateSnapshot: (
    path: string,
    docSnapshot: firebase.firestore.DocumentSnapshot
  ) => void;
}

export interface ImageAdjustments {
  brightness: number;
  contrast: number;
  invert: boolean;
  saturation: number;
  exposure: number;
}

export interface LogData {
  index: number;
  depth: number;
  value: number | undefined;
}

export interface LasFileJson {
  CURVES: Record<string, any[]>;
}

export type CSVFileLine = Record<string, string>;

export type CSVFileJson = CSVFileLine[];

export enum LogCurveName {
  DEPTH = "DEPTH",
  GAMMA_RAY = "GR",
  CALIPER = "CAL",
  BIT_SIZE = "BIT_SIZE",
}
export type LogCurveOptions =
  | LogCurveName.GAMMA_RAY
  | LogCurveName.CALIPER
  | LogCurveName.BIT_SIZE;
export type CurveKeys =
  | LogCurveName.DEPTH
  | LogCurveName.GAMMA_RAY
  | LogCurveName.CALIPER;

export interface LogData {
  index: number;
  depth: number;
  value: number | undefined;
}

export interface IListItem {
  key: string;
  value: string;
  nested: boolean;
  unit?: string;
  hide?: boolean;
  filtered?: boolean;
  information?: ElementInformation;
}

export type Element = {
  originalName: string;
  outputName: string;
  unit?: string;
  decimalPlaces?: number;
  hide?: boolean;
  skipInExcel?: boolean;
  information?: ElementInformation;
};

export type ElementInformation = {
  wikipediaLinks?: string[];
  description?: string;
};

export type ElementsGroup = {
  originalName: string;
  outputName: string;
  unit?: string;
  elements: Element[];
  skipInExcel?: boolean;
  information?: ElementInformation;
};

export type CategoryData = {
  unit?: string;
  elements: Element[];
  groups?: ElementsGroup[];
};

export type ElementsConfig = {
  [category: string]: CategoryData;
};

export type StructuredElement = {
  key: string;
  unit?: string;
  value: string;
  nested: boolean;
  rawValue: number | string | null; // Used when exprorting to Excel
  hide: boolean; // Hide from rendering in the analytics pane, but have available for export to Excel.
  skipInExcel: boolean; // Skip using the value in the generated Excel sheet.
  filtered?: boolean;
  information?: ElementInformation;
};
export type StructuredElementsGroup = {
  key: string;
  unit?: string;
  value: string;
  elements: StructuredElement[];
  rawValue: number | string | null; // Used when exprorting to Excel
  skipInExcel: boolean; // Skip using the value in the generated Excel sheet.
  information?: ElementInformation;
};
export type Stratigraphy = {
  group: { color: string; name: string };
  formation: { color: string; name: string };
};
export type StructuredCategory = {
  name: string;
  unit?: string;
  groups: StructuredElementsGroup[];
  elements: StructuredElement[];
  stratigraphy: Stratigraphy;
};

export type StructuredData = Map<number, StructuredCategory[]>;

export type FluentDropdownOnChange = (
  event: React.FormEvent<HTMLDivElement>,
  option?: IDropdownOption,
  index?: number
) => void;

export type AnalyticsDataColorsOverride = {
  [tabName in AnalyticsName]: { [element: string]: string };
};

export type PlotState = {
  id: string;
  tab: AnalyticsName;
  selectedChartType: ChartType;
  chartTypeToPlot: Exclude<ChartType, ChartType.NONE>;
  data: Optional<ScatterPlotData[] | StackedBarPlotData[]>;
  units: Record<string, string>;
  domain: Optional<Domain>;
  selectedElements: StoredSelection;
};

export type AnalyticsDataKey =
  | "xrfSampleInformation"
  | "qemscanSampleInformation"
  | AnalyticsName;

export interface PlotData {
  depth: number;
  stratigraphy?: any;
}

export interface ScatterPlotData extends PlotData {
  key: string;
  value: number | null;
}

export interface StackedBarPlotData extends PlotData {
  [element: string]: number;
}

export interface WellTopsPlotData {
  numberOfCuttingsAbove: number;
  numberOfCuttingsInFormation: number;
  type: "group" | "formation";
  color: string;
  name: string;
  top: number;
  bottom: number;
  thickness: number;
}

export interface StratigraphyChangesData {
  depth: number;
  prevDepth: number;
  stratigraphy?: Stratigraphy;
  prevStratigraphy?: Stratigraphy;
}

export type Position = [number, number];

export enum ComponentSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export interface WellborePosition {
  name: string;
  coordinates?: firebase.firestore.GeoPoint;
}

export interface MapArea {
  x: [number, number];
  y: [number, number];
  xCoord: [number, number];
  yCoord: [number, number];
  transform: any;
}

export interface TopFormation {
  name: string;
  top: number;
  bottom: number;
  thickness: number;
  color: string;
}

export interface TopGroup {
  name: string;
  top: number;
  bottom: number;
  thickness: number;
  color: string;
  formations: TopFormation[];
}

export interface ProcessedTops {
  [wellboreName: string]: TopGroup[];
}

export interface ProcessedCasingShoes {
  type: string;
  diameter: string;
  depth: number;
  holeDiameter: string;
}

export interface ProcessedWellSymbolsData {
  [wellboreName: string]: ProcessedCasingShoes[];
}

export interface WellDataSymbolsPlotData extends ProcessedCasingShoes {
  cuttingIndex: number;
  cuttingDepth: number | undefined;
}

export interface PinnableTooltipData {
  tooltipType: TooltipType;
  tooltipData: TooltipData;
  tooltipAttributes: TooltipAttributes;
  sourceElementId?: string;
  tooltipElementId?: string;
}

export interface StoredSelection {
  elements: string[];
  groups: string[];
}

export interface StoredPlot {
  t: AnalyticsName;
  c: ChartType;
  e: string[];
  g: string[];
}

export interface NumberRange {
  low: number;
  high: number;
}

export interface BitSizeData {
  depth: number;
  diameter: number;
}
export interface BitSizeLog {
  [wellboreName: string]: BitSizeData[];
}

export interface RenderingConstraints {
  centeredScaleOuterPadding: VerticalPadding;
  trackStartCenter: number;
  trackStep: number;
  outerTrackMargin: VerticalMargin;
  imageCenteredTrackMargin: VerticalMargin;
  trackContentHeight: number;
}

export interface ZoomArea {
  topIdx: number;
  bottomIdx: number;
}
