import { Stack } from "@fluentui/react";
import React, { FC } from "react";
import { AnalyticsName } from "../../../constants";
import { MapLayerSelector } from "./MapLayerSelector";
import { WellboreFilterSelector } from "./WellboreFilterSelector";

interface MapViewMenuProps {
  selectedWellboreFilters: AnalyticsName[];
  setSelectedWellboreFilters: React.Dispatch<
    React.SetStateAction<AnalyticsName[]>
  >;
}

export const MapViewMenu: FC<MapViewMenuProps> = ({
  selectedWellboreFilters,
  setSelectedWellboreFilters,
}) => {
  return (
    <Stack className="map-view-menu">
      <WellboreFilterSelector
        selectedWellboreFilters={selectedWellboreFilters}
        setSelectedWellboreFilters={setSelectedWellboreFilters}
      />
      <MapLayerSelector />
    </Stack>
  );
};
