import React, { FC } from "react";

import {
  List as FluentList,
  IListProps,
  Stack,
  StackItem,
} from "@fluentui/react";
import { IListItem } from "../../types/types";

export const List: FC<IListProps> = (restProps) => {
  return <FluentList {...restProps} />;
};

const cellStyles = {
  root: {
    lineHeight: 32,
    fontSize: 14,
  },
};

export const BasicListItem: FC<{ item: IListItem }> = ({ item }) => (
  <Stack horizontal>
    <StackItem grow styles={cellStyles}>
      {item.key}
    </StackItem>
    <StackItem styles={cellStyles}>{item.value}</StackItem>
  </Stack>
);
