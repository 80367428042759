import {
  Callout,
  DirectionalHint,
  IButtonStyles,
  Icon,
  IContextualMenuItem,
  IIconStyles,
  mergeStyleSets,
  NeutralColors,
  Stack,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React, { FC } from "react";
import { ElementInformation } from "../../types";
import {
  contextualMenuStyles,
  DefaultButton,
  getContextualMenuItems,
  IconButton,
} from "../styledFluentComponents";
import { cancelIcon, moreVerticalIcon } from "./config";

interface ElementMenuProps {
  elementInformation?: ElementInformation;
  elementName: string;
}

export const ElementMenu: FC<ElementMenuProps> = ({
  elementInformation,
  elementName,
}) => (
  <IconButton
    menuIconProps={moreVerticalIcon}
    styles={iconButtonStyles}
    data-selection-disabled
    menuProps={{
      shouldFocusOnMount: true,
      items: getContextualMenuItems("Detailed Information", [
        ...getWikipediaLinkItems(elementInformation?.wikipediaLinks),
        getDescriptionItem(elementInformation?.description, elementName),
      ]),
      directionalHint: DirectionalHint.topLeftEdge,
      styles: contextualMenuStyles,
    }}
  />
);

const iconButtonStyles: IButtonStyles = {
  root: {
    height: "100%",
    width: 36,
    background: "transparent",
  },
  menuIcon: { fontSize: 18 },
  rootExpanded: {
    background: NeutralColors.gray50,
  },
  rootHovered: { background: NeutralColors.gray50 },
};

function getWikipediaLinkItems(
  wikipediaLinks: string[] | undefined
): IContextualMenuItem[] {
  if (wikipediaLinks) {
    return wikipediaLinks.map((link, index) => {
      // Normalize and decode the Wikipedia page name into a display name
      const linkName = decodeURI(link.split("/").pop() ?? "").replaceAll(
        "_",
        " "
      );
      return {
        key: `wikipedia-link-${index}`,
        onRender: () => (
          <DefaultButton
            styles={menuItemStyles}
            grayScale
            href={link}
            target={link}
          >
            {linkName} Wikipedia article
            <Icon styles={linkIconStyles} iconName="OpenInNewWindow" />
          </DefaultButton>
        ),
      };
    });
  }
  return [
    {
      key: `wikipedia-link`,
      disabled: true,
      text: "No external links available",
    },
  ];
}

function getDescriptionItem(
  description: string | undefined,
  elementName: string
): IContextualMenuItem {
  return description
    ? {
        key: `description`,
        onRender: () => (
          <DescriptionItem
            elementName={elementName}
            description={description}
          />
        ),
      }
    : {
        key: `description`,
        disabled: true,
        text: "No mineral details available",
      };
}

const DescriptionItem: FC<{
  description: string;
  elementName: string;
}> = ({ description, elementName }) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(
    false
  );
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");

  return (
    <>
      <DefaultButton
        id={buttonId}
        styles={menuItemStyles}
        grayScale
        onClick={toggleIsCalloutVisible}
      >
        Mineral details
      </DefaultButton>
      {isCalloutVisible && (
        <Callout
          className={calloutStyles.container}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <h2 className={calloutStyles.title}>Mineral Details</h2>
            <IconButton
              iconProps={cancelIcon}
              ariaLabel="Close help"
              onClick={toggleIsCalloutVisible}
              styles={{ root: { background: "inherit", marginRight: 6 } }}
            />
          </Stack>
          <h3 className={calloutStyles.subTitle}>{elementName}</h3>
          <div id={descriptionId}>{description}</div>
        </Callout>
      )}
    </>
  );
};

const menuItemStyles: IButtonStyles = {
  root: {
    color: NeutralColors.gray160,
    border: "none",
    width: "100%",
    padding: "0 10px",
    textAlign: "left",
    fontWeight: "400",
    "& span": {
      fontWeight: "400",
      justifyContent: "left",
    },
  },
  label: {
    margin: 0,
  },
  rootHovered: {
    background: NeutralColors.gray50,
  },
  rootDisabled: {
    border: "none",
    background: "none",
    color: NeutralColors.gray90,
    ":hover": {
      background: "none",
    },
  },
};

const linkIconStyles: IIconStyles = {
  root: {
    fontSize: 16,
    verticalAlign: "middle",
    marginLeft: 10,
    lineHeight: 29,
  },
};

const calloutStyles = mergeStyleSets({
  container: {
    padding: "0 20px 20px 20px",
    background: NeutralColors.gray10,
    maxWidth: 364,
    minWidth: 250,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: NeutralColors.gray190,
    lineHeight: 28,
  },
  subTitle: { fontWeight: 600, fontSize: 16, lineHeight: 22, margin: 0 },
});
