import React, { FC, useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TooltipType, ViewMode } from "../../constants";
import { useAppSprings } from "../../context";
import { useLocalStorage, useTooltipClearSelector } from "../../hooks";
import {
  selectAnalyticsPaneIsOpen,
  selectSelectedImageType,
} from "../../store/selectors";
import { ComponentSize, Domain } from "../../types/types";
import { getClasses } from "../../utils";
import { DropdownDeactivator } from "../dropdown-deactivator/DropdownDeactivator";
import { StageFrame } from "../frames/frames";
import { MiniMap } from "../miniMap/MiniMap";
import { QemScanColorLegend } from "../qemScanColorLegend/qemScanColorLegend";
import { ScaleBars } from "../scaleBars/ScaleBars";
import { ViewerToolbar } from "../toolbar/ViewerToolbar";
import { WellboreNavigation } from "../wellboreNavigation/WellboreNavigation";
import { GridViewViewer } from "./GridViewViewer";
import { ImageViewer } from "./ImageViewer";
import { ViewerController } from "./ViewerController";

export interface StageProps {
  compareMode: boolean;
  setCompareMode: (compareMode: boolean) => void;
  className?: string;
  gridView: boolean;
  setGridView: (gridView: boolean | ((state: boolean) => boolean)) => void;
  mapViewIsOpen: boolean;
  setMapViewIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Stage: FC<StageProps> = ({
  className,
  gridView,
  setGridView,
  compareMode,
  setCompareMode,
  mapViewIsOpen,
  setMapViewIsOpen,
}) => {
  const selectedImageType = useSelector(selectSelectedImageType);
  const { overlayAnalytics } = useAppSprings();
  const [xDomain, setXDomain] = useState<Domain>();
  const [yDomain, setYDomain] = useState<Domain>();
  const analyticsPaneIsOpen = useTooltipClearSelector(
    selectAnalyticsPaneIsOpen,
    TooltipType.ANALYTICS_SELECTION
  );

  const viewerController = useRef<ViewerController>();

  const [viewMode, setViewMode] = useLocalStorage<ViewMode>(
    "viewMode",
    ViewMode.KEEP_VIEW
  );
  const updateViewMode = useCallback(
    (newViewMode: ViewMode) => {
      setViewMode((prevViewMode: ViewMode) => {
        if (prevViewMode === newViewMode) return ViewMode.KEEP_VIEW;
        return newViewMode;
      });
    },
    [setViewMode]
  );

  return (
    <StageFrame className={getClasses("stage", className)}>
      <ScaleBars show={!gridView} xDomain={xDomain} yDomain={yDomain} />
      <ImageViewer
        setXDomain={setXDomain}
        setYDomain={setYDomain}
        show={!gridView}
        compareMode={compareMode}
        ref={viewerController}
        // viewerController={viewerController.current}
        viewMode={viewMode}
      />
      <GridViewViewer show={gridView} setGridView={setGridView} />

      {/* Behind DataAnalyticsPane when map view is closed, and on top of DataAnalyticsPane 
              when map view is open */}
      <WellboreNavigation
        setMapViewIsOpen={setMapViewIsOpen}
        mapViewIsOpen={mapViewIsOpen}
        size={ComponentSize.SMALL}
      />

      <MiniMap
        viewerController={viewerController.current}
        viewMode={viewMode}
        setViewMode={updateViewMode}
        alignLeft={analyticsPaneIsOpen && !mapViewIsOpen && overlayAnalytics}
        mapViewIsOpen={mapViewIsOpen}
        hide={gridView}
      />

      <ViewerToolbar
        gridView={gridView}
        setGridView={setGridView}
        compareMode={compareMode}
        setCompareMode={setCompareMode}
        viewerController={viewerController.current}
      />

      {selectedImageType === "QEMSCAN_RAW_M_1" && !mapViewIsOpen && (
        <QemScanColorLegend />
      )}
      <DropdownDeactivator zIndex={5} />
    </StageFrame>
  );
};
