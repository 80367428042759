import {
  ContextualMenuItemType,
  DirectionalHint,
  IIconProps,
  IToggleStyles,
  Layer,
} from "@fluentui/react";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName, DropdownName } from "../../../constants";
import { useDropdowns } from "../../../context";
import { selectWellbores } from "../../../store";
import {
  ContextualMenu,
  SidebarButton,
  Toggle,
} from "../../styledFluentComponents";

const wellboreFilters: {
  name: AnalyticsName;
  text: string;
}[] = [
  { name: AnalyticsName.XRF, text: "XRF and photos (w/uv)" },
  { name: AnalyticsName.SPEC_CAM, text: "SpecCam" },
  { name: AnalyticsName.XRD, text: "XRD" },
  { name: AnalyticsName.QEM_SCAN, text: "QemScan" },
  { name: AnalyticsName.TOC, text: "TOC" },
];

const iconProps: IIconProps = { iconName: "Filter" };

const toggleStyles: Partial<IToggleStyles> = {
  root: { marginTop: "8px", marginRight: "8px" },
};

interface WellboreFilterSelectorProps {
  selectedWellboreFilters: AnalyticsName[];
  setSelectedWellboreFilters: React.Dispatch<
    React.SetStateAction<AnalyticsName[]>
  >;
}

export const WellboreFilterSelector: FC<WellboreFilterSelectorProps> = ({
  selectedWellboreFilters,
  setSelectedWellboreFilters,
}) => {
  const wellbores = useSelector(selectWellbores);
  const { isOpen, toggleDropdown } = useDropdowns();
  const buttonRef = useRef(null);

  const nrOfWellboresPassingFilter = useMemo(() => {
    const counts = {
      [AnalyticsName.XRF]: 0,
      [AnalyticsName.XRD]: 0,
      [AnalyticsName.TOC]: 0,
      [AnalyticsName.SPEC_CAM]: 0,
      [AnalyticsName.QEM_SCAN]: 0,
    };

    for (const wellbore of wellbores) {
      if (wellbore.analyticsData?.xrf?.dataReference) {
        counts[AnalyticsName.XRF] += 1;
      }
      if (wellbore.analyticsData?.xrd?.dataReference) {
        counts[AnalyticsName.XRD] += 1;
      }
      if (wellbore.analyticsData?.toc?.dataReference) {
        counts[AnalyticsName.TOC] += 1;
      }
      if (wellbore.analyticsData?.speccam?.dataReference) {
        counts[AnalyticsName.SPEC_CAM] += 1;
      }
      if (wellbore.analyticsData?.qemscan?.dataReference) {
        counts[AnalyticsName.QEM_SCAN] += 1;
      }
    }
    return counts;
  }, [wellbores]);

  const updateSelectedWellboreFilters = useCallback(
    (filter: AnalyticsName) => {
      setSelectedWellboreFilters((prevFilters) => {
        if (prevFilters.includes(filter)) {
          return prevFilters.filter((f) => f !== filter);
        }
        return [...prevFilters, filter];
      });
    },
    [setSelectedWellboreFilters]
  );

  const items = [
    {
      key: "wellboreFilterSelection",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        topDivider: true,
        bottomDivider: false,
        title: "Wellbore data type (RWI)",
        items: [
          {
            key: "content",
            onRender: () => (
              <div className="wellbore-filters">
                {wellboreFilters.map((filter) => (
                  <Toggle
                    styles={toggleStyles}
                    key={filter.name}
                    label={
                      <div className="wellbore-filters__label">
                        <span>{filter.text}</span>
                        <span>
                          {nrOfWellboresPassingFilter[filter.name] || ""}
                        </span>
                      </div>
                    }
                    inlineLabel
                    checked={selectedWellboreFilters.includes(filter.name)}
                    onChange={() => updateSelectedWellboreFilters(filter.name)}
                  />
                ))}
              </div>
            ),
          },
        ],
      },
    },
  ];

  return (
    <>
      <SidebarButton
        ariaLabel="Select wellbore data types"
        elementRef={buttonRef}
        onClick={() => toggleDropdown(DropdownName.WELLBORE_FILTER)}
        checked={isOpen[DropdownName.WELLBORE_FILTER]}
        iconProps={iconProps}
        rightAligned
      />

      {isOpen[DropdownName.WELLBORE_FILTER] && (
        <Layer>
          <ContextualMenu
            target={buttonRef.current}
            directionalHint={DirectionalHint.leftTopEdge}
            items={items}
            shouldFocusOnMount={true}
            shouldFocusOnContainer={true}
          />
        </Layer>
      )}
    </>
  );
};
