import { Icon, IIconProps, mergeStyleSets, Stack } from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectActiveCuttings,
  selectSelectedCutting,
  setSelectedCutting,
  useAppDispatch,
} from "../../store";
import { Cutting } from "../../types/types";
import {
  ToolbarIconButton,
  toolbarIconStyles,
} from "../styledFluentComponents/ToolbarIconButton";

const arrowUpIcon: IIconProps = { iconName: "Up" };
const arrowDownIcon: IIconProps = { iconName: "Down" };

interface CuttingsNavigationButtonProps {
  direction: "up" | "down";
  disabled: boolean;
}

export const CuttingsNavigationButton: FC<CuttingsNavigationButtonProps> = ({
  direction,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const selectedCutting = useSelector(selectSelectedCutting);
  const activeCuttings = useSelector(selectActiveCuttings);
  const [nextCutting, setNextCutting] = useState<Cutting>();

  useEffect(() => {
    if (selectedCutting && activeCuttings) {
      const indexOfSelectedCutting = activeCuttings.findIndex(
        (c) => selectedCutting && c.id === selectedCutting.id
      );
      if (direction === "up" && indexOfSelectedCutting - 1 >= 0) {
        setNextCutting(activeCuttings[indexOfSelectedCutting - 1]);
      } else if (
        direction === "down" &&
        indexOfSelectedCutting + 1 <= activeCuttings.length - 1
      ) {
        setNextCutting(activeCuttings[indexOfSelectedCutting + 1]);
      } else {
        setNextCutting(undefined);
      }
    }
  }, [activeCuttings, direction, selectedCutting]);

  const moveSelectedCuttingByNumber = (diff: number) => {
    if (!activeCuttings) return;

    const indexOfSelectedCutting = activeCuttings.findIndex(
      (c) => selectedCutting && c.id === selectedCutting.id
    );
    const newIndex = Math.max(
      0,
      Math.min(activeCuttings.length - 1, indexOfSelectedCutting + diff)
    );
    dispatch(setSelectedCutting(activeCuttings[newIndex]));
  };

  const iconProps = direction === "up" ? arrowUpIcon : arrowDownIcon;

  const buttonIsDisabled = disabled || nextCutting === undefined;

  return (
    <ToolbarIconButton
      className="cuttings-navigation-button"
      ariaLabel={
        direction === "up" ? "Move up one cutting" : "Move down one cutting"
      }
      onClick={() => moveSelectedCuttingByNumber(direction === "up" ? -1 : 1)}
      disabled={buttonIsDisabled}
    >
      <Stack>
        <Icon
          {...iconProps}
          styles={mergeStyleSets(toolbarIconStyles, {
            root: {
              color: buttonIsDisabled ? NeutralColors.gray90 : undefined,
            },
          })}
        />
        {!buttonIsDisabled && (
          <div className="cuttings-navigation-button__depth">
            {nextCutting?.depth} m
          </div>
        )}
      </Stack>
    </ToolbarIconButton>
  );
};
