import * as d3 from "d3";
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import { useResizeObserver, useThrottledState } from "../../../hooks";
import {
  loadNewWellbore,
  selectSelectedWellbore,
  selectWellbores,
  useAppDispatch,
} from "../../../store";
import { MapArea } from "../../../types";
import { StageFrameSVG } from "../../frames/frames";
import { UI_SPRING_CONFIG } from "../../stage/config";
import {
  drawBlocks,
  drawFields,
  drawLand,
  drawNonRwiWellbores,
  drawQuads,
  drawWellbores,
} from "./drawing";
import {
  centerMapOnXY,
  filterWellboresWithCoordinates,
  getContainerSelections,
  getVisibleWellbores,
  getZoomHandler,
  zoomTo,
} from "./helpers";
import useGeoFiles from "./useGeoFiles";
import { useZoomToWellbore } from "./useZoomToWellbore";
import WellboreAttributeResolver from "./WellboreAttributeResolver";
import { WellboreTraversalController } from "./WellboreTraversalController";

const NORWEGIAN_SEA: [number, number] = [3, 63]; // center of map on load
const GREENLAND: [number, number] = [-30, 78]; // top-left corner of the pannable map
const KAZAKHSTAN: [number, number] = [60, 45]; // bottom-right corner of the pannable map

const INITIAL_ZOOM = 0.02;
export const MIN_ZOOM = 0.015;
export const MAX_ZOOM = 8;
export const MAP_TRANSLATION_DURATION = 2200;

export const QUAD_AND_BLOCK_BREAKPOINT = 0.06;
export const SHOW_WELLBORES_BREAKPOINT = 0.45;

const projection = d3.geoMercator().scale(100000);
const path = d3.geoPath().projection(projection);

export const attributeResolver = new WellboreAttributeResolver({
  stepSize: 0.025,
  minScale: MIN_ZOOM,
  maxScale: MAX_ZOOM,
  projection: projection,
});

export const zoom = d3
  .zoom()
  .scaleExtent([MIN_ZOOM, MAX_ZOOM])
  .translateExtent([
    projection(GREENLAND) as [number, number],
    projection(KAZAKHSTAN) as [number, number],
  ]);

interface MapViewProps {
  isOpen: boolean;
  wellboreFilters: AnalyticsName[];
}

export const MapView: FC<MapViewProps> = ({ isOpen, wellboreFilters }) => {
  const dispatch = useAppDispatch();
  const wellbores = useSelector(selectWellbores);
  const selectedWellbore = useSelector(selectSelectedWellbore);

  const filteredRwiWellbores = useMemo(
    () => filterWellboresWithCoordinates(wellbores),
    [wellbores]
  );

  const [size, svgRef] = useResizeObserver();
  const hasDrawnMap = useRef(false);

  const [visibleArea, setVisibleArea] = useThrottledState<MapArea | undefined>(
    undefined,
    500
  );

  const {
    landGeoJson,
    blocksGeoJson,
    quadGeoJson,
    fieldsGeoJson,
    allWellbores,
    loading,
    setLoading,
  } = useGeoFiles();

  const nonRwiWellbores = useMemo(() => {
    const rwiWellboreNames = wellbores.map((w) => w.name);
    const nonRwiWellbores = allWellbores.filter(
      (w) => !rwiWellboreNames.includes(w.name)
    );
    return nonRwiWellbores;
  }, [wellbores, allWellbores]);

  const updateSelectedWellbore = useCallback(
    // eslint-disable-next-line
    (dataOrEvent: any) => {
      let id: string;
      if (dataOrEvent?.srcElement?.__data__?.id) {
        id = dataOrEvent.srcElement.__data__.id;
      } else if (dataOrEvent?.srcElement?.__data__?.data?.id) {
        id = dataOrEvent.srcElement.__data__.data.id;
      } else if (dataOrEvent?.id) {
        id = dataOrEvent.id;
      }
      const selectedWellbore = wellbores?.find((c) => c.id === id);
      if (selectedWellbore) {
        dispatch(loadNewWellbore(selectedWellbore));
      }
    },
    [dispatch, wellbores]
  );

  /**
   * Initialize the map
   */
  useEffect(() => {
    const svg = d3.select("#map-view");
    svg.append("g").attr("id", "land-container");
    svg.append("g").attr("id", "quad-container");
    svg.append("g").attr("id", "block-container");
    svg.append("g").attr("id", "fields-container");
    svg.append("g").attr("id", "non-rwi-wellbore-container");
    svg.append("g").attr("id", "wellbore-container");

    zoom.on("zoom", getZoomHandler(setVisibleArea, projection));

    svg.call(zoom as any); // eslint-disable-line

    centerMapOnXY(svg, zoom, projection(NORWEGIAN_SEA));
    zoomTo(svg, zoom, INITIAL_ZOOM);

    return () => {
      svg.selectAll("*").remove();
    };
    // eslint-disable-next-line
  }, []);

  const zoomToWellbore = useZoomToWellbore(size);

  /**
   * Draw land, blocks and quads
   */
  useEffect(() => {
    if (
      landGeoJson &&
      quadGeoJson &&
      blocksGeoJson &&
      fieldsGeoJson &&
      isOpen &&
      !hasDrawnMap.current
    ) {
      setTimeout(function drawMap() {
        const s = getContainerSelections();
        s.landContainer.selectAll("*").remove();
        s.quadContainer.selectAll("*").remove();
        s.blockContainer.selectAll("*").remove();

        drawLand(landGeoJson, path);
        drawQuads(quadGeoJson, path);
        drawBlocks(blocksGeoJson, path);
        drawFields(fieldsGeoJson, path);

        setLoading(false);
      }, UI_SPRING_CONFIG.duration + 50);

      hasDrawnMap.current = true;
    }
    // eslint-disable-next-line
  }, [landGeoJson, quadGeoJson, blocksGeoJson, fieldsGeoJson, isOpen]);

  useEffect(() => {
    if (filteredRwiWellbores && nonRwiWellbores && visibleArea) {
      const normalizedZoom = attributeResolver.getLinearizedAndNormalizedScale(
        visibleArea.transform.k
      );

      const visibleWellbores = getVisibleWellbores(
        filteredRwiWellbores,
        visibleArea,
        normalizedZoom,
        0
      );

      drawWellbores(
        visibleWellbores,
        visibleArea,
        attributeResolver,
        selectedWellbore,
        updateSelectedWellbore,
        wellboreFilters
      );

      const visibleNonRwiWellbores = getVisibleWellbores(
        nonRwiWellbores,
        visibleArea,
        normalizedZoom,
        SHOW_WELLBORES_BREAKPOINT
      );

      drawNonRwiWellbores(
        visibleNonRwiWellbores,
        visibleArea,
        attributeResolver
      );
    }
  }, [
    filteredRwiWellbores,
    nonRwiWellbores,
    visibleArea,
    selectedWellbore,
    updateSelectedWellbore,
    wellboreFilters,
  ]);

  return (
    <StageFrameSVG
      id="map-view"
      show={isOpen}
      zIndexOnShow={50}
      zIndexOnHide={30}
      ref={svgRef}
      loading={loading}
      nonSvgChildren={() => (
        <WellboreTraversalController
          selectedWellboreFilters={wellboreFilters}
          zoomToWellbore={zoomToWellbore}
        />
      )}
    ></StageFrameSVG>
  );
};
