import React, { FC, PropsWithChildren, ReactElement } from "react";
import { useSelector } from "react-redux";
import { useDrag } from "react-use-gesture";
import {
  SIDEBAR_MENU_DROPDOWNS,
  useAppSprings,
  useDropdowns,
} from "../../context";
import { useElementSize, useThumbnail } from "../../hooks";
import {
  selectActiveWellbore,
  selectCuttingsDepthIndexes,
  selectCuttingsDepths,
  selectSelectedCuttingIndex,
  selectSelectedImageType,
} from "../../store";
import { RenderingConstraints, ThumbnailChunk, ZoomArea } from "../../types";
import { getNumItemsBetween } from "../../utils";
import { ZoomAreaFrame } from "../frames/track-frames";
import { HighlightedDepthIndicator } from "../highlightedDepthIndicator/HighlightedDepthIndicator";
import { ResizeThumb } from "../resizeThumb/ResizeThumb";
import { TrackContent, TrackFooter, TrackHeader } from "../track";

const ZOOM_AREA_MIN_WIDTH = 60;
const ZOOM_AREA_MAX_WIDTH = 5 * ZOOM_AREA_MIN_WIDTH;
const NUMBER_OF_IMAGES_TO_PRELOAD = 300;

interface ZoomAreaProps {
  renderingConstraints: RenderingConstraints;
  highlightedDepth: number | undefined;
  selectedZoomArea: ZoomArea;
}

export const ZoomAreaTrack: FC<ZoomAreaProps> = ({
  renderingConstraints,
  highlightedDepth,
  selectedZoomArea,
}) => {
  const { closeDropdown } = useDropdowns();
  const depths = useSelector(selectCuttingsDepths);
  const depthIndexes = useSelector(selectCuttingsDepthIndexes);
  const selectedCuttingIndex = useSelector(selectSelectedCuttingIndex);

  const [size, contentRef] = useElementSize<HTMLDivElement>();

  const { zoomAreaSpring, setZoomAreaSpring } = useAppSprings();

  const bind = useDrag(
    ({ delta: [dx] }) => {
      const currentWidth = zoomAreaSpring?.width?.getValue() as number;
      const changeLeft = -dx;

      const nextWidth = Math.max(
        Math.min(currentWidth + changeLeft, ZOOM_AREA_MAX_WIDTH),
        ZOOM_AREA_MIN_WIDTH
      );
      if (nextWidth !== currentWidth) {
        setZoomAreaSpring({ width: nextWidth });
      }
    },
    {
      initial: () => [0, zoomAreaSpring?.width?.getValue()],
      filterTaps: true,
      axis: "x",
    }
  );

  const topDepth = depths[selectedZoomArea.topIdx];
  const bottomDepth = depths[selectedZoomArea.bottomIdx];

  return (
    <ZoomAreaFrame
      className="zoom-area"
      onClick={() => closeDropdown(SIDEBAR_MENU_DROPDOWNS)}
    >
      <TrackHeader>{topDepth ? `${topDepth} m` : ""}</TrackHeader>
      <TrackContent ref={contentRef}>
        {size && (
          <>
            <ZoomAreaImages
              zoomArea={selectedZoomArea}
              renderingConstraints={renderingConstraints}
            >
              {selectedCuttingIndex && (
                <CurrentImageOutline cuttingIndex={selectedCuttingIndex} />
              )}
            </ZoomAreaImages>

            <HighlightedDepthIndicator
              size={size}
              highlightedIndex={
                highlightedDepth !== undefined
                  ? depthIndexes[highlightedDepth] - selectedZoomArea.topIdx
                  : undefined
              }
              renderingConstraints={renderingConstraints}
            />
          </>
        )}
      </TrackContent>

      <TrackFooter>{bottomDepth ? `${bottomDepth} m` : ""}</TrackFooter>

      <div className="track__resize-handle" {...bind()}></div>

      <ResizeThumb {...bind()} />
    </ZoomAreaFrame>
  );
};

const ZoomAreaImages: FC<{
  zoomArea: ZoomArea;
  renderingConstraints: RenderingConstraints;
}> = ({ zoomArea, renderingConstraints, children }) => {
  const activeWellbore = useSelector(selectActiveWellbore);
  const selectedImageType = useSelector(selectSelectedImageType);
  const thumbnail = useThumbnail(activeWellbore, selectedImageType);

  if (thumbnail) {
    const numImages = getNumItemsBetween(zoomArea.topIdx, zoomArea.bottomIdx);
    const imageHeight = renderingConstraints.trackContentHeight / numImages;

    return (
      <div
        className="zoom-area-images"
        style={{
          top: renderingConstraints.outerTrackMargin.top,
          height: renderingConstraints.trackContentHeight,
        }}
      >
        <div
          className="zoom-area-images__container"
          style={{
            transform: `translateY(${-zoomArea.topIdx * imageHeight}px)`,
          }}
        >
          {getChunksToLoad(
            thumbnail.chunks,
            zoomArea.bottomIdx,
            NUMBER_OF_IMAGES_TO_PRELOAD
          ).map((c) => {
            const numImagesInChunk = getNumItemsBetween(
              c.startIndex,
              c.endIndex
            );
            return (
              <img
                style={{
                  height: imageHeight * numImagesInChunk,
                }}
                key={c.startIndex}
                src={c.storageReference}
                alt="zoom area cutting images"
              ></img>
            );
          })}

          {React.Children.map(React.Children.toArray(children), (child) => {
            const element = child as ReactElement<
              PropsWithChildren<{ imageHeight: number }>
            >;
            if (element) {
              return React.cloneElement(element, {
                ...element.props,
                imageHeight: imageHeight,
              });
            }
            return null;
          })}
        </div>
      </div>
    );
  }

  return null;
};

const CurrentImageOutline: FC<{
  cuttingIndex: number;
  imageHeight?: number;
}> = ({ cuttingIndex, imageHeight }) => {
  if (imageHeight === undefined) {
    throw new Error("CurrentImageOutline requrie the imageHeight prop");
  }

  return (
    <div
      className="current-image-outline"
      style={{
        top: cuttingIndex * imageHeight,
        height: imageHeight + 0.5, // half pixel added to make sure CurrentImageOutline allways cover whole image,
      }}
    ></div>
  );
};

function getChunksToLoad(
  chunks: ThumbnailChunk[],
  cuttingIndex: number,
  amountToPreload: number
) {
  const maxIndexToLoad = Math.min(
    cuttingIndex + amountToPreload,
    chunks[chunks.length - 1].endIndex
  );

  return chunks.filter((c) => c.endIndex <= maxIndexToLoad);
}
