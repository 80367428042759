import { firebaseConfig } from "../services/configureFirebase";
import { DEV_ORIGIN } from "../constants";

export async function createShortLink(): Promise<{
  previewLink: string;
  shortLink: string;
}> {
  const domainUri =
    process.env.NODE_ENV === "development"
      ? DEV_ORIGIN
      : window.location.origin;

  return fetch(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dynamicLinkInfo: {
          domainUriPrefix: `${domainUri}/share`,
          link: `${domainUri}${window.location.pathname}${window.location.hash}${window.location.search}`,
        },
        suffix: {
          option: "UNGUESSABLE",
        },
      }),
    }
  ).then((res) => {
    if (res.status >= 400) {
      throw Error("Request failed");
    }
    return res.json();
  });
}
