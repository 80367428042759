import {
  MISSING_STRATIGRAPHY_COLOR,
  MISSING_STRATIGRAPHY_NAME,
} from "../config";
import { Optional, Stratigraphy, TopGroup } from "../types";

export function getClasses(
  ...classes: (string | undefined | { [className: string]: boolean })[]
): string {
  return classes
    .flatMap((element) => {
      if (typeof element === "string") {
        return element;
      }
      if (element === undefined) {
        return "";
      }
      return Object.entries(element).reduce<string[]>(
        (acc, [cls, condition]) => {
          if (condition) {
            acc.push(cls);
          }
          return acc;
        },
        []
      );
    })
    .join(" ");
}

export function getGroupAndFormation(
  topGroups: Optional<TopGroup[]>,
  depth: number
): Stratigraphy {
  let group, formation;
  if (topGroups) {
    const lastGroupIndex = topGroups.length - 1;
    group = topGroups.find((topGroup, i) => {
      const isLastGroup = i === lastGroupIndex;
      if (!isLastGroup) {
        return topGroup.top <= depth && depth < topGroup.bottom;
      }
      return topGroup.top <= depth && depth <= topGroup.bottom;
    });
    if (group) {
      const lastFormationIndex = group.formations.length - 1;
      formation = group.formations.find((formation, i) => {
        const isLastFormation = i === lastFormationIndex;
        if (!isLastFormation) {
          return formation.top <= depth && depth < formation.bottom;
        }
        return formation.top <= depth && depth <= formation.bottom;
      });
    }
  }
  return {
    group: {
      name: group?.name ?? MISSING_STRATIGRAPHY_NAME,
      color: group?.color ?? MISSING_STRATIGRAPHY_COLOR,
    },
    formation: {
      name: formation?.name ?? MISSING_STRATIGRAPHY_NAME,
      color: formation?.color ?? MISSING_STRATIGRAPHY_COLOR,
    },
  };
}

export const isTWellbore = (wellboreName: string): boolean =>
  /t/gi.test(wellboreName);

type ClickHandler = (e: PointerEvent) => void;
export class DoubleClickDetector {
  clickHandler?: ClickHandler;
  doubleClickHandler?: ClickHandler;
  private _DOUBLE_CLICK_TIMEOUT = 300;
  private _timeOfLastClick?: number;
  private _targetOfLastClick?: EventTarget | null;
  private _clickHandlerTimeout?: number;

  detect(e: PointerEvent): boolean {
    const timeOfClick = Date.now();

    if (
      this._targetOfLastClick !== undefined &&
      this._timeOfLastClick !== undefined &&
      this._targetOfLastClick === e.target &&
      timeOfClick - this._timeOfLastClick <= this._DOUBLE_CLICK_TIMEOUT
    ) {
      window.clearTimeout(this._clickHandlerTimeout);
      this.doubleClickHandler?.(e);
      return true;
    }

    this._timeOfLastClick = timeOfClick;
    this._targetOfLastClick = e.target;
    this._clickHandlerTimeout = window.setTimeout(
      () => this.clickHandler?.(e),
      this._DOUBLE_CLICK_TIMEOUT + 10
    );
    return false;
  }

  onClick(handler: ClickHandler): this {
    this.clickHandler = handler;
    return this;
  }
  onDoubleClick(handler: ClickHandler): this {
    this.doubleClickHandler = handler;
    return this;
  }
}
