import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AnimatedValue, SetUpdateFn, useSpring } from "react-spring";
import { HEIGHT_OF_MENU, WIDTH_OF_ANALYTICS_LIST } from "../components";
import { UI_SPRING_CONFIG } from "../components/stage/config";
import { WIDTH_OF_WELL_SYMBOLS_TRACK } from "../components/wellDataSymbols/WellDataSymbols";
import { useElementSize } from "../hooks";
import {
  selectOverlayAnalytics,
  selectShowWellTops,
  selectShowZoomAreaTrack,
} from "../store/selectors";

type SpringContextType = {
  rangeFinderSpring: AnimatedValue<{ width: number }> | undefined;
  setRangeFinderSpring: SetUpdateFn<{
    width: number;
  }>;
  analyticsSpring: AnimatedValue<{ width: number; height: number }> | undefined;
  setAnalyticsSpring: SetUpdateFn<{
    width: number;
    height: number;
  }>;
  zoomAreaSpring: AnimatedValue<{ width: number }> | undefined;
  setZoomAreaSpring: SetUpdateFn<{
    width: number;
  }>;
  overlayAnalytics: boolean;
  showWellTops: boolean;
  showZoomAreaTrack: boolean;
  widthOfWellDataSymbols: number;
  setNumberOfSelectedWellDataSymbols: (num: number) => void;
  pageSize: DOMRect | undefined;
  pageRef: React.RefObject<HTMLDivElement>;
};

type SpringProviderProps = {
  children: React.ReactNode;
};

const SpringContext = React.createContext<SpringContextType>({
  rangeFinderSpring: undefined,
  setRangeFinderSpring: () => null,
  zoomAreaSpring: undefined,
  setZoomAreaSpring: () => null,
  analyticsSpring: undefined,
  setAnalyticsSpring: () => null,
  overlayAnalytics: true,
  showWellTops: false,
  showZoomAreaTrack: false,
  widthOfWellDataSymbols: 0,
  setNumberOfSelectedWellDataSymbols: () => null,
  pageSize: undefined,
  pageRef: React.createRef(),
});

export const SpringProvider: React.ComponentType<SpringProviderProps> = ({
  children,
}: SpringProviderProps) => {
  // Todo: avoid proxying this peace of state
  const overlayAnalytics = useSelector(selectOverlayAnalytics);
  const showWellTops = useSelector(selectShowWellTops);
  const showZoomAreaTrack = useSelector(selectShowZoomAreaTrack);

  const [
    numberOfSelectedWellDataSymbols,
    setNumberOfSelectedWellDataSymbols,
  ] = useState(0);
  const [pageSize, pageRef] = useElementSize<HTMLDivElement>();

  const [rangeFinderSpring, setRangeFinderSpring] = useSpring(() => ({
    width: 60,
    immediate: true,
    config: UI_SPRING_CONFIG,
  }));

  const [analyticsSpring, setAnalyticsSpring] = useSpring(() => ({
    width: WIDTH_OF_ANALYTICS_LIST,
    height: HEIGHT_OF_MENU,
    immediate: true,
    config: UI_SPRING_CONFIG,
  }));

  const [zoomAreaSpring, setZoomAreaSpring] = useSpring(() => ({
    width: 60,
    immediate: true,
    config: UI_SPRING_CONFIG,
  }));

  return (
    <SpringContext.Provider
      value={{
        rangeFinderSpring: rangeFinderSpring as AnimatedValue<{
          width: number;
        }>,
        setRangeFinderSpring: setRangeFinderSpring as SetUpdateFn<{
          width: number;
        }>,
        analyticsSpring: analyticsSpring as AnimatedValue<{
          width: number;
          height: number;
        }>,
        setAnalyticsSpring: setAnalyticsSpring as SetUpdateFn<{
          width: number;
          height: number;
        }>,
        zoomAreaSpring: zoomAreaSpring as AnimatedValue<{
          width: number;
        }>,
        setZoomAreaSpring: setZoomAreaSpring as SetUpdateFn<{
          width: number;
        }>,
        overlayAnalytics,
        showWellTops,
        showZoomAreaTrack,

        widthOfWellDataSymbols:
          numberOfSelectedWellDataSymbols * WIDTH_OF_WELL_SYMBOLS_TRACK,
        setNumberOfSelectedWellDataSymbols,
        pageSize,
        pageRef,
      }}
    >
      {children}
    </SpringContext.Provider>
  );
};

export const useAppSprings = (): SpringContextType =>
  React.useContext(SpringContext);
