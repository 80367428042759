import { IButtonStyles, ITextField, ITextFieldStyles } from "@fluentui/react";
import React, { FC, useCallback, useLayoutEffect, useRef } from "react";
import { Optional, StateSetter } from "../../../types";
import { IconButton, TextField } from "../../styledFluentComponents";
import { cancelIcon } from "../config";

interface FilterTextFieldProps {
  setFilterText: StateSetter<Optional<string>>;
  setFilterActive: StateSetter<boolean>;
  placeHolder: string;
}

export const FilterTextField: FC<FilterTextFieldProps> = ({
  setFilterText,
  setFilterActive,
  placeHolder,
}) => {
  const ref = useRef<ITextField>(null);

  useLayoutEffect(() => {
    ref.current?.focus();
  }, []);

  const onKeyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // stop document keydown event listeners from triggering on text input
      event.nativeEvent.stopImmediatePropagation();
      if (event.key === "Escape") {
        setFilterActive(false);
      }
    },
    [setFilterActive]
  );

  const onChangeHandler = useCallback(
    (
      _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue: Optional<string>
    ) => {
      setFilterText(newValue);
    },
    [setFilterText]
  );

  return (
    <TextField
      componentRef={ref}
      placeholder={placeHolder}
      styles={filterTextFieldStyles}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      onRenderSuffix={() => (
        <IconButton
          iconProps={cancelIcon}
          styles={suffixStyles}
          ariaLabel="Close filter"
          onClick={() => {
            setFilterActive(false);
          }}
        />
      )}
    />
  );
};

const filterTextFieldStyles: Partial<ITextFieldStyles> = {
  suffix: { padding: 0, background: "none" },
  root: { flexGrow: 1 },
};

const suffixStyles: IButtonStyles = {
  root: {
    background: "none",
    height: "100%",
  },
};
