import * as d3 from "d3";
import { useCallback, useEffect } from "react";
import { from, Observable, Subject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import {
  loadNewWellbore,
  setSelectedWellbore,
  useAppDispatch,
} from "../../../store";
import { Optional, Size, Wellbore } from "../../../types";
import { attributeResolver, MAP_TRANSLATION_DURATION, zoom } from "./MapView";

const wellboreToZoomTo$ = new Subject<Wellbore>();

export function useZoomToWellbore(
  size: Optional<Size>
): (wellbore: Wellbore) => void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getZoomObservable$ = (wellbore: Wellbore) => {
      return new Observable<Wellbore>((observable) => {
        const svg = d3.select("#map-view");
        if (size && svg) {
          const x = attributeResolver.getPointPositionX(wellbore);
          const y = attributeResolver.getPointPositionY(wellbore);
          svg
            .transition()
            .duration(MAP_TRANSLATION_DURATION)
            .call(
              // @ts-ignore
              zoom.transform,
              d3.zoomIdentity
                .translate(size.width / 2, size.height / 2)
                .translate(-x, -y)
            )
            .on("end", () => {
              observable.next(wellbore);
              observable.complete();
            });
        } else {
          observable.error(
            new Error(
              "Can not perform map transform due to missing size of the svg element of missing the svg element itself"
            )
          );
        }
      });
    };

    const zoomHandlerSubscription = wellboreToZoomTo$
      .pipe(
        tap((wellbore) => dispatch(setSelectedWellbore(wellbore))),
        switchMap((wellbore) => from(getZoomObservable$(wellbore))),
        tap((wellbore) => dispatch(loadNewWellbore(wellbore)))
      )
      .subscribe();

    return () => zoomHandlerSubscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, size]);

  const zoomToWellbore = useCallback((wellbore: Wellbore): void => {
    wellboreToZoomTo$.next(wellbore);
  }, []);

  return zoomToWellbore;
}
