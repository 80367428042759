import firebase from "firebase/app";
import { useState, useEffect } from "react";

type AuthenticationDataType = {
  user: firebase.User | undefined;
  loading: boolean;
};

const useFirebaseAuthentication = (): AuthenticationDataType => {
  const [user, setUser] = useState<firebase.User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setUser(user ?? undefined);
        setLoading(false);
      });
    return unregisterAuthObserver;
  }, []);

  return { user, loading };
};

export default useFirebaseAuthentication;
