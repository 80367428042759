import { WellborePosition } from "../../../types";
import PositionResolver from "./PositionResolver";

const showNonRwiWellboreLabels = 0.6;
const showRwiWellboreLabels = 0.5;

export interface DynamicAttributes {
  point: {
    radius: number;
    padding: number;
  };
  label: {
    display: "none" | "inline";
    fontSize: number;
  };
  nonRwiPoint: {
    radius: number;
  };
  nonRwiLabel: {
    display: "none" | "inline";
    fontSize: number;
  };
}

export default class WellboreAttributeResolver {
  stepSize: number;
  minScale: number;
  maxScale: number;
  lastResolvedStep: number;
  positionResolver: PositionResolver;

  constructor(config: {
    stepSize: number;
    minScale: number;
    maxScale: number;
    projection: d3.GeoProjection;
  }) {
    this.stepSize = config.stepSize;
    this.minScale = config.minScale;
    this.maxScale = config.maxScale;
    this.lastResolvedStep = -Infinity;
    this.positionResolver = new PositionResolver(config.projection);
  }

  getDynamicAttributes(currentScale: number): DynamicAttributes | undefined {
    if (this.shouldUpdateAttributes(currentScale)) {
      return this.forceGetDynamicAttributes(currentScale);
    }
  }

  shouldUpdateAttributes(currentScale: number): boolean {
    return (
      Math.abs(
        this.lastResolvedStep -
          this.getLinearizedAndNormalizedScale(currentScale)
      ) >= this.stepSize
    );
  }

  forceGetDynamicAttributes(currentScale: number): DynamicAttributes {
    const scaleMultiplier = this.getLinearizedAndNormalizedScale(currentScale);
    this.lastResolvedStep = scaleMultiplier;
    return {
      point: {
        radius: this.getPointRadius(scaleMultiplier),
        padding: this.getPointPadding(scaleMultiplier),
      },
      label: {
        display: this.getLabelDisplay(scaleMultiplier, showRwiWellboreLabels),
        fontSize: this.getLabelFontSize(scaleMultiplier),
      },
      nonRwiPoint: {
        radius: this.getPointRadius(scaleMultiplier, 0.5, 20),
      },
      nonRwiLabel: {
        display: this.getLabelDisplay(
          scaleMultiplier,
          showNonRwiWellboreLabels
        ),
        fontSize: this.getLabelFontSize(scaleMultiplier, 1, 20),
      },
    };
  }

  private getPointPadding(
    multiplier: number,
    smallestPadding = 0.2,
    largestPadding = 5
  ) {
    return Math.min(
      Math.max(
        largestPadding - multiplier * (largestPadding - smallestPadding),
        smallestPadding
      ),
      largestPadding
    );
  }

  private getPointRadius(
    multiplier: number,
    smallestRadius = 0.85,
    largestRadius = 30
  ) {
    return Math.min(
      Math.max(
        largestRadius - multiplier * (largestRadius - smallestRadius),
        smallestRadius
      ),
      largestRadius
    );
  }
  private getLabelDisplay(multiplier: number, breakpoint: number) {
    if (multiplier >= breakpoint) {
      return "inline";
    } else {
      return "none";
    }
  }
  private getLabelFontSize(
    multiplier: number,
    smallestFontSize = 2,
    largestFontSize = 30
  ) {
    return Math.min(
      Math.max(
        largestFontSize - multiplier * (largestFontSize - smallestFontSize),
        smallestFontSize
      ),
      largestFontSize
    );
  }

  getPointPositionX(w: WellborePosition): number {
    return this.positionResolver.getPositionX(w);
  }

  getPointPositionY(w: WellborePosition): number {
    return this.positionResolver.getPositionY(w);
  }

  getIconPositionX(w: WellborePosition, circleRadius: number): number {
    return this.positionResolver.getPositionX(w) - circleRadius;
  }

  getIconPositionY(w: WellborePosition, circleRadius: number): number {
    return this.positionResolver.getPositionY(w) - circleRadius;
  }

  /**
   * Get the x coordinate for a wellbore's label. If mulitple points are close in proximity
   * thier labels are distributed around on an elipsis.
   */
  getLabelPositionX(
    w: WellborePosition,
    currentScale: number,
    minXRadius = 9,
    maxXRadius = 80
  ): number {
    const scaleMultiplier = this.getLinearizedAndNormalizedScale(currentScale);
    const xRadius = maxXRadius - scaleMultiplier * (maxXRadius - minXRadius);
    return this.positionResolver.getLabelPositionX(w, xRadius);
  }

  /**
   * Get the y coordinate for a wellbore's label. If mulitple points are close in proximity
   * thier labels are distributed around on an elipsis.
   */
  getLabelPositionY(
    w: WellborePosition,
    currentScale: number,
    minYRadius = 3.5,
    maxYRadius = 45
  ): number {
    const scaleMultiplier = this.getLinearizedAndNormalizedScale(currentScale);
    const yRadius = maxYRadius - scaleMultiplier * (maxYRadius - minYRadius);
    return this.positionResolver.getLabelPositionY(w, yRadius);
  }

  getLinearizedAndNormalizedScale(currentScale: number): number {
    const x = (y: number) => {
      if (y <= 0.1243) {
        return (
          -5.4035616670436735 +
          612.32132594612949 * y +
          -2734.2845609613782 * Math.pow(y, 2)
        );
      } else if (0.1243 < y && y <= 1.2347) {
        return (
          26.59834131179722 +
          46.09861042619815 * y -
          19.949616597768792 * Math.pow(y, 2)
        );
      } else {
        return (
          52.852626684825125 +
          3.234503545662712 * y -
          0.10156517011058759 * Math.pow(y, 2)
        );
      }
    };

    return (
      (x(currentScale) - x(this.minScale)) /
      (x(this.maxScale) - x(this.minScale))
    );
  }
}
