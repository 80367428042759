import { IColumn, IDropdownOption } from "@fluentui/react";
import { AnalyticsName, ChartType } from "../../../constants";
import { ElementsConfig, ElementsGroup } from "../../../types/types";
import { SortMethod } from "../../../utils/sorting";

export const DEFAULT_CHART_TYPE = ChartType.STACKED_BAR;
export const DEFAULT_CHART_TYPES: Record<
  AnalyticsName,
  Exclude<ChartType, ChartType.NONE>
> = {
  [AnalyticsName.XRF]: ChartType.STACKED_BAR,
  [AnalyticsName.TOC]: ChartType.STACKED_BAR,
  [AnalyticsName.XRD]: ChartType.STACKED_BAR,
  [AnalyticsName.QEM_SCAN]: ChartType.STACKED_BAR,
  [AnalyticsName.SPEC_CAM]: ChartType.STACKED_BAR,
};

export function getChartTypeToPlot(
  chartType: ChartType,
  tab: AnalyticsName
): Exclude<ChartType, ChartType.NONE> {
  return chartType === ChartType.NONE ? DEFAULT_CHART_TYPES[tab] : chartType;
}

export const sortOptions: IDropdownOption[] = [
  { key: SortMethod.STRING_ASCENDING, text: "Name: A to Z" },
  { key: SortMethod.STRING_DECENDING, text: "Name: Z to A" },
  { key: SortMethod.STRING_NUMBER_ASCENDING, text: "Value: Low to high" },
  { key: SortMethod.STRING_NUMBER_DECENDING, text: "Value: High to low" },
];

export const sortMethodDropdownStyles = { root: { width: 160 } };
export const infoIcon = { iconName: "Info" };
export const downloadIcon = { iconName: "Download" };
export const filterIcon = { iconName: "Filter" };
export const cancelIcon = { iconName: "Cancel" };
export const moreVerticalIcon = { iconName: "MoreVertical" };

export const columnsConfig: IColumn[] = [
  {
    key: "key",
    name: "key",
    fieldName: "key",
    minWidth: 173,
    maxWidth: 173,
    isResizable: false,
  },
  {
    key: "value",
    name: "value",
    fieldName: "value",
    minWidth: 82,
    maxWidth: 82,
    isResizable: false,
  },
  {
    key: "menu-bar",
    name: "menu-bar",
    fieldName: "menu-bar",
    className: "no-padding",
    minWidth: 16,
    maxWidth: 16,
    isResizable: false,
  },
];

export const PDFtoolTipTexts = {
  xrfData: "XRF info file from Diskos",
  xrfSample: "Sample Quality info file from Diskos",
  speccamData: "SpecCam info file from Diskos",
  xrdData: "XRD info file from Diskos",
  tocData: "TOC info file from Diskos",
  qemscanData: "QemScan detailed mineral info file from Diskos",
  qemscanSampleText: "QemScan info file from Diskos",
};

export const getExportToExcelTooltipText = (wellboreName: string): string =>
  `Export analytics data for wellbore ${wellboreName} to Excel`;

export function getUnits(
  elementsConfig: ElementsConfig
): Record<string, string> {
  const result: Record<string, string> = {};

  Object.entries(elementsConfig).forEach(([category, categoryData]) => {
    categoryData.elements.forEach((element) => {
      result[element.outputName] = categoryData.unit || element.unit || "";
    });
    categoryData?.groups?.forEach((group) => {
      result[group.outputName] = categoryData.unit || group.unit || "";
      group.elements.forEach((element) => {
        result[element.outputName] =
          categoryData.unit || group.unit || element.unit || "";
      });
    });
  });

  return result;
}

export function getElementsInGroups(
  config: ElementsConfig,
  groupKeys: string[]
): string[] {
  const selectedGroups: ElementsGroup[] = [];
  for (const category of Object.values(config)) {
    const _selectedGroups = category.groups?.filter((g) =>
      groupKeys.includes(g.outputName)
    );
    if (_selectedGroups) selectedGroups.push(..._selectedGroups);
  }
  return selectedGroups.flatMap((g) => g.elements).map((e) => e.outputName);
}

export function getElementsInGroup(
  config: ElementsConfig,
  groupKey: string
): string[] {
  let group: ElementsGroup | undefined = undefined;

  for (const category of Object.values(config)) {
    if (category.groups) {
      for (const g of category.groups) {
        if (g.outputName === groupKey) {
          group = g;
          break;
        }
      }
    }
    if (group) {
      break;
    }
  }

  return group ? group.elements.map((e) => e.outputName) : [];
}

export function getGroupOfElement(
  config: ElementsConfig,
  elementKey: string
): string | undefined {
  for (const category of Object.values(config)) {
    if (category.groups) {
      for (const group of category.groups) {
        if (group.elements.map((e) => e.outputName).includes(elementKey)) {
          return group.outputName;
        }
      }
    }
  }
}
