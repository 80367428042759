import * as d3 from "d3";
import React, { FC, useEffect } from "react";
import { useResizeObserver } from "../../hooks";
import { Domain, LogData } from "../../types/types";

const X_MARGIN = 3;

interface ReferenceLogProps {
  data: LogData[] | undefined;
  xDomain?: Domain;
  color: string;
  style?: React.CSSProperties;
}

export const ReferenceLog: FC<ReferenceLogProps> = ({
  data,
  xDomain,
  color,
  style,
}) => {
  const [size, elementRef, node] = useResizeObserver();

  useEffect(() => {
    if (data && size && node) {
      d3.select(node).selectAll("svg").remove();

      const x = d3
        .scaleLinear()
        // @ts-ignore
        .domain([
          xDomain?.min ?? d3.min(data, (d) => d.value),
          xDomain?.max ?? d3.max(data, (d) => d.value),
        ])
        .range([X_MARGIN, size.width - X_MARGIN]);

      const y = d3
        .scaleLinear()
        .domain([0, data.length - 1])
        .range([0, size.height]);

      const line = d3
        .line()
        // @ts-ignore
        .x((d) => x(d.value))
        // @ts-ignore
        .y((d) => y(d.index))
        // @ts-ignore
        .defined((d) => typeof d.value === "number");

      const pathSelect = d3
        .select(node)
        .append("svg")
        .attr("width", size.width)
        .attr("height", size.height)
        .append("g")
        .selectAll(".reference-log-path")
        .data([data]);

      // @ts-ignore
      pathSelect.join((enter) =>
        enter
          .append("path")
          .attr("class", "reference-log-path")
          .attr("fill", "none")
          .attr("stroke", color)
          .attr("stroke-width", 2)
          // @ts-ignore
          .attr("d", line)
      );
    }
  }, [node, data, size, xDomain, color]);

  return <div className="reference-log" ref={elementRef} style={style}></div>;
};
