import React, { FC, useMemo } from "react";
import {
  IDropdownStyles,
  IDropdownProps,
  Dropdown as FluentDropdown,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";

import { Colors } from "../../constants";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    background: NeutralColors.gray30,
    selectors: {
      "&:focus:not(.is-disabled)::after": {
        border: `1px solid ${Colors.primary} !important`,
      },
      "&:hover .ms-Dropdown-title": {
        background: NeutralColors.gray40,
      },
      "&:active .ms-Dropdown-title": {
        background: NeutralColors.gray50,
      },
      "&.is-open .ms-Dropdown-title": {
        background: NeutralColors.gray50,
      },
      "&.is-disabled .ms-Dropdown-title": {
        background: NeutralColors.gray50,
        borderRadius: "2px",
        border: "none",
        color: NeutralColors.gray90,
      },
    },
  },
  title: {
    background: NeutralColors.gray30,
    color: NeutralColors.gray160,
  },
  dropdownItem: {
    background: NeutralColors.gray30,
    selectors: {
      "&:hover": {
        background: `${NeutralColors.gray40} !important`,
        color: NeutralColors.gray160,
      },
      "&:focus": {
        background: `${NeutralColors.gray40} !important`,
        color: NeutralColors.gray160,
      },
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
    },
  },
  dropdownItemSelected: {
    background: Colors.primary,
    color: NeutralColors.white,
    selectors: {
      "&:hover": {
        background: `${Colors.primary} !important`,
        color: NeutralColors.white,
      },

      "&:focus": {
        background: `${Colors.primary} !important`,
        color: `${NeutralColors.white} !important`,
      },
    },
  },
  // dropdownItemsWrapper: {},
  // dropdownItems: {},
  // dropdownItemDisabled: {},
  // dropdownItemSelectedAndDisabled: {},
  // dropdownOptionText: {},
};

export const Dropdown: FC<IDropdownProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(() => mergeStyleSets(dropdownStyles, styles), [
    styles,
  ]);

  return <FluentDropdown styles={mergedStyles} {...restProps} />;
};
