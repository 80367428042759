import { Stack, StackItem } from "@fluentui/react";
import { NeutralColors, SharedColors } from "@fluentui/theme";
import debounce from "lodash/debounce";
import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { animated, useSpring } from "react-spring";
import { DropdownName, ViewMode } from "../../constants";
import { useDropdowns } from "../../context";
import { useWellTopsData } from "../../hooks";
import {
  selectSelectedCutting,
  selectSelectedWellbore,
  selectSelectedWellTops,
  selectSyncSelectedCutting,
} from "../../store";
import { Cutting, Optional, ProcessedTops, Wellbore } from "../../types";
import { getClasses, getGroupAndFormation, isTWellbore } from "../../utils";
import {
  IMAGE_VIEWER_MAX_ZOOM,
  IMAGE_VIEWER_MIN_ZOOM,
  SCALE_BAR_MARGIN,
  UI_SPRING_CONFIG,
} from "../stage/config";
import { ViewerController } from "../stage/ViewerController";
import { IconButton } from "../styledFluentComponents/IconButton";
import { ImageInformationButton } from "./ImageInformationButton";
import { OptionsButton } from "./OptionsButton";

const DEFAULT_ZOOM = 100;
const MIN_ZOOM = IMAGE_VIEWER_MIN_ZOOM * 100;
const MAX_ZOOM = IMAGE_VIEWER_MAX_ZOOM * 100;
const Z_INDEX_WHEN_MAP_VEIW_IS_OPEN = 60;
const Z_INDEX_WHEN_MAP_VIEW_IS_CLOSED = 10;

const DISTANCE_FROM_EDGE_OF_IMAGE_VIEWER = 30;

const NON_MAP_VIEW_DROPDOWNS = [
  DropdownName.ADJUST1,
  DropdownName.ADJUST2,
  DropdownName.BACKDROP,
  DropdownName.SELECT1,
  DropdownName.SELECT2,
  DropdownName.WELL_LOG,
  DropdownName.WELL_TOPS,
];
interface MiniMapProps {
  viewerController: Optional<ViewerController>;
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
  alignLeft: boolean;
  mapViewIsOpen: boolean;
  hide: boolean;
}

export const MiniMapImpl: FC<MiniMapProps> = ({
  viewerController,
  viewMode,
  setViewMode,
  alignLeft,
  mapViewIsOpen,
  hide,
}) => {
  const selectedCutting = useSelector(selectSelectedCutting);
  const syncSelectedCutting = useSelector(selectSyncSelectedCutting);
  const selectedWellbore = useSelector(selectSelectedWellbore);
  const wellTopsData = useWellTopsData();

  const { closeDropdown } = useDropdowns();

  const [zoomValue, setZoomValue] = useState(DEFAULT_ZOOM.toString());
  const [imageIsPixelated, setImageIsPixelated] = useState(false);
  const [displayWellTops, setDisplayWellTops] = useState(false);

  const selectedWellTops = useSelector(selectSelectedWellTops);
  useEffect(() => {
    if (selectedWellTops) {
      setDisplayWellTops(true);
    }
  }, [selectedWellTops]);

  const activeAlignLeft = useRef(alignLeft);
  const [miniMapSpring, setMiniMapSpring] = useSpring(() => ({
    to: {
      opacity: 1,
      right: alignLeft ? "auto" : DISTANCE_FROM_EDGE_OF_IMAGE_VIEWER,
      left: alignLeft ? DISTANCE_FROM_EDGE_OF_IMAGE_VIEWER : "auto",
      zIndex: mapViewIsOpen
        ? Z_INDEX_WHEN_MAP_VEIW_IS_OPEN
        : Z_INDEX_WHEN_MAP_VIEW_IS_CLOSED,
    },
    config: UI_SPRING_CONFIG,
  }));

  useEffect(() => {
    let timeout: number;
    const changePositionOfMiniMap = activeAlignLeft.current !== alignLeft;
    // eslint-disable-next-line
    const positioningSpringProps: any = {
      to: {
        right: alignLeft ? "auto" : DISTANCE_FROM_EDGE_OF_IMAGE_VIEWER,
        left: alignLeft
          ? SCALE_BAR_MARGIN.left + DISTANCE_FROM_EDGE_OF_IMAGE_VIEWER
          : "auto",
        zIndex: mapViewIsOpen // && changePositionOfMiniMap
          ? Z_INDEX_WHEN_MAP_VEIW_IS_OPEN
          : Z_INDEX_WHEN_MAP_VIEW_IS_CLOSED,
      },
      immediate: true,
    };
    if (changePositionOfMiniMap) {
      // Fade in and out mini map when repositioning on the screen
      setMiniMapSpring({
        // @ts-ignore
        to: async (next) => {
          await next({
            to: {
              opacity: 0,
            },
            immediate: false,
          });

          await next(positioningSpringProps);
          activeAlignLeft.current = alignLeft;

          await next({
            to: {
              opacity: 1,
            },
            immediate: false,
          });
        },
      });
    } else {
      // if we are not repositioning the mini map on the screen we do not need to fade it in and out
      positioningSpringProps.to.opacity = 1;
      if (!mapViewIsOpen) {
        // if the mini map is being closed we wait with reducing the z-index of mini map to avoid
        // it being rendered behind the map view for a split second.
        timeout = (setTimeout(() => {
          setMiniMapSpring(positioningSpringProps);
        }, UI_SPRING_CONFIG.duration) as unknown) as number;
      } else {
        // if the mini map is being opened we update the z-index immediatly so it is allways on top of the map view
        setMiniMapSpring(positioningSpringProps);
      }
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [alignLeft, mapViewIsOpen]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setZoomValue(event.target.value);
    try {
      const newParsedZoom = parseInt(event.target.value);
      const oldParsedZoom = parseInt(zoomValue);
      if (Math.abs(newParsedZoom - oldParsedZoom) === 1) {
        clampAndSetZoom(newParsedZoom);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" ||
      event.key === "Space" ||
      event.key === "Escape"
    ) {
      clampAndSetZoom();
    }
  };

  const clampAndSetZoom = (newValue?: number) => {
    try {
      const parsedZoom = newValue ?? parseInt(zoomValue);
      if (!isNaN(parsedZoom)) {
        const clampedZoom = Math.min(Math.max(parsedZoom, MIN_ZOOM), MAX_ZOOM);
        setZoomValue(clampedZoom.toString());

        const clamedZoomInDecimal = clampedZoom / 100;
        viewerController?.viewer?.viewport.zoomTo(
          clamedZoomInDecimal,
          undefined,
          true
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (viewerController?.viewer) {
      const updateImageIsPixelated = (parsedZoomValue: number) => {
        const zoomValueWhereImageBecomesPixelated =
          viewerController.getZoomWhereResolutionOfImageMatchScreen() * 100;
        if (parsedZoomValue > zoomValueWhereImageBecomesPixelated) {
          setImageIsPixelated(true);
        } else {
          setImageIsPixelated(false);
        }
      };

      const debouncedZoomHandler = debounce(() => {
        if (viewerController.viewer) {
          const zoomValue = Math.round(
            viewerController.viewer.viewport.getZoom(false) * 100
          );
          setZoomValue(zoomValue.toString());
          updateImageIsPixelated(zoomValue);
        }
      }, 50);

      viewerController.viewer.addHandler("zoom", debouncedZoomHandler);

      return () =>
        viewerController.viewer?.removeHandler("zoom", debouncedZoomHandler);
    }
  }, [viewerController, viewerController?.viewer]);

  useEffect(() => {
    if (viewMode === ViewMode.FIT_TO_PAGE) {
      viewerController?.zoomToFitPage();
    } else if (viewMode === ViewMode.FIT_TO_WIDTH) {
      viewerController?.zoomToFitWidth();
    }
  }, [viewMode, viewerController]);

  const [
    groupOfSelectedCutting,
    formationOfSelectedCutting,
  ] = getGroupAndFormationOfCutting(
    wellTopsData,
    selectedWellbore,
    selectedCutting
  );

  return (
    <animated.div
      className={`mini-map ${hide ? "hide" : ""}`}
      style={miniMapSpring}
      onClick={() => closeDropdown(NON_MAP_VIEW_DROPDOWNS)}
    >
      <div
        className={getClasses("mini-map__depth", {
          "mini-map__depth--outline": syncSelectedCutting,
        })}
      >
        {selectedCutting ? `${selectedCutting.depth}m (MD)` : "-"}
      </div>

      <div className="mini-map__stratigraphy-container">
        {displayWellTops && groupOfSelectedCutting && (
          <div className="mini-map__stratigraphy mini-map__stratigraphy--group">
            {groupOfSelectedCutting}
          </div>
        )}

        <div>
          {/* Containing element for OSD Viewer, which OSD's DOM manipulation logic require */}
          <div
            className="mini-map__container"
            id="openseadragon-mini-map"
          ></div>
        </div>

        {displayWellTops && formationOfSelectedCutting && (
          <div className="mini-map__stratigraphy mini-map__stratigraphy--formation">
            {formationOfSelectedCutting}
          </div>
        )}
      </div>

      <div className="mini-map__toolbar">
        <Stack horizontal>
          <StackItem grow={1} styles={{ root: { height: "32px" } }}>
            <IconButton
              iconProps={{ iconName: "FitWidth" }}
              ariaLabel="Fit to width"
              checked={ViewMode.FIT_TO_WIDTH === viewMode}
              onClick={() => {
                setViewMode(ViewMode.FIT_TO_WIDTH);
              }}
            />
            <IconButton
              iconProps={{ iconName: "FitPage" }}
              ariaLabel="Fit to page"
              checked={ViewMode.FIT_TO_PAGE === viewMode}
              onClick={() => {
                setViewMode(ViewMode.FIT_TO_PAGE);
              }}
            />
          </StackItem>
          <StackItem styles={{ root: { height: "32px" } }}>
            <Stack horizontal verticalAlign="center">
              <ImageInformationButton />
              <span className="border border--narrow"></span>
              <OptionsButton
                displayWellTops={displayWellTops}
                setDisplayWellTops={setDisplayWellTops}
              />
              <span className="border"></span>
              <input
                aria-label="Zoom level"
                type="number"
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                style={{
                  color: imageIsPixelated
                    ? SharedColors.red20
                    : NeutralColors.black,
                }}
                value={zoomValue}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                onBlur={() => clampAndSetZoom()}
              />
              <span className="unit">%</span>
            </Stack>
          </StackItem>
        </Stack>
      </div>
    </animated.div>
  );
};

export const MiniMap = React.memo(MiniMapImpl);

function getGroupAndFormationOfCutting(
  wellTopsData: Optional<ProcessedTops>,
  selectedWellbore: Optional<Wellbore>,
  selectedCutting: Optional<Cutting>
): [string, string] | [undefined, undefined] {
  if (wellTopsData && selectedWellbore && selectedCutting) {
    const {
      group: { name: groupName },
      formation: { name: formationName },
    } = getGroupAndFormation(
      wellTopsData[selectedWellbore.name],
      selectedCutting.depth
    );
    const isTWell = isTWellbore(selectedWellbore.name);
    const groupOfSelectedCutting = !isTWell
      ? groupName
      : "T-well: no NPD information";
    const formationOfSelectedCutting = !isTWell
      ? formationName
      : "T-well: no NPD information";

    return [groupOfSelectedCutting, formationOfSelectedCutting];
  }

  return [undefined, undefined];
}
