import { IContextualMenuItem } from "@fluentui/react";
import React, { FC, useMemo } from "react";
import { DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import {
  LogCurveName,
  LogCurveOptions,
  ProcessedRefLogs,
} from "../../types/types";
import {
  ContextualMenuSelectionMode,
  getContextualMenuItems,
} from "../styledFluentComponents";
import { SidebarDropdown } from "./SidebarDropdown";

const logCurveDisplayNames: Record<LogCurveOptions, string> = {
  [LogCurveName.CALIPER]: "Caliper",
  [LogCurveName.GAMMA_RAY]: "Gamma Ray",
  [LogCurveName.BIT_SIZE]: "Bit Size",
};

interface WellLogSelectorProps {
  selectedRefLog: string | undefined;
  setSelectedRefLog: React.Dispatch<
    React.SetStateAction<LogCurveOptions | undefined>
  >;
  processedRefLogs: ProcessedRefLogs | undefined;
}

const WellLogSelectorImpl: FC<WellLogSelectorProps> = ({
  selectedRefLog,
  setSelectedRefLog,
  processedRefLogs,
}) => {
  const { closeDropdown } = useDropdowns();

  const onItemClick = (_: unknown, item?: IContextualMenuItem | undefined) => {
    setSelectedRefLog((state) => {
      if (item?.data?.logCurveName) {
        if (state === item.data.logCurveName) {
          return undefined;
        }
        return item.data.logCurveName;
      }
    });
    closeDropdown(DropdownName.WELL_LOG);
  };

  const items: IContextualMenuItem[] = useMemo(() => {
    const logCurveOptions: LogCurveOptions[] = [
      LogCurveName.GAMMA_RAY,
      LogCurveName.CALIPER,
      LogCurveName.BIT_SIZE,
    ];
    return getContextualMenuItems(
      "Logs",
      logCurveOptions.map((logName) => {
        const isDisabled = !processedRefLogs?.[logName];
        return {
          key: logName,
          text: logCurveDisplayNames[logName],
          checked: !isDisabled && logName === selectedRefLog,
          disabled: isDisabled,
          data: {
            logCurveName: logName,
          },
        };
      })
    );
  }, [processedRefLogs, selectedRefLog]);

  return (
    <SidebarDropdown
      onItemClick={onItemClick}
      items={items}
      dropDownName={DropdownName.WELL_LOG}
      buttonAriaLabel="Select well log"
      buttonLabel="L"
      selectionMode={ContextualMenuSelectionMode.SINGLE}
    />
  );
};

export const WellLogSelector = React.memo(WellLogSelectorImpl);
