import { NeutralColors } from "@fluentui/theme";
import * as d3 from "d3";
import { v4 as uuid } from "uuid";
import { Colors } from "../constants";
import OpenSeadragon, {
  Point,
  ViewerEventName,
} from "../dependencies/openseadragon";
import { ImageReference, Optional } from "../types";
import { round } from "../utils";

interface Ruler {
  key: string;
  p1: Point;
  p2: Point;
}

interface RulerPlotData {
  key: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  length: string;
}

export interface MeasurementToolOptions {
  imageReference: ImageReference;
}

// eslint-disable-next-line
export default function extendOSDWithMeasumentTool(OpenSeadragon: any): any {
  const $ = OpenSeadragon;

  $.Viewer.prototype.setMeasurementToolOptions = function (
    options: MeasurementToolOptions
  ) {
    if (!this.measurementToolPluginInstance) {
      this.measurementToolPluginInstance = new MeasumentToolPlugin(
        this,
        options
      );
    } else {
      this.measurementToolPluginInstance.setOptions(options);
    }
  };

  return $;
}

export class MeasumentToolPlugin {
  private _viewerEvents: ViewerEventName[] = ["animation", "open", "resize"];
  private _viewer: OpenSeadragon.Viewer;
  private _imageReference: ImageReference;
  private _svgElement: SVGElement;
  private _svg: d3.Selection<SVGElement, unknown, null, undefined>;
  private _rulers: Ruler[] = [];
  private _rulerBeingDrawn: Optional<Partial<Ruler>>;
  private _svgWidth = 0;
  private _svgHeight = 0;
  private _dragState = {
    mouseDown: false,
    dragged: false,
  };

  constructor(
    viewerInstance: OpenSeadragon.Viewer,
    options: MeasurementToolOptions
  ) {
    this._viewer = viewerInstance;
    this._imageReference = options.imageReference;
    this._svgElement = createSVGElement();
    this._viewer.canvas.appendChild(this._svgElement);

    this._svg = d3.select(this._svgElement);

    this._registerViewerEventHandlers();
    this._draw();
  }

  setOptions(options: MeasurementToolOptions): void {
    this._imageReference = options.imageReference;
    this._reset();
  }

  enableEditing(): void {
    this._viewer.gestureSettingsMouse.clickToZoom = false;
    this._svg.style("pointer-events", "all");
    this._addSVGEventHandlers();
  }

  disableEditing(): void {
    this._viewer.gestureSettingsMouse.clickToZoom = true;
    this._svg.style("pointer-events", "none");
    this._rulerBeingDrawn = undefined;
    this._removeSVGEventHandlers();
    this._reset();
  }

  cleanUp(): void {
    this._removeSVGEventHandlers();
    this._svg.remove();
    this._removeViewerEventHandlers();
  }

  private _registerViewerEventHandlers() {
    this._viewerEvents.forEach((eventName) => {
      this._viewer.addHandler(eventName, this._draw);
    });
  }

  private _removeViewerEventHandlers() {
    this._viewerEvents.forEach((eventName) => {
      this._viewer.removeHandler(eventName, this._draw);
    });
  }

  private _reset() {
    this._rulers = [];
    this._rulerBeingDrawn = undefined;
    this._draw();
  }

  private _canvasDragHandler = () => {
    if (this._dragState.mouseDown) {
      this._dragState.dragged = true;
    }
  };

  private _addSVGEventHandlers() {
    this._removeSVGEventHandlers();

    this._viewer.addHandler("canvas-drag", this._canvasDragHandler);

    this._svg
      .on("pointerdown", (e) => {
        this._dragState.mouseDown = true;
      })
      .on("click", (e: MouseEvent) => {
        const shouldSkip = this._dragState.dragged;
        this._dragState.mouseDown = false;
        this._dragState.dragged = false;
        if (shouldSkip) return;

        const viewportCoords = getPointFromEvent(this._viewer, e);

        if (!this._rulerBeingDrawn) {
          this._rulerBeingDrawn = {
            key: uuid(),
          };
        }
        if (!this._rulerBeingDrawn.p1) {
          this._rulerBeingDrawn.p1 = viewportCoords;
          this._rulerBeingDrawn.p2 = viewportCoords;
          this._draw();

          this._svg.on("mousemove", (e: MouseEvent) => {
            if (this._rulerBeingDrawn) {
              this._rulerBeingDrawn.p2 = getPointFromEvent(this._viewer, e);
            }
            this._draw();
          });
        } else {
          this._rulerBeingDrawn.p2 = viewportCoords;
          this._rulers.push(this._rulerBeingDrawn as Ruler);
          this._rulerBeingDrawn = undefined;
          this._svg.on("mousemove", null);
          this._draw();
        }
      });
  }

  private _removeSVGEventHandlers() {
    this._viewer.removeHandler("canvas-drag", this._canvasDragHandler);
    this._svg.on("pointerdown", null).on("click", null).on("mousemove", null);
  }

  private _draw = () => {
    this._updateSizeOfSvg();

    const rulersToDraw = [...this._rulers];
    if (this._rulerBeingDrawn?.p1 && this._rulerBeingDrawn?.p2) {
      rulersToDraw.push(this._rulerBeingDrawn as Ruler);
    }

    const rulerPlotData: RulerPlotData[] = rulersToDraw.map((ruler) => {
      const p1InPixels = this._viewer.viewport.pixelFromPoint(ruler.p1, true);
      const p2InPixels = this._viewer.viewport.pixelFromPoint(ruler.p2, true);
      return {
        key: ruler.key,
        x1: p1InPixels.x,
        y1: p1InPixels.y,
        x2: p2InPixels.x,
        y2: p2InPixels.y,
        length: getRulerLength(ruler, this._imageReference),
      };
    });

    this._svg
      .selectAll(".ruler")
      .data(rulerPlotData, (d) => (d as RulerPlotData).key)
      .join(
        (enter) => enter.append("g").attr("class", "ruler").call(addRuler),
        (update) => update.call(updateRuler),
        (exit) => exit.remove()
      );
  };

  private _updateSizeOfSvg() {
    if (this._svgWidth !== this._viewer.container.clientWidth) {
      this._svgWidth = this._viewer.container.clientWidth;
      this._svg.attr("width", this._svgWidth);
    }

    if (this._svgHeight !== this._viewer.container.clientHeight) {
      this._svgHeight = this._viewer.container.clientHeight;
      this._svg.attr("height", this._svgHeight);
    }
  }
}

function createSVGElement() {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.style.position = "absolute";
  svg.style.pointerEvents = "none";
  return svg;
}

function setLinePositionAttributes(
  selection: d3.Selection<any, any, any, any> // eslint-disable-line
) {
  selection
    .attr("x1", (d: RulerPlotData) => d.x1)
    .attr("y1", (d: RulerPlotData) => d.y1)
    .attr("x2", (d: RulerPlotData) => d.x2)
    .attr("y2", (d: RulerPlotData) => d.y2);
}
function setP1CirclePositionAttributes(
  selection: d3.Selection<any, any, any, any> // eslint-disable-line
) {
  selection
    .attr("cx", (d: RulerPlotData) => d.x1)
    .attr("cy", (d: RulerPlotData) => d.y1);
}
function setP2CirclePositionAttributes(
  selection: d3.Selection<any, any, any, any> // eslint-disable-line
) {
  selection
    .attr("cx", (d: RulerPlotData) => d.x2)
    .attr("cy", (d: RulerPlotData) => d.y2);
}

const LABEL_WIDTH = 72;
const LABEL_HEIGHT = 32;
const BEAK_SIDE = 16;

function setLengthLabelPositionAttributes(
  selection: d3.Selection<any, any, any, any> // eslint-disable-line
) {
  selection.attr("transform", (d: RulerPlotData) => {
    const p2IsFurthestToTheRight = d.x2 > d.x1;
    if (p2IsFurthestToTheRight) {
      return `translate(${d.x2 + 22}, ${d.y2 - LABEL_HEIGHT / 2})`;
    }
    return `translate(${d.x2 - LABEL_WIDTH - 22}, ${d.y2 - LABEL_HEIGHT / 2})`;
  });
}

function setLengthBeakPositionAttributes(
  selection: d3.Selection<any, any, any, any> // eslint-disable-line
) {
  selection.attr("transform", (d: RulerPlotData) => {
    const p2IsFurthestToTheRight = d.x2 > d.x1;
    const HALF_BEAK_SIDE = BEAK_SIDE / 2;
    if (p2IsFurthestToTheRight) {
      return `translate(-${HALF_BEAK_SIDE}, ${
        LABEL_HEIGHT / 2 - HALF_BEAK_SIDE
      }) rotate(45 ${HALF_BEAK_SIDE} ${HALF_BEAK_SIDE})`;
    }
    return `translate(${LABEL_WIDTH - HALF_BEAK_SIDE}, ${
      LABEL_HEIGHT / 2 - HALF_BEAK_SIDE
    }) rotate(45 ${HALF_BEAK_SIDE} ${HALF_BEAK_SIDE})`;
  });
}

function addRuler(
  selection: d3.Selection<any, any, any, any> // eslint-disable-line
) {
  // Add ruler line
  selection
    .append("line")
    .attr("class", "outer-line")
    .call(setLinePositionAttributes)
    .attr("stroke", NeutralColors.gray10)
    .attr("stroke-width", 4);
  selection
    .append("line")
    .attr("class", "inner-line")
    .call(setLinePositionAttributes)
    .attr("stroke", NeutralColors.gray190)
    .attr("stroke-width", 2);

  // Add circle for first point
  selection
    .append("circle")
    .attr("class", "outer-p1-circle")
    .call(setP1CirclePositionAttributes)
    .attr("r", 8)
    .attr("fill", NeutralColors.gray10);
  selection
    .append("circle")
    .attr("class", "middle-p1-circle")
    .call(setP1CirclePositionAttributes)
    .attr("r", 6)
    .attr("fill", NeutralColors.gray190);
  selection
    .append("circle")
    .attr("class", "inner-p1-circle")
    .call(setP1CirclePositionAttributes)
    .attr("r", 3)
    .attr("fill", Colors.primary);

  // Add circle for second point
  selection
    .append("circle")
    .attr("class", "outer-p2-circle")
    .call(setP2CirclePositionAttributes)
    .attr("r", 8)
    .attr("fill", NeutralColors.gray10);
  selection
    .append("circle")
    .attr("class", "middle-p2-circle")
    .call(setP2CirclePositionAttributes)
    .attr("r", 6)
    .attr("fill", NeutralColors.gray190);
  selection
    .append("circle")
    .attr("class", "inner-p2-circle")
    .call(setP2CirclePositionAttributes)
    .attr("r", 3)
    .attr("fill", Colors.primary);

  // Add label showing ruler length
  const rulerLabel = selection
    .append("g")
    .attr("class", "length-label")
    .call(setLengthLabelPositionAttributes);

  rulerLabel
    .append("rect")
    .attr("class", "length-label__beak")
    .attr("width", BEAK_SIDE)
    .attr("height", BEAK_SIDE)
    .call(setLengthBeakPositionAttributes)
    .attr("fill", NeutralColors.gray10);

  rulerLabel
    .append("rect")
    .attr("class", "length-label__container")
    .attr("width", LABEL_WIDTH)
    .attr("height", LABEL_HEIGHT)
    .attr("rx", 2)
    .attr("fill", NeutralColors.gray10);

  rulerLabel
    .append("text")
    .attr("class", "length-label__text")
    .text((d) => (d as RulerPlotData).length)
    .attr("font-size", 14)
    .attr("color", NeutralColors.gray160)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .attr("transform", `translate(${LABEL_WIDTH / 2}, ${LABEL_HEIGHT / 2 + 1})`)
    .attr("fill", NeutralColors.gray190);
}
function updateRuler(
  selection: d3.Selection<any, any, any, any> // eslint-disable-line
) {
  selection.select(".outer-line").call(setLinePositionAttributes);
  selection.select(".inner-line").call(setLinePositionAttributes);
  selection.select(".outer-p1-circle").call(setP1CirclePositionAttributes);
  selection.select(".middle-p1-circle").call(setP1CirclePositionAttributes);
  selection.select(".inner-p1-circle").call(setP1CirclePositionAttributes);
  selection.select(".outer-p2-circle").call(setP2CirclePositionAttributes);
  selection.select(".middle-p2-circle").call(setP2CirclePositionAttributes);
  selection.select(".inner-p2-circle").call(setP2CirclePositionAttributes);
  const lengthLabel = selection
    .select(".length-label")
    .call(setLengthLabelPositionAttributes);

  lengthLabel
    .select(".length-label__text")
    .text((d) => (d as RulerPlotData).length);

  lengthLabel
    .select(".length-label__beak")
    .call(setLengthBeakPositionAttributes);
}

function getRulerLength(ruler: Ruler, imageReference: ImageReference): string {
  if (!imageReference.dimension) {
    return "Unknown";
  }

  const xCathetusInMilliMeter =
    (ruler.p2.x - ruler.p1.x) * imageReference.dimension.width * 10;
  const yCathetusInMilliMeter =
    (ruler.p2.y - ruler.p1.y) *
    (imageReference.resolutionWidth / imageReference.resolutionHeight) *
    imageReference.dimension.height *
    10;

  const rulerLengthInMilliMeter = Math.sqrt(
    Math.pow(xCathetusInMilliMeter, 2) + Math.pow(yCathetusInMilliMeter, 2)
  );

  return `${round(rulerLengthInMilliMeter, 2)} mm`;
}

const getPointFromEvent = (viewer: OpenSeadragon.Viewer, e: MouseEvent) =>
  viewer.viewport.pointFromPixel(new Point(e.offsetX, e.offsetY));
