import firebase from "firebase/app";
import "firebase/firestore";
import { useState, useEffect, useRef } from "react";

const useFirebaseRoleListener = (
  user: firebase.User | undefined
): string | undefined => {
  const [role, setRole] = useState<string | undefined>();
  const lastCommitted = useRef<string>();

  useEffect(() => {
    const unregisterTokenObserver = firebase
      .auth()
      .onIdTokenChanged(async (user) => {
        const idTokenResult = await user?.getIdTokenResult();
        if (user && idTokenResult?.claims.role !== role) {
          setRole(idTokenResult?.claims.role);
        }
      });
    return unregisterTokenObserver;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      const unregisterTokenObserver = firebase
        .firestore()
        .collection("user-claims")
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          const data = snapshot.data();
          if (data?._lastCommitted) {
            if (
              !lastCommitted.current ||
              !data._lastCommitted.isEqual(lastCommitted.current)
            ) {
              // Force a refresh of the user's ID token
              firebase.auth().currentUser?.getIdToken(true);
            }
            lastCommitted.current = data._lastCommitted;
          }
        });
      return unregisterTokenObserver;
    }
  }, [user]);

  return role;
};

export default useFirebaseRoleListener;
