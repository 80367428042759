import React, { FC, useEffect } from "react";
import { animated, interpolate, useSpring } from "react-spring";
import { useAppSprings } from "../../context";
import { WIDTH_OF_ANALYTICS_LIST } from "../dataAnalyticsPane/DataAnalyticsPane";
import { PLOTTER_SIDEBAR_WIDTH } from "../dataAnalyticsPane/dataPlotter/DataPlotterSidebar";
import { LoadingSpinner } from "../loadingSpinner/LoadingSpinner";
import { WIDTH_OF_SIDEBAR_MENU } from "../sidebarMenu/SidebarMenu";
import { UI_SPRING_CONFIG, VIEWER_MARGIN } from "../stage/config";
import { TRACK_MARGIN } from "../track";
import { WIDTH_OF_WELL_TOPS } from "../wellboreTops/WellboreTops";

type FrameProps<T> = React.HTMLAttributes<T> & {
  show?: boolean;
};

interface AnimatedFrameProps<T> extends FrameProps<T> {
  zIndexOnShow?: number;
  zIndexOnHide?: number;
  loading?: boolean;
}

export const StageFrame = React.forwardRef<
  HTMLDivElement,
  FrameProps<HTMLDivElement>
>(({ children, show = true, ...restProps }, ref) => {
  const {
    pageSize,
    rangeFinderSpring,
    analyticsSpring,
    overlayAnalytics,
    showWellTops,
    widthOfWellDataSymbols,
    zoomAreaSpring,
    showZoomAreaTrack,
  } = useAppSprings();
  if (rangeFinderSpring && analyticsSpring && zoomAreaSpring && pageSize) {
    return (
      <animated.div
        {...restProps}
        style={{
          display: `${show ? "block" : "none"}`,
          position: "absolute",
          width: interpolate(
            [
              rangeFinderSpring.width,
              analyticsSpring.width,
              zoomAreaSpring.width,
            ],
            (
              rangeFinderWidth: number,
              analyticsWidth: number,
              zoomAreaTrackWidth: number
            ) =>
              `${
                pageSize.width -
                WIDTH_OF_SIDEBAR_MENU -
                (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) -
                TRACK_MARGIN -
                rangeFinderWidth -
                (widthOfWellDataSymbols
                  ? TRACK_MARGIN + widthOfWellDataSymbols
                  : 0) -
                (showZoomAreaTrack ? zoomAreaTrackWidth + TRACK_MARGIN : 0) -
                (overlayAnalytics ? 0 : analyticsWidth + TRACK_MARGIN) -
                TRACK_MARGIN
              }px`
          ),
          height: `${pageSize.height}px`,
        }}
      >
        <div className="hidden-ref-element" ref={ref}></div>
        {children}
      </animated.div>
    );
  }
  return null;
});

export const StageFrameSVG = React.forwardRef<
  HTMLDivElement,
  AnimatedFrameProps<SVGElement> & { nonSvgChildren?: () => React.ReactNode }
>(
  (
    {
      children,
      nonSvgChildren,
      zIndexOnShow,
      zIndexOnHide,
      loading,
      show = true,
      ...restProps
    },
    ref
  ) => {
    const {
      pageSize,
      rangeFinderSpring,
      analyticsSpring,
      overlayAnalytics,
      showWellTops,
      widthOfWellDataSymbols,
      zoomAreaSpring,
      showZoomAreaTrack,
    } = useAppSprings();

    const [spring, setSpring] = useSpring(() => ({
      to: {
        display: show ? "block" : "none",
        pointerEvents: show ? "all" : "none",
        zIndex: show ? zIndexOnShow ?? "auto" : zIndexOnHide ?? "auto",
        opacity: show ? 1 : 0,
      },
      config: UI_SPRING_CONFIG,
    }));

    useEffect(() => {
      if (show) {
        setSpring({
          // @ts-ignore
          to: async (next) => {
            await next({
              display: "block",
              pointerEvents: "all",
              zIndex: zIndexOnShow || "auto",
              immediate: true,
            });
            await next({
              opacity: 1,
              immediate: loading ? true : false,
            });
          },
        });
      } else {
        setSpring({
          // @ts-ignore
          to: async (next) => {
            await next({
              pointerEvents: "none",
              immediate: true,
            });
            await next({
              opacity: 0,
              immediate: false,
            });
            await next({
              display: "none",
              zIndex: zIndexOnHide ?? "auto",
              immediate: true,
            });
          },
        });
      }
    }, [setSpring, show, zIndexOnHide, zIndexOnShow, loading]);
    if (rangeFinderSpring && analyticsSpring && zoomAreaSpring && pageSize) {
      return (
        <animated.div
          style={{
            position: "absolute",
            top: 0,
            width: interpolate(
              [
                rangeFinderSpring.width,
                analyticsSpring.width,
                zoomAreaSpring.width,
              ],
              (
                rangeFinderWidth: number,
                analyticsWidth: number,
                zoomAreaTrackWidth: number
              ) =>
                `${
                  pageSize.width -
                  WIDTH_OF_SIDEBAR_MENU -
                  (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) -
                  TRACK_MARGIN -
                  rangeFinderWidth -
                  (widthOfWellDataSymbols
                    ? TRACK_MARGIN + widthOfWellDataSymbols
                    : 0) -
                  (showZoomAreaTrack ? zoomAreaTrackWidth + TRACK_MARGIN : 0) -
                  (overlayAnalytics ? 0 : TRACK_MARGIN + analyticsWidth) -
                  TRACK_MARGIN
                }px`
            ),
            height: `${pageSize.height}px`,
            ...spring,
          }}
        >
          <animated.svg
            style={{
              position: "absolute",
              width: interpolate(
                [
                  rangeFinderSpring.width,
                  analyticsSpring.width,
                  zoomAreaSpring.width,
                ],
                (
                  rangeFinderWidth: number,
                  analyticsWidth: number,
                  zoomAreaTrackWidth: number
                ) =>
                  `${
                    pageSize.width -
                    WIDTH_OF_SIDEBAR_MENU -
                    (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) -
                    TRACK_MARGIN -
                    rangeFinderWidth -
                    (widthOfWellDataSymbols
                      ? TRACK_MARGIN + widthOfWellDataSymbols
                      : 0) -
                    (showZoomAreaTrack
                      ? zoomAreaTrackWidth + TRACK_MARGIN
                      : 0) -
                    (overlayAnalytics ? 0 : TRACK_MARGIN + analyticsWidth) -
                    TRACK_MARGIN
                  }px`
              ),
              height: `${pageSize.height}px`,
            }}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...restProps}
          >
            {children}
          </animated.svg>
          {nonSvgChildren && nonSvgChildren()}
          <LoadingSpinner loading={!!loading} />
          <div className="hidden-ref-element" ref={ref}></div>
        </animated.div>
      );
    }
    return null;
  }
);

export const ViewerFrame: FC<FrameProps<HTMLDivElement>> = ({
  children,
  show = true,
  ...restProps
}) => {
  const {
    pageSize,
    rangeFinderSpring,
    analyticsSpring,
    overlayAnalytics,
    showWellTops,
    widthOfWellDataSymbols,
    zoomAreaSpring,
    showZoomAreaTrack,
  } = useAppSprings();
  if (rangeFinderSpring && analyticsSpring && zoomAreaSpring && pageSize) {
    return (
      <animated.div
        {...restProps}
        style={{
          display: `${show ? "block" : "none"}`,
          position: "absolute",
          top: `${VIEWER_MARGIN.top}px`,
          left: `${VIEWER_MARGIN.left}px`,
          width: interpolate(
            [
              rangeFinderSpring.width,
              analyticsSpring.width,
              zoomAreaSpring.width,
            ],
            (
              rangeFinderWidth: number,
              analyticsWidth: number,
              zoomAreaTrackWidth: number
            ) =>
              `${
                pageSize.width -
                WIDTH_OF_SIDEBAR_MENU -
                (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) -
                TRACK_MARGIN -
                rangeFinderWidth -
                (widthOfWellDataSymbols
                  ? TRACK_MARGIN + widthOfWellDataSymbols
                  : 0) -
                (showZoomAreaTrack ? zoomAreaTrackWidth + TRACK_MARGIN : 0) -
                (overlayAnalytics ? 0 : TRACK_MARGIN + analyticsWidth) -
                TRACK_MARGIN -
                VIEWER_MARGIN.left
              }px`
          ),
          height: `${
            pageSize.height - VIEWER_MARGIN.top - VIEWER_MARGIN.bottom
          }px`,
        }}
      >
        {children}
      </animated.div>
    );
  }
  return null;
};

export const DataAnalyticsFrame: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...restProps
}) => {
  const {
    rangeFinderSpring,
    analyticsSpring,
    showWellTops,
    widthOfWellDataSymbols,
    zoomAreaSpring,
    showZoomAreaTrack,
  } = useAppSprings();
  if (rangeFinderSpring && analyticsSpring && zoomAreaSpring) {
    return (
      <animated.div
        {...restProps}
        style={{
          width: analyticsSpring.width.interpolate(
            (analyticsPaneWidth: number) => `${analyticsPaneWidth}px`
          ),
          height: analyticsSpring.height.interpolate(
            (analyticsPaneHeight: number) => `${analyticsPaneHeight}px`
          ),
          right: interpolate(
            [rangeFinderSpring.width, zoomAreaSpring.width],
            (rangeFinderWidth: number, zoomAreaTrackWidth: number) => {
              return `${
                WIDTH_OF_SIDEBAR_MENU +
                (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) +
                rangeFinderWidth +
                TRACK_MARGIN +
                (widthOfWellDataSymbols
                  ? TRACK_MARGIN + widthOfWellDataSymbols
                  : 0) +
                TRACK_MARGIN +
                (showZoomAreaTrack ? zoomAreaTrackWidth + TRACK_MARGIN : 0)
              }px`;
            }
          ),
        }}
      >
        {children}
      </animated.div>
    );
  }
  return null;
};

export const PlotterContainerFrame: FC<React.HTMLAttributes<
  HTMLDivElement
>> = ({ children, ...restProps }) => {
  const { analyticsSpring } = useAppSprings();

  if (analyticsSpring) {
    return (
      <animated.div
        {...restProps}
        style={{
          width: analyticsSpring.width?.interpolate(
            (analyticsWidth: number) =>
              `${
                analyticsWidth - WIDTH_OF_ANALYTICS_LIST - PLOTTER_SIDEBAR_WIDTH
              }px`
          ),
        }}
      >
        {children}
      </animated.div>
    );
  }
  return null;
};

export const PlotterFrameSVG: FC<
  React.HTMLAttributes<SVGElement> & { numPlots: number }
> = ({ numPlots, children, ...restProps }) => {
  const { analyticsSpring } = useAppSprings();

  if (analyticsSpring) {
    return (
      <animated.svg
        {...restProps}
        style={{
          width: analyticsSpring.width?.interpolate(
            (analyticsWidth: number) =>
              `${
                (analyticsWidth -
                  WIDTH_OF_ANALYTICS_LIST -
                  PLOTTER_SIDEBAR_WIDTH -
                  numPlots) /
                numPlots
              }px`
          ),
        }}
      ></animated.svg>
    );
  }
  return null;
};
