import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  IDetailsGroupRenderProps,
  SelectionMode,
  SelectionZone,
} from "@fluentui/react";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import {
  selectActiveWellbore,
  selectCuttings,
  selectSelectedCutting,
  StoreState,
  useAppDispatch,
} from "../../../store";
import { CSVFileJson, TopGroup } from "../../../types/types";
import { DetailsList } from "../../styledFluentComponents";
import { fetchAnalyticsData } from "../analyticsSlice";
import { columnsConfig, PDFtoolTipTexts, speccamConfig } from "../config";
import {
  useItemsAndGroups,
  useSortMethod,
  useStoredSelection,
} from "../utils/hooks";
import { selections } from "../utils/selections";
import {
  getOnRenderRow,
  getStructuredDataFromCSVFile,
  renderGroupHeader,
} from "../utils/tab-helpers";
import ItemsAndGroupsFilteredMessage from "./ItemsAndGroupsFilteredMessage";
import { SelectedItemsClearer } from "./SelectedItemsClearer";
import { TabHeader } from "./TabHeader";

const listGroupProps: IDetailsGroupRenderProps = {
  onRenderHeader: (props, defaultRenderer) => {
    return renderGroupHeader(props, speccamConfig, defaultRenderer);
  },
};
const onRenderRow = getOnRenderRow(AnalyticsName.SPEC_CAM, speccamConfig);

const SpeccamTabImpl: FC = () => {
  const dispatch = useAppDispatch();
  const selectedCutting = useSelector(selectSelectedCutting);
  const activeWellbore = useSelector(selectActiveWellbore);
  const cuttings = useSelector(selectCuttings);

  const selectedElements = useStoredSelection(AnalyticsName.SPEC_CAM);
  const data = useSelector(
    (state: StoreState) => state.analytics.srcData.speccam
  );
  const [sortMethod, onSortMethodChange] = useSortMethod();

  useEffect(() => {
    if (cuttings) {
      const dataTransformer = (src: CSVFileJson, wellTops: TopGroup[]) => {
        return getStructuredDataFromCSVFile(
          src,
          wellTops,
          speccamConfig,
          cuttings
        );
      };

      dispatch(
        fetchAnalyticsData(
          AnalyticsName.SPEC_CAM,
          activeWellbore?.analyticsData?.speccam?.dataReference,
          dataTransformer
        )
      );
    }
  }, [
    dispatch,
    activeWellbore?.analyticsData?.speccam?.dataReference,
    cuttings,
  ]);

  const {
    items,
    groups,
    filterActive,
    setFilterActive,
    setFilterText,
    groupsCollapsed,
    toggleCollapseAllGroups,
    clearSelection,
    allItemsAndGroupsFiltered,
  } = useItemsAndGroups(
    data,
    selectedCutting,
    selections[AnalyticsName.SPEC_CAM],
    sortMethod
  );

  return (
    <>
      <TabHeader
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        setFilterText={setFilterText}
        groupsCollapsed={groupsCollapsed}
        toggleCollapseAllGroups={toggleCollapseAllGroups}
        informationTooltipText={PDFtoolTipTexts.speccamData}
        informationPDFUrl={
          activeWellbore?.analyticsData?.speccam?.methodologyReference
        }
        sortMethod={sortMethod}
        onSortMethodChange={onSortMethodChange}
        tab={AnalyticsName.SPEC_CAM}
      />

      <ItemsAndGroupsFilteredMessage
        allItemsAndGroupsFiltered={allItemsAndGroupsFiltered}
      />

      <SelectionZone
        className="analytics-pane__selection-zone"
        selection={selections[AnalyticsName.SPEC_CAM]}
        selectionMode={SelectionMode.multiple}
        selectionPreservedOnEmptyClick={true}
      >
        <DetailsList
          columns={columnsConfig}
          items={items}
          groups={groups}
          onRenderRow={onRenderRow}
          groupProps={listGroupProps}
          compact={true}
          isHeaderVisible={false}
          checkboxVisibility={CheckboxVisibility.always}
          setKey="set"
          getKey={(item) => item.key}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selections[AnalyticsName.SPEC_CAM]}
          selectionMode={SelectionMode.multiple}
          selectionPreservedOnEmptyClick={true}
        />
      </SelectionZone>
      <SelectedItemsClearer
        numberOfSelectedItems={selectedElements.length}
        onClearSelection={clearSelection}
      />
    </>
  );
};

export const SpeccamTab = React.memo(SpeccamTabImpl);
