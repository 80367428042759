import React, { FC } from "react";
import {
  IButtonStyles,
  FontWeights,
  IButtonProps,
  DefaultButton,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import { Colors } from "../../constants";

const buttonStyles: IButtonStyles = {
  root: {
    position: "relative",
    background: NeutralColors.gray30,
    border: "none",
    borderRadius: "0",
    padding: 0,
    width: "40px",
    minWidth: "40px",
    height: "40px",
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
      ".ms-Button-menuIcon": { display: "none" },
      "&::before": {
        content: "", // this is stripped away by Fluent, so we add this property with own class
        position: "absolute",

        width: 2,
        background: Colors.primary,
        zIndex: 1,
        opacity: 0,
        transition: "opacity 50ms linear",
      },
      "&.is-checked::before": {
        opacity: 1,
      },
    },
  },
  label: {
    fontWeight: FontWeights.semibold,
    fontSize: "14px",
  },
  rootHovered: {
    background: NeutralColors.gray40,
  },
  rootDisabled: {
    background: NeutralColors.gray30,
  },
  labelDisabled: {
    color: NeutralColors.gray90,
  },
  rootChecked: {
    selectors: {
      "&::before": {
        opacity: 1,
      },
    },
  },
};

const buttonStylesLeft: IButtonStyles = {
  root: {
    selectors: {
      "&::before": {
        top: 0,
        bottom: 0,
        left: 1,
      },
    },
  },
};

const buttonStylesRight: IButtonStyles = {
  root: {
    selectors: {
      "&::before": {
        top: 0,
        bottom: 0,
        right: 1,
      },
    },
  },
};

export const SidebarButton: FC<IButtonProps & { rightAligned?: boolean }> = (
  props
) => {
  const { styles, rightAligned, ...restProps } = props;

  return (
    <DefaultButton
      className="sidebar-button"
      styles={mergeStyleSets(
        buttonStyles,
        rightAligned ? buttonStylesRight : buttonStylesLeft,
        styles
      )}
      {...restProps}
    />
  );
};
