import { Stack } from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Optional } from "../../types";
import { Close, Play } from "../icons";
import { ViewerController } from "../stage/ViewerController";
import { Slider, ToolbarIconButton } from "../styledFluentComponents";

interface ImageCompareSliderProps {
  closeCompareMode: () => void;
  viewerController: Optional<ViewerController>;
}

interface PlayerState {
  prevValue: number;
  lastPlayDirection: -1 | 1;
  interval?: number;
  accumulatedPlayTime: number;
  timeOfPrevUpdate: number;
}

const containerStyles = {
  root: {
    background: NeutralColors.gray30,
    borderBottom: `1px solid ${NeutralColors.gray60}`,
  },
};

const sliderAriaValueText = (value: number) => `${value} percent`;
const sliderValueFormat = (value: number) => `${value}%`;

const DEFAULT_SLIDER_VALUE = 50;
const animationDuration = 6000;
const maxValue = 100;
const center = maxValue / 2;
const wavePeriod = animationDuration;
const waveAmplitude = maxValue / 2;

const triangleWave = (x: number) =>
  ((4 * waveAmplitude) / wavePeriod) *
    Math.abs(
      ((((x - wavePeriod / 4) % wavePeriod) + wavePeriod) % wavePeriod) -
        wavePeriod / 2
    ) -
  waveAmplitude;

const inverseTriangleWave = (directionOfChange: -1 | 1, value: number) => {
  let x = 0;
  if (directionOfChange === 1) {
    if (value >= center) {
      x = 0 + ((value - center) / center) * animationDuration * 0.25;
    } else {
      x =
        animationDuration * 0.75 + (value / center) * animationDuration * 0.25;
    }
  } else if (directionOfChange === -1) {
    if (value >= center) {
      x =
        animationDuration * 0.25 +
        ((maxValue - value) / center) * animationDuration * 0.25;
    } else {
      x =
        animationDuration * 0.5 +
        ((center - value) / center) * animationDuration * 0.25;
    }
  }
  return x;
};

export const ImageCompareSlider: FC<ImageCompareSliderProps> = ({
  closeCompareMode,
  viewerController,
}) => {
  const [play, setPlay] = useState<boolean>(false);
  const [value, setValue] = useState<number>(DEFAULT_SLIDER_VALUE); // Its possible we do not need to keep this state here

  const playerState = useRef<PlayerState>({
    prevValue: value,
    lastPlayDirection: 1,
    interval: undefined,
    accumulatedPlayTime: 0,
    timeOfPrevUpdate: 0,
  });

  const playAnimation = useCallback(() => {
    setPlay(true);

    playerState.current.timeOfPrevUpdate = Date.now();
    clearInterval(playerState.current.interval);
    playerState.current.interval = (setInterval(() => {
      const currentTime = Date.now();
      playerState.current.accumulatedPlayTime +=
        currentTime - playerState.current.timeOfPrevUpdate;

      const newValue = Math.round(
        center + triangleWave(playerState.current.accumulatedPlayTime)
      );

      setValue(newValue);

      if (newValue !== playerState.current.prevValue) {
        playerState.current.lastPlayDirection =
          newValue > playerState.current.prevValue ? 1 : -1;
        playerState.current.prevValue = newValue;
      }
      playerState.current.timeOfPrevUpdate = currentTime;
    }, 40) as unknown) as number;
  }, []);

  const stopAnimation = useCallback(() => {
    clearInterval(playerState.current.interval);
    setPlay(false);
  }, []);

  const onManualChange = useCallback(
    (newValue) => {
      const updatePlayerStateToReflectManuallySelectedValue = () => {
        if (playerState.current.prevValue) {
          const directionOfChange = playerState.current.lastPlayDirection;
          playerState.current.accumulatedPlayTime = inverseTriangleWave(
            directionOfChange,
            newValue
          );
        }
      };

      stopAnimation();
      updatePlayerStateToReflectManuallySelectedValue();
      setValue(newValue);
    },
    [stopAnimation]
  );

  useEffect(() => {
    const reset = () => {
      stopAnimation();
      setValue(DEFAULT_SLIDER_VALUE);
    };
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    viewerController?.setOpacity(value);
  }, [viewerController, value]);

  return (
    <Stack horizontal verticalAlign="center" styles={containerStyles}>
      {!play && (
        <ToolbarIconButton
          title="Play"
          ariaLabel="Play"
          onClick={playAnimation}
          children={<Play />}
          defaultDimensions={true}
          autoFocus
        />
      )}
      {play && (
        <ToolbarIconButton
          iconProps={{ iconName: "Pause" }}
          styles={{ icon: { color: NeutralColors.gray160 } }}
          title="Pause"
          ariaLabel="Pause"
          onClick={stopAnimation}
          defaultDimensions={true}
          autoFocus
        />
      )}
      <Slider
        value={value}
        onChange={onManualChange}
        styles={{ root: { flexGrow: 1 } }}
        min={0}
        max={100}
        step={1}
        ariaValueText={sliderAriaValueText}
        valueFormat={sliderValueFormat}
        showValue
      />
      <ToolbarIconButton
        title="Close"
        ariaLabel="Close"
        onClick={closeCompareMode}
        children={<Close />}
        defaultDimensions={true}
      />
    </Stack>
  );
};
