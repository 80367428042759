import { SelectionMode } from "@fluentui/utilities";
import { AnalyticsName } from "../../../constants";
import { store } from "../../../store";
import { initialUrlSelections } from "../../../store/initialStateFromUrl";
import { pluck } from "../../../utils";
import { changeSelection } from "../analyticsSlice";
import { analyticsConfigs } from "../config";
import ExtendedSelection from "../ExtendedSelection";

export const selections: Record<AnalyticsName, ExtendedSelection> = {
  [AnalyticsName.XRF]: getSelection(AnalyticsName.XRF),
  [AnalyticsName.XRD]: getSelection(AnalyticsName.XRD),
  [AnalyticsName.TOC]: getSelection(AnalyticsName.TOC),
  [AnalyticsName.SPEC_CAM]: getSelection(AnalyticsName.SPEC_CAM),
  [AnalyticsName.QEM_SCAN]: getSelection(AnalyticsName.QEM_SCAN),
};

function getSelection(analyticsName: AnalyticsName) {
  return new ExtendedSelection({
    selectionMode: SelectionMode.multiple,
    onSelectionChanged: function () {
      const self = (this as unknown) as ExtendedSelection;
      if (self.skipOnSelectionChanged === true) return;

      const elements = self.getSelection().map(pluck("key")) as string[];

      self.removeGroupIfChildOfGroupWasSelected(elements);

      const newStoredSelection = {
        elements: elements,
        groups: self.getSelectedGroups(),
      };

      store.dispatch(
        changeSelection({
          tab: analyticsName,
          storedSelection: newStoredSelection,
        })
      );

      self.setCurrentStoredSelection(newStoredSelection);
    },
    elementsConfig: analyticsConfigs[analyticsName],
    initialStoredSelection: initialUrlSelections[analyticsName],
  });
}
