import { useMemo } from "react";
import {
  EXTRA_VERTICAL_PLOT_MARGIN,
  MAX_DATA_HEIGHT,
} from "../../components/dataAnalyticsPane/dataPlotter/DataPlotter";
import { SCALE_BAR_MARGIN } from "../../components/stage/config";
import { HEAD_FOOT_HEIGHT, THUMB_HEIGHT } from "../../components/track";
import { isDefaultZoomArea } from "../../store";
import { Cutting, Optional, RenderingConstraints, ZoomArea } from "../../types";
import { getNumItemsBetween } from "../../utils";

export function useRenderingConstraints(
  pageSize: Optional<DOMRect>,
  cuttings: Optional<Cutting[]>,
  selectedZoomArea: Optional<ZoomArea>
): [Optional<RenderingConstraints>, Optional<RenderingConstraints>] {
  /**
   * Rendering constraints to align data plotters with RangeFinder
   */
  const defaultConstraints = useMemo(() => {
    if (pageSize && cuttings) {
      return getRenderingConstraints(cuttings.length, pageSize);
    }
  }, [cuttings, pageSize]);

  /**
   * Rendering constraints to align data plotters with ZoomAreaTrack
   */
  const zoomAreaConstraints = useMemo(() => {
    if (
      pageSize &&
      selectedZoomArea &&
      cuttings &&
      !isDefaultZoomArea(selectedZoomArea, cuttings.length)
    ) {
      return getRenderingConstraints(
        getNumItemsBetween(selectedZoomArea.topIdx, selectedZoomArea.bottomIdx),
        pageSize
      );
    }
  }, [cuttings, pageSize, selectedZoomArea]);

  return [defaultConstraints, zoomAreaConstraints];
}

function getRenderingConstraints(
  numCuttings: number,
  pageSize: DOMRect
): RenderingConstraints {
  if (numCuttings <= 0) {
    throw Error(
      `Cannot calculate rendering constraings due to invalid number of cuttings: ${numCuttings}`
    );
  }

  const trackHeight =
    pageSize.height -
    SCALE_BAR_MARGIN.top -
    SCALE_BAR_MARGIN.bottom -
    2 * HEAD_FOOT_HEIGHT;

  const optimalDataStartCenter =
    EXTRA_VERTICAL_PLOT_MARGIN + MAX_DATA_HEIGHT / 2;
  const optimalImageStartCenter = Math.max(
    THUMB_HEIGHT / 2,
    trackHeight / numCuttings / 2
  );

  /**
   * Distance to center of data-shape (rect/circle) and center of backdrop image relative to the track header
   */
  const distanceToCenter = Math.max(
    optimalDataStartCenter,
    optimalImageStartCenter
  );

  const imageHeight =
    numCuttings >= 2
      ? (trackHeight - 2 * distanceToCenter) / (numCuttings - 1)
      : trackHeight;

  return {
    // Data plotter
    centeredScaleOuterPadding: {
      top: distanceToCenter - EXTRA_VERTICAL_PLOT_MARGIN,
      bottom: distanceToCenter - EXTRA_VERTICAL_PLOT_MARGIN,
    },
    // Range finder / tracks
    trackStartCenter: distanceToCenter,
    trackStep: imageHeight,
    outerTrackMargin: {
      top: distanceToCenter - imageHeight / 2,
      bottom: distanceToCenter - imageHeight / 2,
    },
    imageCenteredTrackMargin: {
      top: distanceToCenter,
      bottom: distanceToCenter,
    },
    trackContentHeight: trackHeight - 2 * (distanceToCenter - imageHeight / 2),
  };
}
