import {
  ContextualMenuItemType,
  DirectionalHint,
  IIconProps,
  IToggleStyles,
  Layer,
} from "@fluentui/react";
import React, { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import {
  selectSyncSelectedCutting,
  toggleSyncSelectedCutting,
  useAppDispatch,
} from "../../store";
import { StateSetter } from "../../types";
import { ContextualMenu, IconButton, Toggle } from "../styledFluentComponents";

const iconProps: IIconProps = { iconName: "More" };
const toggleStyles: Partial<IToggleStyles> = {
  root: { marginRight: "8px" },
  label: { paddingLeft: "8px", paddingRight: "24px" },
};

interface OptionsButtonProps {
  displayWellTops: boolean;
  setDisplayWellTops: StateSetter<boolean>;
}

export const OptionsButton: FC<OptionsButtonProps> = ({
  displayWellTops,
  setDisplayWellTops,
}) => {
  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const syncSelectedCutting = useSelector(selectSyncSelectedCutting);
  const { isOpen, toggleDropdown } = useDropdowns();

  const staticItems = [
    {
      key: "well-tops-info",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        topDivider: true,
        bottomDivider: false,
        title: "Stratigraphy",
        items: [
          {
            key: "content",
            onRender: () => (
              <Toggle
                styles={toggleStyles}
                label="Display well tops in mini-map"
                inlineLabel
                checked={displayWellTops}
                onChange={() => {
                  setDisplayWellTops((prevState) => !prevState);
                }}
              />
            ),
          },
        ],
      },
    },
    {
      key: "multiple-wellbores",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        topDivider: true,
        bottomDivider: false,
        title: "Multiple wellbores",
        items: [
          {
            key: "content",
            onRender: () => (
              <Toggle
                styles={toggleStyles}
                label="Link range track across windows"
                inlineLabel
                checked={syncSelectedCutting}
                onChange={() => {
                  dispatch(toggleSyncSelectedCutting());
                }}
              />
            ),
          },
        ],
      },
    },
  ];

  return (
    <>
      <IconButton
        elementRef={buttonRef}
        ariaLabel="Multiple wellbores"
        iconProps={iconProps}
        checked={isOpen[DropdownName.MULTIPLE_WELLBORES]}
        onClick={() => toggleDropdown(DropdownName.MULTIPLE_WELLBORES)}
      />

      {isOpen[DropdownName.MULTIPLE_WELLBORES] && (
        <Layer>
          <ContextualMenu
            target={buttonRef.current}
            directionalHint={DirectionalHint.bottomRightEdge}
            items={staticItems}
            shouldFocusOnMount={true}
            shouldFocusOnContainer={true}
          />
        </Layer>
      )}
    </>
  );
};
