import React, { FC } from "react";
import { IIconProps } from "@fluentui/react";
import { ToolbarIconButton } from "../styledFluentComponents/ToolbarIconButton";

const gridViewIcon: IIconProps = { iconName: "GridViewSmall" };

interface GridViewButtonProps {
  gridView: boolean;
  setGridView: (gridView: boolean | ((state: boolean) => boolean)) => void;
}

export const GridViewButton: FC<GridViewButtonProps> = ({
  gridView,
  setGridView,
}) => {
  return (
    <ToolbarIconButton
      ariaLabel="Toggle grid view"
      iconProps={gridViewIcon}
      onClick={() => setGridView((state) => !state)}
      toggle={true}
      checked={gridView}
    />
  );
};
