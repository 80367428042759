import {
  Callout,
  DirectionalHint,
  IIconProps,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useCallback, useRef, useState } from "react";
import { Colors, PromiseStatus } from "../../constants";
import { createShortLink } from "../../services/shortLinkService";
import { DefaultButton, IconButton } from "../styledFluentComponents";

const ICON_PROPS: IIconProps = {
  iconName: "ShareiOS",
};

const CLOSE_ICON_PROPS: IIconProps = {
  iconName: "Cancel",
};

export const ShortLinkButton: FC = () => {
  const [
    isCalloutVisible,
    { setTrue: setCalloutVisibleTrue, setFalse: setCalloutVisibleFalse },
  ] = useBoolean(false);
  const [status, setStatus] = useState<PromiseStatus>(PromiseStatus.IDLE);
  const [sharableLink, setSharableLink] = useState("");
  const [message, setMessage] = useState("");

  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");

  const inputRef = useRef<HTMLInputElement>(null);

  const createSharableLink = useCallback(async () => {
    setStatus(PromiseStatus.LOADING);
    setCalloutVisibleTrue();

    try {
      const response = await createShortLink();
      setSharableLink(response.shortLink);
      setStatus(PromiseStatus.SUCCESS);
    } catch (e) {
      setStatus(PromiseStatus.ERROR);
      setMessage("Failed to create short ULR. Please try again later.");
    }
  }, [setCalloutVisibleTrue]);

  const closeCallout = useCallback(() => {
    setSharableLink("");
    setCalloutVisibleFalse();
    setMessage("");
    setStatus(PromiseStatus.IDLE);
  }, [setCalloutVisibleFalse]);

  const copyLinkToClipboard = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      inputRef.current.blur();
      setMessage("Successfully copied to clipboard");
    }
  }, []);

  return (
    <>
      <IconButton
        styles={iconButtonStyles}
        iconProps={ICON_PROPS}
        ariaLabel="Get sharable link"
        onClick={createSharableLink}
        id={buttonId}
      ></IconButton>
      {isCalloutVisible && (
        <Callout
          styles={calloutStyles}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
          gapSpace={16}
          target={`#${buttonId}`}
          onDismiss={closeCallout}
          setInitialFocus
          isBeakVisible={false}
          directionalHint={DirectionalHint.leftBottomEdge}
        >
          <Stack horizontal>
            <Text block variant="xLarge" className={styles.title} id={labelId}>
              Share findings
            </Text>
            <IconButton
              iconProps={CLOSE_ICON_PROPS}
              styles={closeButtonStyles}
              onClick={closeCallout}
            />
          </Stack>
          <Text
            block
            variant="medium"
            id={descriptionId}
            className={styles.description}
          >
            Copy the link below to share your findings
          </Text>
          <div className={styles.inputContainer}>
            <div className={styles.textFieldContainer}>
              <input
                ref={inputRef}
                value={sharableLink}
                className={styles.textField}
                readOnly
              />

              {status === PromiseStatus.LOADING && (
                <Spinner
                  role="alert"
                  aria-busy={true}
                  ariaLabel="spinner"
                  className={styles.loadingSpinner}
                  size={SpinnerSize.medium}
                />
              )}
            </div>
            <StatusMessage status={status}>{message}</StatusMessage>
          </div>
          <DefaultButton
            text="Copy to clipboard"
            className={styles.clipboardButton}
            onClick={copyLinkToClipboard}
            disabled={!sharableLink}
          />
        </Callout>
      )}
    </>
  );
};

const StatusMessage: FC<{ status: PromiseStatus }> = ({ status, children }) => {
  return (
    <Text
      style={{
        display: "absolute",
        bottom: 0,
        fontSize: 12,
        marginTop: 6,
        color: status === PromiseStatus.ERROR ? Colors.error : Colors.success,
      }}
    >
      {children}
    </Text>
  );
};

const iconButtonStyles = {
  root: {
    width: 40,
    height: 40,
  },
};

const calloutStyles = {
  root: {
    width: 380,
    padding: 24,
    paddingTop: 16,
    background: NeutralColors.gray10,
  },
  calloutMain: { background: NeutralColors.gray10 },
};

const closeButtonStyles = {
  root: {
    background: NeutralColors.gray10,
  },
};

const styles = mergeStyleSets({
  title: { flexGrow: 1 },
  description: {
    marginTop: 24,
    fontWeight: 600,
    color: NeutralColors.gray130,
  },
  inputContainer: {
    position: "relative",
    height: 32 + 18,
  },
  textFieldContainer: {
    position: "relative",
    height: 32,
    width: "100%",
    marginTop: 6,
  },
  textField: {
    height: 32,
    width: "100%",
    padding: "0 8px",
    margin: 0,
    border: `1px solid ${NeutralColors.gray110}`,
    color: NeutralColors.gray110,
    background: NeutralColors.gray30,
    fontSize: 14,
    borderRadius: 2,
    userSelect: "none",
    "&:focus": {
      border: `1px solid ${Colors.primary}`,
      outline: "0 !important",
    },
  },
  loadingSpinner: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 10,
  },
  clipboardButton: {
    float: "right",
    marginTop: 20,
  },
});
