import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  IDetailsGroupRenderProps,
  SelectionMode,
  SelectionZone,
  Stack,
} from "@fluentui/react";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import {
  selectActiveWellbore,
  selectCuttings,
  selectSelectedCutting,
} from "../../../store/selectors";
import { StoreState, useAppDispatch } from "../../../store/store";
import { CSVFileJson, TopGroup } from "../../../types";
import { DetailsList, IconButton, List } from "../../styledFluentComponents";
import { fetchAnalyticsData } from "../analyticsSlice";
import {
  columnsConfig,
  infoIcon,
  PDFtoolTipTexts,
  qemscanConfig,
  qemscanSampleInfoConfig,
} from "../config";
import {
  useItemsAndGroups,
  useSortMethod,
  useStoredSelection,
} from "../utils/hooks";
import { selections } from "../utils/selections";
import {
  getOnRenderRow,
  getStructuredDataFromCSVFile,
  onRenderBasicListCell,
  renderGroupHeader,
} from "../utils/tab-helpers";
import ItemsAndGroupsFilteredMessage from "./ItemsAndGroupsFilteredMessage";
import { SelectedItemsClearer } from "./SelectedItemsClearer";
import { TabHeader } from "./TabHeader";

const listGroupProps: IDetailsGroupRenderProps = {
  onRenderHeader: (props, defaultRenderer) => {
    return renderGroupHeader(props, qemscanConfig, defaultRenderer);
  },
};
const onRenderRow = getOnRenderRow(AnalyticsName.QEM_SCAN, qemscanConfig);

const QemscanTabImpl: FC = () => {
  const dispatch = useAppDispatch();
  const selectedCutting = useSelector(selectSelectedCutting);
  const activeWellbore = useSelector(selectActiveWellbore);
  const cuttings = useSelector(selectCuttings);

  const selectedElements = useStoredSelection(AnalyticsName.QEM_SCAN);
  const sampleInformation = useSelector(
    (state: StoreState) => state.analytics.srcData.qemscanSampleInformation
  );
  const data = useSelector(
    (state: StoreState) => state.analytics.srcData.qemscan
  );
  const [sortMethod, onSortMethodChange] = useSortMethod();

  useEffect(() => {
    if (cuttings) {
      const sampleInfoTransformer = (
        src: CSVFileJson,
        wellTops: TopGroup[]
      ) => {
        return getStructuredDataFromCSVFile(
          src,
          wellTops,
          qemscanSampleInfoConfig,
          cuttings,
          false
        );
      };

      dispatch(
        fetchAnalyticsData(
          "qemscanSampleInformation",
          activeWellbore?.analyticsData?.qemscan?.dataReference,
          sampleInfoTransformer
        )
      );

      const dataTransformer = (src: CSVFileJson, wellTops: TopGroup[]) => {
        return getStructuredDataFromCSVFile(
          src,
          wellTops,
          qemscanConfig,
          cuttings
        );
      };

      dispatch(
        fetchAnalyticsData(
          AnalyticsName.QEM_SCAN,
          activeWellbore?.analyticsData?.qemscan?.dataReference,
          dataTransformer
        )
      );
    }
  }, [dispatch, activeWellbore?.analyticsData?.qemscan, cuttings]);

  const sampleInfoData = useItemsAndGroups(
    sampleInformation,
    selectedCutting,
    selections[AnalyticsName.QEM_SCAN],
    sortMethod
  );

  const {
    items,
    groups,
    filterActive,
    setFilterActive,
    setFilterText,
    groupsCollapsed,
    toggleCollapseAllGroups,
    clearSelection,
    allItemsAndGroupsFiltered,
  } = useItemsAndGroups(
    data,
    selectedCutting,
    selections[AnalyticsName.QEM_SCAN],
    sortMethod,
    false
  );

  return (
    <>
      <TabHeader
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        setFilterText={setFilterText}
        groupsCollapsed={groupsCollapsed}
        toggleCollapseAllGroups={toggleCollapseAllGroups}
        informationTooltipText={PDFtoolTipTexts.qemscanData}
        informationPDFUrl={
          activeWellbore?.analyticsData?.qemscan?.extendedMethodologyReference
        }
        sortMethod={sortMethod}
        onSortMethodChange={onSortMethodChange}
        tab={AnalyticsName.QEM_SCAN}
      />

      {sampleInfoData.items && (
        <>
          <Stack
            className="analytics-pane--horizontal-margin analytics-pane__group-heading--spaced"
            horizontal
            verticalAlign={"center"}
          >
            <h3 className="analytics-pane__group-heading">
              Sample information
            </h3>
            <IconButton
              iconProps={infoIcon}
              ariaLabel="Open file"
              toolTipText={PDFtoolTipTexts.qemscanSampleText}
              disabled={
                !activeWellbore?.analyticsData?.qemscan?.methodologyReference
              }
              onClick={() => {
                const url =
                  activeWellbore?.analyticsData?.qemscan?.methodologyReference;
                if (url) {
                  window.open(url, "_blank");
                }
              }}
            />
          </Stack>
          <List
            className={
              "analytics-pane__basic-list analytics-pane--horizontal-margin-2"
            }
            items={sampleInfoData.items}
            onRenderCell={onRenderBasicListCell}
          />
        </>
      )}

      <ItemsAndGroupsFilteredMessage
        allItemsAndGroupsFiltered={allItemsAndGroupsFiltered}
      />

      <SelectionZone
        className="analytics-pane__selection-zone"
        selection={selections[AnalyticsName.QEM_SCAN]}
        selectionMode={SelectionMode.multiple}
        selectionPreservedOnEmptyClick={true}
      >
        <DetailsList
          columns={columnsConfig}
          items={items}
          groups={groups}
          onRenderRow={onRenderRow}
          groupProps={listGroupProps}
          compact={true}
          isHeaderVisible={false}
          checkboxVisibility={CheckboxVisibility.always}
          setKey="set"
          getKey={(item) => item.key}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selections[AnalyticsName.QEM_SCAN]}
          selectionMode={SelectionMode.multiple}
          selectionPreservedOnEmptyClick={true}
          onShouldVirtualize={() => false}
        />
      </SelectionZone>
      <SelectedItemsClearer
        numberOfSelectedItems={selectedElements.length}
        onClearSelection={clearSelection}
      />
    </>
  );
};

export const QemscanTab = React.memo(QemscanTabImpl);
