/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from "d3";
import { Optional } from "../../../../types";
import { PLOTTER_MARGIN, Scales } from "../DataPlotter";

export const SIZE_SCALE_FACTOR = 1.3;

export type ChartElements = Record<
  | "svg"
  | "xAxis"
  | "yAxis"
  | "grid"
  | "dataContainer"
  | "infobox"
  | "currentDepth"
  | "formation"
  | "highlightedDepth",
  // eslint-disable-next-line
  d3.Selection<d3.BaseType, unknown, HTMLElement, any>
>;

export function getNumberOfTicks(
  width: number,
  maxDomain: number
): number | undefined {
  if (maxDomain < 100 && width < 300) {
    return 6;
  }
  if (maxDomain > 10000 && maxDomain < 100000) {
    return width <= 300 ? 4 : 8;
  }
  if (maxDomain > 100000 && width < 300) {
    return 3;
  }
}

export function drawAxisAndGrid(
  chartElements: ChartElements,
  scales: Scales,
  depths: number[],
  graphWidth: number
): void {
  const lengthOfGridLines = -scales.graphHeight;

  const ticks = getNumberOfTicks(graphWidth, scales.x.domain()[1]);

  chartElements.grid.transition().call(
    // @ts-ignore
    d3
      .axisTop(scales.x)
      .ticks(ticks)
      .tickSize(lengthOfGridLines)
      .tickSizeOuter(0)
      .tickFormat(() => "")
  );

  chartElements.xAxis
    .transition()
    .call((d3.axisTop(scales.x) as any).ticks(ticks));

  const yAxisTickValues = getYAxisTickValues(depths, scales.yAxisHeight, 20);

  chartElements.yAxis
    .transition()
    .call(d3.axisLeft(scales.y).tickValues(yAxisTickValues) as any);
}

function getYAxisTickValues(
  depths: number[],
  graphHeight: number,
  pixelsPerLabel: number
): string[] {
  const numberOfTicks = Math.floor(graphHeight / pixelsPerLabel);
  const periodOfDepthPicking = Math.ceil(depths.length / numberOfTicks);

  return depths.reduce<string[]>((acc, depth, index) => {
    if (index % periodOfDepthPicking === 0) {
      acc.push(String(depth));
    }
    return acc;
  }, []);
}

export function drawHighlightedDepthLine(
  scales: Scales,
  width: number,
  highlightedDepth: number | undefined,
  chartElements: React.MutableRefObject<ChartElements | undefined>
): void {
  function addPositionAttributes(line: any) {
    line
      .attr("class", "highlighted-depth-line")
      .attr("x1", PLOTTER_MARGIN.left)
      .attr("y1", (d: number) => (scales.y(String(d)) as number) + 0.5)
      .attr("x2", width)
      .attr("y2", (d: number) => (scales.y(String(d)) as number) + 0.5);
  }

  const canResolveYPos = (d: number) => typeof scales.y(String(d)) === "number";

  if (highlightedDepth && canResolveYPos(highlightedDepth)) {
    chartElements.current?.highlightedDepth
      .selectAll("line")
      .data([highlightedDepth])
      .join(
        (enter) => enter.append("line").call(addPositionAttributes),
        (update) => update.call(addPositionAttributes),
        (exit) => exit.remove()
      );
  } else {
    chartElements.current?.highlightedDepth.selectAll("line").remove();
  }
}

export function drawCurrentDepthLine(
  scales: Scales,
  width: number,
  selectedCuttingDepth: Optional<number>,
  chartElements: React.MutableRefObject<ChartElements | undefined>
): void {
  function addPositionAttributes(line: any) {
    line
      .attr("class", "current-depth-line")
      .attr("x1", PLOTTER_MARGIN.left)
      .attr("y1", (d: number) => (scales.y(String(d)) as number) + 0.5)
      .attr("x2", width)
      .attr("y2", (d: number) => (scales.y(String(d)) as number) + 0.5);
  }

  const canResolveYPos = (d: number) => typeof scales.y(String(d)) === "number";

  if (selectedCuttingDepth && canResolveYPos(selectedCuttingDepth)) {
    chartElements.current?.currentDepth
      .selectAll("line")
      .data([selectedCuttingDepth])
      .join(
        (enter) => enter.append("line").call(addPositionAttributes),
        (update) => update.call(addPositionAttributes),
        (exit) => exit.remove()
      );
  } else {
    chartElements.current?.currentDepth.selectAll("line").remove();
  }
}
