import { ISliderStyles, ITextFieldStyles } from "@fluentui/react";
import { clamp } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { NumberRange } from "../../../types";
import { toFloat } from "../../../utils";
import { Slider, TextField } from "../../styledFluentComponents";

interface RangeSliderProps {
  rangeLimits: NumberRange;
  onRangeChange: (range: NumberRange) => void;
}

const RANGE_SLIDER_HEIGHT = 22;

const sliderStyles: Partial<ISliderStyles> = {
  root: {
    marginLeft: 8,
    marginRight: 8,
    flexGrow: 1,
  },
  slideBox: {
    height: RANGE_SLIDER_HEIGHT,
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  field: {
    padding: "0px 4px",
  },
  fieldGroup: {
    width: 60,
    height: RANGE_SLIDER_HEIGHT,
    fontSize: 12,
    lineHeight: 16,
  },
};

const getStepValue = (higherRangeLimit: number) => {
  if (higherRangeLimit < 1) {
    return 0.01;
  }
  if (higherRangeLimit < 100) {
    return 0.1;
  }
  if (higherRangeLimit < 1000) {
    return 1;
  }
  if (higherRangeLimit < 10000) {
    return 10;
  }
  return 100;
};

const RangeSliderImpl: FC<RangeSliderProps> = ({
  rangeLimits,
  onRangeChange,
}) => {
  const [range, setRange] = useState<NumberRange>({
    low: rangeLimits.low,
    high: rangeLimits.high,
  });

  const lowerRangeChangeHandler = useCallback(
    (_: unknown, newValueString?: string) => {
      const value = clamp(
        toFloat(newValueString ? newValueString : "0", rangeLimits.low),
        rangeLimits.low,
        rangeLimits.high
      );
      const newRange: NumberRange = {
        low: value,
        high: range.high,
      };
      setRange(newRange);
      onRangeChange(newRange);
    },
    [onRangeChange, range.high, rangeLimits.high, rangeLimits.low]
  );

  const higherRangeChangeHandler = useCallback(
    (_: unknown, newValueString?: string) => {
      const value = clamp(
        toFloat(newValueString ? newValueString : "0", rangeLimits.high),
        rangeLimits.low,
        rangeLimits.high
      );

      const newRange: NumberRange = {
        low: range.low,
        high: value,
      };
      setRange(newRange);
      onRangeChange(newRange);
    },
    [onRangeChange, range.low, rangeLimits.high, rangeLimits.low]
  );

  const onSliderChange = useCallback(
    (_: unknown, range?: [number, number]) => {
      if (range) {
        const newRange: NumberRange = { low: range[0], high: range[1] };
        setRange(newRange);
        onRangeChange(newRange);
      }
    },
    [onRangeChange]
  );

  useEffect(() => {
    setRange({ low: rangeLimits.low, high: rangeLimits.high });
  }, [rangeLimits]);

  const stepValue = useMemo(() => {
    return getStepValue(rangeLimits.high);
  }, [rangeLimits.high]);

  return (
    <div className="range-slider-container">
      <TextField
        aria-label="Range lower value"
        type="number"
        step={stepValue}
        value={range.low.toString()}
        onChange={lowerRangeChangeHandler}
        styles={textFieldStyles}
        disabled={rangeLimits.low === rangeLimits.high}
      />
      <Slider
        min={rangeLimits.low}
        max={rangeLimits.high}
        ranged
        step={stepValue}
        lowerValue={range.low}
        value={range.high}
        showValue={false}
        onChange={onSliderChange}
        styles={sliderStyles}
        disabled={rangeLimits.low === rangeLimits.high}
      />

      <TextField
        aria-label="Range upper value"
        type="number"
        step={stepValue}
        value={range.high.toString()}
        onChange={higherRangeChangeHandler}
        styles={textFieldStyles}
        disabled={rangeLimits.low === rangeLimits.high}
      />
    </div>
  );
};

export const RangeSlider = React.memo(RangeSliderImpl);
