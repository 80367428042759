import React, { FC } from "react";
import { TrackContentProps } from "../track";

const HIGHLIGHTED_DEPTH_INDICATOR_THICKNESS = 2;

interface HighlightedDepthIndicatorProps extends TrackContentProps {
  highlightedIndex: number | undefined;
}

export const HighlightedDepthIndicator: FC<HighlightedDepthIndicatorProps> = ({
  highlightedIndex,
  renderingConstraints,
  size,
}) => {
  if (size && highlightedIndex !== undefined) {
    const highlightedDepthTop =
      renderingConstraints.trackStartCenter +
      renderingConstraints.trackStep * highlightedIndex -
      HIGHLIGHTED_DEPTH_INDICATOR_THICKNESS / 2;

    return (
      <div
        className="highlighted-depth-indicator"
        style={{ top: `${highlightedDepthTop}px` }}
      ></div>
    );
  }

  return null;
};
