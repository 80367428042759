/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  IButtonStyles,
  Icon,
  IconButton,
  IContextualMenuItem,
  IStackTokens,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { Depths, NeutralColors } from "@fluentui/theme";
import firebase from "firebase/app";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName, Breakpoint, DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import { useShouldRender } from "../../hooks";
import {
  loadNewWellbore,
  selectSelectedWellbore,
  selectWellbores,
  useAppDispatch,
} from "../../store";
import { ComponentSize, Wellbore } from "../../types/types";
import {
  ContextualMenu,
  contextualMenuStyles,
  DefaultButton as StyledDefaultButton,
  IconLink,
  navButtonStyles,
} from "../styledFluentComponents";
import { MapView } from "./mapView/MapView";
import { MapViewMenu } from "./mapViewMenu/MapViewMenu";
import { NpdIconButton } from "./NpdIconButton";
import { SearchField } from "./SearchField";

interface WellboreNavigationProps {
  setMapViewIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mapViewIsOpen: boolean;
  size: ComponentSize;
}

export const WellboreNavigationImpl: FC<WellboreNavigationProps> = ({
  mapViewIsOpen,
  setMapViewIsOpen,
}) => {
  const dispatch = useAppDispatch();
  const wellbores = useSelector(selectWellbores);
  const selectedWellbore = useSelector(selectSelectedWellbore);

  const {
    isOpen,
    toggleDropdown,
    closeAllDropdowns,
    openDropdown,
  } = useDropdowns();

  const [selectedWellboreFilters, setSelectedWellboreFilters] = useState<
    AnalyticsName[]
  >([AnalyticsName.XRF]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const nearbyWells = findNearbyWells(selectedWellbore!, wellbores);

  useEffect(() => {
    if (mapViewIsOpen) {
      closeAllDropdowns();
      openDropdown(DropdownName.WELLBORE_FILTER);
    }
    // eslint-disable-next-line
  }, [mapViewIsOpen]);

  /**
   * Add keyboard event listener for map view
   */
  useEffect(() => {
    const keypressHandler = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        setMapViewIsOpen(false);
      } else if (ev.key === "m") {
        setMapViewIsOpen((state) => !state);
      }
    };

    document.addEventListener("keydown", keypressHandler);

    return () => document.removeEventListener("keydown", keypressHandler);
  }, [mapViewIsOpen, setMapViewIsOpen]);

  const menuItems: IContextualMenuItem[] = [
    {
      key: "search-wellbore",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        items: [
          {
            key: "search-field",
            onRender: () => (
              <div>
                <SearchField />
              </div>
            ),
          },
        ],
      },
    },
    {
      key: "nearby-well",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        title: "Nearby wells",
        items: nearbyWells.map((wb) => ({
          key: wb.name,
          onRender: () => (
            <Stack horizontal>
              <StyledDefaultButton
                onClick={() => {
                  dispatch(loadNewWellbore(wb));
                  toggleDropdown(DropdownName.WELLBORE);
                }}
                text={wb.name}
                styles={nearbyWellItem}
                grayScale
              />
              <IconLink
                href={`${window.location.origin}/?well=${wb.name}`}
                target={wb.name}
                toolTipText="Open in new window"
                iconProps={{
                  iconName: "OpenInNewWindow",
                }}
                styles={iconLinkStyles}
              />
            </Stack>
          ),
        })),
      },
    },
  ];

  const shouldRenderMapView = useShouldRender(mapViewIsOpen);

  return (
    <>
      {shouldRenderMapView && (
        <MapView
          isOpen={mapViewIsOpen}
          wellboreFilters={selectedWellboreFilters}
        />
      )}

      <div
        className="wellbore-navigation"
        style={{ zIndex: mapViewIsOpen ? 50 : 30 }}
      >
        <Stack horizontal tokens={stackTokens}>
          <IconButton
            iconProps={{ iconName: "POISolid" }}
            ariaLabel="Toggle map view"
            styles={mergeStyleSets(navButtonStyles, mapViewButtonStyles)}
            checked={mapViewIsOpen}
            onClick={() => setMapViewIsOpen((state) => !state)}
          />
          <DefaultButton
            elementRef={buttonRef}
            styles={mergeStyleSets(navButtonStyles, dataNavButtonStyles)}
            checked={isOpen[DropdownName.WELLBORE]}
            onClick={(e) => {
              toggleDropdown(DropdownName.WELLBORE);
            }}
          >
            <span>{selectedWellbore?.name || "Select wellbore"}</span>
            <Icon iconName="ChevronDown" className={styles.arrowIcon} />
            <div className={styles.npdIcon}>
              <NpdIconButton />
            </div>
          </DefaultButton>
        </Stack>

        {isOpen[DropdownName.WELLBORE] && (
          <ContextualMenu
            items={menuItems}
            styles={mergeStyleSets(contextualMenuStyles, navMenuStyles)}
            target={buttonRef.current}
            directionalHint={DirectionalHint.bottomLeftEdge}
          />
        )}

        {mapViewIsOpen && (
          <MapViewMenu
            selectedWellboreFilters={selectedWellboreFilters}
            setSelectedWellboreFilters={setSelectedWellboreFilters}
          />
        )}
      </div>
    </>
  );
};

export const WellboreNavigation = React.memo(WellboreNavigationImpl);

const distance = (
  pointA: firebase.firestore.GeoPoint,
  pointB: firebase.firestore.GeoPoint
) => {
  if (
    pointA.latitude === pointB.latitude &&
    pointA.longitude === pointB.longitude
  ) {
    return 0;
  } else {
    const radlat1 = (Math.PI * pointA.latitude) / 180;
    const radlat2 = (Math.PI * pointB.latitude) / 180;
    const theta = pointA.longitude - pointB.longitude;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
};

const findNearbyWells = (currentWellbore: Wellbore, wellbores: Wellbore[]) => {
  const filteredWellbores = wellbores.filter(
    (wb) => wb.coordinates && wb.name !== currentWellbore.name
  );
  if (currentWellbore.coordinates) {
    const nearbyWells = filteredWellbores.sort(
      (a, b) =>
        distance(currentWellbore.coordinates!, a.coordinates!) -
        distance(currentWellbore.coordinates!, b.coordinates!)
    );
    return nearbyWells.slice(0, 10);
  }
  return [];
};

const stackTokens: IStackTokens = { childrenGap: 8 };

const mapViewButtonStyles: IButtonStyles = {
  root: {
    boxShadow: Depths.depth16,
    color: NeutralColors.gray160,

    [Breakpoint.S]: {
      width: "40px",
      height: "40px",
    },
    [Breakpoint.M]: {
      width: "56px",
      height: "56px",
    },
    [Breakpoint.L]: {
      width: "72px",
      height: "72px",
      marginRight: "4px",
    },
  },
  icon: {
    [Breakpoint.S]: {
      fontSize: 18,
    },
    [Breakpoint.M]: {
      fontSize: 22,
    },
    [Breakpoint.L]: {
      fontSize: 26,
    },
  },
};

const dataNavButtonStyles: IButtonStyles = {
  root: {
    position: "relative",
    height: "72px",
    width: "320px",
    border: "0",
    fontSize: "20px",
    color: NeutralColors.gray160,
    [Breakpoint.S]: {
      width: "200px",
      height: "40px",
      fontSize: "14px",
    },
    [Breakpoint.M]: {
      width: "256px",
      height: "56px",
      fontSize: "16px",
    },
    [Breakpoint.L]: {
      width: "320px",
      height: "72px",
      fontSize: "20px",
    },
  },
  rootChecked: {
    borderRadius: "2px 2px 0px 0px",
    color: "white",
  },
  flexContainer: { justifyContent: "left" },
};
const navMenuStyles = {
  root: {
    padding: "10px 10px 10px 10px",
    color: NeutralColors.gray160,

    [Breakpoint.S]: {
      width: "200px",
    },
    [Breakpoint.M]: {
      width: "256px",
    },
    [Breakpoint.L]: {
      width: "320px",
    },
  },
  header: {
    padding: "0px",
  },
  container: {
    borderRadius: "2px 2px 0px 0px",
  },
};

const styles = mergeStyleSets({
  arrowIcon: {
    fontSize: 15,
    marginLeft: "auto",
  },
  npdIcon: {
    marginLeft: 16,
  },
});

const nearbyWellItem: IButtonStyles = {
  root: {
    color: NeutralColors.gray160,
    border: "none",
    width: "100%",
    textAlign: "left",
    fontWeight: "400",
    selectors: {
      "& span": {
        fontWeight: "400",
      },
    },
  },
  rootHovered: {
    background: NeutralColors.gray50,
  },
};

const iconLinkStyles = {
  root: {
    selectors: {
      "&:hover .icon-link__icon, &:focus .icon-link__icon": {
        background: NeutralColors.gray40,
      },
    },
  },
};
