import { initializeIcons, registerIcons } from "@fluentui/react";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { NpdIcon } from "./components/icons";
import "./index.scss";
import configureFirebase from "./services/configureFirebase";

// Initalize icons for fluentUI
initializeIcons();
initializeFileTypeIcons();
registerIcons({
  icons: {
    Npd: <NpdIcon />,
  },
});

configureFirebase()
  .then(async () => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch((error) => console.log(error.message));
