import { IDropdownOption, IGroup } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import { createSelectSelectedElements } from "../../../store/selectors";
import {
  Cutting,
  IListItem,
  Optional,
  StateSetter,
  StructuredData,
} from "../../../types";
import { SortMethod } from "../../../utils/sorting";
import ExtendedSelection from "../ExtendedSelection";
import { selections } from "./selections";
import { getItemsAndGroups } from "./tab-helpers";

/**
 * State and setters for sort method of tabs
 */
export type DropdownOnChange = (
  _: React.FormEvent<HTMLDivElement>,
  item: IDropdownOption | undefined
) => void;

export function useSortMethod(): [SortMethod | undefined, DropdownOnChange] {
  const [sortMethod, setSortMethod] = useState<SortMethod>();
  const onSortMethodChange: DropdownOnChange = useCallback(
    (_, item): void => {
      if (item) {
        setSortMethod(item.key as SortMethod);
      }
    },
    [setSortMethod]
  );

  return [sortMethod, onSortMethodChange];
}

/**
 * Fetch, transform and set analytics data for wellbore.
 */
export function useDataFetcher<T>(
  url: string | undefined,
  transformer: (src: T) => StructuredData | undefined
): StructuredData | undefined {
  const [data, setData] = useState<StructuredData>();

  useEffect(() => {
    (async () => {
      if (url) {
        const response = await fetch(url)
          .then((res) => res.json())
          .catch((e) => {
            setData(undefined);
          });
        if (response) {
          const structuredData = transformer(response);
          setData(structuredData);
        }
      } else {
        setData(undefined);
      }
    })();
  }, [url, transformer]);

  return data;
}

export interface ItemsAndGroupsData {
  items: IListItem[];
  groups: IGroup[];
  toggleCollapseAllGroups: () => void;
  clearSelection: () => void;
  groupsCollapsed: boolean;
  filterActive: boolean;
  setFilterText: StateSetter<Optional<string>>;
  setFilterActive: StateSetter<boolean>;
  allItemsAndGroupsFiltered: boolean;
}

interface ItemsAndGroupsState {
  items: IListItem[];
  groups: IGroup[];
  groupsCollapsed: boolean;
  allItemsAndGroupsFiltered: boolean;
}

const itemsAndGroupsInitialValue: ItemsAndGroupsState = {
  items: [],
  groups: [],
  groupsCollapsed: true,
  allItemsAndGroupsFiltered: false,
};

/**
 * State and management of items and groups for fluent DetailsList
 */
export function useItemsAndGroups(
  data: StructuredData | undefined,
  selectedCutting: Cutting | undefined,
  selection: ExtendedSelection,
  sortMethod?: SortMethod | undefined,
  allDefaultSelected = false
): ItemsAndGroupsData {
  const [
    { items, groups, groupsCollapsed, allItemsAndGroupsFiltered },
    setItemsAndGroups,
  ] = useState<ItemsAndGroupsState>(itemsAndGroupsInitialValue);

  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>();

  const toggleCollapseAllGroups = useCallback(() => {
    setItemsAndGroups((prevState) => ({
      items: prevState.items,
      groups: getCollapsedGroups(prevState.groups, prevState.groupsCollapsed),
      groupsCollapsed: !prevState.groupsCollapsed,
      allItemsAndGroupsFiltered: prevState.allItemsAndGroupsFiltered,
    }));
  }, []);

  const clearSelection = useCallback(() => {
    selection.clearAll();
  }, [selection]);

  useEffect(() => {
    if (!filterActive) {
      setFilterText(undefined);
    }
  }, [filterActive]);

  useEffect(() => {
    if (selectedCutting) {
      if (data) {
        const _dataForDepth = data.get(selectedCutting.depth);
        if (_dataForDepth) {
          const [_items, _groups] = getItemsAndGroups(
            _dataForDepth,
            sortMethod,
            filterText
          );

          // Need only check if groups are filtered since they are derived from children items
          const allItemsAndGroupsFiltered = !_groups.some(
            (group) => !group.data.filtered
          );

          setItemsAndGroups((prevState) => ({
            items: _items,
            groups: _groups,
            groupsCollapsed: prevState.groupsCollapsed,
            allItemsAndGroupsFiltered: allItemsAndGroupsFiltered,
          }));

          if (_items && selection.getItems().length === 0) {
            selection.setItems(_items);
            if (allDefaultSelected) {
              selection.setAllSelected(true);
            }
          }
        }
      } else {
        setItemsAndGroups(itemsAndGroupsInitialValue);
      }
    }
  }, [
    allDefaultSelected,
    data,
    selectedCutting,
    selection,
    sortMethod,
    filterText,
  ]);

  return {
    items,
    groups,
    toggleCollapseAllGroups,
    clearSelection,
    groupsCollapsed,
    filterActive,
    setFilterText,
    setFilterActive,
    allItemsAndGroupsFiltered,
  };
}

function getCollapsedGroups(groups: IGroup[], groupsCollapsed: boolean) {
  return groups.map((group) => {
    const children = group.children?.map((childGroup) => {
      // Do not collapse flat-groups as they should always be expanded
      if (!childGroup.name.includes("flat-group")) {
        childGroup.isCollapsed = !groupsCollapsed;
      }
      return childGroup;
    });
    group.children = children;
    return group;
  });
}

/**
 * Get a memoized array of selected elements and groups for a tab from the store
 */
export function useStoredSelection(tabName: AnalyticsName): string[] {
  const storedSelection = useSelector(createSelectSelectedElements(tabName));
  const [selectedElements, setSelectedElements] = useState<string[]>([
    ...storedSelection.elements,
    ...storedSelection.groups,
  ]);

  useEffect(() => {
    selections[tabName].setStoredSelection(storedSelection);
    setSelectedElements([
      ...storedSelection.elements,
      ...storedSelection.groups,
    ]);
  }, [storedSelection, tabName]);

  return selectedElements;
}
