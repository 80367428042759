import React, { FC, useMemo } from "react";
import {
  DefaultButton as FluentDefaultButton,
  IButtonProps,
  IButtonStyles,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";

import { Breakpoint, Colors } from "../../constants";

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    background: Colors.primary,
    color: NeutralColors.white,
    borderRadius: "4px",
    border: "0",
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${NeutralColors.white} !important`,
      },
    },
  },
  rootHovered: {
    background: Colors.darkAlt,
    color: NeutralColors.white,
  },
  rootPressed: {
    background: Colors.dark,
    color: NeutralColors.white,
  },
  rootDisabled: {
    background: NeutralColors.gray50,
    color: NeutralColors.gray90,
  },
  labelDisabled: {
    color: NeutralColors.gray90,
  },
};

const LargeButtonDimensionStyles = {
  root: {
    [Breakpoint.S]: {
      width: "76px",
      height: "32px",
      fontSize: "14px",
    },
    [Breakpoint.M]: {
      width: "110px",
      height: "48px",
      fontSize: "18px",
    },
    [Breakpoint.L]: {
      width: "145px",
      height: "64px",
      fontSize: "24px",
    },
  },
};

const SmallButtonDimensionStyles = {
  root: {
    [Breakpoint.S]: {
      width: "89px",
      height: "32px",
      fontSize: "14px",
    },
    [Breakpoint.M]: {
      width: "89px",
      height: "32px",
      fontSize: "14px",
    },
    [Breakpoint.L]: {
      width: "89px",
      height: "32px",
      fontSize: "14px",
    },
  },
};

export const LoginButton: FC<IButtonProps & { isLargeButton: boolean }> = ({
  styles,
  isLargeButton,
  ...restProps
}) => {
  const mergedStyles = useMemo(
    () =>
      mergeStyleSets(
        buttonStyles,
        styles,
        isLargeButton ? LargeButtonDimensionStyles : SmallButtonDimensionStyles
      ),
    [isLargeButton, styles]
  );
  return <FluentDefaultButton styles={mergedStyles} {...restProps} />;
};
