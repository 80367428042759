import { useEffect, useState } from "react";
import { Optional, UseState } from "../../types";
import { URLParamValue, setQueryParam, getQueryParam } from "../../utils";

export function useQueryParamState<T extends Optional<URLParamValue>>(
  paramName: string,
  defaultValue: T,
  valueResolver?: (state: T) => URLParamValue
): UseState<T> {
  const [state, setState] = useState(
    () => (getQueryParam(paramName) as T) ?? defaultValue
  );

  useEffect(() => {
    if (valueResolver) {
      setQueryParam(paramName, valueResolver(state));
    } else {
      setQueryParam(paramName, state);
    }
  }, [paramName, state, valueResolver]);

  return [state, setState];
}
