import React, { FC, useEffect, useRef } from "react";
import * as d3 from "d3";
import { NeutralColors } from "@fluentui/theme";

import { Margin, Domain } from "../../types/types";

type AxisPosition = "top" | "bottom" | "left";
interface ScaleBarProps {
  width: number;
  height: number;
  position: AxisPosition;
  scalingFactor: number;
  domain: Domain;
  margin: Margin;
}

export const ScaleBar: FC<ScaleBarProps> = ({
  scalingFactor,
  width,
  height,
  position,
  domain,
  margin,
}) => {
  const decimalAxisRef = useRef<SVGGElement>(null);
  const halfNumberAxisRef = useRef<SVGGElement>(null);
  const wholeNumberAxisRef = useRef<SVGGElement>(null);

  useEffect(() => {
    if (
      decimalAxisRef?.current &&
      halfNumberAxisRef?.current &&
      wholeNumberAxisRef?.current
    ) {
      const domainInCm = {
        min: domain.min * scalingFactor,
        max: domain.max * scalingFactor,
      };

      let scale;
      if (position === "bottom" || position === "top") {
        scale = d3
          .scaleLinear([margin.left, width - margin.right])
          .domain([domainInCm.min, domainInCm.max]);
      } else {
        scale = d3
          .scaleLinear([margin.top, height - margin.bottom])
          .domain([domainInCm.min, domainInCm.max]);
      }

      const roundToOneDecimal = (num: number) => Math.round(num * 10) / 10;
      const decimalTickValues = [];
      const halfNumberTickValues = [];
      const wholeNumberTickValues = [];
      for (let i = domainInCm.min; i <= domainInCm.max; i += 0.1) {
        const roundedValue = roundToOneDecimal(i);
        const valueIsADecimal = Math.round(i) !== roundedValue;
        const valueIsAHalf = roundedValue % 1 !== 0.5;
        if (valueIsADecimal && valueIsAHalf) {
          decimalTickValues.push(roundedValue);
        } else if (valueIsADecimal && !valueIsAHalf) {
          halfNumberTickValues.push(roundedValue);
        } else {
          wholeNumberTickValues.push(roundedValue);
        }
      }

      let decimalAxis, halfNumberAxis, wholeNumberAxis;
      if (position === "bottom") {
        decimalAxis = d3.axisBottom(scale);
        halfNumberAxis = d3.axisBottom(scale);
        wholeNumberAxis = d3.axisBottom(scale);
      } else if (position === "top") {
        decimalAxis = d3.axisTop(scale);
        halfNumberAxis = d3.axisTop(scale);
        wholeNumberAxis = d3.axisTop(scale);
      } else {
        decimalAxis = d3.axisLeft(scale);
        halfNumberAxis = d3.axisLeft(scale);
        wholeNumberAxis = d3.axisLeft(scale);
      }

      decimalAxis
        .tickValues(decimalTickValues)
        .tickSize(10)
        .tickSizeOuter(0)
        .tickFormat(() => "");

      halfNumberAxis
        .tickValues(halfNumberTickValues)
        .tickSize(10)
        .tickSizeOuter(0)
        .tickFormat(() => "");

      wholeNumberAxis
        .tickValues(wholeNumberTickValues)
        .tickSize(20)
        .tickSizeOuter(0)
        .tickFormat(d3.format("d"));

      const getTransformProperty = (pos: AxisPosition) => {
        if (pos === "bottom") {
          return `translate(0, ${height - margin.bottom})`;
        } else if (pos === "top") {
          return `translate(0, ${margin.top})`;
        } else {
          return `translate(${margin.left}, 0)`;
        }
      };

      const getTickTextXProperty = (pos: AxisPosition) => {
        if (pos === "top" || pos === "bottom") {
          return "10";
        } else {
          return "-4";
        }
      };

      const getTickTextYProperty = (pos: AxisPosition) => {
        if (pos === "top") {
          return "-5";
        } else if (pos === "bottom") {
          return "5";
        } else {
          return "12";
        }
      };

      d3.select(decimalAxisRef.current)
        .call(decimalAxis)
        .attr("transform", getTransformProperty(position))
        .attr("color", NeutralColors.gray160);

      d3.select(halfNumberAxisRef.current)
        .call(halfNumberAxis)
        .attr("transform", getTransformProperty(position));

      d3.select(wholeNumberAxisRef.current)
        .call(wholeNumberAxis)
        .attr("transform", getTransformProperty(position))
        .selectAll("g.tick text")
        .attr("x", getTickTextXProperty(position))
        .attr("y", getTickTextYProperty(position))
        .attr("font-size", "16px")
        .attr("font-family", "Segoe UI");
    }
  }, [width, height, domain, scalingFactor, position, margin]);

  return (
    <>
      <g ref={decimalAxisRef}></g>
      <g ref={halfNumberAxisRef}></g>
      <g ref={wholeNumberAxisRef}></g>
    </>
  );
};
