import { IStackStyles, Stack } from "@fluentui/react";
import throttle from "lodash/throttle";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { ViewMode } from "../../constants";
import OpenSeaDragon from "../../dependencies/openseadragon";
import extendOSDWithFiltering from "../../openseadragon-plugins/openseadragon-filtering";
import extendOSDWithMeasurementTool from "../../openseadragon-plugins/openseadraon-measument-tool";
import {
  selectSelectedCutting,
  selectSelectedImageType,
  selectSelectedImageType2,
} from "../../store";
import { Optional } from "../../types";
import { Domain, ImageReference } from "../../types/types";
import { Alert, AlertType } from "../alert/Alert";
import { ViewerFrame } from "../frames/frames";
import {
  IMAGE_VIEWER_MAX_ZOOM,
  IMAGE_VIEWER_MIN_ZOOM,
  OSD_SETTINGS,
} from "./config";
import { ViewerController } from "./ViewerController";
import { ViewerToolsMenu } from "./ViewerToolsMenu";

let ExtendedOpenSeaDragon: typeof OpenSeaDragon = extendOSDWithFiltering(
  OpenSeaDragon
);
ExtendedOpenSeaDragon = extendOSDWithMeasurementTool(ExtendedOpenSeaDragon);

export interface ImageViewerProps {
  setXDomain: (domain: Domain) => void;
  setYDomain: (domain: Domain) => void;
  show: boolean;
  compareMode?: boolean;
  // viewerController: ViewerController;
  viewMode: ViewMode;
}

export const ImageViewer = React.forwardRef<
  Optional<ViewerController>,
  ImageViewerProps
>(
  (
    {
      setXDomain,
      setYDomain,
      show,
      compareMode,
      // #remove:  viewerControllerRef,
      viewMode,
    },
    viewerControllerRef
  ) => {
    const [viewer, setViewer] = useState<OpenSeaDragon.Viewer>();
    const [rulerToolActive, setRulerToolActive] = useState(false);
    const selectedCutting = useSelector(selectSelectedCutting);
    const selectedImageType = useSelector(selectSelectedImageType);
    const selectedImageType2 = useSelector(selectSelectedImageType2);

    const viewerController = useRef(new ViewerController()).current;
    useImperativeHandle(viewerControllerRef, () => viewerController);

    const prevSelectedCutting = useRef<string>();
    const prevImageReference = useRef<ImageReference>();
    const prevImageBReference = useRef<ImageReference>();

    const updateDomain = useCallback(
      (osdViewer: OpenSeaDragon.Viewer) => () => {
        const boundsInViewportCoords = osdViewer.viewport.getBounds();
        const viewportPointTopLeft = new OpenSeaDragon.Point(
          boundsInViewportCoords.x,
          boundsInViewportCoords.y
        );
        const viewportPointBottomRight = new OpenSeaDragon.Point(
          boundsInViewportCoords.x + boundsInViewportCoords.width,
          boundsInViewportCoords.y + boundsInViewportCoords.height
        );

        const imageA = osdViewer.world.getItemAt(0);
        if (imageA) {
          const imageCoordTopLeft = imageA.viewportToImageCoordinates(
            viewportPointTopLeft,
            true
          );
          const imageCoordBottomRight = imageA.viewportToImageCoordinates(
            viewportPointBottomRight,
            true
          );

          const imageWidth = imageCoordBottomRight.x - imageCoordTopLeft.x;
          const imageHeight = imageCoordBottomRight.y - imageCoordTopLeft.y;

          const leftEdgeIsOutsideCanvas = imageCoordTopLeft.x < 0;
          const topEdgeIsOutsideCanvas = imageCoordTopLeft.y < 0;

          setXDomain({
            min: leftEdgeIsOutsideCanvas ? imageCoordTopLeft.x : 0,
            max: leftEdgeIsOutsideCanvas ? imageCoordBottomRight.x : imageWidth,
          });
          setYDomain({
            min: topEdgeIsOutsideCanvas ? imageCoordTopLeft.y : 0,
            max: topEdgeIsOutsideCanvas ? imageCoordBottomRight.y : imageHeight,
          });
        }
      },
      [setXDomain, setYDomain]
    );

    /**
     * Initialize open sea dragon
     */
    useEffect(() => {
      const viewerCtrler = viewerController;

      const osdViewer = ExtendedOpenSeaDragon({
        id: "openSeaDragon",
        navigatorId: "openseadragon-mini-map",
        showNavigator: true,
        minZoomLevel: IMAGE_VIEWER_MIN_ZOOM,
        maxZoomLevel: IMAGE_VIEWER_MAX_ZOOM,
        ...OSD_SETTINGS,
      });
      const throttledAnimationHandler = throttle(updateDomain(osdViewer), 40); // 25 fps
      osdViewer.addHandler("animation", throttledAnimationHandler);

      setViewer(osdViewer);
      viewerCtrler.setViewer(osdViewer);

      return () => {
        viewerCtrler.cleanUp();
      };
    }, [updateDomain, viewerController]);

    /**
     * Clear and add new main image when cutting or selected image type change.
     */
    useEffect(() => {
      if (selectedCutting && selectedImageType && viewer) {
        const imageReference = selectedCutting.imageReferences.find(
          (r) => r.type === selectedImageType
        );

        if (
          imageReference &&
          prevImageReference.current?.reference !== imageReference.reference
        ) {
          const zoom = viewer.viewport.getZoom();
          const center = viewer.viewport.getCenter();
          const resolutionHasChanged =
            imageReference.resolutionHeight !==
              prevImageReference.current?.resolutionHeight ||
            imageReference.resolutionWidth !==
              prevImageReference.current?.resolutionWidth;

          const updateZoomHandler = () => {
            const firstLoad = !prevSelectedCutting.current;
            if (firstLoad && viewMode === ViewMode.KEEP_VIEW) {
              viewerController.zoomToFitPage();
            } else if (
              viewMode === ViewMode.KEEP_VIEW ||
              (selectedCutting?.id === prevSelectedCutting.current &&
                !resolutionHasChanged)
            ) {
              viewer.viewport.zoomTo(zoom, undefined, true);
              viewer.viewport.panTo(center, true);
            } else if (viewMode === ViewMode.FIT_TO_WIDTH) {
              viewerController.zoomToFitWidth();
            } else if (viewMode === ViewMode.FIT_TO_PAGE) {
              viewerController.zoomToFitPage();
            }
            prevSelectedCutting.current = selectedCutting?.id;
            updateDomain(viewer)();
          };

          const imageTypeIsTheSameAsTheLastLoadedImage =
            selectedImageType === prevImageReference.current?.type;

          viewerController.replaceImageA({
            tileSource: imageReference.reference,
            success: () => {
              updateZoomHandler();

              viewerController.setMeasurementPluginOptions({
                imageReference: imageReference,
              });

              if (imageTypeIsTheSameAsTheLastLoadedImage) {
                viewerController.setFilters();
              }
            },
          });

          prevImageReference.current = imageReference;
        }
      }
      // eslint-disable-next-line
    }, [selectedCutting, selectedImageType, viewer, viewerController]);

    /**
     * Clear and update new secondary image when cutting or selected image type change
     */
    useEffect(() => {
      if (viewer && compareMode && selectedCutting && selectedImageType2) {
        const imageBReference = selectedCutting.imageReferences.find(
          (r) => r.type === selectedImageType2
        );

        const imageTypeIsTheSameAsTheLastLoadedImage =
          selectedImageType2 === prevImageBReference.current?.type;

        if (imageBReference) {
          viewerController.replaceImageB({
            tileSource: imageBReference.reference,
            success: () => {
              if (imageTypeIsTheSameAsTheLastLoadedImage) {
                viewerController.setFilters();
              }
            },
          });
        }
        prevImageBReference.current = imageBReference;
      }
    }, [
      viewer,
      compareMode,
      selectedCutting,
      selectedImageType2,
      viewerController,
    ]);

    /**
     * Clear secondary image when no longer in compare mode.
     */
    useEffect(() => {
      if (!compareMode) {
        viewerController.clearImageB();
        prevImageBReference.current = undefined;
      }
    }, [compareMode, viewerController]);

    return (
      <ViewerFrame id="openSeaDragon" className={show ? "viewer--visible" : ""}>
        {rulerToolActive && (
          <Stack styles={alertStackStyles}>
            <Alert type={AlertType.INFORMATION}>
              Press the ESC key to exit the tool.
            </Alert>
          </Stack>
        )}
        <ViewerToolsMenu
          viewerController={viewerController}
          rulerToolActive={rulerToolActive}
          setRulerToolActive={setRulerToolActive}
        />
      </ViewerFrame>
    );
  }
);

const alertStackStyles: IStackStyles = {
  root: {
    position: "absolute",
    top: 8,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
  },
};
