import React, { FC } from "react";
import { Stack, IStackStyles } from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";

import { DefaultButton } from "../../styledFluentComponents";

const COMPONENT_HEIGHT = 48;

const stackStyles: IStackStyles = {
  root: {
    paddingRight: 16,
    paddingLeft: 16,
    background: NeutralColors.gray30,
    height: COMPONENT_HEIGHT,
  },
};

const itemStyles: React.CSSProperties = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

const containerStyles: React.CSSProperties = {
  position: "sticky",
  bottom: 0,
  transition: "height 0.3s",
  overflow: "hidden",
  marginTop: 10,
  boxShadow:
    "0px -0.6px 1.8px rgba(0, 0, 0, 0.1), 0px -3.2px 7.2px rgba(0, 0, 0, 0.13)",
};

interface SelectedItemsClearerProps {
  numberOfSelectedItems: number;
  onClearSelection: () => void;
}

const SelectedItemsClearerImpl: FC<SelectedItemsClearerProps> = ({
  numberOfSelectedItems,
  onClearSelection,
}) => {
  return (
    <>
      <div
        style={{
          height: numberOfSelectedItems === 0 ? 0 : COMPONENT_HEIGHT,
          ...containerStyles,
        }}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          styles={stackStyles}
        >
          <span style={itemStyles}>{numberOfSelectedItems} selected</span>
          <DefaultButton
            text="Clear selections"
            style={itemStyles}
            onClick={onClearSelection}
          />
        </Stack>
      </div>
    </>
  );
};

export const SelectedItemsClearer = React.memo(SelectedItemsClearerImpl);
