import React, { FC, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute, LoadingSpinner } from "./components";
import useFirebaseAuthentication from "./services/useFirebaseAuthentication";
import useFirebaseRoleListener from "./services/useFirebaseRoleListener";

const HomeWrapper = React.lazy(() => import("./pages/home/Home"));
const Login = React.lazy(() => import("./pages/login/Login"));
const TOS = React.lazy(() => import("./pages/tos/TOS"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacy/Privacy"));

const App: FC = () => {
  const { user, loading } = useFirebaseAuthentication();
  const role = useFirebaseRoleListener(user);

  if (user && role !== "data-consumer") {
    return (
      <Suspense fallback={<LoadingSpinner loading={true} />}>
        <Login checkAuthorization={true} />
      </Suspense>
    );
  } else {
    return (
      <LoadingSpinner loading={loading}>
        <BrowserRouter>
          <Suspense fallback={<LoadingSpinner loading={true} />}>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                authenticated={!!user}
                component={HomeWrapper}
              ></PrivateRoute>
              <PublicRoute
                path="/login"
                authenticated={!!user}
                component={Login}
              ></PublicRoute>
              <Route path="/tos">
                <TOS />
              </Route>
              <Route path="/privacy">
                <PrivacyPolicy />
              </Route>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </LoadingSpinner>
    );
  }
};

export default App;
