import { IButtonStyles } from "@fluentui/react";
import React, { forwardRef, useEffect, useState } from "react";
import { animated } from "react-spring";
import { isDefaultZoomArea } from "../../store";
import { RenderingConstraints, ZoomArea } from "../../types";
import { getClasses } from "../../utils";
import { DefaultButton } from "../styledFluentComponents";

interface ZoomAreaOutlineProps {
  zoomArea: ZoomArea;
  resetZoomArea: () => void;
  zoomAreaTopThumb: React.ReactNode;
  zoomAreaBottomThumb: React.ReactNode;
  renderingConstraints: RenderingConstraints;
  numberOfCuttings: number;
  onZoomSliderChange: (cuttingIndex: number) => void;
}

export const ZoomAreaOutline = forwardRef<HTMLDivElement, ZoomAreaOutlineProps>(
  (
    {
      zoomArea,
      resetZoomArea,
      zoomAreaTopThumb,
      zoomAreaBottomThumb,
      renderingConstraints,
      numberOfCuttings,
      onZoomSliderChange,
    },
    ref
  ) => {
    const topPositionZoomArea =
      zoomArea.topIdx * renderingConstraints.trackStep;

    const heightOfZoomArea =
      renderingConstraints.trackStep +
      (zoomArea.bottomIdx - zoomArea.topIdx) * renderingConstraints.trackStep;

    const heightBelowZoomArea =
      (numberOfCuttings - zoomArea.bottomIdx - 1) *
      renderingConstraints.trackStep;

    const [hoveringZoomArea, setHoveringZoomArea] = useState<boolean>(false);

    const [showResetButton, setShowResetButton] = useState(false);

    useEffect(() => {
      if (hoveringZoomArea) {
        setShowResetButton(true);
      } else {
        const timeout = setTimeout(() => {
          setShowResetButton(false);
        }, 1000);

        return () => clearTimeout(timeout);
      }
    }, [hoveringZoomArea]);

    return (
      <>
        <div
          className="zoom-area-blurred"
          style={{ height: topPositionZoomArea }}
        />
        <animated.div
          ref={ref}
          className="zoom-area"
          style={{
            top: topPositionZoomArea,
            height: heightOfZoomArea,
          }}
          onMouseEnter={() => setHoveringZoomArea(true)}
          onMouseLeave={() => setHoveringZoomArea(false)}
        >
          {zoomAreaTopThumb}
          {zoomAreaBottomThumb}

          {!isDefaultZoomArea(zoomArea, numberOfCuttings) && (
            <>
              {showResetButton && (
                <DefaultButton
                  className={"zoom-area__clear-button"}
                  grayScale
                  styles={resetButtonStyles}
                  onClick={(e) => {
                    resetZoomArea();
                    e.stopPropagation();
                  }}
                  text="Reset"
                />
              )}
            </>
          )}
        </animated.div>
        <div
          className="zoom-area-blurred"
          style={{
            top: topPositionZoomArea + heightOfZoomArea,
            height: heightBelowZoomArea,
          }}
        />
      </>
    );
  }
);

export const ZoomAreaThumb = forwardRef<
  HTMLDivElement,
  { placement: "top" | "bottom"; thumbIcon: React.ReactNode }
>(({ placement, thumbIcon }, ref) => {
  return (
    <div
      className={getClasses(
        "zoom-area__thumb",
        `zoom-area__thumb--${placement}`
      )}
      ref={ref}
    >
      {thumbIcon}
    </div>
  );
});

export const ZoomAreaThumbIcon = forwardRef<SVGSVGElement>((_, ref) => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="8 6 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <path
        d="M18 4C19.1046 4 20 4.89543 20 6L20 18C20 19.1046 19.1046 20 18 20L13.2361 20C12.4785 20 11.786 19.572 11.4472 18.8944L8 12L11.4472 5.10557C11.786 4.428 12.4785 4 13.2361 4L18 4Z"
        fill="#EDEBE9"
      />
      <path
        d="M18 4.5C18.8284 4.5 19.5 5.17157 19.5 6L19.5 18C19.5 18.8284 18.8284 19.5 18 19.5L13.2361 19.5C12.6679 19.5 12.1485 19.179 11.8944 18.6708L8.55902 12L11.8944 5.32918C12.1485 4.821 12.6679 4.5 13.2361 4.5L18 4.5Z"
        stroke="#605E5C"
      />
      <rect
        x="13"
        y="13"
        width="4"
        height="1"
        fill="#605E5C"
        shapeRendering="crispEdges"
      />
      <rect
        x="13"
        y="10"
        width="4"
        height="1"
        fill="#605E5C"
        shapeRendering="crispEdges"
      />
    </svg>
  );
});

const resetButtonStyles: IButtonStyles = {
  root: {
    height: 20,
    width: 46,
    minWidth: 46,
    fontSize: 12,
    bottom: 15,
  },
};
