import { DEFAULT_ADJUSTMENTS } from "../../constants";
import OpenSeaDragon from "../../dependencies/openseadragon";
import { Filter } from "../../openseadragon-plugins/openseadragon-filtering";
import { MeasurementToolOptions } from "../../openseadragon-plugins/openseadraon-measument-tool";
import { ImageAdjustments } from "../../types/types";

export class ViewerController {
  viewer: OpenSeaDragon.Viewer | undefined;
  indexOfA: number;
  indexOfB: number;
  opacity: number;
  adjustmentsA: ImageAdjustments;
  adjustmentsB: ImageAdjustments;

  constructor(
    options: {
      viewer?: OpenSeaDragon.Viewer;
      indexOfA?: number;
      indexOfB?: number;
      opacity?: number;
    } = {}
  ) {
    this.viewer = options.viewer ?? undefined;
    this.indexOfA = options.indexOfA ?? 0;
    this.indexOfB = options.indexOfB ?? 1;
    this.opacity = options.opacity ?? 0.5;

    this.adjustmentsA = DEFAULT_ADJUSTMENTS;
    this.adjustmentsB = DEFAULT_ADJUSTMENTS;
  }

  cleanUp(): void {
    this.viewer?.measurementToolPluginInstance?.cleanUp();
    this.viewer?.destroy();
    this.setViewer(undefined);
  }

  setViewer(viewer: OpenSeaDragon.Viewer | undefined): void {
    this.viewer = viewer;
    if (viewer) {
      this._registerHandlers(viewer);
    }
  }

  clearImageA(): void {
    this._clearImage(this.indexOfA);
  }
  clearImageB(): void {
    this._clearImage(this.indexOfB);
  }
  clearAllImages(): void {
    if (this.viewer) {
      this.viewer.world.removeAll();
    }
  }
  replaceImageA(options: OpenSeaDragon.TiledImageOptions): void {
    if (this.viewer) {
      const replaceBecauseAllreadyHasImageA = !!this.viewer.world.getItemAt(
        this.indexOfA
      );
      options.index = this.indexOfA;
      options.replace = replaceBecauseAllreadyHasImageA;
      options.maxNumberOfImages = 2;
      options.cacheKeySuffix = "-image-a";
      this.viewer.addTiledImage(options);
    }
  }
  replaceImageB(options: OpenSeaDragon.TiledImageOptions): void {
    if (this.viewer) {
      const replaceBecauseAllreadyHasImageB = !!this.viewer.world.getItemAt(
        this.indexOfB
      );
      options.index = this.indexOfB;
      options.replace = replaceBecauseAllreadyHasImageB;
      options.opacity = this.opacity;
      options.maxNumberOfImages = 2;
      options.cacheKeySuffix = "-image-b";
      this.viewer.addTiledImage(options);
    }
  }
  /**
   * @param opacity number in percent
   */
  setOpacity(opacity: number): void {
    if (this.viewer) {
      const imageB = this.viewer.world.getItemAt(this.indexOfB);
      if (imageB) {
        this.opacity = opacity / 100;
        imageB.setOpacity(this.opacity);
      }
    }
  }

  setAdjustmentsA(adjustments: Partial<ImageAdjustments>): void {
    this.adjustmentsA = { ...this.adjustmentsA, ...adjustments };
    this.setFilters();
  }
  setAdjustmentsB(adjustments: Partial<ImageAdjustments>): void {
    this.adjustmentsB = { ...this.adjustmentsB, ...adjustments };
    this.setFilters();
  }

  setFilters(): void {
    const filters: Filter[] = [];

    if (this.viewer) {
      const imageA = this.viewer.world.getItemAt(this.indexOfA);

      if (imageA) {
        filters.push({
          item: imageA,
          adjustments: this.adjustmentsA,
        });
      }

      const imageB = this.viewer.world.getItemAt(this.indexOfB);
      if (imageB) {
        filters.push({
          item: imageB,
          adjustments: this.adjustmentsB,
        });
      }

      this.viewer.setFilterOptions({
        filters: filters,
      });
    }
  }

  getCenter(): OpenSeaDragon.Point {
    if (this.viewer) {
      const imageA = this.viewer.world.getItemAt(this.indexOfA);
      if (imageA) {
        const bounds = imageA.getBounds();
        return new OpenSeaDragon.Point(0.5, bounds.height / 2);
      }
    }
    return new OpenSeaDragon.Point(0.5, 0.33);
  }

  getZoomWhereResolutionOfImageMatchScreen(): number {
    if (this.viewer) {
      const imageA = this.viewer.world.getItemAt(0);
      if (imageA) {
        const zoomLevelWhereResolutionOfImageMatchScreen =
          imageA.source.dimensions.x /
          this.viewer.viewport.getContainerSize().x;
        return zoomLevelWhereResolutionOfImageMatchScreen;
      }
    }
    return 1;
  }

  zoomToOneToOneScale(): void {
    if (this.viewer) {
      const tiledImage = this.viewer.world.getItemAt(0);
      const targetZoom =
        tiledImage.source.dimensions.x /
        this.viewer.viewport.getContainerSize().x;
      this.viewer.viewport.zoomTo(targetZoom, undefined, true);
    }
  }
  zoomToFitPage(): void {
    if (this.viewer) {
      this.viewer.viewport.goHome();
    }
  }

  zoomToFitWidth(): void {
    if (this.viewer) {
      const center = this.getCenter();
      this.viewer.viewport.zoomTo(1, undefined, false);
      this.viewer.viewport.panTo(center, true);
    }
  }

  setMeasurementPluginOptions(options: MeasurementToolOptions): void {
    if (this.viewer) {
      this.viewer.setMeasurementToolOptions(options);
    }
  }

  enableMeasurementTool(): void {
    if (this.viewer) {
      this.viewer.measurementToolPluginInstance.enableEditing();
    }
  }

  disableMeasurementTool(): void {
    if (this.viewer) {
      this.viewer.measurementToolPluginInstance.disableEditing();
    }
  }

  private _clearImage(imageIndex: number): void {
    if (this.viewer) {
      const image = this.viewer.world.getItemAt(imageIndex);
      if (image) this.viewer.world.removeItem(image);
    }
  }

  private _registerHandlers(viewer: OpenSeaDragon.Viewer): void {
    const _this = this; // eslint-disable-line @typescript-eslint/no-this-alias
    viewer.navigator.world.addHandler(
      "add-item",
      this._cropImageBInNavigator.bind(_this)
    );
  }

  private _cropImageBInNavigator(): void {
    if (this.viewer) {
      const imageB = this.viewer.navigator.world.getItemAt(this.indexOfB);
      if (imageB) {
        const viewportBounds = imageB.getBounds();
        const bottomLeft = imageB.viewportToImageCoordinates(
          new OpenSeaDragon.Point(viewportBounds.x, viewportBounds.height)
        );
        const topRight = imageB.viewportToImageCoordinates(
          new OpenSeaDragon.Point(viewportBounds.width, viewportBounds.y)
        );
        const bottomRight = imageB.viewportToImageCoordinates(
          new OpenSeaDragon.Point(viewportBounds.width, viewportBounds.height)
        );
        imageB.setCroppingPolygons([[bottomLeft, topRight, bottomRight]]);
        this.viewer.navigator.forceRedraw();
      }
    }
  }
}
