import { IButtonStyles, IIconProps } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as d3 from "d3";
import { debounce } from "lodash";
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { ChartType } from "../../../constants";
import { useAppSprings, usePinnedTooltips } from "../../../context";
import {
  selectActiveCuttings,
  selectAnalyticsPaneIsOpen,
  selectCuttings,
  selectSelectedCuttingDepth,
  setSelectedCutting,
  useAppDispatch,
} from "../../../store";
import {
  NumberRange,
  PlotData,
  PlotState,
  RenderingConstraints,
  ScatterPlotData,
  StackedBarPlotData,
} from "../../../types";
import { getClasses, removeDuplicates } from "../../../utils";
import { PlotterFrameSVG } from "../../frames/frames";
import { addTooltipElement } from "../../plot-tooltip/plot-tooltip";
import { IconButton } from "../../styledFluentComponents";
import { HEAD_FOOT_HEIGHT } from "../../track";
import { getDomainMax, setDomain } from "../analyticsSlice";
import {
  ChartElements,
  drawAxisAndGrid,
  drawCurrentDepthLine,
  drawHighlightedDepthLine,
} from "./drawing/common-drawing";
import { drawFormationTopChanges } from "./drawing/formation-tops";
import { drawScatterChart } from "./drawing/scatter-chart";
import { drawStackedBarChart } from "./drawing/stacked-bar-chart";
import { RangeSlider } from "./RangeSlider";

export const MIN_WIDTH_OF_PLOTTER = 200;
export const DEFAULT_WIDTH_OF_PLOTTER = 600;
export const MAX_DATA_HEIGHT = 32;
export const MIN_POINT_RADIUS = 4;
export const EXTRA_VERTICAL_PLOT_MARGIN = 10;

export const PLOTTER_MARGIN = {
  top: HEAD_FOOT_HEIGHT + EXTRA_VERTICAL_PLOT_MARGIN,
  bottom: HEAD_FOOT_HEIGHT + EXTRA_VERTICAL_PLOT_MARGIN,
  left: 40,
  right: 45,
};

const closeIconProps: IIconProps = { iconName: "Cancel" };
const closeButtonStyles: IButtonStyles = {
  root: {
    position: "absolute",
    top: 6,
    right: 6,
    width: 24,
    height: 24,
    background: "transparent",
  },
};

export interface Scales {
  x: d3.ScaleLinear<number, number, never>;
  y: d3.ScalePoint<string>;
  barThickness: number;
  pointRadius: number;
  graphHeight: number;
  yAxisHeight: number;
}

interface DataPlotterProps {
  height: number; // height to tell D3 to use
  width: number; // width to tell D3 to use
  numPlots: number; // passed down to the frame for the plot (PlotterFrameSVG), so that it can calculate its width
  plotState: PlotState;
  setHighlightedDepth: (depth: number | undefined) => void;
  highlightedDepth: number | undefined;
  outlined: boolean;
  highlighted: boolean;
  clickable: boolean;
  setSelected: () => void;
  removePlot: () => void;
  renderingConstraints: RenderingConstraints;
}

export const DataPlotter: FC<DataPlotterProps> = ({
  height,
  width,
  numPlots,
  plotState,
  setHighlightedDepth,
  highlightedDepth,
  outlined,
  highlighted,
  clickable,
  setSelected,
  removePlot,
  renderingConstraints,
}) => {
  width = Math.max(width, MIN_WIDTH_OF_PLOTTER);

  const analyticsPaneIsOpen = useSelector(selectAnalyticsPaneIsOpen);
  const dispatch = useAppDispatch();
  const cuttings = useSelector(selectCuttings);
  const activeCuttings = useSelector(selectActiveCuttings);
  const selectedCuttingDepth = useSelector(selectSelectedCuttingDepth);

  const activeCuttingsPlotData = useMemo(() => {
    if (!plotState.data) {
      return undefined;
    }

    const activeData = (plotState.data as PlotData[]).filter((data) =>
      activeCuttings?.some((c) => c.depth === data.depth)
    );

    return activeData as ScatterPlotData[] | StackedBarPlotData[];
  }, [activeCuttings, plotState.data]);

  const { showWellTops: showStratigraphyInTooltip } = useAppSprings();
  const { setActiveTooltip } = usePinnedTooltips();

  const currentlyDrawnChartType = useRef<
    ChartType.SCATTER | ChartType.STACKED_BAR
  >();
  const currentlyDrawnNumberOfDepths = useRef<number>();

  const [domainLimit, setDomainLimit] = useState<NumberRange>({
    low: 0,
    high: 0,
  });

  const id = useId();
  const tooltipId = `data-plotter-tooltip-${id}`;
  const chartElements = useRef<ChartElements>();

  const updateSelectedCutting = useCallback(
    // eslint-disable-next-line
    (dataOrEvent: any) => {
      let depth: number;
      if (dataOrEvent?.srcElement?.__data__?.depth) {
        depth = dataOrEvent.srcElement.__data__.depth;
      } else if (dataOrEvent?.srcElement?.__data__?.data?.depth) {
        depth = dataOrEvent.srcElement.__data__.data.depth;
      } else if (dataOrEvent?.depth) {
        depth = dataOrEvent.depth;
      }
      const selectedCutting = activeCuttings?.find((c) => c.depth === depth);
      if (selectedCutting) {
        dispatch(setSelectedCutting(selectedCutting));
      }
    },
    [activeCuttings, dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const domainChangeHandler = useCallback(
    debounce((range: NumberRange) => {
      dispatch(
        setDomain({
          plotId: plotState.id,
          domain: { min: range.low, max: range.high },
        })
      );
    }, 200),
    [dispatch, plotState.id]
  );

  /**
   * Set domain values on data changes
   */
  useEffect(() => {
    const maxValue = getDomainMax(
      activeCuttingsPlotData,
      plotState.chartTypeToPlot
    );

    if (maxValue) {
      setDomainLimit({ low: 0, high: maxValue });
      dispatch(
        setDomain({
          plotId: plotState.id,
          domain: { min: 0, max: maxValue },
        })
      );
    } else {
      setDomainLimit({ low: 0, high: 0 });
    }
  }, [
    dispatch,
    plotState.chartTypeToPlot,
    plotState.id,
    activeCuttingsPlotData,
  ]);

  const scales: Scales = useMemo(() => {
    d3.select(`#${id}`).attr("height", height).attr("width", width);

    const graphHeight = height - PLOTTER_MARGIN.bottom - PLOTTER_MARGIN.top;
    const xRange: [number, number] = [
      PLOTTER_MARGIN.left,
      width - PLOTTER_MARGIN.right,
    ];
    const yRange: [number, number] = [
      PLOTTER_MARGIN.top + renderingConstraints.centeredScaleOuterPadding.top,
      height -
        PLOTTER_MARGIN.bottom -
        renderingConstraints.centeredScaleOuterPadding.bottom,
    ];

    const xScale = d3
      .scaleLinear()
      .range(xRange)
      .clamp(plotState.chartTypeToPlot === ChartType.STACKED_BAR);

    const yScale = d3.scalePoint().padding(0).round(false).range(yRange);

    if (activeCuttingsPlotData) {
      if (plotState.domain) {
        xScale.domain([plotState.domain.min, plotState.domain.max]);
      }

      yScale.domain(
        (activeCuttingsPlotData as PlotData[]).map((d) => d.depth.toString())
      );
    }

    const barThickness = activeCuttingsPlotData?.length
      ? getBarThickness(activeCuttingsPlotData.length, yRange)
      : 0;

    const pointRadius = Math.max(barThickness / 2, MIN_POINT_RADIUS);

    return {
      x: xScale,
      y: yScale,
      barThickness: barThickness,
      pointRadius: pointRadius,
      graphHeight: graphHeight,
      yAxisHeight: yRange[1] - yRange[0],
    };
  }, [
    id,
    height,
    width,
    renderingConstraints.centeredScaleOuterPadding.top,
    renderingConstraints.centeredScaleOuterPadding.bottom,
    plotState.chartTypeToPlot,
    plotState.domain,
    activeCuttingsPlotData,
  ]);

  /**
   * Init chart
   */
  useLayoutEffect(() => {
    const svg = d3.select(`#${id}`).attr("width", width).attr("height", height);

    const gridContainer = svg
      .append<d3.BaseType>("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${PLOTTER_MARGIN.top})`);

    const xAxisContainer = svg
      .append<d3.BaseType>("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${PLOTTER_MARGIN.top})`);

    const yAxisContainer = svg
      .append<d3.BaseType>("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(${PLOTTER_MARGIN.left}, 0)`);

    const currentDepthContainer = svg
      .append<d3.BaseType>("g")
      .attr("class", "current-depth-container");

    const highlightedDepthContainer = svg
      .append<d3.BaseType>("g")
      .attr("class", "hightlighted-depth-container");

    const dataContainer = svg
      .append<d3.BaseType>("g")
      .attr("class", "data-container");

    const formationContainer = svg
      .append<d3.BaseType>("g")
      .attr("class", "formation-container");

    const tooltip = addTooltipElement(tooltipId);

    chartElements.current = {
      svg: svg,
      xAxis: xAxisContainer,
      yAxis: yAxisContainer,
      grid: gridContainer,
      dataContainer: dataContainer,
      infobox: svg.select(".infobox"),
      currentDepth: currentDepthContainer,
      formation: formationContainer,
      highlightedDepth: highlightedDepthContainer,
    };

    return () => {
      tooltip.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Draw/update chart
   */
  useLayoutEffect(() => {
    if (analyticsPaneIsOpen && activeCuttingsPlotData && scales.graphHeight) {
      const depths = Array.from(
        new Set((activeCuttingsPlotData as PlotData[]).map((d) => d.depth))
      );

      if (
        plotState.chartTypeToPlot !== currentlyDrawnChartType.current ||
        depths.length !== currentlyDrawnNumberOfDepths.current
      ) {
        chartElements.current?.dataContainer.selectAll("*").remove();
      }

      drawAxisAndGrid(
        chartElements.current as ChartElements,
        scales,
        depths,
        width
      );

      if (plotState.chartTypeToPlot === ChartType.SCATTER) {
        drawScatterChart(
          chartElements.current as ChartElements,
          scales,
          activeCuttingsPlotData as ScatterPlotData[],
          plotState.units,
          plotState.tab,
          updateSelectedCutting,
          setHighlightedDepth,
          setActiveTooltip,
          showStratigraphyInTooltip,
          tooltipId
        );
        currentlyDrawnChartType.current = ChartType.SCATTER;
      } else if (plotState.chartTypeToPlot === ChartType.STACKED_BAR) {
        drawStackedBarChart(
          chartElements.current as ChartElements,
          scales,
          activeCuttingsPlotData as StackedBarPlotData[],
          plotState.units,
          plotState.tab,
          updateSelectedCutting,
          setHighlightedDepth,
          setActiveTooltip,
          showStratigraphyInTooltip,
          tooltipId
        );
        currentlyDrawnChartType.current = ChartType.STACKED_BAR;
      }

      currentlyDrawnNumberOfDepths.current = depths.length;
    }
  }, [
    analyticsPaneIsOpen,
    scales,
    setActiveTooltip,
    setHighlightedDepth,
    updateSelectedCutting,
    plotState.chartTypeToPlot,
    plotState.units,
    plotState.tab,
    showStratigraphyInTooltip,
    tooltipId,
    width,
    activeCuttingsPlotData,
  ]);

  /**
   * Draw line at depth of the selected cutting
   */
  useLayoutEffect(() => {
    drawCurrentDepthLine(scales, width, selectedCuttingDepth, chartElements);
  }, [selectedCuttingDepth, scales, width]);

  /**
   * Draw lines for formation tops / groups when active cuttings is a subset of cuttings
   */
  useLayoutEffect(() => {
    if (activeCuttingsPlotData && cuttings?.length !== activeCuttings?.length) {
      drawFormationTopChanges(
        chartElements.current as ChartElements,
        scales,
        width,
        activeCuttingsPlotData
      );
    } else {
      chartElements.current?.formation.selectAll("*").remove();
    }
  }, [
    activeCuttings?.length,
    activeCuttingsPlotData,
    cuttings?.length,
    scales,
    width,
  ]);

  /**
   * Draw line at the highlighted depth
   */
  useLayoutEffect(() => {
    drawHighlightedDepthLine(scales, width, highlightedDepth, chartElements);
  }, [highlightedDepth, scales, width]);

  return (
    <div
      className={getClasses("plotter-container__item", {
        "plotter-container__item--highlighted": highlighted,
        "plotter-container__item--outlined": outlined,
        "plotter-container__item--clickable": clickable,
      })}
    >
      <PlotterFrameSVG
        id={id}
        numPlots={numPlots}
        onClick={setSelected}
        className="data-plotter"
      ></PlotterFrameSVG>

      <div className="plotter-container__item__units">
        {getUnitsOfPlottedData(plotState)}
      </div>

      <IconButton
        className={"data-plotter__close-btn"}
        styles={closeButtonStyles}
        iconProps={closeIconProps}
        onClick={removePlot}
      />

      <RangeSlider
        rangeLimits={domainLimit}
        onRangeChange={domainChangeHandler}
      />
    </div>
  );
};

function getBarThickness(numberOfBars: number, yRange: [number, number]) {
  const MIN_INNER_PADDING = 0.15;

  if (yRange[1] - yRange[0] === 0) return MAX_DATA_HEIGHT;

  return Math.min(
    ((yRange[1] - yRange[0]) * (1 - MIN_INNER_PADDING)) / numberOfBars,
    MAX_DATA_HEIGHT
  );
}

function getUnitsOfPlottedData(plotState: PlotState) {
  return removeDuplicates(
    [
      ...plotState.selectedElements.elements,
      ...plotState.selectedElements.groups,
    ]
      .map((elementKey) => plotState.units[elementKey])
      .filter((unit) => unit !== "")
  ).join(" | ");
}
