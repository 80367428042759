import React, { FC } from "react";
import {
  IButtonStyles,
  FontWeights,
  IButtonProps,
  DefaultButton,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import { Breakpoint, Colors } from "../../constants";

const activeState = {
  background: Colors.primary,
  color: NeutralColors.white,
};

const buttonStyles: IButtonStyles = {
  root: {
    background: NeutralColors.gray30,
    border: "none",
    borderRadius: "0",
    [Breakpoint.S]: {
      height: "64px",
      padding: "0 16px",
    },
    [Breakpoint.M]: {
      height: "80px",
      padding: "0 20px",
    },
    [Breakpoint.L]: {
      height: "96px",
      padding: "0 28px",
    },
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
    },
  },
  label: {
    fontWeight: FontWeights.regular,
    [Breakpoint.S]: {
      fontSize: "16px",
    },
    [Breakpoint.M]: {
      fontSize: "20px",
    },
    [Breakpoint.L]: {
      fontSize: "24px",
    },
  },
  rootHovered: {
    background: NeutralColors.gray40,
  },
  rootPressed: {
    ...activeState,
  },
  rootExpanded: {
    ...activeState,
  },
  rootDisabled: {
    background: NeutralColors.gray30,
  },
  labelDisabled: {
    color: NeutralColors.gray90,
  },
  rootChecked: {
    ...activeState,
  },
  rootCheckedHovered: {
    ...activeState,
  },
};

const iconButtonStyles = {
  root: {
    padding: 0,
    [Breakpoint.S]: {
      width: "64px",
      minWidth: "64px",
      height: "64px",
      padding: "0",
    },
    [Breakpoint.M]: {
      width: "80px",
      height: "80px",
      padding: "0",
    },
    [Breakpoint.L]: {
      width: "96px",
      height: "96px",
      padding: "0",
    },
    selectors: { ".ms-Button-menuIcon": { display: "none" } },
  },
};

export const ToolbarButton: FC<
  IButtonProps & { displayAsIconButton?: boolean }
> = (props) => {
  const { styles, displayAsIconButton, ...restProps } = props;

  return (
    <DefaultButton
      styles={mergeStyleSets(
        buttonStyles,
        displayAsIconButton ? iconButtonStyles : {},
        styles
      )}
      {...restProps}
    />
  );
};
