import {
  MISSING_STRATIGRAPHY_COLOR,
  MISSING_STRATIGRAPHY_NAME,
} from "../../config";
import {
  Cutting,
  TopFormation,
  TopGroup,
  WellTopsPlotData,
} from "../../types/types";

export function getPlotData(
  topsDataForWellbore: TopGroup[],
  cuttings: Cutting[]
): WellTopsPlotData[] {
  const groupPlotData = getPlotDataForWellbore(
    "group",
    topsDataForWellbore,
    cuttings
  );

  const formationsForWellbore = topsDataForWellbore.reduce<TopFormation[]>(
    (acc, t) => {
      acc.push(...t.formations);
      return acc;
    },
    []
  );

  const formationPlotData = getPlotDataForWellbore(
    "formation",
    formationsForWellbore,
    cuttings
  );

  return [...groupPlotData, ...formationPlotData];
}

function getPlotDataForWellbore(
  type: "group" | "formation",
  topsDataForWellbore: TopGroup[] | TopFormation[],
  cuttings: Cutting[]
): WellTopsPlotData[] {
  const wellTopsPlotData: WellTopsPlotData[] = [];

  const seenBefore: (TopGroup | TopFormation)[] = [];
  const hasBeenSeenBefore = (instance: TopGroup | TopFormation) =>
    seenBefore.includes(instance);

  for (let i = 0; i < cuttings.length; i++) {
    const c = cuttings[i];
    const formationOrGroupOfCutting = topsDataForWellbore.find((t) => {
      const isLastCutting = i === cuttings.length - 1;
      if (isLastCutting) {
        return t.top <= c.depth && c.depth <= t.bottom;
      }
      return t.top <= c.depth && c.depth < t.bottom;
    });

    const lastPlotData = wellTopsPlotData[wellTopsPlotData.length - 1];

    if (!formationOrGroupOfCutting) {
      if (lastPlotData && lastPlotData.name === "Undefined") {
        lastPlotData.numberOfCuttingsInFormation += 1;
      } else {
        const undefinedFormationOrGroup = getUndefined(
          type,
          lastPlotData?.bottom ?? c.depth,
          i
        );
        undefinedFormationOrGroup.numberOfCuttingsInFormation += 1;
        wellTopsPlotData.push(undefinedFormationOrGroup);
      }
    } else {
      endPreviousIfItIsUndefined(lastPlotData, formationOrGroupOfCutting);

      if (hasBeenSeenBefore(formationOrGroupOfCutting)) {
        lastPlotData.numberOfCuttingsInFormation += 1;
      } else {
        const plotData = getPlotDataForType(type, formationOrGroupOfCutting, i);
        plotData.numberOfCuttingsInFormation += 1;

        wellTopsPlotData.push(plotData);
        seenBefore.push(formationOrGroupOfCutting);
      }
    }
  }

  endLastGroupOrFormationIfItIsUndefined(wellTopsPlotData, cuttings);

  return wellTopsPlotData;
}

function getUndefined(
  type: "formation" | "group",
  startDepth: number,
  numberOfCuttingsAbove: number
): WellTopsPlotData {
  return {
    numberOfCuttingsAbove: numberOfCuttingsAbove,
    numberOfCuttingsInFormation: 0,
    type: type,
    color: MISSING_STRATIGRAPHY_COLOR,
    name: MISSING_STRATIGRAPHY_NAME,
    top: startDepth,
    bottom: 0,
    thickness: 0,
  };
}

function getPlotDataForType(
  type: "group" | "formation",
  groupOrFormationOfCutting: TopGroup | TopFormation,
  numberOfCuttingsAbove: number
): WellTopsPlotData {
  return {
    numberOfCuttingsAbove: numberOfCuttingsAbove,
    numberOfCuttingsInFormation: 0,
    type: type,
    color: groupOrFormationOfCutting.color,
    name: groupOrFormationOfCutting.name,
    top: groupOrFormationOfCutting.top,
    bottom: groupOrFormationOfCutting.bottom,
    thickness: groupOrFormationOfCutting.thickness,
  };
}

function endPreviousIfItIsUndefined(
  lastPlotData: WellTopsPlotData,
  nextGroupOrFormation: TopGroup | TopFormation
): void {
  if (lastPlotData && lastPlotData.name === MISSING_STRATIGRAPHY_NAME) {
    lastPlotData.bottom = nextGroupOrFormation.top;
    lastPlotData.thickness = lastPlotData.bottom - lastPlotData.top;
  }
}

function endLastGroupOrFormationIfItIsUndefined(
  wellTopsPlotData: WellTopsPlotData[],
  cuttings: Cutting[]
) {
  const lastWellTopsPlotData = wellTopsPlotData[wellTopsPlotData.length - 1];
  const lastCutting = cuttings[cuttings.length - 1];

  if (lastWellTopsPlotData.name === MISSING_STRATIGRAPHY_NAME) {
    lastWellTopsPlotData.bottom = lastCutting.depth;
    lastWellTopsPlotData.thickness =
      lastWellTopsPlotData.bottom - lastWellTopsPlotData.top;
  }
}
