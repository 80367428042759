/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from "d3";
import { AnalyticsName, TooltipType } from "../../../../constants";
import { PinnableTooltipData, StackedBarPlotData } from "../../../../types";
import { getElementColor } from "../../../dataAnalyticsPane/config";
import {
  createTooltipAdder,
  DISTANCE_FROM_TOOLTIP_TO_MOUSE,
  TooltipData,
} from "../../../plot-tooltip/plot-tooltip";
import { Scales } from "../DataPlotter";
import { ChartElements, SIZE_SCALE_FACTOR } from "./common-drawing";

export function drawStackedBarChart(
  chartElements: ChartElements,
  scales: Scales,
  data: StackedBarPlotData[],
  units: Record<string, string>,
  selectedTab: AnalyticsName,
  updateSelectedCutting: (dataOrEvent: any) => void, // eslint-disable-next
  setHighlightedDepth: (depth: number | undefined) => void,
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void,
  showStratigraphyInTooltip: boolean,
  tooltipId: string
): void {
  const elements = Object.keys(data?.[0] ?? []).filter(
    (key) => key !== "depth" && key !== "stratigraphy"
  );

  const stack = d3
    .stack()
    .keys(elements)
    .value((d, key) => d[key]);

  const series = stack(data);

  const [
    addHoveredTooltip,
    removeHoveredTooltip,
    addSiblingTooltip,
    removeSiblingTooltip,
  ] = createTooltipAdder(
    tooltipId,
    createStackedBarPlotTooltipData(units),
    setActiveTooltip,
    TooltipType.ANALYTICS_SELECTION,
    showStratigraphyInTooltip
  );

  const increaseSizeOfBar = (e: any, d: any) => {
    d3.select(e.target)
      .attr("height", scales.barThickness * SIZE_SCALE_FACTOR)
      .attr("y", () => {
        const newBarHeight = scales.barThickness * SIZE_SCALE_FACTOR;
        const centerY = scales.y(d.data.depth + "") as number;
        return centerY - newBarHeight / 2;
      });
  };
  const resetSizeOfBar = (e: any) => {
    // @ts-ignore
    d3.select(e.target)
      .attr("height", scales.barThickness)
      .attr("y", (d) => {
        const centerY = scales.y((d as any).data.depth + "") as number;
        return centerY - scales.barThickness / 2;
      });
  };

  const addRectEventHandlers = (rect: any) => {
    rect
      .on("mousedown", (event: any) => {
        event.stopPropagation();
      })
      .on("mouseup", (event: any) => {
        event.stopPropagation();
      })
      .on("click", (event: any) => {
        event.stopPropagation();
        updateSelectedCutting(event);
      })
      .on("mouseover", (e: any, d: any) => {
        const eventWasNotProducedProgrammatically = !!e.relatedTarget;
        if (eventWasNotProducedProgrammatically) {
          addHoveredTooltip(e);
          setHighlightedDepth(d.data.depth);
          increaseSizeOfBar(e, d);
          triggerMouseOverEventsInSiblingPlots(
            d.data.depth,
            chartElements.svg.node() as Element
          );
        } else {
          addSiblingTooltip(e);
        }
      })
      .on("mouseout", (e: any, d: any) => {
        const eventWasNotProducedProgrammatically = !!e.relatedTarget;
        if (eventWasNotProducedProgrammatically) {
          removeHoveredTooltip();
          setHighlightedDepth(undefined);
          resetSizeOfBar(e);
          triggerMouseOutEventsInSiblingPlots(
            d.data.depth,
            chartElements.svg.node() as Element
          );
        } else {
          removeSiblingTooltip();
        }
      });
  };

  const addRectAttributes = (rect: any) => {
    rect
      .attr("data-id", (d: d3.SeriesPoint<{ [key: string]: number }>) => {
        return String(d.data.depth);
      })
      .attr("x", (d: d3.SeriesPoint<{ [key: string]: number }>) =>
        scales.x(d[0])
      )
      .attr("y", (d: d3.SeriesPoint<{ [key: string]: number }>) => {
        const centerY = scales.y(d.data.depth + "");
        return centerY !== undefined
          ? centerY - scales.barThickness / 2
          : undefined;
      })
      .attr(
        "width",
        (
          d: d3.SeriesPoint<{
            [key: string]: number;
          }>
        ) => scales.x(d[1]) - scales.x(d[0])
      )
      .attr("height", scales.barThickness)
      .style("opacity", (d: d3.SeriesPoint<{ [key: string]: number }>) =>
        d[1] > scales.x.domain()[1] ? 0.1 : 1
      );
  };

  chartElements.dataContainer
    .selectAll(".bar-container")
    .data(series, (s) => (s as { [key: string]: string }).key)
    .join(
      (enter) =>
        enter
          .append("g")
          .attr("class", "bar-container")
          .attr("fill", (d) => getElementColor(d.key, selectedTab))
          .attr("opacity", 0)
          .call((_enter) => _enter.transition().attr("opacity", 1)),
      (update) =>
        update.call((_update) =>
          _update
            .transition()
            .attr("fill", (d) => getElementColor(d.key, selectedTab))
            .attr("opacity", 1)
        ),
      (exit) =>
        exit.call((_exit) => _exit.transition().attr("opacity", 0).remove())
    )
    .selectAll("rect")
    .data((d) => d)
    .join(
      (enter) =>
        // @ts-ignore
        enter
          .append("rect")
          .call(addRectEventHandlers)
          .call(addRectAttributes)
          .attr("opacity", 0)
          .call((_enter) => _enter.transition().attr("opacity", 1)),
      (update) =>
        update.call((_update) =>
          // @ts-ignore
          _update
            .call(addRectEventHandlers)
            .transition()
            .call(addRectAttributes)
            .attr("opacity", 1)
        ),
      (exit) =>
        exit.call((_exit) => _exit.transition().attr("opacity", 0).remove())
    );
}

const createStackedBarPlotTooltipData = (
  units: Record<string, string>
) => (plotData: { data: StackedBarPlotData }): TooltipData => {
  const data = plotData.data;

  if (!data) {
    return { heading: "", rows: [] };
  }

  return {
    stratigraphy: data.stratigraphy,
    heading: `Depth: ${data.depth} m`,
    rows: Object.keys(data)
      .filter((k) => k !== "depth" && k !== "stratigraphy")
      .map((elementKey) => ({
        label: elementKey,
        value: `${data[elementKey]} ${
          units[elementKey] ? units[elementKey] : ""
        }`,
      })),
  };
};

/**
 * Trigger mouse over event in other plots in order to render sibling tooltips
 */
function triggerMouseOverEventsInSiblingPlots(
  depth: number,
  hoveredPlot: Element
) {
  const notHoveredPlots = Array.from(
    document.getElementsByClassName("data-plotter")
  ).filter((dataPlotter) => !dataPlotter.isSameNode(hoveredPlot));

  for (const notHoveredPlot of notHoveredPlots) {
    const elementsForDepth = notHoveredPlot.querySelectorAll(
      `[data-id="${depth}"]`
    );

    if (elementsForDepth.length > 0) {
      const lastElement = elementsForDepth[elementsForDepth.length - 1];
      const domRect = lastElement.getBoundingClientRect();
      lastElement.dispatchEvent(
        new MouseEvent("mouseover", {
          clientX: domRect.x + domRect.width / 2,
          clientY:
            domRect.y + domRect.height / 2 + DISTANCE_FROM_TOOLTIP_TO_MOUSE,
        })
      );
    }
  }
}

/**
 * Trigger mouse out event in other plots in order to remove sibling tooltips
 */
function triggerMouseOutEventsInSiblingPlots(
  depth: number,
  hoveredPlot: Element
) {
  const notHoveredPlots = Array.from(
    document.getElementsByClassName("data-plotter")
  ).filter((dataPlotter) => !dataPlotter.isSameNode(hoveredPlot));

  for (const notHoveredPlot of notHoveredPlots) {
    const elementsForDepth = notHoveredPlot.querySelectorAll(
      `[data-id="${depth}"]`
    );
    if (elementsForDepth.length > 0) {
      const lastElement = elementsForDepth[elementsForDepth.length - 1];
      lastElement.dispatchEvent(new MouseEvent("mouseout"));
    }
  }
}
