import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectActiveWellbore } from "../../store";

interface BackdropImageProps {
  backdropType: string;
}

export const BackdropImage: FC<BackdropImageProps> = ({
  backdropType,
  children,
}) => {
  const activeWellbore = useSelector(selectActiveWellbore);

  const backdrop = activeWellbore?.backdrops?.find(
    (b) => b.type === backdropType
  );

  if (backdrop) {
    return (
      <div className="range-finder__backdrop">
        <img src={backdrop.reference} alt="Backdrop"></img>
        {children}
      </div>
    );
  }
  return null;
};
