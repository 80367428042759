import React, { useState } from "react";
import {
  ContextualMenu,
  IButtonStyles,
  IconButton,
  IDragOptions,
  Modal,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import QemScanColorLegendImage from "../../assets/qemscan-color-legend.png";

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

export const QemScanColorLegend: React.FC = () => {
  const [minimized, setMinized] = useState(false);

  const containerClassName = `color-legend-container ${
    minimized ? "minimized" : "expanded"
  }`;

  return (
    <Modal
      isOpen={true}
      isModeless={true}
      containerClassName={containerClassName}
      dragOptions={dragOptions}
    >
      <div className="color-legend-container__header">
        <span>Color legend</span>
        <IconButton
          ariaLabel="Minimize color legend"
          styles={iconButtonStyles}
          iconProps={{ iconName: minimized ? "ChevronDown" : "ChevronUp" }}
          onClick={() => setMinized(!minimized)}
        />
      </div>
      <img
        className="color-legend-container__image"
        src={QemScanColorLegendImage}
        alt="Qemscan color legend"
        draggable={false}
      ></img>
    </Modal>
  );
};

const iconButtonStyles: IButtonStyles = {
  root: {
    color: "black",
    marginLeft: "auto",
    marginTop: "2px",
    marginRight: "2px",
    height: "30px",
    width: "30px",
    selectors: {
      "& > i": {
        fontSize: "13px",
      },
    },
  },
  rootHovered: {
    color: "black",
    background: NeutralColors.gray40,
  },
};
