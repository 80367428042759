import {
  ContextualMenuItemType,
  DirectionalHint,
  IContextualMenuItem,
  Layer,
} from "@fluentui/react";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import { selectSelectedCutting } from "../../store";
import { ImageReference } from "../../types/types";
import { stringAscendingSort } from "../../utils/sorting";
import {
  ContextualMenu,
  ContextualMenuSelectionMode,
  ToolbarButton,
} from "../styledFluentComponents";

interface DropdownListProps {
  dropdownName: DropdownName;
  compareModeActive: boolean;
  setSelectedImageType: (type: string) => void;
  selectedImageType: string | undefined;
  disabled?: boolean;
}

export const ImageTypeDropdown: FC<DropdownListProps> = ({
  dropdownName,
  selectedImageType,
  setSelectedImageType,
  compareModeActive,
  disabled,
}) => {
  const selectedCutting = useSelector(selectSelectedCutting);
  const { isOpen, toggleDropdown, closeDropdown } = useDropdowns();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const tileSource = selectedCutting?.imageReferences.find(
    (r) => r.type === selectedImageType
  );
  const items = useMemo<IContextualMenuItem[]>(
    () => [
      {
        key: "imageSelection",
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          topDivider: true,
          bottomDivider: false,
          title: "Image data",

          items: selectedCutting
            ? selectedCutting.imageReferences
                .slice()
                .sort((a, b) => stringAscendingSort(a.name, b.name))
                .map((r) => ({
                  key: r.type,
                  text: r.name,
                  secondaryText: getFormattedDate(r),
                  checked: r.type === selectedImageType,
                  disabled: compareModeActive && !r.comparable,
                }))
            : [],
        },
      },
    ],
    [selectedCutting, selectedImageType, compareModeActive]
  );

  const onItemClick = useCallback(
    (e, item) => {
      setSelectedImageType(item?.key);
      closeDropdown(dropdownName);
    },
    [setSelectedImageType, closeDropdown, dropdownName]
  );

  return (
    <>
      <ToolbarButton
        ariaLabel="Select image type"
        displayAsIconButton={true}
        elementRef={buttonRef}
        toggle={true}
        checked={isOpen[dropdownName]}
        onClick={() => toggleDropdown(dropdownName)}
        disabled={disabled}
      >
        {tileSource?.shortname ?? "-"}
      </ToolbarButton>
      {isOpen[dropdownName] && (
        <Layer>
          <ContextualMenu
            target={buttonRef.current}
            items={items}
            onItemClick={onItemClick}
            shouldFocusOnMount={true}
            shouldFocusOnContainer={true}
            directionalHint={DirectionalHint.bottomLeftEdge}
            selectionMode={ContextualMenuSelectionMode.SINGLE}
          />
        </Layer>
      )}
    </>
  );
};

function getFormattedDate(r: ImageReference) {
  const imageDate = r.originalMetadata?.date?.toDate();
  if (!imageDate) {
    return "";
  }
  const formattedMonth = ("0" + (imageDate.getMonth() + 1)).slice(-2);
  return `${formattedMonth}-${imageDate.getFullYear()}`;
}
