import React, { FC } from "react";

interface ItemsAndGroupsFilteredMessageProps {
  allItemsAndGroupsFiltered: boolean;
}

const ItemsAndGroupsFilteredMessage: FC<ItemsAndGroupsFilteredMessageProps> = ({
  allItemsAndGroupsFiltered,
}) => {
  if (allItemsAndGroupsFiltered) {
    return (
      <>
        <h2 className="analytics-pane__filtered-message-header analytics-pane--horizontal-margin">
          No matches were found
        </h2>
        <div className="analytics-pane__filtered-message analytics-pane--horizontal-margin-2">
          Please make sure the spelling is correct.
        </div>
      </>
    );
  }
  return null;
};

export default ItemsAndGroupsFilteredMessage;
