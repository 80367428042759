import { DirectionalHint, Layer, Stack } from "@fluentui/react";
import React, { FC, useEffect, useRef } from "react";
import { DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import { StateSetter } from "../../types";
import {
  ContextualMenu,
  ContextualMenuSelectionMode,
  getContextualMenuItems,
  SidebarButton,
} from "../styledFluentComponents";
import { ViewerController } from "./ViewerController";

interface ViewerToolsMenuProps {
  viewerController: ViewerController;
  rulerToolActive: boolean;
  setRulerToolActive: StateSetter<boolean>;
}

export const ViewerToolsMenu: FC<ViewerToolsMenuProps> = ({
  viewerController,
  rulerToolActive,
  setRulerToolActive,
}) => {
  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === "r") {
        setRulerToolActive(true);
      }
    };

    window.addEventListener("keydown", keydownHandler);

    return () => window.removeEventListener("keydown", keydownHandler);
  }, [setRulerToolActive]);

  useEffect(() => {
    if (rulerToolActive) {
      const keydownHandler = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          setRulerToolActive(false);
        }
      };

      viewerController.enableMeasurementTool();

      window.addEventListener("keydown", keydownHandler);
      return () => window.removeEventListener("keydown", keydownHandler);
    } else {
      viewerController.disableMeasurementTool();
    }
  }, [rulerToolActive, viewerController, setRulerToolActive]);

  return (
    <Stack className="viewer-tools-menu">
      <ToolSelector
        rulerToolActive={rulerToolActive}
        setRulerToolActive={setRulerToolActive}
      />
    </Stack>
  );
};

const ToolSelector: FC<{
  rulerToolActive: boolean;
  setRulerToolActive: StateSetter<boolean>;
}> = ({ rulerToolActive, setRulerToolActive }) => {
  const { isOpen, toggleDropdown, closeDropdown } = useDropdowns();
  const buttonRef = useRef(null);

  const items = getContextualMenuItems("Tools", [
    {
      key: "ruler-tool",
      text: "Ruler tool (R)",
      checked: rulerToolActive,
      iconProps: { iconName: "Design" },
    },
  ]);

  const onItemClick = () => {
    setRulerToolActive((state) => !state);
    closeDropdown(DropdownName.VIEWER_TOOLS);
  };

  return (
    <>
      <SidebarButton
        ariaLabel="Select tool"
        elementRef={buttonRef}
        onClick={() => toggleDropdown(DropdownName.VIEWER_TOOLS)}
        checked={isOpen[DropdownName.VIEWER_TOOLS]}
        iconProps={{ iconName: "DeveloperTools" }}
        rightAligned
      />

      {isOpen[DropdownName.VIEWER_TOOLS] && (
        <Layer>
          <ContextualMenu
            target={buttonRef.current}
            onItemClick={onItemClick}
            directionalHint={DirectionalHint.leftTopEdge}
            items={items}
            shouldFocusOnMount={true}
            shouldFocusOnContainer={true}
            selectionMode={ContextualMenuSelectionMode.SINGLE}
          />
        </Layer>
      )}
    </>
  );
};
