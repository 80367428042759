import { IContextualMenuItem } from "@fluentui/react";
import React, { FC, useMemo } from "react";
import { DropdownName } from "../../constants";
import { stringAscendingSort } from "../../utils/sorting";
import {
  ContextualMenuSelectionMode,
  getContextualMenuItems,
} from "../styledFluentComponents";
import {
  WellDataSymbol,
  WELL_DATA_SYMBOLS,
} from "../wellDataSymbols/WellDataSymbols";
import { SidebarDropdown } from "./SidebarDropdown";

interface WellDataSymbolSelectorProps {
  selectedWellDataSymbols: (string | undefined)[];
  setSelectedWellDataSymbols: React.Dispatch<
    React.SetStateAction<WellDataSymbol[]>
  >;
}

export const WellDataSymbolSelector: FC<WellDataSymbolSelectorProps> = ({
  selectedWellDataSymbols,
  setSelectedWellDataSymbols,
}) => {
  const onItemClick = (e: unknown, item: IContextualMenuItem | undefined) => {
    setSelectedWellDataSymbols((state) => {
      const wellDataSymbol = item?.data;
      if (wellDataSymbol) {
        if (state.includes(wellDataSymbol)) {
          return state.filter((item) => item !== wellDataSymbol);
        }
        return [...state, wellDataSymbol];
      }
      return state;
    });
  };

  const items: IContextualMenuItem[] = useMemo(
    () =>
      getContextualMenuItems(
        "Well data symbols",
        WELL_DATA_SYMBOLS.sort(stringAscendingSort).map((wellDataSymbol) => ({
          key: wellDataSymbol,
          text: wellDataSymbol,
          canCheck: true,
          checked: selectedWellDataSymbols.includes(wellDataSymbol),
          disabled: false,
          data: wellDataSymbol,
        }))
      ),
    [selectedWellDataSymbols]
  );

  return (
    <SidebarDropdown
      onItemClick={onItemClick}
      items={items}
      dropDownName={DropdownName.WELL_DATA_SYMBOLS}
      buttonAriaLabel="Well data symbols"
      buttonLabel="W"
      selectionMode={ContextualMenuSelectionMode.MULTIPLE}
    />
  );
};
