import {
  ContextualMenu,
  ContextualMenuItemType,
  DirectionalHint,
  IContextualMenuStyles,
  IIconProps,
  Layer,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Colors, DEFAULT_ADJUSTMENTS, DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import { selectActiveWellbore } from "../../store";
import { Optional } from "../../types";
import { ImageAdjustments } from "../../types/types";
import { DefaultButton, Slider, Toggle } from "../styledFluentComponents";
import { ToolbarIconButton } from "../styledFluentComponents/ToolbarIconButton";

interface ImageAdjustButtonProps {
  dropdownName: DropdownName;
  disabled?: boolean;
  updateAdjustments: Optional<(adjustments: Partial<ImageAdjustments>) => void>;
  selectedImageType?: string;
}

const iconProps: IIconProps = { iconName: "Equalizer" };
const sliderStyles = {
  root: {
    margin: "8px 0",
  },
};
const toggleStyles = {
  root: { marginTop: "8px", marginRight: "8px" },
};

const contextualMenuStyles: Partial<IContextualMenuStyles> = {
  root: {
    background: NeutralColors.gray30,
  },
  header: {
    color: Colors.primary,
  },
};

const sliderAriaValueText = (value: number) => `${value} percent`;
const sliderValuePercentageFormat = (value: number) => `${value}%`;

export const ImageAdjustPanel: FC<ImageAdjustButtonProps> = ({
  dropdownName,
  disabled,
  updateAdjustments,
  selectedImageType,
}) => {
  const activeWellbore = useSelector(selectActiveWellbore);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isOpen, toggleDropdown } = useDropdowns();

  const [brightness, setBrightness] = useState(DEFAULT_ADJUSTMENTS.brightness);
  const [saturation, setSaturation] = useState(DEFAULT_ADJUSTMENTS.saturation);
  const [contrast, setContrast] = useState(DEFAULT_ADJUSTMENTS.contrast);
  const [exposure, setExposure] = useState(DEFAULT_ADJUSTMENTS.exposure);
  const [invert, setInvert] = useState(DEFAULT_ADJUSTMENTS.invert);

  const resetStatesToDefault = () => {
    setBrightness(DEFAULT_ADJUSTMENTS.brightness);
    setContrast(DEFAULT_ADJUSTMENTS.saturation);
    setSaturation(DEFAULT_ADJUSTMENTS.contrast);
    setExposure(DEFAULT_ADJUSTMENTS.exposure);
    setInvert(DEFAULT_ADJUSTMENTS.invert);
  };

  useEffect(() => {
    resetStatesToDefault();
    updateAdjustments?.(DEFAULT_ADJUSTMENTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImageType, activeWellbore]);

  const staticItems = [
    {
      key: "image-adjustments",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        topDivider: true,
        bottomDivider: false,
        title: "Adjustments",
        items: [
          {
            key: "content",
            onRender: () => (
              <div className="image-adjustments-container">
                <Slider
                  styles={sliderStyles}
                  label="Brightness"
                  min={-100}
                  max={100}
                  step={1}
                  ariaValueText={sliderAriaValueText}
                  valueFormat={sliderValuePercentageFormat}
                  value={brightness}
                  onChange={(value) => {
                    setBrightness(value);
                    updateAdjustments?.({ brightness: value });
                  }}
                />
                <Slider
                  styles={sliderStyles}
                  label="Saturation"
                  min={-100}
                  max={100}
                  step={1}
                  ariaValueText={sliderAriaValueText}
                  valueFormat={sliderValuePercentageFormat}
                  value={saturation}
                  onChange={(value) => {
                    setSaturation(value);
                    updateAdjustments?.({ saturation: value });
                  }}
                />
                <Slider
                  styles={sliderStyles}
                  label="Contrast"
                  min={-100}
                  max={100}
                  step={1}
                  ariaValueText={sliderAriaValueText}
                  valueFormat={sliderValuePercentageFormat}
                  value={contrast}
                  onChange={(value) => {
                    setContrast(value);
                    updateAdjustments?.({ contrast: value });
                  }}
                />
                <Slider
                  styles={sliderStyles}
                  label="Exposure"
                  min={-100}
                  max={100}
                  step={1}
                  ariaValueText={sliderAriaValueText}
                  valueFormat={sliderValuePercentageFormat}
                  value={exposure}
                  onChange={(value) => {
                    setExposure(value);
                    updateAdjustments?.({ exposure: value });
                  }}
                />
                <Toggle
                  styles={toggleStyles}
                  label="Invert"
                  inlineLabel
                  checked={invert}
                  onChange={() => {
                    setInvert(!invert);
                    updateAdjustments?.({ invert: !invert });
                  }}
                />
                <DefaultButton
                  onClick={() => {
                    resetStatesToDefault();
                    updateAdjustments?.(DEFAULT_ADJUSTMENTS);
                  }}
                  grayScale
                  styles={{ root: { marginTop: "12px", marginBottom: "8px" } }}
                >
                  Reset
                </DefaultButton>
              </div>
            ),
          },
        ],
      },
    },
  ];

  return (
    <>
      <ToolbarIconButton
        ariaLabel="Toggle image adjust panel"
        elementRef={buttonRef}
        toggle={true}
        checked={isOpen[dropdownName]}
        onClick={() => toggleDropdown(dropdownName)}
        disabled={disabled}
        iconProps={iconProps}
      />
      {isOpen[dropdownName] && (
        <Layer>
          <ContextualMenu
            target={buttonRef.current}
            directionalHint={DirectionalHint.bottomLeftEdge}
            styles={contextualMenuStyles}
            items={staticItems}
            shouldFocusOnMount={true}
            shouldFocusOnContainer={true}
          />
        </Layer>
      )}
    </>
  );
};
