import {
  ContextualMenu,
  IButtonStyles,
  IDragOptions,
  IIconProps,
  IModalStyles,
  Modal,
  NeutralColors,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectAnalyticsPaneIsOpen, selectPlots } from "../../../store";
import { analyticsNames } from "../../../texts";
import { stringDecendingSort } from "../../../utils";
import { IconButton } from "../../styledFluentComponents";
import { getElementColor, UNITS } from "../config";

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

export const PlotLegendButton: FC = () => {
  const [
    isPlotLegendOpen,
    { setFalse: hidePlotLegend, toggle: togglePlotLegend },
  ] = useBoolean(false);

  const titleId = useId("title");

  const plots = useSelector(selectPlots);
  const analyticsPaneIsOpen = useSelector(selectAnalyticsPaneIsOpen);

  return (
    <>
      <IconButton
        onClick={togglePlotLegend}
        ariaLabel="Toggle display of plot legend"
        iconProps={{ iconName: "PageList" }}
        toolTipText="Plot legend"
        checked={isPlotLegendOpen}
      />
      <Modal
        titleAriaId={titleId}
        isOpen={isPlotLegendOpen && analyticsPaneIsOpen}
        onDismiss={hidePlotLegend}
        className="plot-legend"
        styles={modalStyles}
        isModeless={true}
        dragOptions={dragOptions}
      >
        <div className="plot-legend__title">
          <span id={titleId}>Plot legend</span>
          <IconButton
            iconProps={cancelIcon}
            ariaLabel="Close plot legend"
            onClick={hidePlotLegend}
            styles={buttonStyles}
          />
        </div>
        <ul className="plot-legend__plot-container">
          {plots.map((plot, i) => {
            const elements = [
              ...plot.selectedElements.groups,
              ...plot.selectedElements.elements,
            ].sort(stringDecendingSort);
            const units = UNITS[plot.tab];

            return (
              <li className="plot-legend__plot" key={plot.id}>
                <div className="plot-legend__plot__title">
                  Plot panel {i + 1}
                </div>
                <div className="plot-legend__plot__tab">
                  {analyticsNames[plot.tab]}
                </div>
                <ul className="plot-legend-element-list">
                  {elements.map((element) => (
                    <li
                      className="plot-legend-element-list__item"
                      key={element}
                    >
                      <span
                        className="plot-legend-element-list__item__color"
                        style={{
                          background: getElementColor(element, plot.tab),
                        }}
                      ></span>
                      <span className="plot-legend-element-list__item__element">
                        {element} ({units[element]})
                      </span>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </Modal>
    </>
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    background: "inherit",
  },
};
const modalStyles: Partial<IModalStyles> = {
  main: {
    background: NeutralColors.gray10,
  },
};
