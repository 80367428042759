import type { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { AnalyticsName } from "../../../constants";
import {
  AnalyticsDataKey,
  Optional,
  ProcessedTops,
  StructuredCategory,
  StructuredData,
  StructuredElement,
  Wellbore,
} from "../../../types";
import { getGroupAndFormation } from "../../../utils";

export async function exportToExcel(
  wellbore: Wellbore,
  analyticsData: Record<AnalyticsDataKey, Optional<StructuredData>>,
  wellTopsData: ProcessedTops,
  tab: AnalyticsName | undefined
): Promise<void> {
  const {
    xrfSampleInformation: sampleInformation,
    xrf: xrfData,
    speccam: speccamData,
    xrd: xrdData,
    toc: tocData,
    qemscan: qemscanData,
  } = analyticsData;

  const { Workbook } = await import("exceljs");
  const workbook = new Workbook();

  function addWS(name: string, data: StructuredData) {
    addWorksheet(workbook, name, data, wellTopsData, wellbore);
  }

  // Add selected wellbore first
  if (tab === AnalyticsName.XRF && xrfData) {
    addWS("XRF", xrfData);
  } else if (tab === AnalyticsName.XRD && xrdData) {
    addWS("XRD", xrdData);
  } else if (tab === AnalyticsName.TOC && tocData) {
    addWS("TOC", tocData);
  } else if (tab === AnalyticsName.SPEC_CAM && speccamData) {
    addWS("SpecCam", speccamData);
  } else if (tab === AnalyticsName.QEM_SCAN && qemscanData) {
    addWS("QemScan Bulk", qemscanData);
  }

  // Add the rest of the wellbores in regular order
  if (sampleInformation) {
    addWS("Sample Information", sampleInformation);
  }
  if (xrfData && tab !== AnalyticsName.XRF) {
    addWS("XRF", xrfData);
  }
  if (speccamData && tab !== AnalyticsName.SPEC_CAM) {
    addWS("SpecCam", speccamData);
  }
  if (xrdData && tab !== AnalyticsName.XRD) {
    addWS("XRD", xrdData);
  }
  if (tocData && tab !== AnalyticsName.TOC) {
    addWS("TOC", tocData);
  }
  if (qemscanData && tab !== AnalyticsName.QEM_SCAN) {
    addWS("QemScan Bulk", qemscanData);
  }

  saveWorkbook(workbook, `analytics-data-for-wellbore_${wellbore.name}.xlsx`);
}

function addWorksheet(
  workbook: Workbook,
  name: string,
  data: StructuredData,
  wellTopsData: ProcessedTops,
  wellbore: Wellbore
) {
  const worksheet = workbook.addWorksheet(name);

  const firstStructuredCategories = data.values().next().value;
  const structuredElements = getFlattendElementsWithUnits(
    firstStructuredCategories
  );

  const headers = [
    "Wellbore Name",
    "Group (NPD)",
    "Formation (NPD)",
    "Depth (m)",
    ...structuredElements.map((element) => {
      const unit = element.unit ? `(${element.unit})` : "";
      return `${element.key} ${unit}`;
    }),
  ];

  const header = worksheet.addRow(headers);
  header.font = { bold: true };

  data.forEach((structuredCategories, depth) => {
    const structuredElements = getFlattendElementsWithUnits(
      structuredCategories
    );

    const {
      group: { name: groupName },
      formation: { name: formationName },
    } = getGroupAndFormation(wellTopsData[wellbore.name], depth);

    worksheet.addRow([
      wellbore.name,
      groupName,
      formationName,
      depth,
      ...structuredElements.map((e) => e.rawValue),
    ]);
  });

  return workbook;
}

async function saveWorkbook(workbook: Workbook, fileName: string) {
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), fileName);
}

function getFlattendElementsWithUnits(
  structuredCategories: StructuredCategory[]
): StructuredElement[] {
  const result: StructuredElement[] = [];

  structuredCategories.forEach((structuredCategory) => {
    const categoryElements = structuredCategory.elements.reduce<
      StructuredElement[]
    >((acc, element) => {
      if (!element.skipInExcel) {
        acc.push(updateCategory(element, structuredCategory.unit));
      }
      return acc;
    }, []);
    result.push(...categoryElements);

    structuredCategory.groups.forEach((group) => {
      const groupElements = group.elements.reduce<StructuredElement[]>(
        (acc, element) => {
          if (!element.skipInExcel) {
            acc.push(
              updateCategory(element, group.unit || structuredCategory.unit)
            );
          }
          return acc;
        },
        []
      );
      result.push(...groupElements);
      if (!group.skipInExcel) {
        const groupAsElement: StructuredElement = {
          key: group.key,
          value: "",
          rawValue: group.rawValue,
          unit: group.unit,
          nested: false,
          hide: false,
          skipInExcel: group.skipInExcel,
        };
        result.push(groupAsElement);
      }
    });
  });

  return result;
}

function updateCategory(e: StructuredElement, parentUnit: string | undefined) {
  const clonedElement = {
    ...e,
    unit: e.unit || parentUnit,
  };
  return clonedElement;
}
