import { IButtonProps, IButtonStyles } from "@fluentui/react";
import React, { FC } from "react";
import { Breakpoint } from "../../constants";

import { ToolbarButton } from "../styledFluentComponents/ToolbarButton";

interface CompareToggleProps {
  openCompareMode: () => void;
}

const compareToggleStyles: IButtonStyles = {
  root: {
    padding: 0,
    [Breakpoint.S]: {
      width: `${64 * 2}px`,
      minWidth: `${64 * 2}px`,
      padding: "0",
    },
    [Breakpoint.M]: {
      width: `${80 * 2}px`,
      padding: "0",
    },
    [Breakpoint.L]: {
      width: `${96 * 2}px`,
      padding: "0",
    },
  },
};

export const CompareToggle: FC<IButtonProps & CompareToggleProps> = ({
  openCompareMode,
  ...buttonProps
}) => {
  return (
    <ToolbarButton
      {...buttonProps}
      styles={compareToggleStyles}
      onClick={openCompareMode}
    >
      Compare
    </ToolbarButton>
  );
};
