import { AnalyticsName, WellTopsName } from "../constants";
import {
  AnalyticsDataKey,
  Cutting,
  Optional,
  PlotState,
  ProcessedTops,
  StoredSelection,
  StructuredData,
  Wellbore,
  ZoomArea,
} from "../types";
import { CuttingsSliceState } from "./appSlice";
import { StoreState } from "./store";

/**
 * Analytics Slice
 */
export const selectPlots = (state: StoreState): PlotState[] =>
  state.analytics.plots;

export const selectSelectedPlot = (state: StoreState): Optional<PlotState> =>
  state.analytics.plots.find((s) => s.id === state.analytics.selectedPlot);

export const selectSelectedTab = (state: StoreState): Optional<AnalyticsName> =>
  state.analytics.selectedTab;

export const selectAnalyticsPaneIsOpen = (state: StoreState): boolean =>
  Boolean(state.analytics.selectedTab);

export const selectOverlayAnalytics = (state: StoreState): boolean =>
  state.analytics.overlayAnalytics;

export const selectAllAnalyticsData = (
  state: StoreState
): Record<AnalyticsDataKey, Optional<StructuredData>> => ({
  xrfSampleInformation: state.analytics.srcData.xrfSampleInformation,
  qemscanSampleInformation: state.analytics.srcData.qemscanSampleInformation,
  xrf: state.analytics.srcData.xrf,
  speccam: state.analytics.srcData.speccam,
  xrd: state.analytics.srcData.xrd,
  toc: state.analytics.srcData.toc,
  qemscan: state.analytics.srcData.qemscan,
});

export const createSelectSelectedElements = (tab: AnalyticsName) => (
  state: StoreState
): StoredSelection => state.analytics.tabSelections[tab];

/**
 * App slice
 */
export const selectWellbores = (state: StoreState): Wellbore[] =>
  state.app.wellbores;

export const selectCuttingsState = (state: StoreState): CuttingsSliceState =>
  state.app;

export const selectSelectedWellbore = (state: StoreState): Optional<Wellbore> =>
  state.app.selectedWellbore;

export const selectActiveWellbore = (state: StoreState): Optional<Wellbore> =>
  state.app.activeWellbore;

export const selectSelectedCutting = (state: StoreState): Optional<Cutting> =>
  state.app.selectedCutting;

export const selectSelectedImageType = (state: StoreState): Optional<string> =>
  state.app.selectedImageType;

export const selectSelectedImageType2 = (state: StoreState): Optional<string> =>
  state.app.selectedImageType2;

export const selectCuttings = (state: StoreState): Optional<Cutting[]> =>
  state.app.cuttings;

export const selectActiveCuttings = (state: StoreState): Optional<Cutting[]> =>
  state.app.activeCuttings;

export const selectCuttingsDepths = (state: StoreState): number[] =>
  state.app.depths;

export const selectCuttingsDepthIndexes = (
  state: StoreState
): { [depth: number]: number } => state.app.depthIndexes;

export const selectSelectedCuttingIndex = (
  state: StoreState
): Optional<number> =>
  state.app.selectedCutting?.depth
    ? state.app.depthIndexes[state.app.selectedCutting.depth]
    : undefined;

export const selectSelectedCuttingDepth = (
  state: StoreState
): Optional<number> => state.app.selectedCutting?.depth;

export const selectSyncSelectedCutting = (state: StoreState): boolean =>
  state.app.syncSelectedCutting;

export const selectWellTopsData = (
  state: StoreState
): Optional<ProcessedTops> => state.app.wellTopsData;

export const selectSelectedWellTops = (
  state: StoreState
): Optional<WellTopsName> => state.app.selectedWellTops;

export const selectShowWellTops = (state: StoreState): boolean =>
  Boolean(state.app.selectedWellTops);

export const selectSelectedZoomArea = (state: StoreState): Optional<ZoomArea> =>
  state.app.selectedZoomArea;

export const selectActiveZoomArea = (state: StoreState): Optional<ZoomArea> =>
  state.app.activeZoomArea;

export const selectShowZoomAreaTrack = (state: StoreState): boolean =>
  state.app.selectedZoomArea !== undefined &&
  state.app.cuttings !== undefined &&
  !isDefaultZoomArea(state.app.selectedZoomArea, state.app.cuttings.length);

/**
 * Helpers
 */

export function isDefaultZoomArea(
  zoomArea: Optional<ZoomArea>,
  numberOfCuttings: number
): boolean {
  if (!zoomArea) {
    return false;
  }
  return zoomArea.topIdx === 0 && zoomArea.bottomIdx === numberOfCuttings - 1;
}
