import * as d3 from "d3";
import React, { FC, useEffect, useMemo } from "react";
import { TooltipType } from "../../constants";
import { usePinnedTooltips } from "../../context";
import {
  Domain,
  PinnableTooltipData,
  WellDataSymbolsPlotData,
} from "../../types/types";
import {
  addTooltipElement,
  createTooltipAdder,
} from "../plot-tooltip/plot-tooltip";
import { TrackContentProps } from "../track";

const HEIGHT_OF_CASING_SHOE = 17.5;

interface Scales {
  x: d3.ScaleBand<string>;
  y: d3.ScaleLinear<number, number, never>;
}

interface WellDataSymbolsChartProps extends TrackContentProps {
  xDomain: string[];
  yDomain: Domain;
  data: WellDataSymbolsPlotData[] | undefined;
}

export const WellDataSymbolsChart: FC<WellDataSymbolsChartProps> = ({
  size,
  renderingConstraints,
  xDomain,
  yDomain,
  data,
}) => {
  const { setActiveTooltip } = usePinnedTooltips();

  const scales: Scales = useMemo(
    () => ({
      x: d3.scaleBand().range([0, size.width]).domain(xDomain).padding(0),
      y: d3
        .scaleLinear()
        .range([
          renderingConstraints.imageCenteredTrackMargin.top,
          size.height - renderingConstraints.imageCenteredTrackMargin.bottom,
        ])
        .domain([yDomain.min, yDomain.max]),
    }),
    [renderingConstraints.imageCenteredTrackMargin, size, xDomain, yDomain]
  );

  /**
   * Initialize the plot
   */
  useEffect(() => {
    const svg = d3.select("#wellbore-symbols__chart");

    svg.append("g").attr("id", "wellbore-symbols__data-container");

    const tooltip = addTooltipElement("wellbore-symbols-tooltip");

    return () => {
      svg.selectAll("*").remove();
      tooltip.remove();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      clearPlot();
      drawCasingShoes(scales, data, setActiveTooltip);
    } else {
      clearPlot();
    }
  }, [data, size, scales, setActiveTooltip]);

  return (
    <svg
      id="wellbore-symbols__chart"
      width={size.width}
      height={size.height}
    ></svg>
  );
};

function drawCasingShoes(
  scales: Scales,
  data: WellDataSymbolsPlotData[],
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void
) {
  const dataContainer = d3.select("#wellbore-symbols__data-container");

  // eslint-disable-next-line
  const pathAttributeSetter = (selection: any) => {
    selection
      .attr("d", "M1.20711 17.5H17.5V1.20711L1.20711 17.5Z")
      .attr("class", "wellbore-symbols__casing-shoe")
      .attr(
        "transform",
        (d: WellDataSymbolsPlotData) =>
          `translate(5, ${scales.y(d.cuttingIndex) - HEIGHT_OF_CASING_SHOE})`
      );
  };

  const [addTooltip, removeTooltip] = createTooltipAdder(
    "wellbore-symbols-tooltip",
    getTooltipData,
    setActiveTooltip,
    TooltipType.WELLBORE_SYMBOL
  );

  dataContainer
    .selectAll(".wellbore-symbols__casing-shoe")
    .data(data)
    .join(
      (enter) =>
        enter
          .append("path")
          .call(pathAttributeSetter)
          .on("mouseover", addTooltip)
          .on("mouseout", removeTooltip),
      (update) => update.call(pathAttributeSetter),
      (exit) => exit.remove()
    );
}

function clearPlot() {
  d3.select("#wellbore-symbols__data-container").selectAll("*").remove();
}

const getTooltipData = (plotData: WellDataSymbolsPlotData) => ({
  heading: "Casing shoe",
  rows: [
    {
      label: "Type",
      value: `${plotData.type ? plotData.type : "Unknown"}`,
    },
    {
      label: "Diameter",
      value: `${plotData.diameter ? plotData.diameter + '"' : "Unknown"}`,
    },
    {
      label: "Hole diameter",
      value: `${
        plotData.holeDiameter ? plotData.holeDiameter + '"' : "Unknown"
      }`,
    },
    { label: "Depth", value: `${plotData.depth} m` },
    { label: "Cutting depth", value: `${plotData.cuttingDepth} m` },
  ],
});
