import { useEffect, useState } from "react";
import * as topojson from "topojson";
import { WellborePosition } from "../../../types";

export default function useGeoFiles(): {
  landGeoJson: any; // eslint-disable-line
  blocksGeoJson: any; // eslint-disable-line
  quadGeoJson: any; // eslint-disable-line
  fieldsGeoJson: any; // eslint-disable-line
  allWellbores: WellborePosition[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
} {
  const [landGeoJson, setLandGeoJson] = useState<any>(); // eslint-disable-line
  const [blocksGeoJson, setBlocksGeoJson] = useState<any>(); // eslint-disable-line
  const [quadGeoJson, setQuadGeoJson] = useState<any>(); // eslint-disable-line
  const [fieldsGeoJson, setFieldGeoJson] = useState<any>(); // eslint-disable-line
  const [allWellbores, setAllWellbores] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    (async () => {
      const countriesTopoJson = await fetch(
        "/files/countries_topo.json"
      ).then((res) => res.json());
      if (countriesTopoJson) {
        setLandGeoJson(
          topojson.feature(countriesTopoJson, countriesTopoJson.objects.land)
        );
      }
    })();
    (async () => {
      const blocksTopoJson = await fetch(
        "/files/blkArea_topo.json"
      ).then((res) => res.json());
      if (blocksTopoJson) {
        setBlocksGeoJson(
          topojson.feature(blocksTopoJson, blocksTopoJson.objects.blkArea)
        );
      }
    })();
    (async () => {
      const quadTopoJson = await fetch("/files/qadArea_topo.json").then((res) =>
        res.json()
      );
      if (quadTopoJson) {
        setQuadGeoJson(
          topojson.feature(quadTopoJson, quadTopoJson.objects.qadArea)
        );
      }
    })();

    (async () => {
      const fieldsTopoJson = await fetch(
        "/files/fldArea_topo.json"
      ).then((res) => res.json());
      if (fieldsTopoJson) {
        setFieldGeoJson(
          topojson.feature(fieldsTopoJson, fieldsTopoJson.objects.fldArea)
        );
      }
    })();

    (async () => {
      const wellboreInfo = await fetch(
        "/files/wellbore-info.json"
      ).then((res) => res.json());
      if (wellboreInfo) {
        setAllWellbores(wellboreInfo);
      }
    })();
  }, []);

  return {
    landGeoJson,
    blocksGeoJson,
    quadGeoJson,
    fieldsGeoJson,
    allWellbores,
    loading,
    setLoading,
  };
}
