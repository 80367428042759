import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  IDetailsGroupRenderProps,
  SelectionMode,
  SelectionZone,
  Stack,
} from "@fluentui/react";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import {
  selectActiveWellbore,
  selectCuttings,
  selectSelectedCutting,
  StoreState,
  useAppDispatch,
} from "../../../store";
import { CSVFileJson, LasFileJson, TopGroup } from "../../../types";
import { DetailsList, IconButton, List } from "../../styledFluentComponents";
import { fetchAnalyticsData } from "../analyticsSlice";
import {
  columnsConfig,
  infoIcon,
  PDFtoolTipTexts,
  xrfConfig,
  xrfSampleInfoConfig,
} from "../config";
import {
  useItemsAndGroups,
  useSortMethod,
  useStoredSelection,
} from "../utils/hooks";
import { selections } from "../utils/selections";
import {
  getOnRenderRow,
  getStructuredDataFromCSVFile,
  getStructuredDataFromLasFile,
  onRenderBasicListCell,
  renderGroupHeader,
} from "../utils/tab-helpers";
import ItemsAndGroupsFilteredMessage from "./ItemsAndGroupsFilteredMessage";
import { SelectedItemsClearer } from "./SelectedItemsClearer";
import { TabHeader } from "./TabHeader";

const listGroupProps: IDetailsGroupRenderProps = {
  onRenderHeader: (props) => {
    return renderGroupHeader(props, xrfConfig, undefined);
  },
};
const onRenderRow = getOnRenderRow(AnalyticsName.XRF, xrfConfig);

const getKey = (item: { key: string }) => item.key;

const XRFTabImpl: FC = () => {
  const dispatch = useAppDispatch();
  const selectedCutting = useSelector(selectSelectedCutting);
  const activeWellbore = useSelector(selectActiveWellbore);
  const cuttings = useSelector(selectCuttings);

  const selectedElements = useStoredSelection(AnalyticsName.XRF);
  const sampleInformation = useSelector(
    (state: StoreState) => state.analytics.srcData.xrfSampleInformation
  );
  const data = useSelector((state: StoreState) => state.analytics.srcData.xrf);
  const [sortMethod, onSortMethodChange] = useSortMethod();

  useEffect(() => {
    if (cuttings) {
      const sampleInfoTransformer = (
        src: CSVFileJson,
        wellTops: TopGroup[]
      ) => {
        return getStructuredDataFromCSVFile(
          src,
          wellTops,
          xrfSampleInfoConfig,
          cuttings,
          false
        );
      };

      dispatch(
        fetchAnalyticsData(
          "xrfSampleInformation",
          activeWellbore?.analyticsData?.xrf?.cuttingsAnalysisReference,
          sampleInfoTransformer
        )
      );

      const dataTransformer = (src: LasFileJson, wellTops: TopGroup[]) => {
        return getStructuredDataFromLasFile(src, wellTops, xrfConfig, cuttings);
      };

      dispatch(
        fetchAnalyticsData(
          AnalyticsName.XRF,
          activeWellbore?.analyticsData?.xrf?.dataReference,
          dataTransformer
        )
      );
    }
  }, [dispatch, activeWellbore?.analyticsData?.xrf, cuttings]);

  const sampleInfoData = useItemsAndGroups(
    sampleInformation,
    selectedCutting,
    selections[AnalyticsName.XRF],
    sortMethod
  );

  const {
    items,
    groups,
    filterActive,
    setFilterActive,
    setFilterText,
    groupsCollapsed,
    toggleCollapseAllGroups,
    clearSelection,
    allItemsAndGroupsFiltered,
  } = useItemsAndGroups(
    data,
    selectedCutting,
    selections[AnalyticsName.XRF],
    sortMethod
  );

  return (
    <>
      <TabHeader
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        setFilterText={setFilterText}
        groupsCollapsed={groupsCollapsed}
        toggleCollapseAllGroups={toggleCollapseAllGroups}
        informationTooltipText={PDFtoolTipTexts.xrfData}
        informationPDFUrl={
          activeWellbore?.analyticsData?.xrf?.methodologyReference
        }
        sortMethod={sortMethod}
        onSortMethodChange={onSortMethodChange}
        tab={AnalyticsName.XRF}
        disableExpand={true}
      />

      {sampleInfoData.items && (
        <>
          <Stack
            className="analytics-pane--horizontal-margin analytics-pane__group-heading--spaced"
            horizontal
            verticalAlign={"center"}
          >
            <h3 className="analytics-pane__group-heading">
              Cuttings sample information
            </h3>
            <IconButton
              iconProps={infoIcon}
              ariaLabel="Open file"
              toolTipText={PDFtoolTipTexts.xrfSample}
              disabled={
                !activeWellbore?.analyticsData?.xrf
                  ?.cuttingsAnalysisMethodologyReference
              }
              onClick={() => {
                const url =
                  activeWellbore?.analyticsData?.xrf
                    ?.cuttingsAnalysisMethodologyReference;
                if (url) {
                  window.open(url, "_blank");
                }
              }}
            />
          </Stack>
          <List
            className={
              "analytics-pane__basic-list analytics-pane--horizontal-margin-2"
            }
            items={sampleInfoData.items}
            onRenderCell={onRenderBasicListCell}
          />
        </>
      )}

      <ItemsAndGroupsFilteredMessage
        allItemsAndGroupsFiltered={allItemsAndGroupsFiltered}
      />

      <SelectionZone
        className="analytics-pane__selection-zone"
        selection={selections[AnalyticsName.XRF]}
        selectionMode={SelectionMode.multiple}
        selectionPreservedOnEmptyClick={true}
      >
        <DetailsList
          columns={columnsConfig}
          items={items}
          groups={groups}
          onRenderRow={onRenderRow}
          groupProps={listGroupProps}
          compact={true}
          isHeaderVisible={false}
          checkboxVisibility={CheckboxVisibility.always}
          setKey="set"
          getKey={getKey}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selections[AnalyticsName.XRF]}
          selectionMode={SelectionMode.multiple}
          selectionPreservedOnEmptyClick={true}
        />
      </SelectionZone>
      <SelectedItemsClearer
        numberOfSelectedItems={selectedElements.length}
        onClearSelection={clearSelection}
      />
    </>
  );
};

export const XRFTab = React.memo(XRFTabImpl);
