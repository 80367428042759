import {
  IPivotProps,
  IPivotStyles,
  mergeStyleSets,
  Pivot as FluentPivot,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../constants";

const pivotStyles: Partial<IPivotStyles> = {
  root: {},
  link: {
    padding: "0 14px",
    background: "transparent",
    marginRight: 1,
    selectors: {
      "&:hover": {
        background: NeutralColors.gray40,
      },
      "&:active": {
        background: NeutralColors.gray50,
      },
      "&.is-disabled": {
        color: NeutralColors.gray90,
      },
    },
  },
  linkContent: {},
  linkIsSelected: {
    marginRight: 1,
    padding: "0 14px",
    selectors: {
      "&:hover": {
        background: NeutralColors.gray40,
      },
      "&:active": {
        background: NeutralColors.gray50,
      },
      "&::before": {
        backgroundColor: Colors.primary + " !important",
      },
    },
  },
  text: {},
  itemContainer: {},
};

export const Pivot: FC<IPivotProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(() => mergeStyleSets(pivotStyles, styles), [
    styles,
  ]);
  return <FluentPivot styles={mergedStyles} {...restProps} />;
};
