import { v4 as uuidv4 } from "uuid";
import {
  getChartTypeToPlot,
  UNITS,
} from "../components/dataAnalyticsPane/config";
import { AnalyticsName } from "../constants";
import {
  JsonWithoutNull,
  Optional,
  PlotState,
  StoredPlot,
  StoredSelection,
} from "../types";
import { getQueryParam } from "../utils";

export const WELLBORE_QUERY_PARAM_NAME = "well";
export const IMAGE_A_QUERY_PARAM_NAME = "imgA";
export const DEPTH_QUERY_PARAM_NAME = "depth";
export const TAB_QUERY_PARAM_NAME = "tab";
export const PLOTS_QUERY_PARAM_NAME = "plots";
export const GROUP_QUERY_PARAM_SUFFIX = "-gr";
export const ELEMENT_QUERY_PARAM_SUFFIX = "-el";
export const WELLBORE_QUERY_PARAM_NPDID = "npdId";
export const SELECTED_WELL_TOPS_QUERY_PARAM = "tops";

const DEFAULT_TOC_SELECTION = ["TOC"];

export const initialUrlSelections: Record<AnalyticsName, StoredSelection> = {
  [AnalyticsName.XRF]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.XRF}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.XRF}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.XRD]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.XRD}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.XRD}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.TOC]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.TOC}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? DEFAULT_TOC_SELECTION,
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.TOC}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.SPEC_CAM]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.SPEC_CAM}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.SPEC_CAM}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.QEM_SCAN]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.QEM_SCAN}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.QEM_SCAN}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
};

export const initialUrlTab = getQueryParam(TAB_QUERY_PARAM_NAME);

export const initialUrlPlotStates: PlotState[] = (
  getQueryParam<JsonWithoutNull[]>(PLOTS_QUERY_PARAM_NAME) ?? []
).map((storedPlot) => {
  const plot = (storedPlot as unknown) as StoredPlot;
  return {
    id: uuidv4(),
    tab: plot.t,
    selectedChartType: plot.c,
    chartTypeToPlot: getChartTypeToPlot(plot.c, plot.t),
    data: undefined,
    units: UNITS[plot.t],
    domain: undefined,
    selectedElements: { elements: plot.e, groups: plot.g },
  };
});

export const initialUrlWellboreName = getQueryParam(
  WELLBORE_QUERY_PARAM_NAME
) as Optional<string>;

export const initialUrlWellboreNpdId = getQueryParam(
  WELLBORE_QUERY_PARAM_NPDID
) as Optional<string>;

export const initialUrlCuttingDepth = getQueryParam<number>(
  DEPTH_QUERY_PARAM_NAME
);
export const initialUrlImageA = getQueryParam<string>(IMAGE_A_QUERY_PARAM_NAME);

export const initialSelectedWellTops = getQueryParam<string>(
  SELECTED_WELL_TOPS_QUERY_PARAM
);
