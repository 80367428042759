import { configureStore, MiddlewareArray } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { useDispatch } from "react-redux";
import thunk from "redux-thunk";
import { Observable } from "rxjs";
import { analyticsReducer } from "../components/dataAnalyticsPane/analyticsSlice";
import { appReducer } from "./appSlice";

enableMapSet();

export const store = configureStore({
  reducer: {
    analytics: analyticsReducer,
    app: appReducer,
  },
  middleware: new MiddlewareArray().concat(thunk), // (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

type Store = typeof store;
export type StoreState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const storeState$ = getState$(store);

function getState$(store: Store) {
  return new Observable<StoreState>((observer) => {
    // emit the current state as first value:
    observer.next(store.getState());
    const unsubscribe = store.subscribe(function () {
      // emit on every new state changes
      observer.next(store.getState());
    });
    return unsubscribe;
  });
}
