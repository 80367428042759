import firebase from "firebase/app";
import "firebase/firestore";
import { Cutting } from "../types/types";

export async function getCuttingsForWellbore(
  wellboreId?: string
): Promise<Cutting[]> {
  if (!wellboreId) {
    return [];
  }

  const docSnapshot = await firebase
    .firestore()
    .collection("wellbores")
    .doc(wellboreId)
    .collection("cuttings")
    .orderBy("depth")
    .get();

  return docSnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() } as Cutting;
  });
}
