import { PivotItem, Stack } from "@fluentui/react";
import React, { FC, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDrag } from "react-use-gesture";
import { AnalyticsName, ChartType, TooltipType } from "../../constants";
import { useAppSprings } from "../../context";
import {
  useDebouncedState,
  useThrottledState,
  useTooltipClearSelector,
} from "../../hooks";
import {
  selectActiveWellbore,
  selectPlots,
  selectSelectedPlot,
  selectSelectedTab,
} from "../../store/selectors";
import { useAppDispatch } from "../../store/store";
import { AnalyticsData, Optional, RenderingConstraints } from "../../types";
import { roundUp } from "../../utils";
import { DataAnalyticsFrame, PlotterContainerFrame } from "../frames/frames";
import { ResizeThumb } from "../resizeThumb/ResizeThumb";
import { SCALE_BAR_MARGIN } from "../stage/config";
import {
  addPlot,
  changeChartType,
  openTabIfClosed,
  removePlot,
  setSelectedPlot,
  setSelectedTab,
  toggleAnalyticsPane,
  toggleOverlayAnalytics,
  toggleSelectedTab,
} from "./analyticsSlice";
import { DataAnalyticsMenu } from "./DataAnalyticsMenu";
import {
  DataPlotter,
  DEFAULT_WIDTH_OF_PLOTTER,
} from "./dataPlotter/DataPlotter";
import {
  DataPlotterSidebar,
  PLOTTER_SIDEBAR_WIDTH,
} from "./dataPlotter/DataPlotterSidebar";
import { QemscanTab, SpeccamTab, TOCTab, XRDTab, XRFTab } from "./tabs";
import { TabContainer } from "./utils/tab-helpers";

export const HEIGHT_OF_MENU = 44;
export const WIDTH_OF_MENU = 379;
export const WIDTH_OF_SCROLLBAR = 4;
export const WIDTH_OF_ANALYTICS_LIST = WIDTH_OF_MENU + WIDTH_OF_SCROLLBAR;

export const MAX_WIDTH_OF_PLOTTERS = 1500;

const DEFAULT_WIDTH =
  WIDTH_OF_ANALYTICS_LIST + PLOTTER_SIDEBAR_WIDTH + DEFAULT_WIDTH_OF_PLOTTER;
const MIN_WIDTH = WIDTH_OF_ANALYTICS_LIST;
const MAX_WIDTH = MIN_WIDTH + MAX_WIDTH_OF_PLOTTERS;

const MAX_NUMBER_OF_PLOTS = 3;

interface DataAnalyticsPaneProps {
  hide: boolean;
  setHighlightedDepth: (depth: Optional<number>) => void;
  highlightedDepth: number | undefined;
  renderingConstraints: RenderingConstraints;
}

export const DataAnalyticsPaneImpl: FC<DataAnalyticsPaneProps> = ({
  hide,
  setHighlightedDepth,
  highlightedDepth,
  renderingConstraints,
}) => {
  const dispatch = useAppDispatch();
  const activeWellbore = useSelector(selectActiveWellbore);
  const { pageSize } = useAppSprings();

  const selectedTab = useSelector(selectSelectedTab);
  const plots = useTooltipClearSelector(
    selectPlots,
    TooltipType.ANALYTICS_SELECTION
  );
  const selectedPlot = useSelector(selectSelectedPlot);

  /**
   * State for size and logic to resize and animate the analytics pane
   */
  const {
    analyticsSpring,
    setAnalyticsSpring,
    overlayAnalytics,
  } = useAppSprings();

  const [paneWidth, debouncedSetPaneWidth] = useDebouncedState(
    DEFAULT_WIDTH,
    500
  );
  const [paneWidthForUI, setPaneWidthForUI] = useThrottledState(
    paneWidth,
    1000 / 30
  );

  const bind = useDrag(
    ({ delta: [dx] }) => {
      const changeLeft = -dx;
      const currentWidth = analyticsSpring?.width?.getValue() as number;
      const nextWidth = Math.max(
        Math.min(currentWidth + changeLeft, MAX_WIDTH),
        MIN_WIDTH
      );
      if (nextWidth !== currentWidth) {
        setAnalyticsSpring({ width: nextWidth, immediate: true });
        setPaneWidthForUI(nextWidth);
        debouncedSetPaneWidth(nextWidth);
      }
    },
    {
      initial: () => [0, analyticsSpring?.width?.getValue()],
      filterTaps: true,
      axis: "x",
    }
  );

  useEffect(() => {
    if (!overlayAnalytics) {
      if (pageSize) {
        setAnalyticsSpring({
          height:
            pageSize.height - SCALE_BAR_MARGIN.top - SCALE_BAR_MARGIN.bottom,
          width: paneWidth,
          immediate: true,
        });
      }
    } else {
      if (selectedTab && pageSize) {
        setAnalyticsSpring({
          // @ts-ignore
          to: async (next) => {
            await next({
              height:
                pageSize.height -
                SCALE_BAR_MARGIN.top -
                SCALE_BAR_MARGIN.bottom,
            });
            await next({
              width: paneWidth,
            });
          },
          immediate: false,
        });
      } else {
        setAnalyticsSpring({
          // @ts-ignore
          to: async (next) => {
            await next({
              width: WIDTH_OF_ANALYTICS_LIST,
            });
            await next({
              height: HEIGHT_OF_MENU,
            });
          },
          immediate: false,
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedTab, pageSize, setAnalyticsSpring, overlayAnalytics]);

  /**
   * Keyboard event listener to open and close the analytics pane
   */
  useEffect(() => {
    const keypressHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch(setSelectedTab(undefined));
      } else if (event.key === "e") {
        dispatch(toggleAnalyticsPane());
      } else if (event.key === "o") {
        dispatch(toggleOverlayAnalytics());
      }
    };
    document.addEventListener("keydown", keypressHandler);

    return () => document.removeEventListener("keydown", keypressHandler);
  }, [selectedTab, overlayAnalytics, dispatch]);

  /**
   * Force open analytics pane when analytics pane is to the side of stage
   */
  useEffect(() => {
    if (!overlayAnalytics && activeWellbore) {
      dispatch(
        openTabIfClosed(getFirstAvailableTab(activeWellbore.analyticsData))
      );
    }
  }, [overlayAnalytics, activeWellbore, dispatch]);

  /**
   * Check if open tab exists on new wellbore selection
   */
  useEffect(() => {
    if (selectedTab) {
      const selectedTabIsDisabled = !activeWellbore?.analyticsData?.[
        selectedTab
      ]?.dataReference;
      if (selectedTabIsDisabled) {
        dispatch(
          setSelectedTab(getFirstAvailableTab(activeWellbore?.analyticsData))
        );
      }
    }
  }, [activeWellbore, dispatch, selectedTab]);

  const onTabChange = useCallback(
    (item?: PivotItem) => {
      if (item) {
        dispatch(
          toggleSelectedTab(item.props.itemKey as Optional<AnalyticsName>)
        );
      }
    },
    [dispatch]
  );

  return (
    <DataAnalyticsFrame
      className={`analytics-pane ${hide && overlayAnalytics ? "hide" : ""}`}
    >
      {selectedTab && (
        <div className="analytics-pane__resize-handle" {...bind()}>
          <div className="width-percentage">
            {roundUp(
              ((paneWidthForUI - WIDTH_OF_ANALYTICS_LIST) /
                MAX_WIDTH_OF_PLOTTERS) *
                100,
              1
            )}
            %
          </div>
          <ResizeThumb />
        </div>
      )}
      <div className="analytics-pane__overflow-container">
        {pageSize && (
          <Stack horizontal>
            <div className="analytics-pane__tabs">
              <DataAnalyticsMenu
                onChange={onTabChange}
                selectedKey={selectedTab}
              />
              <div
                className="scrollable"
                style={{
                  height: `${
                    pageSize.height -
                    SCALE_BAR_MARGIN.top -
                    SCALE_BAR_MARGIN.bottom -
                    HEIGHT_OF_MENU
                  }px`,
                  width: `${WIDTH_OF_MENU + WIDTH_OF_SCROLLBAR}px`,
                }}
              >
                <TabContainer show={selectedTab === AnalyticsName.XRF}>
                  <XRFTab />
                </TabContainer>

                <TabContainer show={selectedTab === AnalyticsName.TOC}>
                  <TOCTab />
                </TabContainer>

                <TabContainer show={selectedTab === AnalyticsName.XRD}>
                  <XRDTab />
                </TabContainer>

                <TabContainer show={selectedTab === AnalyticsName.SPEC_CAM}>
                  <SpeccamTab />
                </TabContainer>

                <TabContainer show={selectedTab === AnalyticsName.QEM_SCAN}>
                  <QemscanTab />
                </TabContainer>
              </div>
            </div>

            {analyticsSpring && plots.length && (
              <Stack
                className="analytics-pane__plotter"
                styles={{
                  root: {
                    height:
                      pageSize.height -
                      SCALE_BAR_MARGIN.top -
                      SCALE_BAR_MARGIN.bottom,
                    width: "100%",
                  },
                }}
                horizontal
              >
                <DataPlotterSidebar
                  selectedChartType={selectedPlot?.selectedChartType}
                  updateSelectedChartType={(chartType: ChartType) => {
                    dispatch(changeChartType({ chartType: chartType }));
                  }}
                  addPlot={() => {
                    if (selectedTab) {
                      dispatch(addPlot());
                    }
                  }}
                  addPlotDisabled={plots.length >= MAX_NUMBER_OF_PLOTS}
                />
                <PlotterContainerFrame className="plotter-container">
                  {plots.map((plotState, i) => {
                    return (
                      <DataPlotter
                        key={plotState.id}
                        height={
                          pageSize.height -
                          SCALE_BAR_MARGIN.top -
                          SCALE_BAR_MARGIN.bottom
                        }
                        width={
                          (paneWidth -
                            WIDTH_OF_ANALYTICS_LIST -
                            PLOTTER_SIDEBAR_WIDTH -
                            plots.length) /
                          plots.length
                        }
                        renderingConstraints={renderingConstraints}
                        numPlots={plots.length}
                        plotState={plotState}
                        setHighlightedDepth={setHighlightedDepth}
                        highlightedDepth={highlightedDepth}
                        outlined={
                          plots.length > 1 && selectedPlot?.id === plotState.id
                        }
                        highlighted={selectedPlot?.id === plotState.id}
                        clickable={plots.length > 1}
                        setSelected={() =>
                          dispatch(setSelectedPlot(plotState.id))
                        }
                        removePlot={() => {
                          if (plots.length === 1) {
                            dispatch(setSelectedTab(undefined));
                          } else {
                            dispatch(removePlot(plotState.id));
                          }
                        }}
                      />
                    );
                  })}
                </PlotterContainerFrame>
              </Stack>
            )}
          </Stack>
        )}
      </div>
    </DataAnalyticsFrame>
  );
};

export const DataAnalyticsPane = React.memo(DataAnalyticsPaneImpl);

function getFirstAvailableTab(
  analyticsData: Optional<AnalyticsData>
): Optional<AnalyticsName> {
  if (!analyticsData) {
    return undefined;
  }
  if (analyticsData?.xrf?.dataReference) {
    return AnalyticsName.XRF;
  } else if (analyticsData?.xrd?.dataReference) {
    return AnalyticsName.XRD;
  } else if (analyticsData?.speccam?.dataReference) {
    return AnalyticsName.SPEC_CAM;
  } else if (analyticsData?.toc?.dataReference) {
    return AnalyticsName.TOC;
  } else if (analyticsData?.qemscan?.dataReference) {
    return AnalyticsName.QEM_SCAN;
  }
  return undefined;
}
