import React, { FC, useCallback } from "react";
import { useDropdowns, atLeastOneIsOpen } from "../../context";
import { DropdownName } from "../../constants";

interface DropdownDeactivatorProps {
  fixed?: boolean;
  dropdownsToClose?: DropdownName[];
  zIndex?: number;
}

export const DropdownDeactivator: FC<DropdownDeactivatorProps> = ({
  fixed,
  dropdownsToClose,
  zIndex,
}) => {
  const {
    isOpen,
    closeDropdown,
    closeAllDropdowns,
    dropdownsAreOpen,
  } = useDropdowns();

  const onClickHandler = useCallback(() => {
    if (dropdownsToClose) {
      closeDropdown(dropdownsToClose);
    } else {
      closeAllDropdowns();
    }
  }, [dropdownsToClose, closeDropdown, closeAllDropdowns]);

  const shouldRender = dropdownsToClose
    ? atLeastOneIsOpen(dropdownsToClose, isOpen)
    : dropdownsAreOpen;

  if (shouldRender) {
    return (
      <div
        className={`dropdown-deactivator ${
          fixed ? "dropdown-deactivator--fixed" : ""
        }`}
        style={{
          zIndex: zIndex,
        }}
        onClick={onClickHandler}
      ></div>
    );
  }
  return null;
};
