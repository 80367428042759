import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WellTopsName } from "../../constants";
import { SIDEBAR_MENU_DROPDOWNS, useDropdowns } from "../../context";
import { useResizeObserver, useWellTopsData } from "../../hooks";
import {
  selectActiveWellbore,
  selectCuttings,
  selectCuttingsDepthIndexes,
} from "../../store";
import { RenderingConstraints, WellTopsPlotData } from "../../types/types";
import { WellTopsFrame } from "../frames/track-frames";
import { HighlightedDepthIndicator } from "../highlightedDepthIndicator/HighlightedDepthIndicator";
import { TrackContent, TrackFooter, TrackHeader } from "../track";
import { getPlotData } from "./getPlotData";
import { WellboreTopsChart } from "./WellboreTopsChart";

export const WIDTH_OF_WELL_TOPS = 60;

interface WellTopsProps {
  selectedWellTops: WellTopsName | undefined;
  highlightedDepth: number | undefined;
  renderingConstraints: RenderingConstraints;
}

export const WellboreTopsImpl: FC<WellTopsProps> = ({
  selectedWellTops,
  highlightedDepth,
  renderingConstraints,
}) => {
  const cuttings = useSelector(selectCuttings);
  const depthIndexes = useSelector(selectCuttingsDepthIndexes);
  const activeWellbore = useSelector(selectActiveWellbore);
  const { closeDropdown } = useDropdowns();
  const [size, contentRef] = useResizeObserver<HTMLDivElement>();

  const wellTopsData = useWellTopsData();
  const [chartData, setChartData] = useState<WellTopsPlotData[]>();

  useEffect(() => {
    if (wellTopsData && activeWellbore && cuttings) {
      const topsDataForWellbore = wellTopsData[activeWellbore.name];
      if (topsDataForWellbore) {
        const plotData = getPlotData(topsDataForWellbore, cuttings);
        setChartData(plotData);
      } else {
        setChartData(undefined);
      }
    } else {
      setChartData(undefined);
    }
  }, [activeWellbore, cuttings, wellTopsData]);

  return (
    <WellTopsFrame
      className="wellbore-tops"
      onClick={() => closeDropdown(SIDEBAR_MENU_DROPDOWNS)}
    >
      <TrackHeader>FM | GP</TrackHeader>
      <TrackContent className="wellbore-tops__chart-root" ref={contentRef}>
        {size && (
          <>
            <WellboreTopsChart
              data={chartData}
              size={size}
              renderingConstraints={renderingConstraints}
            />
            <HighlightedDepthIndicator
              highlightedIndex={
                highlightedDepth !== undefined
                  ? depthIndexes[highlightedDepth]
                  : undefined
              }
              size={size}
              renderingConstraints={renderingConstraints}
            />
          </>
        )}
      </TrackContent>
      <TrackFooter>
        {selectedWellTops ? selectedWellTops.toUpperCase() : ""}
      </TrackFooter>
    </WellTopsFrame>
  );
};

export const WellboreTops = React.memo(WellboreTopsImpl);
