import {
  DirectionalHint,
  ITeachingBubbleStyles,
  Stack,
  StackItem,
  TeachingBubble,
  Text,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { NeutralColors } from "@fluentui/theme";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Colors } from "../../constants";
import { selectSelectedCutting, selectSelectedImageType } from "../../store";
import { IconButton } from "../styledFluentComponents";

const CalloutProps = { directionalHint: DirectionalHint.topRightEdge };

const styles: Partial<ITeachingBubbleStyles> = {
  root: {
    selectors: {
      ".ms-Callout-beak": {
        background: NeutralColors.gray30,
      },
      ".ms-Callout-main": {
        background: NeutralColors.gray30,
      },
      ".ms-Button--icon": {
        background: NeutralColors.gray30,
        color: NeutralColors.gray130,
        borderRadius: 0,
      },
      ".ms-Button--icon:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
      ".ms-Button--icon:hover": {
        background: NeutralColors.gray40,
        color: NeutralColors.gray130,
      },
    },
  },
  body: {
    background: NeutralColors.gray30,
  },
  bodyContent: {
    background: NeutralColors.gray30,
    color: NeutralColors.gray160,
  },
  subText: {
    color: NeutralColors.gray160,
  },
  header: {
    color: NeutralColors.gray160,
  },
  headline: {
    color: NeutralColors.gray160,
    fontSize: "20px",
  },
};

const textHeadingStyles = { root: { fontSize: "16px", fontWeight: "600" } };
const textStyles = {
  root: {
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "6px",
    wordBreak: "break-all",
  },
};

export const ImageInformationButton: FC = () => {
  const selectedCutting = useSelector(selectSelectedCutting);
  const selectedImageType = useSelector(selectSelectedImageType);
  const [visible, { toggle: toggleVisible }] = useBoolean(false);

  const metadata =
    selectedCutting?.imageReferences.find((r) => r.type === selectedImageType)
      ?.originalMetadata ?? {};

  return (
    <div>
      <IconButton
        id="image-information-button"
        ariaLabel="Show image information"
        iconProps={{ iconName: "Info" }}
        onClick={toggleVisible}
      />

      {visible && (
        <TeachingBubble
          styles={styles}
          calloutProps={CalloutProps}
          isWide={true}
          hasSmallHeadline={true}
          hasCloseButton={true}
          closeButtonAriaLabel="Close"
          target="#image-information-button"
          onDismiss={toggleVisible}
          headline="Image Details"
        >
          <Text styles={textHeadingStyles} block>
            Source filename
          </Text>
          <Text styles={textStyles} block>
            {metadata.filename ?? "-"}
          </Text>

          <Stack
            styles={{ root: { marginTop: "16px" } }}
            horizontal
            wrap={false}
          >
            <StackItem>
              <Text styles={textHeadingStyles} block>
                Resolution
              </Text>
              <Text styles={textStyles} block>
                {metadata.resolutionWidth && metadata.resolutionWidth
                  ? `${metadata.resolutionWidth}x${metadata.resolutionHeight}`
                  : "-"}
              </Text>
            </StackItem>

            <StackItem styles={{ root: { paddingLeft: "24px" } }}>
              <Text styles={textHeadingStyles} block>
                Color Space
              </Text>
              <Text styles={textStyles} block>
                {metadata.colorSpace ?? "-"}
              </Text>
            </StackItem>
          </Stack>
        </TeachingBubble>
      )}
    </div>
  );
};
