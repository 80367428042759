/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from "d3";
import { AnalyticsName, TooltipType } from "../../../../constants";
import { PinnableTooltipData, ScatterPlotData } from "../../../../types";
import { getElementColor } from "../../../dataAnalyticsPane/config";
import {
  createTooltipAdder,
  TooltipData,
} from "../../../plot-tooltip/plot-tooltip";
import { PLOTTER_MARGIN, Scales } from "../DataPlotter";
import { ChartElements, SIZE_SCALE_FACTOR } from "./common-drawing";

export function drawScatterChart(
  chartElements: ChartElements,
  scales: Scales,
  data: ScatterPlotData[],
  units: Record<string, string>,
  selectedTab: AnalyticsName,
  // eslint-disable-next-line
  updateSelectedCutting: (dataOrEvent: any) => void,
  setHighlightedDepth: (depth: number | undefined) => void,
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void,
  showStratigraphyInTooltip: boolean,
  tooltipId: string
): void {
  const [addHoveredTooltip, removeHoveredTooltip] = createTooltipAdder(
    tooltipId,
    createScatterPlotTooltipData(units),
    setActiveTooltip,
    TooltipType.ANALYTICS_SELECTION,
    showStratigraphyInTooltip
  );

  const increaseSizeOfCircle = (e: any) => {
    d3.select(e.target).attr("r", scales.pointRadius * SIZE_SCALE_FACTOR);
  };
  const resetSizeOfCircle = (e: any) => {
    d3.select(e.target).attr("r", scales.pointRadius);
  };

  const addCircleEventHandlers = (circle: any) => {
    circle
      .on("mousedown", (event: any) => {
        event.stopPropagation();
      })
      .on("mouseup", (event: any) => {
        event.stopPropagation();
      })
      .on("click", (e: any) => {
        e?.stopPropagation();
        updateSelectedCutting(e);
      })
      .on("mouseover", (e: any, d: ScatterPlotData) => {
        addHoveredTooltip(e);
        setHighlightedDepth(d.depth);
        increaseSizeOfCircle(e);
      })
      .on("mouseout", (e: any) => {
        removeHoveredTooltip();
        setHighlightedDepth(undefined);
        resetSizeOfCircle(e);
      });
  };

  const addCircleAttributes = (circle: any) => {
    // Add display none when cx value less than plot margin left
    circle
      .attr("r", scales.pointRadius)
      .attr("cx", (d: ScatterPlotData) => scales.x(d.value as number))
      .attr("cy", (d: ScatterPlotData) => scales.y(String(d.depth)))
      .attr("fill", (d: ScatterPlotData) => getElementColor(d.key, selectedTab))
      .style("opacity", (d: ScatterPlotData) =>
        scales.x(d.value as number) < PLOTTER_MARGIN.left ? 0 : 1
      );
  };

  chartElements.dataContainer
    .selectAll("circle")
    .data(
      data,
      (d) => (d as ScatterPlotData).depth + "-" + (d as ScatterPlotData).key
    )
    .join(
      (enter) =>
        enter
          .append("circle")
          .call(addCircleEventHandlers)
          .call(addCircleAttributes)
          .attr("opacity", 0)
          .call((_enter) => _enter.transition().attr("opacity", 1)),
      (update) =>
        update.call((_update) =>
          _update
            .call(addCircleEventHandlers)
            .transition()
            .call(addCircleAttributes)
        ),
      (exit) =>
        exit.call((_exit) => _exit.transition().attr("opacity", 0).remove())
    );
}

const createScatterPlotTooltipData = (units: Record<string, string>) => (
  plotData: ScatterPlotData
): TooltipData => {
  return {
    stratigraphy: plotData.stratigraphy,
    heading: `Depth: ${plotData.depth} m`,
    rows: [
      {
        label: plotData.key,
        value: `${plotData.value} ${
          units[plotData.key] ? units[plotData.key] : ""
        }`,
      },
    ],
  };
};
