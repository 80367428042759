import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { FirebaseConfig } from "../types";

class AppConfig implements Pick<FirebaseConfig, "apiKey"> {
  apiKey = "";

  set(config: FirebaseConfig) {
    this.apiKey = config.apiKey;
  }
}

export const firebaseConfig = new AppConfig();

const configureFirebase = async (): Promise<void> => {
  const response = await fetch("/__/firebase/init.json");
  const config = await response.json();
  if (process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN) {
    config.authDomain = process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN;
  }

  firebase.initializeApp(config);
  firebaseConfig.set(config);
};

export default configureFirebase;
