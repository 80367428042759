import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDrag } from "react-use-gesture";
import {
  SIDEBAR_MENU_DROPDOWNS,
  useAppSprings,
  useDropdowns,
} from "../../context";
import { useElementSize } from "../../hooks";
import {
  selectCuttings,
  selectCuttingsDepthIndexes,
  selectCuttingsDepths,
  selectSelectedCutting,
} from "../../store";
import {
  LogCurveName,
  LogCurveOptions,
  ProcessedRefLogs,
  RenderingConstraints,
} from "../../types/types";
import { RangeFinderFrame } from "../frames/track-frames";
import { HighlightedDepthIndicator } from "../highlightedDepthIndicator/HighlightedDepthIndicator";
import { PreviewImage } from "../miniMap/PreviewImage";
import { ResizeThumb } from "../resizeThumb/ResizeThumb";
import { INDEX_OF_IMAGE_A_BACKDROP } from "../sidebarMenu/BackdropSelector";
import {
  HEAD_FOOT_HEIGHT,
  TrackContent,
  TrackContentProps,
  TrackFooter,
  TrackHeader,
} from "../track";
import { BackdropImage } from "./BackdropImage";
import { RangeFinderSlider } from "./RangeFinderSlider";
import { ReferenceLog } from "./ReferenceLog";
import { DepthIndicatorData, useCuttingsUpdater } from "./useCuttingsUpdater";
import { gammaRayXDomain, RefLogColor } from "./useRefLog";

const BACKDROP_MIN_WIDTH = 60;
const BACKDROP_MAX_WIDTH = 5 * BACKDROP_MIN_WIDTH;

const CURR_LABEL_HEIGHT = 46;

interface RangeFinderProps {
  setGridView: (value: boolean) => void;
  highlightedDepth: number | undefined;
  selectedBackdrops: (string | undefined)[];
  selectedRefLog: LogCurveOptions | undefined;
  processedRefLogs: ProcessedRefLogs | undefined;
  renderingConstraints: RenderingConstraints;
}

export const RangeFinderImpl: FC<RangeFinderProps> = ({
  setGridView,
  highlightedDepth,
  selectedBackdrops,
  selectedRefLog,
  processedRefLogs,
  renderingConstraints,
}) => {
  const cuttings = useSelector(selectCuttings);
  const depths = useSelector(selectCuttingsDepths);
  const depthIndexes = useSelector(selectCuttingsDepthIndexes);

  const { closeDropdown } = useDropdowns();
  const [size, contentRef] = useElementSize<HTMLDivElement>();

  const [
    sliderValue,
    min,
    max,
    onSlideChange,
    onZoomChange,
    onScroll,
    onKeyDown,
    showDepthIndicator,
    depthIndicatorData,
  ] = useCuttingsUpdater(setGridView);

  const { rangeFinderSpring, setRangeFinderSpring } = useAppSprings();
  const bind = useDrag(
    ({ delta: [dx] }) => {
      const numberOfSelectedBackdrops = selectedBackdrops.filter(
        (b) => b !== undefined
      ).length;
      const currentWidth = rangeFinderSpring?.width?.getValue() as number;
      const changeLeft = -dx;

      const minWidth = numberOfSelectedBackdrops * BACKDROP_MIN_WIDTH;
      const maxWidth = numberOfSelectedBackdrops * BACKDROP_MAX_WIDTH;

      const nextWidth = Math.max(
        Math.min(currentWidth + changeLeft, maxWidth),
        minWidth
      );
      if (nextWidth !== currentWidth) {
        setRangeFinderSpring({ width: nextWidth });
      }
    },
    {
      initial: () => [0, rangeFinderSpring?.width?.getValue()],
      filterTaps: true,
      axis: "x",
    }
  );

  /**
   * Update width of range-finder to uphold min and max width of individual backdrop images
   */
  useEffect(() => {
    const currentWidth = rangeFinderSpring?.width?.getValue() as number;
    const numberOfSelectedBackdrops = selectedBackdrops.filter(
      (b) => b !== undefined
    ).length;

    if (
      currentWidth < numberOfSelectedBackdrops * BACKDROP_MIN_WIDTH ||
      currentWidth % BACKDROP_MIN_WIDTH === 0
    ) {
      setRangeFinderSpring({
        width: numberOfSelectedBackdrops * BACKDROP_MIN_WIDTH,
      });
    } else if (currentWidth > numberOfSelectedBackdrops * BACKDROP_MAX_WIDTH) {
      setRangeFinderSpring({
        width: numberOfSelectedBackdrops * BACKDROP_MAX_WIDTH,
      });
    }

    // eslint-disable-next-line
  }, [selectedBackdrops]);

  if (!cuttings) return null;

  return (
    <>
      <RangeFinderFrame
        className={"range-finder"}
        onClick={() => closeDropdown(SIDEBAR_MENU_DROPDOWNS)}
      >
        <TrackHeader>{depths[min]} m</TrackHeader>
        <TrackContent ref={contentRef}>
          {size && (
            <>
              <RangeFinderBackdrops
                selectedBackdrops={selectedBackdrops}
                selectedRefLog={selectedRefLog}
                processedRefLogs={processedRefLogs}
                size={size}
                renderingConstraints={renderingConstraints}
              />

              <HighlightedDepthIndicator
                size={size}
                renderingConstraints={renderingConstraints}
                highlightedIndex={
                  highlightedDepth !== undefined
                    ? depthIndexes[highlightedDepth]
                    : undefined
                }
              />

              <RangeFinderSlider
                maxValue={max}
                sliderValue={sliderValue}
                onSliderChange={onSlideChange}
                onZoomChange={onZoomChange}
                onScroll={onScroll}
                onKeyDown={onKeyDown}
                size={size}
                renderingConstraints={renderingConstraints}
              />
            </>
          )}
        </TrackContent>

        <TrackFooter>{depths[max]} m</TrackFooter>

        <div className="track__resize-handle" {...bind()}></div>

        <ResizeThumb {...bind()} />

        {size && (
          <CurrentDepthIndicator
            show={showDepthIndicator}
            data={depthIndicatorData}
            size={size}
            renderingConstraints={renderingConstraints}
          />
        )}
      </RangeFinderFrame>

      <PreviewImage cuttingIndex={sliderValue} />
    </>
  );
};

export const RangeFinder = React.memo(RangeFinderImpl);

interface RangeFinderBackdropsProps extends TrackContentProps {
  selectedBackdrops: (string | undefined)[];
  selectedRefLog: LogCurveOptions | undefined;
  processedRefLogs: ProcessedRefLogs | undefined;
}

const RangeFinderBackdrops: FC<RangeFinderBackdropsProps> = ({
  selectedBackdrops,
  selectedRefLog,
  processedRefLogs,
  size,
  renderingConstraints,
}) => {
  if (size) {
    return (
      <div
        className="range-finder__backdrops"
        style={{
          top: renderingConstraints.outerTrackMargin.top,
          height:
            size.height -
            renderingConstraints.outerTrackMargin.top -
            renderingConstraints.outerTrackMargin.bottom,
        }}
      >
        {selectedBackdrops.map((backdropType, i) => {
          if (backdropType) {
            return (
              <BackdropImage key={backdropType} backdropType={backdropType}>
                {i === INDEX_OF_IMAGE_A_BACKDROP &&
                  selectedRefLog &&
                  processedRefLogs?.[selectedRefLog] && (
                    <ReferenceLog
                      data={processedRefLogs[selectedRefLog]}
                      xDomain={
                        selectedRefLog === LogCurveName.GAMMA_RAY
                          ? gammaRayXDomain
                          : undefined
                      }
                      color={RefLogColor[selectedRefLog]}
                    />
                  )}
              </BackdropImage>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return null;
};

interface CurrentDepthIndicatorProps extends TrackContentProps {
  show: boolean;
  data: DepthIndicatorData;
}

const CurrentDepthIndicator: FC<CurrentDepthIndicatorProps> = ({
  show,
  data,
  size,
  renderingConstraints,
}) => {
  const selectedCutting = useSelector(selectSelectedCutting);
  const depths = useSelector(selectCuttingsDepths);

  if (size && selectedCutting && renderingConstraints.trackStep) {
    let currLabelTop =
      HEAD_FOOT_HEIGHT -
      CURR_LABEL_HEIGHT / 2 +
      renderingConstraints.trackStep * data.depthIndex;

    if (data.position === "bottom") {
      currLabelTop +=
        renderingConstraints.outerTrackMargin.top +
        renderingConstraints.trackStep;
    } else if (data.position === "top") {
      currLabelTop += renderingConstraints.outerTrackMargin.top;
    } else {
      currLabelTop += renderingConstraints.trackStartCenter;
    }

    return (
      <div
        className={`range-finder__current ${show ? "visible" : ""}`}
        style={{
          top: `${currLabelTop}px`,
        }}
      >
        {depths[data.depthIndex]}m
      </div>
    );
  }

  return null;
};
