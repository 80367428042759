import {
  IIconProps,
  Modal,
  NeutralColors,
  PivotItem,
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { FC, useCallback, useState } from "react";
import { getClasses } from "../../utils";
import { IconButton, Pivot, SidebarButton } from "../styledFluentComponents";

enum HelpTab {
  RELEASE_NOTES = "Release Notes",
  SHORTKEYS = "Shortkeys",
}

const HELP_ICON_PROPS: IIconProps = {
  iconName: "Unknown",
};

const CLOSE_ICON_PROPS: IIconProps = {
  iconName: "Cancel",
};

export const HelpButton: FC = () => {
  const [isOpen, { toggle: toggleIsOpen, setFalse: close }] = useBoolean(false);
  const [selectedTab, setSelectedTab] = useState<HelpTab>(HelpTab.SHORTKEYS);

  const onKeyChange = useCallback((item?: PivotItem) => {
    if (item) {
      setSelectedTab(item.props.itemKey as HelpTab);
    }
  }, []);

  return (
    <>
      <SidebarButton
        iconProps={HELP_ICON_PROPS}
        ariaLabel="Open help"
        onClick={toggleIsOpen}
        checked={isOpen}
      />
      <Modal
        isOpen={isOpen}
        className="help-modal"
        isModeless={true}
        styles={{
          main: {
            background: NeutralColors.gray10,
          },
        }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Pivot
            aria-label="Help menu"
            selectedKey={selectedTab ? selectedTab : null}
            onLinkClick={onKeyChange}
            headersOnly={true}
          >
            <PivotItem
              headerText={HelpTab.SHORTKEYS}
              itemKey={HelpTab.SHORTKEYS}
              headerButtonProps={{
                ariaLabel: "Open the shortkey tab",
              }}
            />
            <PivotItem
              headerText={HelpTab.RELEASE_NOTES}
              itemKey={HelpTab.RELEASE_NOTES}
              headerButtonProps={{
                ariaLabel: "Open the release notes tab",
              }}
            />
          </Pivot>
          <IconButton
            iconProps={CLOSE_ICON_PROPS}
            ariaLabel="Close help"
            onClick={close}
            styles={{ root: { background: "inherit", marginRight: 6 } }}
          />
        </Stack>
        <ShortkeysTab show={selectedTab === HelpTab.SHORTKEYS} />
        <ReleaseNotesTab show={selectedTab === HelpTab.RELEASE_NOTES} />
      </Modal>
    </>
  );
};

const TabContainer: FC<{ show: boolean }> = ({ show, children }) => {
  return (
    <div className={getClasses("help-tab", { "help-tab--visible": show })}>
      {children}
    </div>
  );
};

const ReleaseNotesTab: FC<{ show: boolean }> = ({ show }) => {
  return (
    <TabContainer show={show}>
      <h2>Release Notes</h2>
      <h3>2021-07-06 - v1.6.0 - July Feature Update</h3>
      <h4>Features</h4>
      <ul>
        <li>
          Added new button and functionality to filter on item names in the data
          analytics pane.
        </li>
        <li>Added wellbore content icons to the map view.</li>
        <li>Added Support for wellbores with data registered in feet.</li>
      </ul>

      <h4>Resolved bugs</h4>
      <ul>
        <li>Fixed minimap frame not correctly sized for lower resolutions.</li>
      </ul>
      <h3>2021-06-15 - v1.5.0 - June Feature Update</h3>
      <h4>Features</h4>
      <ul>
        <li>
          Added measurement tool to image viewer. Can be used by clicking the
          tools icon.
        </li>
        <li>Added a plot legend in the analytics section.</li>
        <li>
          Added shortkeys information and release notes in a new help view in
          the sidebar menu.
        </li>
        <li>
          Wellbores have been updated with NPD ids. You can click the NPD icon
          to open a new tab in NPD factpages for the selected wellbore.
        </li>
        <li>
          Well tops information added in the minimap and in the plot tooltips
          when activated in the sidebar menu.
        </li>
        <li>
          Bit size reference log can now be viewed for wellbores that has the
          data available.
        </li>
        <li>Added a clear all selections button for analytics panel.</li>
        <li>
          Tooltips for the same depth will now show when multiple plot types are
          activated in the analytics panel.
        </li>
      </ul>

      <h4>Resolved bugs</h4>
      <ul>
        <li>Minimap freezes when site is under heavy load.</li>
        <li>
          Casing shoe data updated with correct information for T-wellbores and
          their associated wellbores.
        </li>
        <li>
          Fixed general data ingestion bugs to improve quality of wellbore data.
        </li>
      </ul>
      <h3>2021-05-28 - v1.4.0 - May Feature Update</h3>
      <ul>
        <li>
          MapView: Jump to next/previous wellbore that matches filter criteria.
        </li>
        <li>NPD formations tops available in analyics panel excel download.</li>
        <li>
          Added feature to link multiple wellify browser windows on depth.
        </li>
        <li>
          Compare analytic data across data types by adding additional plots to
          the analytics panel
        </li>
        <li>
          X - Domain of plots in the analytics panel can now be manually
          adjusted
        </li>
        <li>
          Image viewer zoom level and position is now kept when loading new
          image
        </li>
        <li>
          Pick up where you left off - the last selected wellbore will be
          remembered for future sessions
        </li>
        <li>
          Percentage indicator added to the analytics panel resize
          functionality.
        </li>
      </ul>
    </TabContainer>
  );
};

const ShortkeysTab: FC<{ show: boolean }> = ({ show }) => {
  return (
    <TabContainer show={show}>
      <h2>Keyboard shortcuts</h2>

      <table className="shortcuts-table">
        <thead>
          <th colSpan={2}>Image viewer</th>
        </thead>
        <tbody>
          <tr>
            <td>Move image viewport up</td>
            <td>[ W ] or [ up arrow ]</td>
          </tr>
          <tr>
            <td>Move image viewport down</td>
            <td>[ S ] or [ down arrow ]</td>
          </tr>
          <tr>
            <td>Move image viewport left</td>
            <td>[ A ] or [ left arrow ]</td>
          </tr>
          <tr>
            <td>Move image viewport right</td>
            <td>[ D ] or [ right arrow ]</td>
          </tr>
          <tr>
            <td>Fit image to viewport</td>
            <td>[ 0 ]</td>
          </tr>
          <tr>
            <td>Zoom image viewport out</td>
            <td>[ - ] , [ shift + W ] or [ shift + up arrow ]</td>
          </tr>
          <tr>
            <td>Zoom image viewport in</td>
            <td>[ + ] , [ shift + S ] or [ shift + down arrow ]</td>
          </tr>
          <tr>
            <td>Ruler tool on</td>
            <td>[ R ]</td>
          </tr>
          <tr>
            <td>Ruler tool off</td>
            <td>[ esc ]</td>
          </tr>
        </tbody>
      </table>

      <table className="shortcuts-table">
        <thead>
          <th colSpan={2}>Tabs and UI elements</th>
        </thead>
        <tbody>
          <tr>
            <td>Open/Close Map</td>
            <td>[ M ]</td>
          </tr>
          <tr>
            <td>Close Tabs/Map</td>
            <td>[ esc ]</td>
          </tr>
          <tr>
            <td>Pin the current tooltip</td>
            <td>[ P ]</td>
          </tr>
          <tr>
            <td>Clear all pinned tooltips</td>
            <td>[ C ]</td>
          </tr>
        </tbody>
      </table>

      <table className="shortcuts-table">
        <thead>
          <th colSpan={2}>Analytics</th>
        </thead>
        <tbody>
          <tr>
            <td>Show/Hide Analytics tab</td>
            <td>[ E ]</td>
          </tr>
          <tr>
            <td>Analytics tab overlay on/off</td>
            <td>[ O ]</td>
          </tr>
          <tr>
            <td>Select multiple items</td>
            <td>[ shift + left click ]</td>
          </tr>
        </tbody>
      </table>
    </TabContainer>
  );
};
