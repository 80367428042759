import { Stack } from "@fluentui/react";
import React, { FC } from "react";
import { ChartType } from "../../../constants";
import { useAppDispatch } from "../../../store";
import { IconButton } from "../../styledFluentComponents";
import { clearAllSelections } from "../analyticsSlice";
import { PlotLegendButton } from "./PlotLegendButton";

export const PLOTTER_SIDEBAR_WIDTH = 32;

interface DataPlotterSidebarProps {
  selectedChartType: ChartType | undefined;
  updateSelectedChartType: (charType: ChartType) => void;
  addPlot: () => void;
  addPlotDisabled: boolean;
}

export const DataPlotterSidebar: FC<DataPlotterSidebarProps> = ({
  selectedChartType,
  updateSelectedChartType,
  addPlot,
  addPlotDisabled,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Stack className="data-plotter__sidebar">
      <IconButton
        onClick={addPlot}
        ariaLabel="Add plot"
        disabled={addPlotDisabled}
        iconProps={{ iconName: "CircleAddition" }}
        toolTipText="New plot panel"
      ></IconButton>
      <div className="data-plotter__sidebar__separator"></div>
      <PlotLegendButton />
      <div className="data-plotter__sidebar__separator"></div>
      <IconButton
        onClick={() => updateSelectedChartType(ChartType.STACKED_BAR)}
        ariaLabel="Set default chart type to stacked bar chart"
        checked={selectedChartType === ChartType.STACKED_BAR}
        iconProps={{ iconName: "StackedBarChart" }}
      ></IconButton>
      <IconButton
        onClick={() => updateSelectedChartType(ChartType.SCATTER)}
        ariaLabel="Set default chart type to scatter chart"
        checked={selectedChartType === ChartType.SCATTER}
        iconProps={{ iconName: "ScatterChart" }}
      ></IconButton>
      <div className="data-plotter__sidebar__separator"></div>
      <IconButton
        onClick={() => dispatch(clearAllSelections())}
        ariaLabel="Clear all selections"
        iconProps={{ iconName: "ClearSelection" }}
        toolTipText="Clear all selections"
      ></IconButton>
    </Stack>
  );
};
