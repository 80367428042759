import { Position, WellborePosition } from "../../../types";

interface WellborePositions {
  realPosition: Position;
  pointPosition: Position;
  labelAngles: [number, number];
}

const positionOutsideMap: Position = [Infinity, Infinity];

export default class PositionResolver {
  private projection: d3.GeoProjection;
  private wellborePositions: {
    [wellboreId: string]: WellborePositions;
  };
  private numberPlottedAtPosition: { [position: string]: number };
  private stackedPointsRadius: number;
  private labelPlottingStartAngle: number;
  private labelPlottingAngleIncrement: number;

  constructor(projection: d3.GeoProjection) {
    this.projection = projection;
    this.wellborePositions = {};
    this.numberPlottedAtPosition = {};
    this.stackedPointsRadius = 4;
    this.labelPlottingStartAngle = Math.PI / 2;
    const maxNumberOfPointsInSamePosition = 7;
    this.labelPlottingAngleIncrement =
      (2 * Math.PI) / maxNumberOfPointsInSamePosition;
  }

  getPositionX(w: WellborePosition): number {
    return this.getWellborePositions(w).pointPosition[0];
  }

  getPositionY(w: WellborePosition): number {
    return this.getWellborePositions(w).pointPosition[1];
  }

  getLabelPositionX(w: WellborePosition, xRadius: number): number {
    const wellborePositions = this.getWellborePositions(w);
    const labelAngles = wellborePositions.labelAngles;
    return wellborePositions.pointPosition[0] + xRadius * labelAngles[0];
  }

  getLabelPositionY(w: WellborePosition, yRadius: number): number {
    const wellborePositions = this.getWellborePositions(w);
    const labelAngles = wellborePositions.labelAngles;

    return wellborePositions.pointPosition[1] + yRadius * labelAngles[1];
  }

  setProjection(projection: d3.GeoProjection): void {
    this.projection = projection;
  }

  /* Returns wellbore positions, they are calculated if they have not been seen before */
  private getWellborePositions(w: WellborePosition): WellborePositions {
    if (this.wellborePositions[w.name]) {
      return this.wellborePositions[w.name];
    } else {
      const realPosition = this.getRealPosition(w);
      const pointPosition = this.getPointPosition(realPosition);
      const labelAngles = this.getLabelAngles(realPosition);
      this.incrementNumberPlottedAtPosition(realPosition);

      this.wellborePositions[w.name] = {
        realPosition,
        pointPosition,
        labelAngles,
      };
      return this.wellborePositions[w.name];
    }
  }

  /**
   * Resolve the x and y coordinates for the true position of the wellbore.
   *
   * The wellbore coordinates are rounded to two decimals due to S and T wellbores in some cases having
   * only slightly different latitude and longitude from the main wellbore, causing the wellbores to be
   * plotted on top of each other. Rounding to two decimals looses a precision of up to approximately
   * 650 meters for each wellbore. This causes wellbores with a distance of up to approximately 1300 meters
   * from each other to be considered to have the same position.
   */
  private getRealPosition(w: WellborePosition): Position {
    if (w.coordinates) {
      return this.projection([
        roundToTwoDecimals(w.coordinates.longitude),
        roundToTwoDecimals(w.coordinates.latitude),
      ]) as Position;
    }
    return positionOutsideMap;
  }

  private getPointPosition(realPosition: Position) {
    if (this.positionHasBeenSeenBefore(realPosition)) {
      const numberOfWellboresPlottedInThisLocation = this.getNumberOfWellboresPlottedAt(
        realPosition
      );

      const pointPositionOffset = this.getPointPositionOffset(
        numberOfWellboresPlottedInThisLocation
      );
      return this.addVectors(realPosition, pointPositionOffset);
    }
    return realPosition;
  }

  private getLabelAngles(realPosition: Position): Position {
    const numberOfWellboresPlottedInThisLocation = this.getNumberOfWellboresPlottedAt(
      realPosition
    );

    let angleOfLabelRelativeToPoint: number;
    if (numberOfWellboresPlottedInThisLocation === 0) {
      angleOfLabelRelativeToPoint = Math.PI / 2.5;
    } else {
      angleOfLabelRelativeToPoint =
        this.labelPlottingStartAngle +
        this.labelPlottingAngleIncrement *
          numberOfWellboresPlottedInThisLocation;
    }

    return [
      Math.cos(angleOfLabelRelativeToPoint),
      Math.sin(angleOfLabelRelativeToPoint),
    ];
  }

  private positionHasBeenSeenBefore(position: Position) {
    const positionKey = JSON.stringify(position);
    if (this.numberPlottedAtPosition[positionKey]) {
      return true;
    }
    return false;
  }

  private incrementNumberPlottedAtPosition(realPosition: Position): void {
    const positionKey = JSON.stringify(realPosition);
    if (!this.numberPlottedAtPosition[positionKey]) {
      this.numberPlottedAtPosition[positionKey] = 1;
    } else {
      this.numberPlottedAtPosition[positionKey] += 1;
    }
  }

  private getNumberOfWellboresPlottedAt(realPosition: Position): number {
    const positionKey = JSON.stringify(realPosition);
    if (this.numberPlottedAtPosition[positionKey]) {
      return this.numberPlottedAtPosition[positionKey];
    }
    return 0;
  }

  private getPointPositionOffset(
    numberAlreadyPlotted: number
  ): [number, number] {
    const angleOfLabelRelativeToPoint =
      this.labelPlottingStartAngle +
      this.labelPlottingAngleIncrement * numberAlreadyPlotted;

    const x = this.stackedPointsRadius * Math.cos(angleOfLabelRelativeToPoint);
    const y = this.stackedPointsRadius * Math.sin(angleOfLabelRelativeToPoint);
    return [x, y];
  }

  private addVectors(
    v1: [number, number],
    v2: [number, number]
  ): [number, number] {
    return [v1[0] + v2[0], v1[1] + v2[1]];
  }
}

function roundToTwoDecimals(num: number) {
  return Math.round(num * 100) / 100;
}
