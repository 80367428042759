import { AnalyticsName } from "../../../constants";
import { ElementsConfig } from "../../../types";
import { xrfConfig } from "./xrfConfig";
import { xrdConfig } from "./xrdConfig";
import { tocConfig } from "./tocConfig";
import { qemscanConfig } from "./qemscanConfig";
import { speccamConfig } from "./speccamConfig";
import { getUnits } from "./config";

export * from "./config";
export * from "./qemscanConfig";
export * from "./speccamConfig";
export * from "./tocConfig";
export * from "./xrdConfig";
export * from "./xrfConfig";
export * from "./qemscanConfig";
export * from "./elementColorsConfig";

export const analyticsConfigs: Record<AnalyticsName, ElementsConfig> = {
  [AnalyticsName.XRF]: xrfConfig,
  [AnalyticsName.XRD]: xrdConfig,
  [AnalyticsName.TOC]: tocConfig,
  [AnalyticsName.QEM_SCAN]: qemscanConfig,
  [AnalyticsName.SPEC_CAM]: speccamConfig,
};

export const UNITS: Record<AnalyticsName, Record<string, string>> = {
  [AnalyticsName.XRF]: getUnits(xrfConfig),
  [AnalyticsName.XRD]: getUnits(xrdConfig),
  [AnalyticsName.TOC]: getUnits(tocConfig),
  [AnalyticsName.QEM_SCAN]: getUnits(qemscanConfig),
  [AnalyticsName.SPEC_CAM]: getUnits(speccamConfig),
};
