import { IContextualMenuItem } from "@fluentui/react";
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { DropdownName } from "../../constants";
import { selectActiveWellbore } from "../../store";
import { stringAscendingSort } from "../../utils/sorting";
import {
  ContextualMenuSelectionMode,
  getContextualMenuItems,
} from "../styledFluentComponents";
import { SidebarDropdown } from "./SidebarDropdown";

export const INDEX_OF_IMAGE_A_BACKDROP = 0;
export const INDEX_OF_IMAGE_B_BACKDROP = 1;

interface BackdropSelectorProps {
  selectedBackdrops: (string | undefined)[];
  setSelectedBackdrops: React.Dispatch<
    React.SetStateAction<(string | undefined)[]>
  >;
}

export const BackdropSelector: FC<BackdropSelectorProps> = ({
  selectedBackdrops,
  setSelectedBackdrops,
}) => {
  const activeWellbore = useSelector(selectActiveWellbore);
  const onItemClick = (e: unknown, item: IContextualMenuItem | undefined) => {
    setSelectedBackdrops((state) => {
      const modifiableState = state.slice(INDEX_OF_IMAGE_B_BACKDROP + 1);
      if (item?.data?.type) {
        const indexToRemove = modifiableState.indexOf(item.data.type);
        if (indexToRemove !== -1) {
          modifiableState.splice(indexToRemove, 1);
        } else {
          modifiableState.push(item.data.type);
        }
      }

      return [
        state[INDEX_OF_IMAGE_A_BACKDROP],
        state[INDEX_OF_IMAGE_B_BACKDROP],
        ...modifiableState,
      ];
    });
  };

  const items: IContextualMenuItem[] = useMemo(
    () =>
      getContextualMenuItems(
        "Image data",
        activeWellbore?.backdrops
          ? activeWellbore.backdrops
              ?.slice()
              .sort((a, b) => stringAscendingSort(a.name, b.name))
              .map((backdrop) => {
                const itemIsImageABackdrop =
                  selectedBackdrops[INDEX_OF_IMAGE_A_BACKDROP] ===
                  backdrop.type;
                const itemIsImageBBackdrop =
                  selectedBackdrops[INDEX_OF_IMAGE_B_BACKDROP] ===
                  backdrop.type;

                return {
                  key: backdrop.type,
                  text: backdrop.name,
                  canCheck: true,
                  checked: selectedBackdrops.includes(backdrop.type),
                  disabled: itemIsImageABackdrop || itemIsImageBBackdrop,
                  data: backdrop,
                };
              })
          : []
      ),
    [selectedBackdrops, activeWellbore]
  );

  return (
    <SidebarDropdown
      onItemClick={onItemClick}
      items={items}
      dropDownName={DropdownName.BACKDROP}
      buttonAriaLabel="Select backdrops"
      buttonLabel="B"
      selectionMode={ContextualMenuSelectionMode.MULTIPLE}
    />
  );
};
