import {
  Depths,
  IIconProps,
  IStackStyles,
  NeutralColors,
  Stack,
} from "@fluentui/react";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../../constants";
import { selectSelectedWellbore, selectWellbores } from "../../../store";
import { Wellbore } from "../../../types";
import { naturalOrderStringAscendingSort } from "../../../utils/sorting";
import { UI_SPRING_CONFIG } from "../../stage/config";
import { IconButton } from "../../styledFluentComponents";
import { passesFilter } from "../common";

const prevIconProps: IIconProps = { iconName: "ChevronLeft" };
const nextIconProps: IIconProps = { iconName: "ChevronRight" };
const closeIconProps: IIconProps = { iconName: "Cancel" };

export interface WellboreTraversalControllerProps {
  selectedWellboreFilters: AnalyticsName[];
  zoomToWellbore: (wellbore: Wellbore) => void;
}

export const WellboreTraversalControllerImpl: FC<WellboreTraversalControllerProps> = ({
  selectedWellboreFilters,
  zoomToWellbore,
}) => {
  const wellbores = useSelector(selectWellbores);
  const selectedWellbore = useSelector(selectSelectedWellbore);

  const [isOpen, setIsOpen] = useState(true);

  const filteredWellbores = useMemo(
    () =>
      wellbores
        .filter((w) => passesFilter(w, selectedWellboreFilters))
        .sort((a, b) => naturalOrderStringAscendingSort(a.name, b.name)),
    [selectedWellboreFilters, wellbores]
  );

  const text = `Displaying ${filteredWellbores.length} wellbore${
    filteredWellbores.length !== 0 ? "s" : ""
  }`;

  const selectedIndex = filteredWellbores.findIndex(
    (w) => w.id === selectedWellbore?.id
  );
  const selectedWellboreIsAmongFilteredWellbores = selectedIndex !== -1;
  const firstIndex = 0;
  const lastIndex = filteredWellbores.length - 1;

  useEffect(() => {
    setIsOpen(true);
  }, [selectedWellboreFilters]);

  return (
    <Stack
      horizontal
      className="wellbore-traversal-controller"
      styles={getStackStyles(isOpen)}
    >
      <span className="wellbore-traversal-controller__text">{text}</span>
      <span className="wellbore-traversal-controller__ratio">
        {selectedIndex > -1 ? selectedIndex + 1 : "-"} /
        {filteredWellbores.length}
      </span>
      <span className="wellbore-traversal-controller__border"></span>
      <IconButton
        aria-label="Go to previous wellbore"
        title="Previous wellbore"
        iconProps={prevIconProps}
        disabled={selectedIndex === firstIndex}
        onClick={() => {
          const prevWellboreIndex = selectedWellboreIsAmongFilteredWellbores
            ? Math.max(firstIndex, selectedIndex - 1)
            : lastIndex;

          zoomToWellbore(filteredWellbores[prevWellboreIndex]);
        }}
      />
      <IconButton
        aria-label="Go to next wellbore"
        title="Next wellbore"
        iconProps={nextIconProps}
        styles={{ root: { marginRight: 8 } }}
        disabled={selectedIndex === lastIndex}
        onClick={() => {
          const nextWellboreIndex = selectedWellboreIsAmongFilteredWellbores
            ? Math.min(lastIndex, selectedIndex + 1)
            : firstIndex;

          zoomToWellbore(filteredWellbores[nextWellboreIndex]);
        }}
      />
      <IconButton
        aria-label="Close controls"
        iconProps={closeIconProps}
        onClick={() => setIsOpen(false)}
      />
    </Stack>
  );
};

export const WellboreTraversalController = React.memo(
  WellboreTraversalControllerImpl
);

const getStackStyles = (isOpen: boolean): IStackStyles => {
  return {
    root: {
      position: "absolute",
      transition: `opacity ${UI_SPRING_CONFIG.duration}ms linear`,
      height: 48,
      alignItems: "center",
      bottom: 48,
      left: "50%",
      transform: "translateX(-50%)",
      paddingRight: "12px",
      paddingLeft: "16px",
      background: NeutralColors.gray30,
      borderRadius: 2,
      boxShadow: Depths.depth8,
      opacity: isOpen ? 1 : 0,
      pointerEvents: isOpen ? "all" : "none",
    },
  };
};
