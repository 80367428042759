import * as d3 from "d3";
import firebase from "firebase/app";
import "firebase/firestore";
import filter from "lodash/filter";
import { wellboreContentIconReferences } from "../../../constants";
import {
  MapArea,
  Wellbore,
  WellboreContent,
  WellborePosition,
} from "../../../types";
import { MIN_ZOOM, QUAD_AND_BLOCK_BREAKPOINT } from "./MapView";

type GP = firebase.firestore.GeoPoint;

export function centerMapOnXY(
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, // eslint-disable-line
  zoom: d3.ZoomBehavior<Element, unknown>,
  xy: [number, number] | null
): void {
  if (xy) {
    const [x, y] = xy;
    zoom.translateTo(svg as any, x, y); // eslint-disable-line
  }
}

export function zoomTo(
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, // eslint-disable-line
  zoom: d3.ZoomBehavior<Element, unknown>,
  scale: number
): void {
  svg.call(zoom.scaleBy as any, scale); // eslint-disable-line
}

export function getContainerSelections(): {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>; // eslint-disable-line
  landContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, any>; // eslint-disable-line
  quadContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, any>; // eslint-disable-line
  blockContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, any>; // eslint-disable-line
  fieldsContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, any>; // eslint-disable-line
  nonRwiWellboreContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, any>; // eslint-disable-line
  wellboreContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, any>; // eslint-disable-line
} {
  const svg = d3.select("#map-view");
  return {
    svg: svg,
    landContainer: svg.select("#land-container"),
    quadContainer: svg.select("#quad-container"),
    blockContainer: svg.select("#block-container"),
    fieldsContainer: svg.select("#fields-container"),
    nonRwiWellboreContainer: svg.select("#non-rwi-wellbore-container"),
    wellboreContainer: svg.select("#wellbore-container"),
  };
}

export function getVisibleArea(
  transform: any, // eslint-disable-line
  projection: d3.GeoProjection,
  svg: SVGElement
): MapArea {
  const topLeft = transform.invert([-150, -150]);
  const rect = svg.getBoundingClientRect();
  const bottomRight = transform.invert([rect.width + 150, rect.height + 150]);

  const topLeftCoordinates = projection.invert?.(topLeft) as [number, number];
  const bottomRightCoordinates = projection.invert?.(bottomRight) as [
    number,
    number
  ];

  return {
    x: [topLeft[0], bottomRight[0]],
    y: [topLeft[1], bottomRight[1]],
    xCoord: [topLeftCoordinates[0], bottomRightCoordinates[0]],
    yCoord: [topLeftCoordinates[1], bottomRightCoordinates[1]],
    transform: transform,
  };
}

export const getZoomHandler = (
  setVisibleArea: (visibleArea: MapArea) => void,
  projection: d3.GeoProjection
) => (
  event: any // eslint-disable-line
): void => {
  const currentZoom = event.transform.k;

  const s = getContainerSelections();

  s.landContainer.attr("transform", event.transform);
  s.wellboreContainer.attr("transform", event.transform);
  s.nonRwiWellboreContainer.attr("transform", event.transform);

  if (MIN_ZOOM <= currentZoom && currentZoom <= QUAD_AND_BLOCK_BREAKPOINT) {
    s.blockContainer.attr("display", "none");
    s.fieldsContainer.attr("display", "none");
    s.quadContainer
      .attr("display", "inline")
      .attr("transform", event.transform);
  } else if (QUAD_AND_BLOCK_BREAKPOINT < currentZoom) {
    s.quadContainer.attr("display", "none");
    s.blockContainer
      .attr("display", "inline")
      .attr("transform", event.transform);
    s.fieldsContainer
      .attr("display", "inline")
      .attr("transform", event.transform);
  }

  const svgNode = (s.svg.node() as unknown) as SVGElement;
  setVisibleArea(getVisibleArea(event.transform, projection, svgNode));
};

export function filterWellboresWithCoordinates(
  wellbores: Wellbore[]
): Wellbore[] {
  return wellbores.filter((w) => {
    return w.coordinates;
  });
}

export function getVisibleWellbores<T extends WellborePosition>(
  wellbores: T[],
  visibleArea: MapArea,
  normalizedZoom: number,
  showWellboresBreakPoint: number
): T[] {
  const dontRenderAnyWellbores = normalizedZoom < showWellboresBreakPoint;
  if (dontRenderAnyWellbores) {
    return [];
  }

  const visibleWellbores = filter(wellbores, (w) => {
    const withinVerticalBounds =
      visibleArea.yCoord[1] <= (w.coordinates as GP).latitude &&
      (w.coordinates as GP).latitude <= visibleArea.yCoord[0];

    if (!withinVerticalBounds) return false;

    const isWithinHorizontalBounds =
      visibleArea.xCoord[0] <= (w.coordinates as GP).longitude &&
      (w.coordinates as GP).longitude <= visibleArea.xCoord[1];

    if (!isWithinHorizontalBounds) return false;

    return true;
  });

  return visibleWellbores;
}

export function getWellboreIconUrl(wellbore: Wellbore): string {
  if (wellbore.metadata.content) {
    return wellboreContentIconReferences[wellbore.metadata.content];
  }
  return wellboreContentIconReferences[WellboreContent.NOT_APPLICABLE];
}
