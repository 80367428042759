/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from "d3";
import { PlotData, StratigraphyChangesData } from "../../../../types";
import { PLOTTER_MARGIN, Scales } from "../DataPlotter";
import { ChartElements } from "./common-drawing";

const FM_TEXT_MARGIN = 10;
const FM_TEXT_TOP_OFFSET = -5;
const FM_TEXT_BOTTOM_OFFSET = 13;

export function drawFormationTopChanges(
  chartElements: ChartElements,
  scales: Scales,
  graphWidth: number,
  data: PlotData[]
): void {
  function addLinePositionAttributes(line: any) {
    line
      .attr("class", "formation-line")
      .attr("x1", PLOTTER_MARGIN.left)
      .attr("y1", (d: StratigraphyChangesData) => getFormationYPos(scales.y, d))
      .attr("x2", graphWidth)
      .attr("y2", (d: StratigraphyChangesData) => getFormationYPos(scales.y, d))
      .style("stroke-dasharray", "5,5");
  }

  function addTextTopPositionAttributes(text: any) {
    text
      .attr("class", "formation-text formation-text-top")
      .attr("x", graphWidth - FM_TEXT_MARGIN)
      .attr(
        "y",
        (d: StratigraphyChangesData) =>
          getFormationYPos(scales.y, d) + FM_TEXT_TOP_OFFSET
      )
      .attr("text-anchor", "end")
      .text(
        (d: StratigraphyChangesData) =>
          d.prevStratigraphy?.formation.name ?? "Undefined"
      );
  }

  function addTextBottomPositionAttributes(text: any) {
    text
      .attr("class", "formation-text formation-text-bottom")
      .attr("x", graphWidth - FM_TEXT_MARGIN)
      .attr(
        "y",
        (d: StratigraphyChangesData) =>
          getFormationYPos(scales.y, d) + FM_TEXT_BOTTOM_OFFSET
      )
      .attr("text-anchor", "end")
      .text(
        (d: StratigraphyChangesData) =>
          d.stratigraphy?.formation.name ?? "Undefined"
      );
  }
  const stratigraphyChangesData = createStratigraphyChangesData(data);

  chartElements.formation
    .selectAll(".formation-line")
    .data(stratigraphyChangesData)
    .join(
      (enter) => enter.append("line").call(addLinePositionAttributes),
      (update) => update.call(addLinePositionAttributes),
      (exit) => exit.remove()
    );
  chartElements.formation
    .selectAll(".formation-text-top")
    .data(stratigraphyChangesData)
    .join(
      (enter) => enter.append("text").call(addTextTopPositionAttributes),
      (update) => update.call(addTextTopPositionAttributes),
      (exit) => exit.remove()
    );
  chartElements.formation
    .selectAll(".formation-text-bottom")
    .data(stratigraphyChangesData)
    .join(
      (enter) => enter.append("text").call(addTextBottomPositionAttributes),
      (update) => update.call(addTextBottomPositionAttributes),
      (exit) => exit.remove()
    );
}

const getFormationYPos = (
  yScale: d3.ScalePoint<string>,
  data: StratigraphyChangesData
) => {
  const currentYPos = yScale(String(data.depth)) as number;
  const prevDepthYPos = yScale(String(data.prevDepth)) as number;
  return (currentYPos + prevDepthYPos) / 2;
};

const createStratigraphyChangesData = (
  data: PlotData[]
): StratigraphyChangesData[] => {
  const stratigraphyChangesData: StratigraphyChangesData[] = [];
  for (const [index, dataForDepth] of data.entries()) {
    if (index === 0) {
      continue;
    }
    const prevData = data[index - 1];
    if (
      dataForDepth.stratigraphy?.formation.name !==
      prevData.stratigraphy?.formation.name
    ) {
      stratigraphyChangesData.push({
        prevDepth: prevData.depth,
        depth: dataForDepth.depth,
        stratigraphy: dataForDepth.stratigraphy,
        prevStratigraphy: prevData.stratigraphy,
      });
    }
  }

  return stratigraphyChangesData;
};
