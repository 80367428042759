import { NeutralColors } from "@fluentui/theme";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useResizeObserver } from "../../hooks";
import { selectSelectedCutting, selectSelectedImageType } from "../../store";
import { Domain, ScaleFactor } from "../../types/types";
import { StageFrameSVG } from "../frames/frames";
import { SCALE_BAR_MARGIN } from "../stage/config";
import { ScaleBar } from "./ScaleBar";

interface ScaleBarsProps {
  xDomain: Domain | undefined;
  yDomain: Domain | undefined;
  show?: boolean;
}

export const ScaleBars: FC<ScaleBarsProps> = ({ xDomain, yDomain, show }) => {
  const selectedCutting = useSelector(selectSelectedCutting);
  const selectedImageType = useSelector(selectSelectedImageType);
  const [scalebarsAreActive, setScalebarsAreActive] = useState<boolean>();
  const [scaleFactor, setScaleFactor] = useState<ScaleFactor>();
  const [size, svgRef] = useResizeObserver();

  useEffect(() => {
    const imageReference = selectedCutting?.imageReferences.find(
      (r) => r.type === selectedImageType
    );
    // Scalebar should only be active if dimensions on image is set
    if (imageReference?.dimension) {
      setScaleFactor({
        horizontal:
          imageReference?.dimension?.width / imageReference.resolutionWidth,
        vertical:
          imageReference?.dimension?.height / imageReference.resolutionHeight,
      });

      setScalebarsAreActive(true);
    } else {
      setScalebarsAreActive(false);
    }
  }, [selectedCutting, selectedImageType]);

  if (
    xDomain &&
    yDomain &&
    scalebarsAreActive &&
    scaleFactor?.horizontal &&
    scaleFactor?.vertical
  ) {
    let unitOfMeasurement;
    if (xDomain && yDomain && scaleFactor) {
      const domainInCm = {
        min: xDomain.min * scaleFactor.horizontal,
        max: xDomain.max * scaleFactor.horizontal,
      };
      unitOfMeasurement = domainInCm.max - domainInCm.min <= 1 ? "MM" : "CM";
    } else {
      unitOfMeasurement = "CM";
    }

    return (
      <StageFrameSVG className="scalebars" show={show} ref={svgRef}>
        {size && (
          <>
            <ScaleBar
              width={size.width}
              height={size.height}
              position={"top"}
              scalingFactor={scaleFactor.horizontal}
              domain={xDomain}
              margin={SCALE_BAR_MARGIN}
            />
            <ScaleBar
              width={size.width}
              height={size.height}
              position={"bottom"}
              scalingFactor={scaleFactor.horizontal}
              domain={xDomain}
              margin={SCALE_BAR_MARGIN}
            />
            <ScaleBar
              width={size.width}
              height={size.height}
              position={"left"}
              scalingFactor={scaleFactor.vertical}
              domain={yDomain}
              margin={SCALE_BAR_MARGIN}
            />
            <rect
              fill="white"
              stroke={NeutralColors.gray160}
              strokeDasharray={`${
                SCALE_BAR_MARGIN.top
              },${-SCALE_BAR_MARGIN.left}`}
              width={SCALE_BAR_MARGIN.left}
              height={SCALE_BAR_MARGIN.top}
            ></rect>
            <text
              className="unit-of-measurement"
              x={SCALE_BAR_MARGIN.left / 2}
              y={SCALE_BAR_MARGIN.top - 5}
            >
              {unitOfMeasurement}
            </text>
            <rect
              fill="white"
              stroke={NeutralColors.gray160}
              strokeDasharray={`${
                SCALE_BAR_MARGIN.top + SCALE_BAR_MARGIN.right
              },0`}
              y={size.height - SCALE_BAR_MARGIN.bottom}
              width={SCALE_BAR_MARGIN.left}
              height={SCALE_BAR_MARGIN.bottom}
            ></rect>
          </>
        )}
      </StageFrameSVG>
    );
  }

  return null;
};
