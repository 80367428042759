import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { setIndexDiff, setRefIndex } from "./appSlice";
import { selectActiveWellbore } from "./selectors";
import { StoreState, storeState$, useAppDispatch } from "./store";

const UPDATE_REF_INDEX_KEY = "update-ref-index";
const INDEX_DIFF_KEY = "index-diff";

export function useSyncStateBetweenWindows(): void {
  const dispatch = useAppDispatch();
  const activeWellbore = useSelector(selectActiveWellbore);

  useEffect(() => {
    const updateRefIndexStorageObservable = getStorageItem$(
      UPDATE_REF_INDEX_KEY
    );
    const indexDiffStorageObservable = getStorageItem$(INDEX_DIFF_KEY);

    let innerSubscriptions: Subscription[] = [];

    const subscription = storeState$
      .pipe(
        map((state: StoreState) => state.app.syncSelectedCutting),
        distinctUntilChanged()
      )
      .subscribe((syncSelectedCutting) => {
        innerSubscriptions.forEach((s) => s.unsubscribe());

        if (syncSelectedCutting) {
          // Set new reference index when other tabs link up and set their reference index
          const refIndexStorageSubscription = updateRefIndexStorageObservable.subscribe(
            () => {
              dispatch(setRefIndex());
            }
          );

          // Update index diff when index diff of other tabs change
          const indexDiffStorageSubscription = indexDiffStorageObservable.subscribe(
            (indexDiffStr) => {
              try {
                const indexDiff = parseInt(indexDiffStr, 10);
                dispatch(setIndexDiff(indexDiff));
              } catch (e) {
                console.error(
                  "Failed to parse and dispatch index difference from local storage. Error: ",
                  e
                );
              }
            }
          );

          // Update other tabs when refIndex changes (when changing wellbore for instance)
          const refIndexStoreSubscription = storeState$
            .pipe(
              map((state: StoreState) => state.app.refIndex),
              distinctUntilChanged()
            )
            .subscribe((refIndex) => {
              if (refIndex !== undefined) {
                localStorage.setItem(UPDATE_REF_INDEX_KEY, uuidv4());
              }
            });

          // Update other tabs when indexDiff changes
          const indexDiffStoreSubscription = storeState$
            .pipe(
              map((state: StoreState) => state.app.indexDiff),
              distinctUntilChanged()
            )
            .subscribe((indexDiff) => {
              if (indexDiff !== undefined) {
                localStorage.setItem(INDEX_DIFF_KEY, String(indexDiff));
              }
            });

          innerSubscriptions = [
            refIndexStorageSubscription,
            indexDiffStorageSubscription,
            refIndexStoreSubscription,
            indexDiffStoreSubscription,
          ];

          // Tell other tabs to udpate their referance index
          dispatch(setRefIndex());
        } else {
          innerSubscriptions = [];
        }
      });

    return () => {
      innerSubscriptions.forEach((s) => s.unsubscribe());
      subscription.unsubscribe();
    };
  }, [dispatch, activeWellbore]);
}

function getStorageItem$(storageKey: string) {
  return new Observable<string>((observer) => {
    const storageChangeHandler = (e: StorageEvent) => {
      const newValue = localStorage.getItem(storageKey);
      if (newValue !== null) {
        observer.next(newValue);
      }
    };
    window.addEventListener("storage", storageChangeHandler);

    const unsubscribe = () => {
      window.removeEventListener("storage", storageChangeHandler);
    };
    return unsubscribe;
  }).pipe(distinctUntilChanged());
}
