import React, { FC } from "react";
import { ReactEventHandlers } from "react-use-gesture/dist/types";

export const ResizeThumb: FC<ReactEventHandlers> = (props) => {
  return (
    <div className="resize-thumb" {...props}>
      <svg
        width="12"
        height="10"
        viewBox="0 0 6 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 0.25H5.25V1.75H3.75V0.25ZM3.75 4.75V3.25H5.25V4.75H3.75ZM3.75 7.75V6.25H5.25V7.75H3.75ZM0.75 1.75V0.25H2.25V1.75H0.75ZM0.75 4.75V3.25H2.25V4.75H0.75ZM0.75 7.75V6.25H2.25V7.75H0.75Z"
          fill="#605E5C"
        />
      </svg>
    </div>
  );
};
