import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as d3 from "d3";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { AnalyticsName, ChartType } from "../../constants";
import { WELL_TOPS_URL } from "../../environment";
import {
  initialUrlPlotStates,
  initialUrlSelections,
  initialUrlTab,
} from "../../store/initialStateFromUrl";
import { AppDispatch, StoreState } from "../../store/store";
import {
  AnalyticsDataKey,
  Domain,
  Optional,
  PlotState,
  ProcessedTops,
  ScatterPlotData,
  StackedBarPlotData,
  StoredSelection,
  StructuredData,
  TopGroup,
} from "../../types";
import { arraysHaveSameElements, roundUp } from "../../utils";
import { DEFAULT_CHART_TYPE, getChartTypeToPlot, UNITS } from "./config";
import {
  getScatterPlotData,
  getStackedBarPlotData,
} from "./utils/plot-helpers";

export type PlotUpdate = {
  selection: string[];
  data: StructuredData | undefined;
};

export type AnalyticsState = {
  overlayAnalytics: boolean;
  selectedTab: Optional<AnalyticsName>;
  selectedPlot: Optional<string>;
  plots: PlotState[];
  tabSelections: Record<AnalyticsName, StoredSelection>;
  srcData: Record<AnalyticsDataKey, Optional<StructuredData>>;
};

export const MIN_NUM_PLOTS = 1;
export const MAX_NUM_PLOTS = 3;

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    overlayAnalytics: true,
    selectedTab: initialUrlTab,
    selectedPlot: initialUrlPlotStates.find((s) => s.tab === initialUrlTab)?.id,
    plots: initialUrlPlotStates,
    tabSelections: {
      [AnalyticsName.XRF]: initialUrlSelections[AnalyticsName.XRF],
      [AnalyticsName.XRD]: initialUrlSelections[AnalyticsName.XRD],
      [AnalyticsName.TOC]: initialUrlSelections[AnalyticsName.TOC],
      [AnalyticsName.SPEC_CAM]: initialUrlSelections[AnalyticsName.SPEC_CAM],
      [AnalyticsName.QEM_SCAN]: initialUrlSelections[AnalyticsName.QEM_SCAN],
    },
    srcData: {
      xrfSampleInformation: undefined,
      qemscanSampleInformation: undefined,
      [AnalyticsName.XRF]: undefined,
      [AnalyticsName.XRD]: undefined,
      [AnalyticsName.TOC]: undefined,
      [AnalyticsName.SPEC_CAM]: undefined,
      [AnalyticsName.QEM_SCAN]: undefined,
    },
  } as AnalyticsState,
  reducers: {
    toggleOverlayAnalytics: (state) => {
      state.overlayAnalytics = !state.overlayAnalytics;
    },

    /**
     * Sets the tab as specified in the payload, but protect against closing the analytics pane when not in overlay mode.
     */
    setSelectedTab: (state, action: PayloadAction<Optional<AnalyticsName>>) => {
      const prevTab = state.selectedTab;
      if (state.overlayAnalytics) {
        state.selectedTab = action.payload;
      } else if (action.payload !== undefined) {
        state.selectedTab = action.payload;
      }

      if (shouldInitiatePlots(state)) {
        const newPlot = getNewPlot(
          state,
          DEFAULT_CHART_TYPE,
          state.selectedTab as AnalyticsName,
          state.selectedTab && initialUrlSelections[state.selectedTab]
        );
        state.plots = [newPlot];
        state.selectedPlot = newPlot.id;
      } else if (shouldUpdatePlots(prevTab, state)) {
        state.plots = getUpdatedPlotsForTabChange(
          state,
          state.selectedTab as AnalyticsName
        );
      }
    },

    /**
     * Toggle the tab specified in the payload, but protect against closing the analytics pane when not in overlay mode.
     */
    toggleSelectedTab: (
      state,
      action: PayloadAction<Optional<AnalyticsName>>
    ) => {
      const prevTab = state.selectedTab;
      if (action.payload) {
        const canCloseAnalyticsPane = state.overlayAnalytics;
        if (action.payload === state.selectedTab && canCloseAnalyticsPane) {
          state.selectedTab = undefined;
        } else {
          state.selectedTab = action.payload;
        }

        if (shouldInitiatePlots(state)) {
          const newPlot = getNewPlot(
            state,
            DEFAULT_CHART_TYPE,
            state.selectedTab as AnalyticsName,
            state.selectedTab && initialUrlSelections[state.selectedTab]
          );
          state.plots = [newPlot];
          state.selectedPlot = newPlot.id;
        } else if (shouldUpdatePlots(prevTab, state)) {
          state.plots = getUpdatedPlotsForTabChange(
            state,
            state.selectedTab as AnalyticsName
          );
        }
      }
    },

    toggleAnalyticsPane: (state) => {
      const prevTab = state.selectedTab;
      if (state.selectedTab && state.overlayAnalytics) {
        state.selectedTab = undefined;
      } else {
        const tabOfSelectedPlot = state.plots.find(
          (s) => s.id === state.selectedPlot
        )?.tab;
        state.selectedTab = tabOfSelectedPlot ?? AnalyticsName.XRF;

        if (shouldInitiatePlots(state)) {
          const newPlot = getNewPlot(
            state,
            DEFAULT_CHART_TYPE,
            state.selectedTab as AnalyticsName,
            state.selectedTab && initialUrlSelections[state.selectedTab]
          );
          state.plots = [newPlot];
          state.selectedPlot = newPlot.id;
        } else if (shouldUpdatePlots(prevTab, state)) {
          state.plots = getUpdatedPlotsForTabChange(
            state,
            state.selectedTab as AnalyticsName
          );
        }
      }
    },

    openTabIfClosed: (
      state,
      action: PayloadAction<Optional<AnalyticsName>>
    ) => {
      const prevTab = state.selectedTab;
      if (state.selectedTab === undefined) {
        state.selectedTab = action.payload ?? AnalyticsName.XRF;

        if (shouldInitiatePlots(state)) {
          const newPlot = getNewPlot(
            state,
            DEFAULT_CHART_TYPE,
            state.selectedTab as AnalyticsName,
            state.selectedTab && initialUrlSelections[state.selectedTab]
          );
          state.plots = [newPlot];
          state.selectedPlot = newPlot.id;
        } else if (shouldUpdatePlots(prevTab, state)) {
          state.plots = getUpdatedPlotsForTabChange(
            state,
            state.selectedTab as AnalyticsName
          );
        }
      }
    },

    setData: (
      state,
      action: PayloadAction<{
        key: AnalyticsDataKey;
        data: Optional<StructuredData>;
      }>
    ) => {
      state.srcData[action.payload.key] = action.payload.data;

      const hasPlotNeedingNewData = state.plots.find(
        (s) => s.tab === action.payload.key
      );
      if (hasPlotNeedingNewData) {
        state.plots = state.plots.map((s) => {
          if (s.tab === action.payload.key) {
            const chartTypeToPlot = getChartTypeToPlot(
              s.selectedChartType,
              action.payload.key
            );
            const analyticsData = state.srcData[action.payload.key];
            const selectedElements = [
              ...s.selectedElements.elements,
              ...s.selectedElements.groups,
            ];

            const plotData =
              chartTypeToPlot === ChartType.SCATTER
                ? getScatterPlotData(analyticsData, selectedElements)
                : getStackedBarPlotData(analyticsData, selectedElements);

            const maxValue = getDomainMax(plotData, chartTypeToPlot);

            return {
              ...s,
              chartTypeToPlot: chartTypeToPlot,
              data: plotData,
              units: UNITS[action.payload.key],
              domain: maxValue ? { min: 0, max: maxValue } : undefined,
            };
          }
          return s;
        });
      }
    },

    addPlot: (state) => {
      const srcData = state.selectedTab && state.srcData[state.selectedTab];
      if (state.selectedTab && srcData && state.plots.length < MAX_NUM_PLOTS) {
        const newPlot = getNewPlot(
          state,
          DEFAULT_CHART_TYPE,
          state.selectedTab
        );

        state.selectedPlot = newPlot.id;
        state.plots.push(newPlot);
        state.tabSelections[state.selectedTab] = newPlot.selectedElements;
      }
    },

    removePlot: (state, action: PayloadAction<string>) => {
      if (state.plots.length > MIN_NUM_PLOTS) {
        const filteredPlots = state.plots.filter(
          (s) => s.id !== action.payload
        );
        const removedSelectedPlot = state.selectedPlot === action.payload;
        const selectedPlot = removedSelectedPlot
          ? filteredPlots[0]
          : filteredPlots.find((s) => s.id === state.selectedPlot);

        if (selectedPlot) {
          state.selectedPlot = selectedPlot.id;
          state.selectedTab = selectedPlot.tab;
          state.tabSelections[state.selectedTab] =
            selectedPlot.selectedElements;
          state.plots = filteredPlots;
        }
      }
    },

    changeChartType: (
      state,
      action: PayloadAction<{ chartType: ChartType }>
    ) => {
      if (state.selectedTab) {
        state.plots = state.plots.map((s) => {
          if (s.id === state.selectedPlot) {
            const selectedChartType =
              s.selectedChartType === action.payload.chartType
                ? ChartType.NONE
                : action.payload.chartType;
            const currentlyDrawnPlotType = s.chartTypeToPlot;
            const chartTypeToPlot = getChartTypeToPlot(
              selectedChartType,
              s.tab
            );
            const shouldUpdateChartData =
              chartTypeToPlot !== currentlyDrawnPlotType;
            const srcData = state.srcData[s.tab];

            s.selectedChartType = selectedChartType;
            s.chartTypeToPlot = chartTypeToPlot;
            if (shouldUpdateChartData && srcData) {
              s.data = getPlotData(
                chartTypeToPlot,
                srcData,
                s.selectedElements
              );
              s.units = UNITS[s.tab];
            }
          }
          return s;
        });
      }
    },

    setSelectedPlot: (state, action: PayloadAction<string>) => {
      state.selectedPlot = action.payload;
      const selectedPlot = state.plots.find((p) => p.id === action.payload);
      if (selectedPlot) {
        state.selectedTab = selectedPlot.tab;
        state.tabSelections[state.selectedTab] = selectedPlot.selectedElements;
      }
    },

    changeSelection: (
      state,
      action: PayloadAction<{
        tab: AnalyticsName;
        storedSelection: StoredSelection;
      }>
    ) => {
      if (
        !storedSelectionsAreEqual(
          state.tabSelections[action.payload.tab],
          action.payload.storedSelection
        )
      ) {
        state.tabSelections[action.payload.tab] =
          action.payload.storedSelection;
      }

      state.plots = state.plots.map((s) => {
        const srcData = state.srcData[s.tab];

        const shouldUpdatePlot =
          s.id === state.selectedPlot &&
          !storedSelectionsAreEqual(
            s.selectedElements,
            action.payload.storedSelection
          ) &&
          s.tab === action.payload.tab;

        if (shouldUpdatePlot && srcData) {
          const chartTypeToPlot = getChartTypeToPlot(
            s.selectedChartType,
            s.tab
          );
          const plotData = getPlotData(
            chartTypeToPlot,
            srcData,
            action.payload.storedSelection
          );
          const maxValue = getDomainMax(plotData, chartTypeToPlot);

          s.chartTypeToPlot = chartTypeToPlot;
          s.data = plotData;
          s.units = UNITS[s.tab];
          s.domain = maxValue ? { min: 0, max: maxValue } : undefined;
          s.selectedElements = action.payload.storedSelection;
        }
        return s;
      });
    },

    setDomain: (
      state,
      action: PayloadAction<{ plotId: string; domain: Domain }>
    ) => {
      state.plots = state.plots.map((s) => {
        if (s.id === action.payload.plotId) {
          s.domain = action.payload.domain;
        }
        return s;
      });
    },

    clearAllSelections: (state) => {
      for (const tab of Object.values(AnalyticsName)) {
        state.tabSelections[tab] = getEmptySelection();
      }

      state.plots = state.plots.map((s) => {
        s.selectedElements = getEmptySelection();
        s.data = [];
        s.domain = undefined;
        return s;
      });
    },
  },
});

export const analyticsReducer = analyticsSlice.reducer;
export const {
  toggleOverlayAnalytics,
  setSelectedTab,
  toggleSelectedTab,
  toggleAnalyticsPane,
  openTabIfClosed,
  addPlot,
  removePlot,
  changeChartType,
  setSelectedPlot,
  changeSelection,
  setDomain,
  clearAllSelections,
} = analyticsSlice.actions;

/**
 * Thunks
 */
export function fetchAnalyticsData<T>(
  dataKey: AnalyticsDataKey,
  url: Optional<string>,
  transformer: (data: T, wellTops: TopGroup[]) => Optional<StructuredData>
) {
  return function (dispatch: AppDispatch, getState: () => StoreState): void {
    const state = getState();

    if (url) {
      Promise.all([
        fetch(url).then((res) => res.json() as Promise<T>),
        new Promise<ProcessedTops>((resolve, reject) => {
          if (state.app.wellTopsData) {
            resolve(state.app.wellTopsData);
          } else {
            fetch(WELL_TOPS_URL)
              .then((res) => res.json())
              .then((json) => resolve(json))
              .catch((e) => reject(e));
          }
        }),
      ])
        .then(([data, wellTops]) => {
          if (state.app.activeWellbore) {
            dispatch(
              analyticsSlice.actions.setData({
                key: dataKey,
                data: transformer(
                  data,
                  wellTops[state.app.activeWellbore.name]
                ),
              })
            );
          } else {
            throw Error(
              "Cannot transform data in fetchAnalyticsData-thunk due to activeWellbore being undefined"
            );
          }
        })
        .catch((e) => {
          dispatch(
            analyticsSlice.actions.setData({ key: dataKey, data: undefined })
          );
        });
    } else {
      dispatch(
        analyticsSlice.actions.setData({ key: dataKey, data: undefined })
      );
    }
  };
}

/**
 * Helpers
 */
function shouldInitiatePlots(state: AnalyticsState) {
  if (state.plots.length === 0 && state.selectedTab) {
    return true;
  }
  return false;
}

function shouldUpdatePlots(
  prevTab: Optional<AnalyticsName>,
  state: AnalyticsState
): boolean {
  const nextTab = state.selectedTab;
  const tabHasChanged = prevTab !== nextTab;
  const selectedPlot = state.plots.find((p) => p.id === state.selectedPlot);

  if (
    selectedPlot &&
    nextTab &&
    tabHasChanged &&
    selectedPlot.tab !== nextTab
  ) {
    return true;
  }
  return false;
}

function storedSelectionsAreEqual(
  s1: StoredSelection,
  s2: StoredSelection
): boolean {
  if (
    arraysHaveSameElements(s1.elements, s2.elements) &&
    arraysHaveSameElements(s1.groups, s2.groups)
  ) {
    return true;
  }
  return false;
}

function getNewPlot(
  state: AnalyticsState,
  chartType: ChartType,
  tab: AnalyticsName,
  storedSelection: StoredSelection = { elements: [], groups: [] }
): PlotState {
  const chartTypeToPlot = getChartTypeToPlot(chartType, tab);
  const srcData = state.srcData[tab];
  const plotData = srcData
    ? getPlotData(chartTypeToPlot, srcData, storedSelection)
    : undefined;

  const maxValue = getDomainMax(plotData, chartTypeToPlot);

  return {
    id: uuidv4(),
    tab: tab,
    selectedChartType: chartType,
    chartTypeToPlot: chartTypeToPlot,
    data: plotData,
    units: UNITS[tab],
    domain: maxValue ? { min: 0, max: maxValue } : undefined,
    selectedElements: storedSelection,
  };
}

function getUpdatedPlotsForTabChange(
  state: AnalyticsState,
  newTab: AnalyticsName
): PlotState[] {
  return state.plots.map((s) => {
    if (s.id === state.selectedPlot && s.tab !== newTab) {
      const chartTypeToPlot = getChartTypeToPlot(s.selectedChartType, newTab);
      const analyticsData = state.srcData[newTab];
      const newStoredSelection = cloneDeep(state.tabSelections[newTab]);
      const selectedElements = [
        ...newStoredSelection.elements,
        ...newStoredSelection.groups,
      ];

      const plotData =
        chartTypeToPlot === ChartType.SCATTER
          ? getScatterPlotData(analyticsData, selectedElements)
          : getStackedBarPlotData(analyticsData, selectedElements);

      const maxValue = getDomainMax(plotData, chartTypeToPlot);

      return {
        ...s,
        tab: newTab,
        chartTypeToPlot: chartTypeToPlot,
        data: plotData,
        units: UNITS[newTab],
        domain: maxValue ? { min: 0, max: maxValue } : undefined,
        selectedElements: newStoredSelection,
      };
    }
    return s;
  });
}

function getPlotData(
  chartType: Exclude<ChartType, ChartType.NONE>,
  data: StructuredData,
  storedSelection: StoredSelection
): ScatterPlotData[] | StackedBarPlotData[] {
  const selectedElements = [
    ...storedSelection.elements,
    ...storedSelection.groups,
  ];
  if (chartType === ChartType.SCATTER) {
    return getScatterPlotData(data, selectedElements);
  }
  return getStackedBarPlotData(data, selectedElements);
}

export function getDomainMax(
  data: Optional<ScatterPlotData[] | StackedBarPlotData[]>,
  chartTypeToPlot: ChartType
): Optional<number> {
  let maxValue: Optional<number>;
  if (data && chartTypeToPlot === ChartType.SCATTER) {
    maxValue = d3.max(data as ScatterPlotData[], (d) => d.value);
  } else if (data && chartTypeToPlot === ChartType.STACKED_BAR) {
    maxValue = d3.max(data as StackedBarPlotData[], (d) =>
      Object.entries(d).reduce(
        (sum, [key, val]) =>
          key !== "depth" && typeof val === "number" ? sum + val : sum,
        0
      )
    );
  }
  if (maxValue) {
    return roundUp(maxValue, 1);
  }
}

function getEmptySelection(): StoredSelection {
  return {
    elements: [],
    groups: [],
  };
}
