import {
  DefaultButton as FluentDefaultButton,
  IButtonProps,
  IButtonStyles,
  mergeStyleSets,
} from "@fluentui/react";
import { Depths, NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../constants";

const activeState = {
  background: Colors.primary,
  color: NeutralColors.white,
};

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    background: Colors.primary,
    color: NeutralColors.white,
    border: "0",
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${NeutralColors.white} !important`,
      },
    },
  },
  rootHovered: {
    background: Colors.darkAlt,
    color: NeutralColors.white,
  },
  rootPressed: {
    background: Colors.dark,
    color: NeutralColors.white,
  },
  rootDisabled: {
    background: NeutralColors.gray50,
    color: NeutralColors.gray90,
  },
  labelDisabled: {
    color: NeutralColors.gray90,
  },
};

const grayButtonStyles: Partial<IButtonStyles> = {
  root: {
    background: NeutralColors.gray30,
    color: NeutralColors.gray160,
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
    },
  },
  rootHovered: {
    background: NeutralColors.gray40,
    color: NeutralColors.gray160,
  },
  rootPressed: {
    background: NeutralColors.gray50,
    color: NeutralColors.gray160,
  },
  rootDisabled: {
    background: NeutralColors.gray50,
    color: NeutralColors.gray90,
    border: `1px solid ${NeutralColors.gray50}`,
  },
  labelDisabled: {
    color: NeutralColors.gray90,
  },
};

export const navButtonStyles: Partial<IButtonStyles> = {
  root: {
    background: NeutralColors.gray30,
    color: NeutralColors.gray190,
    boxShadow: Depths.depth16,
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
    },
  },
  rootHovered: {
    background: NeutralColors.gray40,
    color: NeutralColors.gray160,
  },
  rootPressed: {
    ...activeState,
  },
  rootExpanded: {
    ...activeState,
  },
  rootChecked: {
    ...activeState,
  },
  rootCheckedHovered: {
    ...activeState,
  },
  flexContainer: {
    position: "relative",
  },
};

export const DefaultButton: FC<IButtonProps & { grayScale?: boolean }> = ({
  grayScale,
  styles,
  ...restProps
}) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(grayScale ? grayButtonStyles : buttonStyles, styles),
    [styles, grayScale]
  );
  return <FluentDefaultButton styles={mergedStyles} {...restProps} />;
};
