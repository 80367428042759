import { IPivotStyles, PivotItem } from "@fluentui/react";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AnalyticsName } from "../../constants";
import { selectSelectedWellbore } from "../../store";
import { analyticsNames } from "../../texts";
import { Pivot } from "../styledFluentComponents";

interface DataAnalyticsMenuProps {
  onChange: (item?: PivotItem) => void;
  selectedKey?: string;
}

const pivotStyles: Partial<IPivotStyles> = {
  link: {
    padding: "0px 18.4px",
  },
};

export const DataAnalyticsMenu: FC<DataAnalyticsMenuProps> = ({
  onChange,
  selectedKey,
}) => {
  const selectedWellbore = useSelector(selectSelectedWellbore);

  return (
    <Pivot
      aria-label="Data Anlytics Menu"
      // Setting to null to reset the selection. Undefined sets the first element.
      selectedKey={selectedKey ? selectedKey : null}
      onLinkClick={onChange}
      headersOnly={true}
      className={`${!selectedKey ? "pivot--hide-selection" : ""}`}
      styles={pivotStyles}
    >
      <PivotItem
        headerText={analyticsNames[AnalyticsName.XRF]}
        itemKey={AnalyticsName.XRF}
        headerButtonProps={{
          disabled: !selectedWellbore?.analyticsData?.xrf?.dataReference,
          ariaLabel: "Open the XRF tab",
        }}
      ></PivotItem>
      <PivotItem
        headerText={analyticsNames[AnalyticsName.SPEC_CAM]}
        itemKey={AnalyticsName.SPEC_CAM}
        headerButtonProps={{
          disabled: !selectedWellbore?.analyticsData?.speccam?.dataReference,
          ariaLabel: "Open the SpecCam tab",
        }}
      ></PivotItem>
      <PivotItem
        headerText={analyticsNames[AnalyticsName.XRD]}
        itemKey={AnalyticsName.XRD}
        headerButtonProps={{
          disabled: !selectedWellbore?.analyticsData?.xrd?.dataReference,
          ariaLabel: "Open the XRD tab",
        }}
      ></PivotItem>
      <PivotItem
        headerText={analyticsNames[AnalyticsName.TOC]}
        itemKey={AnalyticsName.TOC}
        headerButtonProps={{
          disabled: !selectedWellbore?.analyticsData?.toc?.dataReference,
          ariaLabel: "Open the TOC tab",
        }}
      ></PivotItem>
      <PivotItem
        headerText={analyticsNames[AnalyticsName.QEM_SCAN]}
        itemKey={AnalyticsName.QEM_SCAN}
        headerButtonProps={{
          disabled: !selectedWellbore?.analyticsData?.qemscan?.dataReference,
          ariaLabel: "Open the QemScan tab",
        }}
      ></PivotItem>
    </Pivot>
  );
};
