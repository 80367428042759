import { IContextualMenuItem } from "@fluentui/react";
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { DropdownName, WellTopsName } from "../../constants";
import { useDropdowns } from "../../context";
import {
  selectSelectedWellTops,
  setSelectedWellTops,
  useAppDispatch,
} from "../../store";
import { stringAscendingSort } from "../../utils/sorting";
import {
  ContextualMenuSelectionMode,
  getContextualMenuItems,
} from "../styledFluentComponents";
import { SidebarDropdown } from "./SidebarDropdown";

const wellTopsOptionText: Record<WellTopsName, string> = {
  [WellTopsName.NPD]: "NPD well tops",
};

export const WellTopSelector: FC = () => {
  const dispatch = useAppDispatch();
  const { closeDropdown } = useDropdowns();
  const selectedWellTops = useSelector(selectSelectedWellTops);

  const onItemClick = (e: unknown, item: IContextualMenuItem | undefined) => {
    if (item?.data?.type) {
      dispatch(setSelectedWellTops(item.data.type as WellTopsName));
    }
    closeDropdown(DropdownName.WELL_TOPS);
  };

  const items: IContextualMenuItem[] = useMemo(
    () =>
      getContextualMenuItems(
        "Stratigraphy",
        Object.values(WellTopsName)
          .sort(stringAscendingSort)
          .map((wellTopName, i) => {
            return {
              key: wellTopName,
              text: wellTopsOptionText[wellTopName],
              canCheck: true,
              checked: wellTopName === selectedWellTops,
              data: {
                type: wellTopName,
              },
            };
          })
      ),
    [selectedWellTops]
  );

  return (
    <SidebarDropdown
      onItemClick={onItemClick}
      items={items}
      dropDownName={DropdownName.WELL_TOPS}
      buttonAriaLabel="Select stratigraphy visualization"
      buttonLabel="S"
      selectionMode={ContextualMenuSelectionMode.MULTIPLE}
    />
  );
};
