import { IObjectWithKey, Selection } from "@fluentui/react";
import { ElementsConfig, StoredSelection } from "../../types/types";
import { getElementsInGroup, getGroupOfElement } from "./config";

interface ExtededSelectionOptions {
  onSelectionChanged: () => void;
  elementsConfig: ElementsConfig;
  initialStoredSelection?: StoredSelection;
  [key: string]: any; // eslint-disable-line
}

export default class ExtendedSelection extends Selection {
  private _selectedGroups: string[] = [];
  private _prevSelectedElements: string[] = [];
  private _onSelectedElementsChange: () => void;
  private _elementsConfig: ElementsConfig;
  private _currentStoredSelection?: StoredSelection;
  skipOnSelectionChanged = false;

  constructor(options: ExtededSelectionOptions) {
    super(options);
    // Proxy onSelectionChanged to be able to invoke this manually
    this._onSelectedElementsChange = options.onSelectionChanged;
    // Used to resolve item/group relationships
    this._elementsConfig = options.elementsConfig;
    // Enable creation of selection to be initiated to a stored selection
    if (options.initialStoredSelection) {
      this._currentStoredSelection = options.initialStoredSelection;
      this._restoreCurrentStoredSelection();
    }
  }

  setItems(items: IObjectWithKey[], shouldClear?: boolean | undefined): void {
    super.setItems(items, shouldClear);
    if (!shouldClear) {
      this._restoreCurrentStoredSelection();
    }
  }

  /**
   * Set selection to the elements given in a stored selection.
   * onSelectionChanged calls caused by this method should be skipped.
   */
  setStoredSelection(storedSelection: StoredSelection): void {
    this.skipOnSelectionChanged = true;

    this.clearAll();

    for (const elementKey of storedSelection.elements) {
      this.setKeySelected(elementKey, true, false);
    }
    for (const groupKey of storedSelection.groups) {
      this._setGroupSelected(groupKey, true);
    }

    this.setCurrentStoredSelection(storedSelection);

    this.skipOnSelectionChanged = false;
  }

  getSelectedGroups(): string[] {
    return [...this._selectedGroups];
  }

  toggleGroupItem(groupKey: string): void {
    if (this._selectedGroups.includes(groupKey)) {
      const indexToRemove = this._selectedGroups.findIndex(
        (item) => item === groupKey
      );
      this._selectedGroups.splice(indexToRemove, 1);
    } else {
      this._selectedGroups.push(groupKey);
      this._clearChildrenOfGroup(groupKey);
    }

    this._onSelectedElementsChange();
  }

  clearGroupItems = (): void => {
    this._selectedGroups = [];
    this._onSelectedElementsChange();
  };

  removeGroupIfChildOfGroupWasSelected(newElements: string[]): void {
    const addedElements = newElements.filter(
      (e) => !this._prevSelectedElements.includes(e)
    );

    if (addedElements.length) {
      for (const addedElement of addedElements) {
        const groupOfAddedElement = getGroupOfElement(
          this._elementsConfig,
          addedElement
        );
        if (groupOfAddedElement) {
          this._setGroupSelected(groupOfAddedElement, false);
        }
      }
    }

    this._prevSelectedElements = [...newElements];
  }

  clearAll(): void {
    this.setAllSelected(false);
    this.clearGroupItems();
  }

  setCurrentStoredSelection(newStoredSelection: StoredSelection): void {
    this._currentStoredSelection = newStoredSelection;
  }

  private resetCurrentStoredSelection(): void {
    this._currentStoredSelection = { elements: [], groups: [] };
  }

  private _restoreCurrentStoredSelection() {
    if (this._currentStoredSelection) {
      this.setStoredSelection(this._currentStoredSelection);
    }
  }

  private _clearChildrenOfGroup(groupKey: string) {
    const elementsInGroup = getElementsInGroup(this._elementsConfig, groupKey);
    for (const element of elementsInGroup) {
      this.setKeySelected(element, false, false);
    }
  }

  private _setGroupSelected(groupKey: string, isSelected: boolean): void {
    const selectedGroups = new Set(this._selectedGroups);
    if (isSelected) {
      selectedGroups.add(groupKey);
    } else {
      selectedGroups.delete(groupKey);
    }
    this._selectedGroups = Array.from(selectedGroups);
  }
}
