import {
  DetailsRow,
  IColumn,
  IDetailsGroupDividerProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroup,
  IGroupHeaderProps,
  IRenderFunction,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC } from "react";
import { AnalyticsName } from "../../../constants";
import {
  CSVFileJson,
  CSVFileLine,
  Cutting,
  Element,
  ElementsConfig,
  IListItem,
  LasFileJson,
  LogCurveName,
  Optional,
  StructuredCategory,
  StructuredData,
  StructuredElement,
  StructuredElementsGroup,
  TopGroup,
} from "../../../types";
import {
  arraysIntersect,
  getClasses,
  getGroupAndFormation,
  getRawValue,
  getValue,
} from "../../../utils";
import { getCurve } from "../../../utils/las-files";
import { sortKeyValueList, SortMethod } from "../../../utils/sorting";
import { BasicListItem } from "../../styledFluentComponents";
import {
  getElementColor,
  getElementsInGroup,
  getElementsInGroups,
} from "../config";
import ExtendedSelection from "../ExtendedSelection";
import { ElementMenu } from "../MineralMenu";

const HIDDEN_ELEMENT = {
  key: "HIDDEN",
  value: "",
  nested: true,
  rawValue: null,
  hide: true,
  skipInExcel: true,
};

export function getStructuredDataFromLasFile(
  data: LasFileJson,
  wellTops: TopGroup[],
  config: ElementsConfig,
  cuttings: Cutting[]
): StructuredData {
  const structuredData: StructuredData = new Map();

  for (const cutting of cuttings) {
    structuredData.set(
      cutting.depth,
      getDataForDepthFromLasFile(data, wellTops, config, cutting.depth)
    );
  }

  return structuredData;
}

function getDataForDepthFromLasFile(
  data: LasFileJson,
  wellTops: TopGroup[],
  config: ElementsConfig,
  depth: number
): StructuredCategory[] {
  const depths = getCurve(data, LogCurveName.DEPTH) as number[];
  const indexOfDepth = depths.findIndex((d) => d === depth);

  return Object.entries(config).map<StructuredCategory>(
    ([category, config]) => {
      return {
        name: category,
        unit: config.unit,
        groups: [],
        elements: config.elements.map(
          (elementConfig): StructuredElement => ({
            key: elementConfig.outputName,
            unit: elementConfig.unit,
            nested: false,
            value: getValue(
              data.CURVES[elementConfig.originalName]?.[indexOfDepth],
              "-"
            ),
            skipInExcel: !!elementConfig.skipInExcel,
            hide: !!elementConfig.hide,
            rawValue: getRawValue(
              data.CURVES[elementConfig.originalName]?.[indexOfDepth],
              null
            ),
            information: elementConfig.information,
          })
        ),
        stratigraphy: getGroupAndFormation(wellTops, depth),
      };
    }
  );
}

export function getStructuredDataFromCSVFile(
  data: CSVFileJson,
  wellTops: TopGroup[],
  config: ElementsConfig,
  cuttings: Cutting[],
  parseValuesToNumber = true
): StructuredData {
  const structuredData: StructuredData = new Map();

  cuttings.forEach((c) => {
    structuredData.set(
      c.depth,
      getDataForDepthFromCSVFile(
        data,
        wellTops,
        config,
        c.depth,
        parseValuesToNumber
      )
    );
  });

  return structuredData;
}

function getDataForDepthFromCSVFile(
  data: CSVFileJson,
  wellTops: TopGroup[],
  config: ElementsConfig,
  depth: number,
  parseValuesToNumber: boolean
): StructuredCategory[] {
  const structuredDataForDepth: StructuredCategory[] = [];

  const rawDataForDepth = getRawDataOfCSVDepth(data, depth);

  Object.entries(config).forEach(([category, config]) => {
    let structuredGroups: StructuredElementsGroup[] = [];
    if (config.groups) {
      structuredGroups = config.groups?.map((groupConfig) => ({
        key: groupConfig.outputName,
        unit: groupConfig.unit,
        skipInExcel: !!groupConfig.skipInExcel,
        value: getValue(
          rawDataForDepth?.[groupConfig.originalName],
          "-",
          parseValuesToNumber
        ),
        elements: mapToStructuredElements(
          groupConfig.elements,
          rawDataForDepth,
          true,
          true
        ),
        rawValue: getRawValue(
          rawDataForDepth?.[groupConfig.originalName],
          null
        ),
        information: groupConfig.information,
      }));
    }

    const structuredCategory: StructuredCategory = {
      name: category,
      unit: config.unit,
      groups: structuredGroups,
      elements: mapToStructuredElements(
        config.elements,
        rawDataForDepth,
        parseValuesToNumber
      ),
      stratigraphy: getGroupAndFormation(wellTops, depth),
    };

    structuredDataForDepth.push(structuredCategory);
  });

  return structuredDataForDepth;
}

function mapToStructuredElements(
  elements: Element[],
  data: CSVFileLine,
  parseValuesToNumber = true,
  nested = false
): StructuredElement[] {
  return elements.map((element) => ({
    key: element.outputName,
    unit: element.unit,
    nested: nested,
    value: getValue(
      data?.[element.originalName],
      "-",
      parseValuesToNumber,
      element.decimalPlaces
    ),
    rawValue: getRawValue(data?.[element.originalName], null),
    hide: !!element.hide,
    skipInExcel: !!element.skipInExcel,
    information: element.information,
  }));
}

function getRawDataOfCSVDepth(data: CSVFileJson, depth: number) {
  const depthKey =
    Object.keys(data?.[0] ?? []).find((key) =>
      ["depth", "Depth", "DEPTH"].includes(key)
    ) ?? "INVALID_KEY";

  const indexOfDepth = data.findIndex((d) => +d[depthKey] === depth);
  return data[indexOfDepth];
}

function isStructuredElement(
  item: StructuredElement | StructuredElementsGroup
): item is StructuredElement {
  return (item as StructuredElement).hide !== undefined;
}

export function getItemsAndGroups(
  structuredCategories: StructuredCategory[],
  sortMethod?: SortMethod,
  filterText?: string
): [IListItem[], IGroup[]] {
  let startIndex = 0;
  const _items: IListItem[] = [];
  const _groups: IGroup[] = [];

  for (const category of structuredCategories) {
    const [
      allCategoryElementsFiltered,
      filteredCategoryElements,
    ] = filterElements(filterText, category.elements);

    let shouldFilterCategory = allCategoryElementsFiltered;

    const structuredItems: (StructuredElementsGroup | StructuredElement)[] = [
      ...category.groups,
      ...filteredCategoryElements,
    ];

    const sortedStructuredItems = sortKeyValueList(structuredItems, sortMethod);

    let categoryElementsCount = 0;
    const groupChildren: IGroup[] = [];

    for (const item of sortedStructuredItems) {
      if (isStructuredElement(item)) {
        /**
         * Adding flat group to contain and handle each item so that it can be sorted together with groups.
         * A flat group will not have a rendered header. Making the group invisible for the UI.
         */
        groupChildren.push({
          count: 1,
          key: `${item}-flat-group`,
          name: "flat-group",
          startIndex: startIndex + categoryElementsCount,
          level: 1,
          isCollapsed: false,
        });
        _items.push(item);
        categoryElementsCount++;
      } else {
        const [
          allGroupElementsFiltered,
          filteredGroupElements,
        ] = filterElements(filterText, item.elements);

        const groupMatchesFilter = filterText
          ? item.key.toLowerCase().includes(filterText.toLowerCase())
          : true;

        if (!allGroupElementsFiltered || groupMatchesFilter) {
          shouldFilterCategory = false;
        }

        /**
         * Adding hidden element, which the user cannot select, to effectivly disable the Fluent DetailsList
         * behaviour where the parent group get selected automatically if all its children are selected.
         */
        filteredGroupElements.push(HIDDEN_ELEMENT);

        groupChildren.push({
          count: filteredGroupElements.length,
          key: item.key,
          name: item.key,
          startIndex: startIndex + categoryElementsCount,
          level: 1,
          isCollapsed: !filterText,
          data: {
            value: item.value,
            filtered: groupMatchesFilter ? false : allGroupElementsFiltered,
            information: item.information,
          },
        });
        _items.push(...sortKeyValueList(filteredGroupElements, sortMethod));
        categoryElementsCount += filteredGroupElements.length;
      }
    }

    _groups.push({
      count: categoryElementsCount,
      key: category.name,
      name: category.name,
      startIndex: startIndex,
      level: 0,
      children: groupChildren,
      data: { filtered: shouldFilterCategory },
    });

    startIndex += categoryElementsCount;
  }

  return [_items, _groups];
}

const filterHiddenElements = (element: StructuredElement) => !element?.hide;

export const getOnRenderRow = (
  tabName: AnalyticsName,
  config: ElementsConfig
): IRenderFunction<IDetailsRowProps> => (props) => {
  if (props) {
    const elementColor = getElementColor(props.item.key, tabName);
    const parentIsSelected = isParentSelected(
      props.item.key,
      config,
      props.selection as ExtendedSelection
    );

    const customStyles = getCustomRowStyles(
      elementColor,
      parentIsSelected,
      props.item.hide,
      props.item.nested,
      props.item.filtered
    );

    return (
      <DetailsRow
        {...props}
        styles={customStyles}
        onRenderItemColumn={onRenderItemColumn}
      />
    );
  }
  return null;
};

function filterElements(
  filterText: Optional<string>,
  elements: StructuredElement[]
): [boolean, StructuredElement[]] {
  const visibleElements = elements.filter(filterHiddenElements);

  if (!filterText) {
    return [false, visibleElements];
  }

  let allElementsFiltered = true;
  const filteredElements = visibleElements.map((element) => {
    const passesFilter = element.key
      .toLowerCase()
      .includes(filterText.toLowerCase());
    if (passesFilter) {
      allElementsFiltered = false;
    }
    return { ...element, filtered: !passesFilter };
  });
  return [allElementsFiltered, filteredElements];
}

function isParentSelected(
  elementKey: string,
  config: ElementsConfig,
  selection: ExtendedSelection
): boolean {
  const selectedGroups = selection.getSelectedGroups();
  const elementsInSelectedGroup = getElementsInGroups(config, selectedGroups);

  return elementsInSelectedGroup.includes(elementKey);
}

const getCustomRowStyles = (
  backgroundColor: string,
  highlighted = false,
  hide = false,
  nested = false,
  filtered = false
): Partial<IDetailsRowStyles> => {
  const customStyles: Partial<IDetailsRowStyles> = {
    root: {
      display: hide || filtered ? "none" : undefined,
      color: NeutralColors.gray160,
      background: highlighted
        ? `${NeutralColors.gray40} !important`
        : undefined,
      fontSize: 14,
      selectors: {
        '&[aria-selected="true"]': {
          fontWeight: 600,
          background: NeutralColors.gray40,
        },
        '&[aria-selected="true"] .ms-DetailsRow-cellCheck': {
          background: backgroundColor,
        },
        '.ms-DetailsRow-cell[aria-colindex="4"]': {
          textAlign: "right",
        },
        '.ms-DetailsRow-cell[aria-colindex="3"]': {
          paddingLeft: nested ? 48 : 12,
        },
        ".ms-Check::before": {
          background: NeutralColors.gray30,
        },
        ".ms-Check-circle": {
          color: NeutralColors.gray160,
        },
        ".ms-Check-check": {
          color: NeutralColors.gray160,
        },
      },
    },
    check: {
      selectors: {
        "&::before": {
          background: NeutralColors.gray30,
        },
      },
    },
  };

  return customStyles;
};

const onRenderItemColumn = (
  item?: IListItem,
  index?: number,
  column?: IColumn
): React.ReactNode => {
  if (column && item) {
    if (column.key === "key") {
      const keyString = item.unit ? `${item.key} (${item.unit})` : item.key;
      return <span>{keyString}</span>;
    } else if (column.key === "menu-bar") {
      return (
        <ElementMenu
          elementInformation={item.information}
          elementName={item.key}
        />
      );
    } else {
      return <span>{item.value}</span>;
    }
  }
};

export const onRenderBasicListCell = (item: IListItem): JSX.Element | null => {
  if (item) {
    return <BasicListItem item={item} />;
  }
  return null;
};

export const onRenderGroupTitle = (
  headerProps: IGroupHeaderProps | undefined
): JSX.Element | null => {
  if (headerProps && headerProps.group) {
    return (
      <>
        <div
          role="gridcell"
          aria-readonly="true"
          aria-colindex={3}
          className="ms-DetailsRow-cell"
          style={{ width: 156, paddingLeft: 12 }}
        >
          {headerProps.group.key}
        </div>
        <div
          role="gridcell"
          aria-readonly="true"
          aria-colindex={4}
          className="ms-DetailsRow-cell"
          style={{ width: 102, textAlign: "right", paddingRight: 5 }}
        >
          {headerProps.group.data?.value ?? "-"}
        </div>
        <div
          role="gridcell"
          aria-readonly="true"
          aria-colindex={5}
          style={{ width: 36, height: "100%" }}
        >
          <ElementMenu
            elementInformation={headerProps.group.data.information}
            elementName={headerProps.group.key}
          />
        </div>
      </>
    );
  }
  return null;
};

export const renderGroupHeader = (
  props: IDetailsGroupDividerProps | undefined,
  config: ElementsConfig,
  defaultRender:
    | ((props?: IDetailsGroupDividerProps | undefined) => JSX.Element | null)
    | undefined
): JSX.Element | null => {
  if (props?.group && props.group.level === 0) {
    const unit = config[props.group.name]?.unit;
    const name = unit ? `${props.group.name} (${unit})` : props.group.name;

    return (
      <h2
        className={getClasses(
          "analytics-pane__group-heading analytics-pane__group-heading--spaced analytics-pane--horizontal-margin",
          { hide: props.group.data?.filtered }
        )}
      >
        {name}
      </h2>
    );
  } else if (props?.group && props.group.level === 1 && defaultRender) {
    if (props.group.key.includes("flat-group")) {
      return null;
    }

    const selection = props?.selection as Optional<ExtendedSelection>;
    const groupIsSelected = selection
      ?.getSelectedGroups()
      ?.includes(props.group.key);
    const childIsSelected = selection
      ? isChildSelected(props.group.key, config, selection)
      : false;

    const normalizedGroupKey = props.group.key
      .replace(/[ /]/g, "-")
      .toLowerCase();

    const className = getClasses(
      `analytics-pane__group-devider--${normalizedGroupKey}`,
      {
        "analytics-pane__group-devider--highlighted":
          groupIsSelected || childIsSelected,
      },
      { hide: props.group.data?.filtered }
    );

    return defaultRender({
      ...props,
      className: className,
      onRenderTitle: onRenderGroupTitle,
      isSelected: groupIsSelected,
      onToggleSelectGroup: (group) => {
        selection?.toggleGroupItem?.(group.key);
      },
    });
  }
  return null;
};

function isChildSelected(
  groupKey: string,
  config: ElementsConfig,
  selection: ExtendedSelection
): boolean {
  const selectedElements = selection.getSelection().map((obj) => obj.key);
  const elementsInGroup = getElementsInGroup(config, groupKey);

  return arraysIntersect(elementsInGroup, selectedElements);
}

export const TabContainer: FC<
  React.HTMLAttributes<HTMLDivElement> & { show?: boolean }
> = ({ children, show, className, ...restProps }) => (
  <div
    {...restProps}
    className={getClasses(className, "analytics-pane__tab", {
      "analytics-pane__tab--show": !!show,
    })}
  >
    {children}
  </div>
);
