import clamp from "lodash/clamp";
import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { animated, AnimatedValue, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import { ReactEventHandlers } from "react-use-gesture/dist/types";
import { MINIMUM_ZOOM_AREA_SIZE } from "../../config";
import { useAppSprings } from "../../context";
import {
  isCuttingIndexWithinZoomArea,
  resetZoomArea,
  selectActiveZoomArea,
  selectCuttings,
  updateActiveZoomArea,
  useAppDispatch,
} from "../../store";
import { Optional, RenderingConstraints, ZoomArea } from "../../types";
import { TrackContentProps } from "../track";
import { ZoomAreaOutline, ZoomAreaThumb, ZoomAreaThumbIcon } from "./zoom-area";

interface RangeFinderSliderProps extends TrackContentProps {
  maxValue: number;
  sliderValue: number;
  onSliderChange: (cuttingIndex: number) => void;
  onZoomChange: (cuttingIndex: number, position: "top" | "bottom") => void;
  onScroll: (e: React.WheelEvent) => void;
  onKeyDown: (e: KeyboardEvent) => void;
}

enum DraggableElement {
  ZOOM_AREA_TOP = "ZOOM_AREA_TOP",
  ZOOM_AREA_BOTTOM = "ZOOM_AREA_BOTTOM",
  SLIDER_THUMB = "SLIDER_THUMB",
}

export const RangeFinderSlider: FC<RangeFinderSliderProps> = ({
  maxValue,
  sliderValue,
  onSliderChange,
  onZoomChange,
  onScroll,
  onKeyDown,
  size,
  renderingConstraints: rc,
}) => {
  const dispatch = useAppDispatch();
  const cuttings = useSelector(selectCuttings);
  const { rangeFinderSpring } = useAppSprings();
  const activeZoomArea = useSelector(selectActiveZoomArea);

  const sliderRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const zoomAreaTopRef = useRef<HTMLDivElement>(null);
  const zoomAreaTopIconRef = useRef<SVGSVGElement>(null);
  const zoomAreaBottomRef = useRef<HTMLDivElement>(null);
  const zoomAreaBottomIconRef = useRef<SVGSVGElement>(null);
  const zoomAreaRef = useRef<HTMLDivElement>(null);
  const beingDragged = useRef<Optional<DraggableElement>>();

  const [sliderThumbSpring, setSliderThumbSpring] = useSpring(() => ({
    top: rc.trackStep / 2 + sliderValue * rc.trackStep,
    immediate: true,
  }));

  useEffect(() => {
    setSliderThumbSpring({
      top: rc.trackStep / 2 + sliderValue * rc.trackStep,
      immediate: true,
    });
  }, [setSliderThumbSpring, sliderValue, rc.trackStep]);

  const rangeFinderThumbBind = useGesture(
    {
      onPointerDown: ({ event, ...sharedState }) => {
        const currentTop = sliderThumbSpring?.top?.getValue();
        if (!sliderRef.current || currentTop === undefined) return;

        const nativEvent = ((event as unknown) as React.PointerEvent<
          HTMLDivElement
        >).nativeEvent;

        if (
          event.target === sliderRef.current ||
          event.target === thumbRef.current ||
          event.target === zoomAreaRef.current
        ) {
          beingDragged.current = DraggableElement.SLIDER_THUMB;

          const offsetY = getOffsetY(nativEvent, sliderRef.current);

          const [nextTop, nextSliderValue] = snapToStep(offsetY, rc);

          if (
            nextTop !== currentTop &&
            activeZoomArea &&
            isCuttingIndexWithinZoomArea(nextSliderValue, activeZoomArea)
          ) {
            setSliderThumbSpring({
              top: nextTop,
              immediate: true,
            });
            onSliderChange(nextSliderValue);
          }
        } else if (
          event.target === zoomAreaTopRef.current ||
          event.target === zoomAreaTopIconRef.current
        ) {
          beingDragged.current = DraggableElement.ZOOM_AREA_TOP;
        } else if (
          event.target === zoomAreaBottomRef.current ||
          event.target === zoomAreaBottomIconRef.current
        ) {
          beingDragged.current = DraggableElement.ZOOM_AREA_BOTTOM;
        } else {
          beingDragged.current = undefined;
        }
      },
      onDrag: ({ movement: [, my], event }) => {
        if (!sliderRef.current || my === 0) return;

        const offsetY = getOffsetY(event, sliderRef.current);

        if (beingDragged.current === DraggableElement.SLIDER_THUMB) {
          const currentTop = sliderThumbSpring?.top?.getValue();

          if (currentTop === undefined) return;

          const [nextSliderTop, nextSliderValue] = snapToStep(offsetY, rc);

          if (
            nextSliderTop !== currentTop &&
            activeZoomArea &&
            isCuttingIndexWithinZoomArea(nextSliderValue, activeZoomArea)
          ) {
            setSliderThumbSpring({
              top: nextSliderTop,
              immediate: true,
            });
            onSliderChange(nextSliderValue);
          }
        } else if (beingDragged.current === DraggableElement.ZOOM_AREA_TOP) {
          if (!activeZoomArea) return;

          const [, nextZoomAreaTopIdx] = snapToStep(offsetY, rc, "top");

          const maxTopIndex = Math.max(
            activeZoomArea.bottomIdx - MINIMUM_ZOOM_AREA_SIZE + 1,
            0
          );

          const topIndex = clamp(nextZoomAreaTopIdx, 0, maxTopIndex);

          const nextZoomArea: ZoomArea = {
            topIdx: topIndex,
            bottomIdx: activeZoomArea.bottomIdx,
          };

          onZoomChange(topIndex, "top");

          dispatch(updateActiveZoomArea(nextZoomArea));
        } else if (beingDragged.current === DraggableElement.ZOOM_AREA_BOTTOM) {
          if (!activeZoomArea) return;

          const [, nextZoomAreaBottomIdx] = snapToStep(offsetY, rc, "bottom");

          const minBottomIndex = Math.min(
            activeZoomArea.topIdx + MINIMUM_ZOOM_AREA_SIZE - 1,
            maxValue
          );
          const bottomIndex = clamp(
            nextZoomAreaBottomIdx,
            minBottomIndex,
            maxValue
          );

          const nextZoomArea: ZoomArea = {
            topIdx: activeZoomArea.topIdx,
            bottomIdx: bottomIndex,
          };

          onZoomChange(bottomIndex, "bottom");

          dispatch(updateActiveZoomArea(nextZoomArea));
        }
      },
    },
    {
      drag: {
        initial: () => {
          const top = sliderThumbSpring?.top?.getValue();
          return [0, top !== undefined ? +top.valueOf() : 0];
        },
        filterTaps: true,
        axis: "y",
      },
    }
  );

  // #Todo: Detect when the slider has focus and set up handling of keydown events
  const [focused] = useState(false);
  useEffect(() => {
    if (focused) {
      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    }
  }, [focused, onKeyDown]);

  if (size) {
    return (
      <animated.div
        className="range-finder__slider"
        aria-label="Select cutting"
        role="slider"
        data-is-focusable="true"
        aria-valuemin={0}
        aria-valuemax={maxValue}
        aria-valuenow={sliderValue}
        aria-valuetext={String(sliderValue)}
        ref={sliderRef}
        style={{
          top: rc.outerTrackMargin.top,
          height:
            size.height - rc.outerTrackMargin.top - rc.outerTrackMargin.bottom,
          width: rangeFinderSpring?.width?.interpolate(
            (rangeFinderWidth: number) => `${rangeFinderWidth}px`
          ),
        }}
        onWheel={(e) => onScroll(e)}
        {...rangeFinderThumbBind()}
      >
        {activeZoomArea && cuttings && (
          <ZoomAreaOutline
            ref={zoomAreaRef}
            zoomArea={activeZoomArea}
            renderingConstraints={rc}
            numberOfCuttings={cuttings.length}
            zoomAreaTopThumb={
              <ZoomAreaThumb
                placement="top"
                ref={zoomAreaTopRef}
                thumbIcon={<ZoomAreaThumbIcon ref={zoomAreaTopIconRef} />}
              />
            }
            zoomAreaBottomThumb={
              <ZoomAreaThumb
                placement="bottom"
                ref={zoomAreaBottomRef}
                thumbIcon={<ZoomAreaThumbIcon ref={zoomAreaBottomIconRef} />}
              />
            }
            resetZoomArea={() => dispatch(resetZoomArea())}
            onZoomSliderChange={onSliderChange}
          />
        )}
        <Thumb
          ref={thumbRef}
          sliderThumbSpring={
            sliderThumbSpring as AnimatedValue<{ top: number }>
          }
        />
      </animated.div>
    );
  }

  return null;
};

const Thumb = forwardRef<
  HTMLDivElement,
  ReactEventHandlers & {
    sliderThumbSpring: AnimatedValue<{ top: number }>;
  }
>(({ sliderThumbSpring, ...bindProps }, ref) => {
  return (
    <animated.div
      ref={ref}
      className="range-finder__thumb"
      style={sliderThumbSpring}
      {...bindProps}
    >
      <svg
        className="range-finder__thumb__icon"
        height="16"
        width="28"
        viewBox="0 0 28 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="#EDEBE9" width="28px" height="16px" />
        <rect y="6" x="6" width="15px" height="1px" fill="#605E5C" />
        <rect y="9" x="6" width="15px" height="1px" fill="#605E5C" />
      </svg>
    </animated.div>
  );
});

function getOffsetY(
  event: React.PointerEvent<Element> | PointerEvent,
  referenceElement: HTMLElement
) {
  const clientY = event.clientY;
  const referenceY = referenceElement.getBoundingClientRect().y;
  return clientY - referenceY;
}

function snapToStep(
  offsetY: number,
  rc: RenderingConstraints,
  alignment: "top" | "bottom" | "center" = "center"
): [number, number] {
  const baseOffset =
    alignment === "top"
      ? 0
      : alignment === "center"
      ? rc.trackStep / 2
      : rc.trackStep;

  const normalizedOffsetY = offsetY - baseOffset;

  const snappedOffsetRelativeToBase = clamp(
    Math.round(normalizedOffsetY / rc.trackStep) * rc.trackStep,
    0,
    rc.trackContentHeight - rc.trackStep
  );

  const snappedOffset = baseOffset + snappedOffsetRelativeToBase;

  const sliderValue = Math.round(snappedOffsetRelativeToBase / rc.trackStep);

  return [snappedOffset, sliderValue];
}
