import { AnalyticsName } from "../../constants";
import { Wellbore } from "../../types";

export function passesFilter(
  wellbore: Wellbore,
  filters: AnalyticsName[]
): boolean {
  if (filters.length === 0) {
    return false;
  }

  for (const filterName of filters) {
    if (!wellbore?.analyticsData?.[filterName]) {
      return false;
    }
  }

  return true;
}
