import { CategoryData, ElementsConfig } from "../../../types/types";

const speccamMineralsCategory: CategoryData = {
  unit: "%",
  elements: [
    { originalName: "clay_total %", outputName: "Clay total" },
    { originalName: "biotite %", outputName: "Biotite" },
    { originalName: "chlorite_total %", outputName: "Chlorite total" },
    { originalName: "zircon %", outputName: "Zircon" },
    { originalName: "zeolite %", outputName: "Zeolite" },
    { originalName: "epidote %", outputName: "Epidote" },
    { originalName: "total_all_minerals %", outputName: "All minerals total" },
    { originalName: "unknown %", outputName: "Unknown minerals" },
  ],
  groups: [
    {
      originalName: "carbonate_total %",
      outputName: "Carbonate total",
      elements: [
        { originalName: "calcite %", outputName: "Calcite" },
        { originalName: "dolomite %", outputName: "Dolomite" },
        { originalName: "fe-carbonate %", outputName: "Fe-carbonate" },
      ],
    },
    {
      originalName: "illite_total %",
      outputName: "Illite total",
      elements: [
        { originalName: "illite %", outputName: "Illite" },
        { originalName: "smectite-illite %", outputName: "Smectite-illite" },
      ],
    },
    {
      originalName: "smectite_total %",
      outputName: "Smectite total",
      elements: [
        { originalName: "hectorite %", outputName: "Hectorite" },
        { originalName: "nontronite %", outputName: "Nontronite" },
        { originalName: "saponite %", outputName: "Saponite" },
        { originalName: "montmorillonite %", outputName: "Montmorillonite" },
        { originalName: "corrensite %", outputName: "Corrensite" },
      ],
    },
    {
      originalName: "mica_total %",
      outputName: "Mica total",
      elements: [
        { originalName: "paragonite %", outputName: "Paragonite" },
        { originalName: "muscovite %", outputName: "Muscovite" },
        { originalName: "phengite %", outputName: "Phengite" },
        { originalName: "mica-illite %", outputName: "Mica-illite" },
        { originalName: "glauconite %", outputName: "Glauconite" },
      ],
    },
    {
      originalName: "kaolinite_total %",
      outputName: "Kaolin total",
      elements: [
        { originalName: "kaolinite-lo %", outputName: "Kaolinite-lo" },
        { originalName: "kaolinite-hi %", outputName: "Kaolinite-hi" },
        { originalName: "dickite %", outputName: "Dickite" },
        { originalName: "halloysite %", outputName: "Halloysite" },
      ],
    },
    {
      originalName: "sulphate_total %",
      outputName: "Sulphate total",
      elements: [
        { originalName: "bassanite %", outputName: "Bassanite" },
        { originalName: "gypsum %", outputName: "Gypsum" },
      ],
    },
  ],
};

const speccamHydrocarbonsCategory: CategoryData = {
  unit: "%",
  elements: [{ originalName: "toc %", outputName: "TOC", skipInExcel: true }],
  groups: [
    {
      originalName: "hydrocarb_total %",
      outputName: "HC total",
      skipInExcel: true,
      elements: [
        {
          originalName: "hydrocarb-solid %",
          outputName: "Hydrocarb-solid",
          skipInExcel: true,
        },
        {
          originalName: "hydrocarb-liquid %",
          outputName: "Hydrocarb-liquid",
          skipInExcel: true,
        },
      ],
    },
  ],
};

const speccamIndiciesCategory: CategoryData = {
  elements: [
    {
      originalName: "hydrocarb_composition_index",
      outputName: "HC composition index",
      skipInExcel: true,
    },
    {
      originalName: "albedo_index",
      outputName: "Albedo index",
      skipInExcel: true,
    },
    {
      originalName: "correlation_index",
      outputName: "Correlation index",
      skipInExcel: true,
    },
    {
      originalName: "stdev_index",
      outputName: "Standard deviation index",
      skipInExcel: true,
    },
    {
      originalName: "mineral_id_index",
      outputName: "Mineral id index",
      skipInExcel: true,
    },
    {
      originalName: "ferroan_index",
      outputName: "Ferroan index",
      skipInExcel: true,
    },
    {
      originalName: "carbonatepos_index",
      outputName: "Carbonate pos index",
      skipInExcel: true,
    },
    {
      originalName: "dolomite_pos_index",
      outputName: "Dolomite pos index",
      skipInExcel: true,
    },
    {
      originalName: "dolomite_hydroxyl_index",
      outputName: "Dolomite hydroxyl index",
      skipInExcel: true,
    },
    {
      originalName: "dolomite_particle_index",
      outputName: "Dolomite particle index",
      skipInExcel: true,
    },
    {
      originalName: "clay_hydroxyl_depth_index",
      outputName: "Clay hydroxyl depth index",
      skipInExcel: true,
    },
    {
      originalName: "alohpos_index",
      outputName: "Alohpos index",
      skipInExcel: true,
    },
    {
      originalName: "alohdepth_index",
      outputName: "Alohdepth index",
      skipInExcel: true,
    },
    {
      originalName: "mica_pos_index",
      outputName: "Mica pos index",
      skipInExcel: true,
    },
    {
      originalName: "illite_pos_index",
      outputName: "Illite pos index",
      skipInExcel: true,
    },
    {
      originalName: "kaolin_index",
      outputName: "Kaolin index",
      skipInExcel: true,
    },
    {
      originalName: "chlorite_index",
      outputName: "Chlorite index",
      skipInExcel: true,
    },
    {
      originalName: "mask %",
      outputName: "Mask",
      unit: "%",
      skipInExcel: true,
    },
    {
      originalName: "alohcrystly_index",
      outputName: "Alohcrystly index",
      skipInExcel: true,
    },
    {
      originalName: "alohasym_index",
      outputName: "Alohasym index",
      skipInExcel: true,
    },
    {
      originalName: "silica_index",
      outputName: "Silica index",
      skipInExcel: true,
    },
    {
      originalName: "silica_pixel_total %",
      outputName: "Silica pixel total",
      unit: "%",
      skipInExcel: true,
    },
    {
      originalName: "anhydrite_index",
      outputName: "Anhydrite index",
      skipInExcel: true,
    },
    {
      originalName: "anhydrite_pixel_total %",
      outputName: "Anhydrite pixel total",
      unit: "%",
      skipInExcel: true,
    },
    {
      originalName: "hydroxyl_area_index",
      outputName: "Hydroxyl area index",
      skipInExcel: true,
    },
    {
      originalName: "water_area_index",
      outputName: "Water area index",
      skipInExcel: true,
    },
    {
      originalName: "clay_area_index",
      outputName: "Clay area index",
      skipInExcel: true,
    },
  ],
  groups: [
    {
      originalName: "hydrocarb_pixel_total %",
      outputName: "HC Pixel total",
      unit: "%",
      skipInExcel: true,
      elements: [
        {
          originalName: "hydrocarb_pixelcount_solid %",
          outputName: "HC pixelcount solid",
          unit: "%",
          skipInExcel: true,
        },
        {
          originalName: "hydrocarb_pixelcount_liquid %",
          outputName: "HC pixelcount liquid",
          unit: "%",
          skipInExcel: true,
        },
      ],
    },
  ],
};

const speccamSpectraMapCategory: CategoryData = {
  elements: [
    {
      originalName: "total_pixminsig %",
      outputName: "Total pixminsig",
      unit: "%",
    },
    { originalName: "dcorrcorfac_index", outputName: "DcorrCorFac index" },
    {
      originalName: "dcorr_pixel_total %",
      outputName: "Dcorr pixel total",
      unit: "%",
    },
    { originalName: "hydrocorfac_index", outputName: "HydroCorFac index" },
    { originalName: "hydrocorpos_index", outputName: "HydroCorPos index" },
    {
      originalName: "plsradd_pixel_total %",
      outputName: "PLSRADD pixel total",
      unit: "%",
    },
  ],
};

export const speccamConfig: ElementsConfig = {
  Minerals: speccamMineralsCategory,
  Hydrocarbons: speccamHydrocarbonsCategory,
  Indices: speccamIndiciesCategory,
  "Spectra-Map QC": speccamSpectraMapCategory,
};
