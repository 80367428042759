import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import OpenSeaDragon from "../../dependencies/openseadragon";
import {
  selectActiveCuttings,
  selectSelectedImageType,
  setSelectedCutting,
  useAppDispatch,
} from "../../store";
import { Cutting } from "../../types/types";
import { ViewerFrame } from "../frames/frames";
import { OSD_SETTINGS } from "./config";

export interface GridViewViewerProps {
  show: boolean;
  setGridView: (gridView: boolean | ((state: boolean) => boolean)) => void;
}

export const GridViewViewer: FC<GridViewViewerProps> = ({
  show,
  setGridView,
}) => {
  const dispatch = useAppDispatch();
  const [viewer, setViewer] = useState<OpenSeaDragon.Viewer>();
  const activeCuttings = useSelector(selectActiveCuttings);
  const selectedImageType = useSelector(selectSelectedImageType);
  const prevSelection = useRef<{
    viewer?: OpenSeaDragon.Viewer;
    cuttings?: Cutting[];
    selectedImageType?: string;
  }>({});

  /**
   * Initialize open sea dragon viewer
   */
  useEffect(() => {
    const osdviewer = OpenSeaDragon({
      id: "openSeaDragon-gridView",
      ...OSD_SETTINGS,
      zoomPerClick: 1, // disable zooming on click
      maxZoomLevel: 0.1,
      collectionMode: true,
      collectionLayout: "horizontal",
      collectionRows: 12,
      collectionTileSize: 1024,
      collectionTileMargin: 100,
    });

    setViewer(osdviewer);

    return () => {
      osdviewer.destroy();
    };
  }, []);

  /**
   * Attach handler to viewer in order to update overlays on each image in grid view.
   */
  useEffect(() => {
    if (!viewer) return;

    const updateOverlays = () => {
      viewer.clearOverlays();

      if (!activeCuttings) return;

      activeCuttings.forEach((cutting, index) => {
        const tiledImage = viewer.world.getItemAt(index);
        if (tiledImage) {
          const overlayElement = document.createElement("div");
          overlayElement.id = `overlay-${cutting.depth}`;
          overlayElement.className = "overlay";
          overlayElement.addEventListener("click", () => {
            dispatch(setSelectedCutting(cutting));
            setGridView(false);
          });

          const textElement = document.createElement("span");
          textElement.innerHTML = `${cutting.depth}m`;
          overlayElement.appendChild(textElement);

          const tileViewportBounds = tiledImage.getBounds();
          const tileImageBounds = tiledImage.viewportToImageRectangle(
            tileViewportBounds
          );

          const imageRect = new OpenSeaDragon.Rect(
            0,
            0,
            tileImageBounds.width,
            tileImageBounds.height
          );
          const viewportRect = tiledImage.imageToViewportRectangle(imageRect);
          const overlayConfig = {
            height: viewportRect.height,
            width: viewportRect.width,
            x: viewportRect.x,
            y: viewportRect.y,
            id: `overlay-${cutting.depth}`,
            element: overlayElement,
          };

          viewer.addOverlay(overlayConfig);
        }
      });
    };

    viewer.addHandler("open", updateOverlays);

    return () => viewer.removeHandler("open", updateOverlays);
  }, [viewer, activeCuttings, dispatch, setGridView]);

  /**
   * Open cutting images or update to new ones, but only do so when grid view is visible
   * and the image references are different
   */
  useEffect(() => {
    const needToOpenNewTilesBecauseImageReferencesAreDifferent =
      viewer !== prevSelection.current.viewer ||
      activeCuttings !== prevSelection.current.cuttings ||
      selectedImageType !== prevSelection.current.selectedImageType;
    if (
      needToOpenNewTilesBecauseImageReferencesAreDifferent &&
      viewer &&
      show &&
      activeCuttings &&
      selectedImageType
    ) {
      const zoom = viewer.viewport.getZoom();
      const center = viewer.viewport.getCenter();

      const openHandler = () => {
        const cuttingsHaveNotChanged =
          prevSelection.current.cuttings === activeCuttings;
        const cuttingsAreCurrentlyOpen = prevSelection.current.cuttings;
        if (cuttingsHaveNotChanged && cuttingsAreCurrentlyOpen) {
          // Only retain zoom level when cuttings have not changed. This would be the case if only image type has updated.

          viewer.viewport.zoomTo(zoom, undefined, true);
          viewer.viewport.panTo(center, true);
        } else {
          viewer.viewport.goHome();
        }
        prevSelection.current = {
          viewer,
          cuttings: activeCuttings,
          selectedImageType: selectedImageType,
        };
        viewer.removeHandler("open", openHandler);
      };

      viewer.addHandler("open", openHandler);

      viewer.open(
        activeCuttings.reduce<string[]>((acc, c) => {
          const refForSelectedImageType = c.imageReferences.find(
            (r) => r.type === selectedImageType
          );
          if (refForSelectedImageType) {
            acc.push(refForSelectedImageType.reference);
          }
          return acc;
        }, [])
      );
    }
  }, [viewer, activeCuttings, selectedImageType, show]);

  return (
    <ViewerFrame
      id="openSeaDragon-gridView"
      className={show ? "viewer--visible" : ""}
    />
  );
};
