import { useEffect } from "react";
import { distinctUntilChanged, map } from "rxjs/operators";
import { SELECTED_WELLBORE_LOCAL_STORAGE_KEY } from "./initialStateFromLocalStorage";
import { storeState$ } from "./store";

/**
 * Setup subscriptions to update localStorage
 */
export function useSyncStateToLocalStorage(): void {
  useEffect(() => {
    const wellboreSubscription = storeState$
      .pipe(
        map((state) => state.app.selectedWellbore),
        distinctUntilChanged()
      )
      .subscribe((selectedWellbore) => {
        localStorage.setItem(
          SELECTED_WELLBORE_LOCAL_STORAGE_KEY,
          selectedWellbore?.name ?? ""
        );
      });

    return () => {
      wellboreSubscription.unsubscribe();
    };
  }, []);
}
