import { clamp } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCuttings,
  selectCuttingsDepths,
  selectSelectedCutting,
  selectSelectedZoomArea,
  setSelectedCutting,
  useAppDispatch,
} from "../../store";

export interface DepthIndicatorData {
  depthIndex: number;
  position: "top" | "bottom" | "center";
}

export function useCuttingsUpdater(
  setGridView: (gridView: boolean) => void
): [
  number,
  number,
  number,
  (cuttingIndex: number) => void,
  (cuttingIndex: number, position: "top" | "bottom") => void,
  (e: React.WheelEvent) => void,
  (e: KeyboardEvent) => void,
  boolean,
  DepthIndicatorData
] {
  const dispatch = useAppDispatch();
  const cuttings = useSelector(selectCuttings);
  const selectedCutting = useSelector(selectSelectedCutting);
  const depths = useSelector(selectCuttingsDepths);
  const selectedZoomArea = useSelector(selectSelectedZoomArea);

  const [sliderValue, setSliderValue] = useState(0);
  const [depthIndicatorData, setDepthIndicatorData] = useState<
    DepthIndicatorData
  >({ position: "center", depthIndex: 0 });
  const [showDepthIndicator, setShowDepthIndicator] = useState<boolean>(false);

  const sliderTimerRef = useRef<(number | null)[]>([]);
  const depthIndicatorTimerRef = useRef<(number | null)[]>([]);

  const updateCuttingIndex = useCallback(
    (depthIndex: number) => {
      if (sliderTimerRef.current) {
        sliderTimerRef.current.forEach((t) => t && clearTimeout(t));
        sliderTimerRef.current = [];
      }

      let zoomAreaAdjustedIndex = depthIndex;

      if (selectedZoomArea) {
        zoomAreaAdjustedIndex = clamp(
          depthIndex,
          selectedZoomArea.topIdx,
          selectedZoomArea.bottomIdx
        );
      }

      setSliderValue(zoomAreaAdjustedIndex);

      const selectedCutting = cuttings
        ? cuttings[zoomAreaAdjustedIndex]
        : undefined;

      if (selectedCutting) {
        sliderTimerRef.current.push(
          window.setTimeout(() => {
            dispatch(setSelectedCutting(selectedCutting));
            setGridView(false);
          }, 400)
        );
      }
    },
    [cuttings, dispatch, selectedZoomArea, setGridView]
  );

  const updateDepthIndicatorIndex = useCallback(
    (depthIndex: number, position: "top" | "bottom" | "center") => {
      if (depthIndicatorTimerRef.current) {
        depthIndicatorTimerRef.current.forEach((t) => t && clearTimeout(t));
        depthIndicatorTimerRef.current = [];
      }

      setDepthIndicatorData({ depthIndex: depthIndex, position });
      setShowDepthIndicator(true);

      depthIndicatorTimerRef.current.push(
        window.setTimeout(() => {
          setShowDepthIndicator(false);
        }, 1200)
      );
    },
    []
  );

  useEffect(() => {
    if (selectedCutting && cuttings) {
      setSliderValue(cuttings.findIndex((c) => c.id === selectedCutting.id));
    } else {
      setSliderValue(0);
    }
  }, [selectedCutting, cuttings]);

  const min = 0;
  const max = depths.length - 1;

  const onSlideChange = (cuttingIndex: number) => {
    updateCuttingIndex(cuttingIndex);
    updateDepthIndicatorIndex(cuttingIndex, "center");
  };

  const onZoomChange = (cuttingIndex: number, position: "top" | "bottom") => {
    updateDepthIndicatorIndex(cuttingIndex, position);
  };

  const onScroll = (e: React.WheelEvent) => {
    if (e.deltaY > 0) {
      updateCuttingIndex(Math.min(sliderValue + 1, max));
    } else if (e.deltaY < 0) {
      updateCuttingIndex(Math.max(sliderValue - 1, min));
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
      event.preventDefault();
      updateCuttingIndex(Math.max(sliderValue - 1, min));
    } else if (event.key === "ArrowDown" || event.key === "ArrowRight") {
      event.preventDefault();
      updateCuttingIndex(Math.min(sliderValue + 1, max));
    }
  };

  return [
    sliderValue,
    min,
    max,
    onSlideChange,
    onZoomChange,
    onScroll,
    onKeyDown,
    showDepthIndicator,
    depthIndicatorData,
  ];
}
