import { DEFAULT_ADJUSTMENTS } from "../constants";
import OpenSeadragon from "../dependencies/openseadragon";
import { ImageAdjustments } from "../types/types";

export interface Filter {
  item: OpenSeadragon.TiledImage;
  adjustments: ImageAdjustments;
}

export interface FilterOptions {
  filters: Filter[];
  viewer: OpenSeadragon.Viewer;
}

export interface TileLoadedEvent {
  tile: OpenSeadragon.Tile;
  tiledImage: OpenSeadragon.TiledImage;
  image: HTMLImageElement;
  tileRequest: XMLHttpRequest;
  getCompletionCallback: () => () => void;
}

export interface TileDrawingEvent {
  context: CanvasRenderingContext2D;
  eventSource: OpenSeadragon.Viewer;
  rendered: CanvasRenderingContext2D & {
    _filterId?: string;
    _originalImageData?: ImageData;
  };
  tile: OpenSeadragon.Tile;
  tiledImage: OpenSeadragon.TiledImage;
  userData: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const defaultAdjustmentsId = JSON.stringify(DEFAULT_ADJUSTMENTS);

// eslint-disable-next-line
export default function extendOSDWithFiltering(OpenSeadragon: any): any {
  const $ = OpenSeadragon;

  $.Viewer.prototype.setFilterOptions = function (options: FilterOptions) {
    if (!this.filterPluginInstance) {
      options.viewer = this;
      this.filterPluginInstance = new FilterPlugin(options);
    } else {
      this.filterPluginInstance.setOptions(options);
    }
  };

  return $;
}

export class FilterPlugin {
  viewer: OpenSeadragon.Viewer;
  filterIncrement: number;
  filters: Filter[];
  gl: WebGLRenderingContext;
  varLocs: Record<keyof ImageAdjustments, WebGLUniformLocation>;

  constructor(options: FilterOptions) {
    this.viewer = options.viewer;
    this.filterIncrement = 0;
    this.filters = [];
    const [gl, varLocations] = this._createAndConfigureWebGlContext();
    this.gl = gl;
    this.varLocs = varLocations;

    const _this = this; // eslint-disable-line @typescript-eslint/no-this-alias

    this.viewer.addHandler(
      "tile-loaded",
      (this.tileLoadedHandler.bind(
        _this
      ) as unknown) as OpenSeadragon.EventHandler<OpenSeadragon.ViewerEvent>
    );
    this.viewer.addHandler(
      "tile-drawing",
      (this.tileDrawingHandler.bind(
        _this
      ) as unknown) as OpenSeadragon.EventHandler<OpenSeadragon.ViewerEvent>
    );

    this.setOptions(options);
  }

  setOptions(options: FilterOptions): void {
    this.filters = options.filters;

    this.viewer.forceRedraw(); // This will trigger tile-drawing events
  }

  tileLoadedHandler(event: TileLoadedEvent): void {
    const adjustments = this._getFilterAdjustments(event.tiledImage);
    const adjustmentsId = JSON.stringify(adjustments);
    if (adjustments || adjustmentsId === defaultAdjustmentsId) {
      return;
    }
    if (event.image !== null && event.image !== undefined && adjustments) {
      const canvas = window.document.createElement("canvas");
      canvas.width = event.image.width;
      canvas.height = event.image.height;
      const context = canvas.getContext("2d") as CanvasRenderingContext2D;
      context.drawImage(event.image, 0, 0);
      event.tile._renderedContext = context;
      const callback = event.getCompletionCallback();

      this.applyFilter(context, adjustments, callback);
      event.tile._filterId = adjustmentsId;
    }
  }

  tileDrawingHandler(e: TileDrawingEvent): void {
    const adjustments = this._getFilterAdjustments(e.tiledImage);
    const filterId = JSON.stringify(adjustments);
    if (e.rendered._filterId === filterId) {
      return;
    }
    if (!adjustments) {
      if (hasPreviouslyBeenFiltered(e)) {
        resetImageData(e);
      }
      return;
    }

    if (hasPreviouslyBeenFiltered(e)) {
      softlyResetImageData(e);
    } else {
      captureOriginalImageData(e);
    }

    if (e.tile._renderedContext) {
      if (this._tileContainsImageDataForTheCurrentFilter(e, filterId)) {
        copyImageDataFromTileToRendered(e);
        this._updateFilterId(e, filterId);
        return;
      }
      resetTile(e);
    }

    this.applyFilter(e.rendered, adjustments); //  only support one filter with this onCompletionCallback.
    this._updateFilterId(e, filterId);
  }

  applyFilter(
    context: CanvasRenderingContext2D,
    adjustments: ImageAdjustments,
    completionCallback?: () => void
  ): void {
    this.gl.canvas.width = context.canvas.width;
    this.gl.canvas.height = context.canvas.height;
    this.gl.viewport(
      0,
      0,
      this.gl.drawingBufferWidth,
      this.gl.drawingBufferHeight
    );

    this.gl.uniform1f(this.varLocs.brightness, adjustments.brightness / 200);
    this.gl.uniform1f(this.varLocs.saturation, adjustments.saturation / 200);
    this.gl.uniform1f(this.varLocs.contrast, adjustments.contrast / 200);
    this.gl.uniform1f(this.varLocs.exposure, adjustments.exposure / 200);
    this.gl.uniform1i(this.varLocs.invert, adjustments.invert ? 1 : 0);

    this.gl.texImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      context.canvas
    );
    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.finish(); // Would be great if we could make this non-blocking

    context.drawImage(this.gl.canvas, 0, 0);
    completionCallback?.();
  }

  private _createAndConfigureWebGlContext(): [
    WebGLRenderingContext,
    Record<keyof ImageAdjustments, WebGLUniformLocation>
  ] {
    const canvas = document.createElement("canvas");
    const gl = canvas.getContext("webgl") as WebGLRenderingContext;

    const verts = new Float32Array([-1, -1, -1, 1, 1, 1, -1, -1, 1, 1, 1, -1]);
    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, verts, gl.STATIC_DRAW);

    const vertShaderSource = `
        attribute vec2 position;
        varying vec2 texCoords;
        void main() {
          texCoords = (position + 1.0) / 2.0;
          texCoords.y = 1.0 - texCoords.y;
          gl_Position = vec4(position, 0, 1.0);
        }
      `;

    const fragShaderSource = `
        precision highp float;
        varying vec2 texCoords;
        uniform sampler2D textureSampler;
        uniform bool invert;
        uniform float saturation;
        uniform float brightness;
        uniform float exposure;
        uniform float contrast;
        
        void main() {
          vec4 color = texture2D(textureSampler, texCoords);
          float contrastFactor = (1.015 * (contrast + 1.0)) / (1.0 * (1.015 - contrast));
          
          if (invert) {
            color.rgb = 1.0 - color.rgb;
          }
          
          color.rgb = color.rgb + brightness;

          color.rgb = contrastFactor * (color.rgb - 0.5) + 0.5;

          vec3 luminosityFactor = vec3(0.2126, 0.7152, 0.0722);
          vec3 grayscale = vec3(dot(color.rgb, luminosityFactor));
          color.rgb = mix(grayscale, color.rgb, 1.0 + saturation);

          color.rgb = (1.0 + exposure) * color.rgb;
          
          gl_FragColor = color;
        }
      `;

    const vertShader = gl.createShader(gl.VERTEX_SHADER) as WebGLShader;
    const fragShader = gl.createShader(gl.FRAGMENT_SHADER) as WebGLShader;

    gl.shaderSource(vertShader, vertShaderSource);
    gl.shaderSource(fragShader, fragShaderSource);

    gl.compileShader(vertShader);
    gl.compileShader(fragShader);

    const program = gl.createProgram() as WebGLProgram;
    gl.attachShader(program, vertShader);
    gl.attachShader(program, fragShader);

    gl.linkProgram(program);
    gl.useProgram(program);

    const positionLocation = gl.getAttribLocation(program, "position");

    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(positionLocation);

    const texture = gl.createTexture();
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    return [gl, this._getVarLocations(gl, program)];
  }

  private _getVarLocations(
    gl: WebGLRenderingContext,
    program: WebGLProgram
  ): Record<keyof ImageAdjustments, WebGLUniformLocation> {
    type Loc = WebGLUniformLocation;

    return {
      brightness: gl.getUniformLocation(program, "brightness") as Loc,
      saturation: gl.getUniformLocation(program, "saturation") as Loc,
      contrast: gl.getUniformLocation(program, "contrast") as Loc,
      exposure: gl.getUniformLocation(program, "exposure") as Loc,
      invert: gl.getUniformLocation(program, "invert") as Loc,
    };
  }

  private _getFilterAdjustments(
    item: OpenSeadragon.TiledImage
  ): ImageAdjustments | undefined {
    const filterProcessor = this.filters.find((filter) => filter.item === item);
    return filterProcessor?.adjustments;
  }

  private _updateFilterId(e: TileDrawingEvent, filterId: string): void {
    e.rendered._filterId = filterId;
  }

  private _tileContainsImageDataForTheCurrentFilter(
    e: TileDrawingEvent,
    filterId: string
  ): boolean {
    return e.tile._filterId === filterId;
  }
}

function hasPreviouslyBeenFiltered(e: TileDrawingEvent): boolean {
  return !!e.rendered._originalImageData;
}

function resetImageData(e: TileDrawingEvent): void {
  if (e.rendered._originalImageData) {
    e.rendered.putImageData(e.rendered._originalImageData, 0, 0);
    delete e.rendered._filterId;
    delete e.rendered._originalImageData;
  }
}

function softlyResetImageData(e: TileDrawingEvent): void {
  if (e.rendered._originalImageData) {
    e.rendered.putImageData(e.rendered._originalImageData, 0, 0);
  }
}

function captureOriginalImageData(e: TileDrawingEvent): void {
  e.rendered._originalImageData = e.rendered.getImageData(
    0,
    0,
    e.rendered.canvas.width,
    e.rendered.canvas.height
  );
}

function copyImageDataFromTileToRendered(e: TileDrawingEvent): void {
  const imgData = e.tile._renderedContext.getImageData(
    0,
    0,
    e.tile._renderedContext.canvas.width,
    e.tile._renderedContext.canvas.height
  );
  e.rendered.putImageData(imgData, 0, 0);

  resetTile(e);
}

function resetTile(e: TileDrawingEvent): void {
  // @ts-ignore
  delete e.tile._renderedContext;
  // @ts-ignore
  delete e.tile._filterId;
}
