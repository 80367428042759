import * as d3 from "d3";
import { PinnableTooltipData } from "../../types/types";

interface Position {
  y: number;
  x: number;
  rect: DOMRect;
}

interface PositionsData {
  key: string;
  sourcePos: Position;
  tooltipPos: Position;
}

const TOOLTIP_BEAK_HEIGHT = 16;
const TOOLTIPS_LINES_SVG_ID = "pinned-tooltips-lines-svg";
const TOOLTIPS_LINES_CLASSNAME = "pinned-tooltip-line";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addLineAttributes = (line: any) => {
  line
    .attr("class", TOOLTIPS_LINES_CLASSNAME)
    .attr("x1", (d: PositionsData) => d.sourcePos.x)
    .attr("y1", (d: PositionsData) => d.sourcePos.y)
    .attr("x2", (d: PositionsData) => d.tooltipPos.x)
    .attr("y2", (d: PositionsData) => d.tooltipPos.y);
};

const getSourceElementPosition = (
  pinnedTooltipData: PinnableTooltipData
): Position | undefined => {
  const sourceElement = document.querySelector(
    `[data-element-id="${pinnedTooltipData.sourceElementId}"]`
  );

  if (!sourceElement) {
    return undefined;
  }

  const sourceElementRect = sourceElement.getBoundingClientRect();
  return {
    y: sourceElementRect.top + sourceElementRect.height * 0.5,
    x: sourceElementRect.left + sourceElementRect.width * 0.5,
    rect: sourceElementRect,
  };
};

const getPinnedTooltipElementPosition = (
  pinnedTooltipData: PinnableTooltipData
): Position | undefined => {
  const pinnedTooltipElement = document.querySelector(
    `[data-tooltip-id="${pinnedTooltipData.tooltipElementId}"]`
  );

  if (!pinnedTooltipElement) {
    return undefined;
  }

  const pinnedTooltipRect = pinnedTooltipElement.getBoundingClientRect();
  return {
    y: pinnedTooltipData.tooltipAttributes.tooltipAboveMouse
      ? pinnedTooltipRect.top + pinnedTooltipRect.height + TOOLTIP_BEAK_HEIGHT
      : pinnedTooltipRect.top - TOOLTIP_BEAK_HEIGHT,
    x: pinnedTooltipRect.left + pinnedTooltipRect.width * 0.5,
    rect: pinnedTooltipRect,
  };
};

const isPinnedTooltipWithinSourceElement = (
  sourceElemPos: Position,
  tooltipElemPos: Position
) => {
  const isTooltipWithinXBounds =
    sourceElemPos.rect.left < tooltipElemPos.x &&
    tooltipElemPos.x < sourceElemPos.rect.right;
  const isTooltipWithinYBounds =
    sourceElemPos.rect.top <= tooltipElemPos.y &&
    tooltipElemPos.y <= sourceElemPos.rect.bottom;
  return isTooltipWithinXBounds && isTooltipWithinYBounds ? true : false;
};

const retreiveTooltipLineData = (pinnedTooltips: PinnableTooltipData[]) =>
  pinnedTooltips.reduce((result: PositionsData[], pinnedTooltipData) => {
    const sourceElemPos = getSourceElementPosition(pinnedTooltipData);
    const pinnedTooltipElemPos = getPinnedTooltipElementPosition(
      pinnedTooltipData
    );
    if (
      sourceElemPos &&
      pinnedTooltipElemPos &&
      !isPinnedTooltipWithinSourceElement(sourceElemPos, pinnedTooltipElemPos)
    ) {
      result.push({
        key: `${pinnedTooltipData.sourceElementId}-${pinnedTooltipData.tooltipElementId}`,
        sourcePos: sourceElemPos,
        tooltipPos: pinnedTooltipElemPos,
      });
    }
    return result;
  }, []);

export const clearTooltipLines = (): void => {
  d3.select(`#${TOOLTIPS_LINES_SVG_ID}`)
    .selectAll(`.${TOOLTIPS_LINES_CLASSNAME}`)
    .remove();
};

export const drawTooltipLines = (
  pinnedTooltips: PinnableTooltipData[]
): void => {
  const positionsToDraw = retreiveTooltipLineData(pinnedTooltips);
  d3.select(`#${TOOLTIPS_LINES_SVG_ID}`)
    .selectAll(`.${TOOLTIPS_LINES_CLASSNAME}`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .data(positionsToDraw, (d: any) => d.key)
    .join(
      (enter) => enter.append("line").call(addLineAttributes),
      (update) => update.call((_update) => _update.call(addLineAttributes)),
      (exit) => exit.call((_exit) => _exit.remove())
    );
};
