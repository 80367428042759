import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQueryParamState } from "../../hooks";
import { selectSelectedImageType, selectSelectedImageType2 } from "../../store";
import {
  INDEX_OF_IMAGE_A_BACKDROP,
  INDEX_OF_IMAGE_B_BACKDROP,
} from "../sidebarMenu/BackdropSelector";

export const useBackdrops = (
  compareMode: boolean
): [
  (string | undefined)[],
  React.Dispatch<React.SetStateAction<(string | undefined)[]>>
] => {
  const selectedImageType = useSelector(selectSelectedImageType);
  const selectedImageType2 = useSelector(selectSelectedImageType2);

  const [selectedBackdrops, setSelectedBackdrops] = useQueryParamState<
    (string | undefined)[]
  >("backdrops", [selectedImageType, undefined]);

  const showBackdropForImageB =
    compareMode &&
    selectedImageType2 &&
    selectedImageType2 !== selectedImageType;

  /**
   * Sync selectedBackdrops with change of selectedImageType and selectedImageType2
   */
  useEffect(() => {
    setSelectedBackdrops((state) => {
      const newState = [];
      newState[INDEX_OF_IMAGE_A_BACKDROP] = selectedImageType;
      newState[INDEX_OF_IMAGE_B_BACKDROP] = showBackdropForImageB
        ? selectedImageType2
        : undefined;

      const selectableState = state
        .slice(INDEX_OF_IMAGE_B_BACKDROP + 1)
        .filter(
          (backdropType) =>
            backdropType !== selectedImageType &&
            backdropType !== selectedImageType2
        );

      return newState.concat(selectableState);
    });
  }, [
    selectedImageType,
    selectedImageType2,
    setSelectedBackdrops,
    showBackdropForImageB,
  ]);

  return [selectedBackdrops, setSelectedBackdrops];
};
