import React, { FC, useMemo } from "react";
import {
  Toggle as FluentToggle,
  IToggleProps,
  IToggleStyles,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";

import { Colors } from "../../constants";

const toggleStyles: Partial<IToggleStyles> = {
  root: {
    flexDirection: "row-reverse",
    selectors: {
      "& .ms-Toggle-background": {
        background: NeutralColors.gray30 + " !important",
      },
      "&.is-checked .ms-Toggle-background": {
        background: Colors.primary + " !important",
      },
      "&.is-checked .ms-Toggle-thumb": {
        background: NeutralColors.white + " !important",
      },
      "&.is-checked:hover .ms-Toggle-background": {
        background: Colors.darkAlt + " !important",
      },
      "&.is-checked:hover .ms-Toggle-thumb": {
        background: NeutralColors.white + " !important",
      },
      "&:hover .ms-Toggle-thumb": {
        background: Colors.darkAlt + " !important",
      },
      "&.is-disabled .ms-Toggle-background": {
        background: NeutralColors.gray30 + " !important",
      },
      "&.is-disabled .ms-Toggle-thumb": {
        background: NeutralColors.gray60 + " !important",
      },
      "&.is-disabled.is-checked .ms-Toggle-background": {
        background: NeutralColors.gray50 + " !important",
      },
      "&.is-disabled.is-checked .ms-Toggle-thumb": {
        background: NeutralColors.gray30 + " !important",
      },
    },
  },
  label: {
    fontSize: "14px",
    color: NeutralColors.gray130,
    flexGrow: 1,
    margin: "0",
  },
  pill: {
    "&:focus::after": {
      outline: `1px solid ${Colors.primary} !important`,
    },
  },
};

export const Toggle: FC<IToggleProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(() => mergeStyleSets(toggleStyles, styles), [
    styles,
  ]);
  return <FluentToggle styles={mergedStyles} {...restProps} />;
};
