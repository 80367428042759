import { List, mergeStyleSets, SearchBox } from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Colors, DropdownName } from "../../constants";
import { useDropdowns } from "../../context";
import {
  loadNewWellbore,
  selectSelectedWellbore,
  selectWellbores,
  useAppDispatch,
} from "../../store";
import { Wellbore } from "../../types/types";
import { naturalOrderStringAscendingSort } from "../../utils/sorting";

const MAX_NUMBER_OF_SUGGESTIONS = 6;

export const SearchField: FC = () => {
  const dispatch = useAppDispatch();
  const selectedWellbore = useSelector(selectSelectedWellbore);
  const wellbores = useSelector(selectWellbores);
  const { closeDropdown } = useDropdowns();

  const [inputText, setInputText] = React.useState("");
  const [focusIndex, setFocusIndex] = React.useState(0);

  const wellboreSuggestions = filterAndSortSuggestions(wellbores, inputText);

  const itemsList = wellboreSuggestions.map((wb) => ({ name: wb.name }));

  const onRenderCell = (
    item?: { name: string },
    itemIndex?: number
  ): React.ReactNode => {
    return (
      <div data-is-focusable>
        <div
          className={itemIndex === focusIndex ? styles.focused : ""}
          onClick={(ev) => {
            ev.preventDefault();
            itemIndex !== undefined && selectSuggestion(itemIndex);
          }}
          onMouseOver={() =>
            itemIndex !== undefined && setFocusIndex(itemIndex)
          }
        >
          {item?.name}
        </div>
      </div>
    );
  };

  const selectSuggestion = (index: number) => {
    if (index < wellboreSuggestions.length) {
      const wellbore = wellboreSuggestions[index];
      if (wellbore) {
        dispatch(loadNewWellbore(wellbore));
      }
      setInputText("");
      closeDropdown(DropdownName.WELLBORE);
    }
  };

  const onInputChange = (
    event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    if (newValue !== undefined) {
      setInputText(newValue);
      if (newValue.length === 0) setFocusIndex(0);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // stop document keydown event listeners from triggering on text input
    event.nativeEvent.stopImmediatePropagation();
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
      //avoid calculating modulo zero, which results in NaN
      if (wellboreSuggestions.length !== 0) {
        if (event.key === "ArrowUp") {
          setFocusIndex(
            (wellboreSuggestions.length + focusIndex - 1) %
              wellboreSuggestions.length
          );
        } else if (event.key === "ArrowDown") {
          setFocusIndex((focusIndex + 1) % wellboreSuggestions.length);
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <SearchBox
        placeholder={selectedWellbore?.name || "Search wellbore"}
        onSearch={() => selectSuggestion(focusIndex)}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        value={inputText}
        className={styles.searchField}
      />
      {inputText.length > 0 && wellboreSuggestions.length > 0 && (
        <List
          className={styles.dropdown}
          items={itemsList}
          onRenderCell={onRenderCell}
        />
      )}
    </div>
  );
};

const filterAndSortSuggestions = (wellbores: Wellbore[], inputText: string) =>
  wellbores
    .filter((wb) => wb.name.toLowerCase().includes(inputText.toLowerCase()))
    .sort((a, b) => {
      const aIndex = a.name.match(inputText)?.index;
      const bIndex = b.name.match(inputText)?.index;
      if (aIndex === undefined || bIndex === undefined) {
        return 0;
      }
      if (aIndex === bIndex) {
        return naturalOrderStringAscendingSort(a.name, b.name);
      }
      return aIndex - bIndex;
    })
    .slice(0, MAX_NUMBER_OF_SUGGESTIONS);

const styles = mergeStyleSets({
  dropdown: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    transform: "translateY(100%)",
    width: "100%",
    background: "#FFF",
    border: `1px solid ${NeutralColors.gray110}`,
    borderTop: "0",
    cursor: "pointer",
  },
  focused: {
    background: NeutralColors.gray50,
  },

  container: {
    position: "relative",
    zIndex: 2,
  },
  searchField: {
    width: "100%",
    border: `1px solid ${NeutralColors.gray110} !important`,
    borderRadius: "0",
    selectors: {
      "input::placeholder": {
        color: NeutralColors.gray110,
      },

      "& i": {
        color: Colors.primary,
      },
      "&::after": {
        border: "0px",
      },
    },
  },
});
