import { toFloat } from ".";
import { Optional } from "../types";

export enum SortMethod {
  STRING_ASCENDING = "stringAscending",
  STRING_DECENDING = "stringDecending",
  STRING_NUMBER_ASCENDING = "stringValueAscending",
  STRING_NUMBER_DECENDING = "stringValueDecending",
}

const collator = new Intl.Collator(undefined, { numeric: true });

export function sortKeyValueList<T extends { key: string; value: string }>(
  list: T[],
  sortMethod: Optional<SortMethod>
): T[] {
  if (!sortMethod) {
    return list;
  }
  return list.slice(0).sort((a, b) => {
    switch (sortMethod) {
      case SortMethod.STRING_ASCENDING:
        return stringAscendingSort(a.key, b.key);
      case SortMethod.STRING_DECENDING:
        return stringDecendingSort(a.key, b.key);
      case SortMethod.STRING_NUMBER_ASCENDING:
        return stringValueAscendingSort(a.value, b.value);
      case SortMethod.STRING_NUMBER_DECENDING:
        return stringValueDecendingSort(a.value, b.value);
      default:
        return stringValueDecendingSort(a.value, b.value);
    }
  });
}

export function stringAscendingSort(a: string, b: string): number {
  return a.localeCompare(b);
}

export function naturalOrderStringAscendingSort(a: string, b: string): number {
  return collator.compare(a, b);
}

export function stringDecendingSort(a: string, b: string): number {
  return b.localeCompare(a);
}

export function stringValueAscendingSort(a: string, b: string): number {
  return toFloat(a, 0) - toFloat(b, 0);
}

export function stringValueDecendingSort(a: string, b: string): number {
  return toFloat(b, 0) - toFloat(a, 0);
}

export function valueAscendingSort(
  a: Optional<number> | null,
  b: Optional<number> | null
): number {
  if (a && b) {
    return a - b;
  }
  return 0;
}
